import {observable} from 'mobx';
import {Model, Store} from './Base';
import {Campaign} from "./Campaign";
import {FormStore} from "./Form";
import {ContentbundleAgeWildcardStore} from "./ContentbundleAgeWildcard"
import {ContentbundleFolderStore} from './Contentbundle/ContentbundleFolder'

export class Contentbundle extends Model {
    static backendResourceName = 'contentbundle';

    @observable id = null;
    @observable name = '';
    @observable logo = null;
    @observable settings = [];
    //@observable finalContentbundle = null;
   // @observable draftContentbundle = null;

    relations() {
        return {
            forms: FormStore,
            ageWildcards: ContentbundleAgeWildcardStore,
            campaign: Campaign,
            folders: ContentbundleFolderStore
        }
    }
}

export class ContentbundleStore extends Store {
    Model = Contentbundle;
    static backendResourceName = 'contentbundle';
}
