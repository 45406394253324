import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { MenuRow, NavMenu, TopMenu, Logo } from 're-cy-cle';
import { Link, NavLink, Route, withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Icon, Loader, Menu, Responsive, Sidebar } from 'semantic-ui-react';
import { FEATURES } from 'helpers';
import { DirectResult, CeoMarketing } from "helpers/customerCheck";
import { observer } from "mobx-react";
import { getTheme } from "../styles";
import { checkActiveV1, checkActiveV2, NavItem } from '../component/Navigation'; // Relative, so that v1 can also import this file.
import Version from 'spider/component/Version';
import User from 'spider/component/User';
import '../component/Briggs/sidebar.css'
import '../component/Briggs/style/appheader.css'
import { SmallAvatar } from '../component/UserAvatar';
import { t } from '../i18n/index'
import { hasMyResults } from 'helpers/router';
import { HotNetworkz } from 'helpers/customerCheck'

const StyledMenu = styled(Menu)`
    border-radius: 0px !important;
    margin-bottom: 0px !important;
    align-items: center;
`;

const MenuLoaderContainer = styled(Menu.Item)`
    padding-top: 0px !important;
    padding-bottom: 0px !important;

:before {
    display: none;
}
`;

const StyledNavMenu = styled(NavMenu)`
.nav-item:before {
    border-bottom-color: #fff;
}

color: ${getTheme().COLOR_TEXT};
`;

const StyledTopNavMenu = styled(NavMenu)`
.nav-item:before {
    border-bottom-color: ${getTheme().primaryColor};
}
`;

@withTheme
export class MyLogo extends Component {
    static propTypes = {
        theme: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired
    };

    render() {

        return (

            <img src={this.props.theme ? this.props.theme.logo : ''} style={{
                display: 'block',
                maxWidth: '40px',
                maxHeight: '40px',
                width: 'auto',
                height: 'auto',
                alignSelf: "center",
                padding: 0,
                marginTop: -15,
                marginBottom: 10,
            }} />
        );
    }

}

@observer
export class BriggsPlusDiv extends Component {
    static propTypes = {
        active: PropTypes.bool.isRequired,
        toggleActive: PropTypes.func.isRequired
    }

    static defaultProps = {
        active: false
    }

    active = {
        cursor: 'pointer',
        height: '53px',
        width: '54px',
        backgroundColor: getTheme().secondaryColor,
        color: 'black',
        textAlign: 'center',
        transition: 'all .3s ease-in-out',
        fontSize: '16px'
    }
    inactive = {
        cursor: 'pointer',
        height: '53px',
        width: '54px',
        backgroundColor: getTheme().secondaryColor,
        color: 'black',
        textAlign: 'center',
        transition: 'all .3s ease-in-out',
        fontSize: '16px'
    }


    render() {
        const { active, toggleActive } = this.props

        return (
            <div id="logo">
                <div onClick={(event) => {
                    toggleActive()
                    event.stopPropagation();
                    event.nativeEvent.stopPropagation();

                }} className={active ? "logo-plus opened" : "logo-plus closed"}>
                    <div className="logo">
                        <div className="horizontal"></div>
                        <div className="vertical"></div>
                    </div>
                </div>
                <div className="logotext"><span className="c-white font-monserrat">Agency</span> <span
                    className="c-green font-sanchez">Suite</span></div>
            </div>
        )
    }
}

@withTheme
@observer
export class CyLogo extends Component {
    static propTypes = {
        theme: PropTypes.object.isRequired,
    };

    @observable
    isHoovering = false


    @action
    setHoovering(value) {
        this.isHoovering = value
    }


    render() {
        return (
            <div style={this.isHoovering ? { backgroundColor: 'black' } : {}}
                onMouseEnter={() => this.setHoovering(true)}
                onMouseLeave={() => this.setHoovering(false)}
            >
                <img src={this.props.theme ? this.props.theme.cyLogo : ''}
                    style={{
                        display: 'block',
                        maxWidth: '40px',
                        maxHeight: '40px',
                        width: 'auto',
                        height: 'auto',
                        alignSelf: "center",
                        marginTop: 10, marginBottom: 10

                    }} />
            </div>

        );
    }
}

@observer
class DropdownMenu extends Component {

    static propTypes = {
        menu: PropTypes.object.isRequired,
        setMainChapter: PropTypes.func.isRequired,
        setSubChapter: PropTypes.func.isRequired,
    }


    render() {

        const { menu, setSubChapter, setMainChapter } = this.props

        if (menu.link === null) {
            return null
        } else {
            return (
                <li className="has-dropdown">
                    <NavLink onClick={() => {
                        setMainChapter(menu.name)
                        setSubChapter(menu.elements[0].name)
                    }} to={menu.link
                    } className="menu-item">{menu.name}</NavLink>
                    <ul className="dropdown-menu">
                        {menu.elements.map((element) => {
                            if (element.show) {

                                return (<li><NavLink onClick={() => {
                                    setMainChapter(menu.name)
                                    setSubChapter(element.name)
                                }} className="menu-item" to={element.link
                                }>{element.name}</NavLink></li>)
                            } else {
                                return null
                            }
                        })}
                    </ul>
                </li>
            )
        }

    }

}

@withTheme
@withRouter
@observer
export default class AppHeader extends Component {
    static
        propTypes = {
            isAuthenticated: PropTypes.bool.isRequired,
            currentUserName: PropTypes.string.isRequired,
            hasPermission: PropTypes.func.isRequired,
            viewStore: PropTypes.object,
            theme: PropTypes.object.isRequired,
            toggleSideBar: PropTypes.func.isRequired,
            sidebarActive: PropTypes.bool.isRequired,
            history: PropTypes.object.isRequired,

        };

    @observable isMenuVisible = false;
    @observable enableResultsPage = false;

    showMenu = () => this.isMenuVisible = true;
    hideMenu = () => this.isMenuVisible = false;

    @observable MenuItems = [

        {
            name: t('nav.result.overview'),
            link: '/result/team/overview',
            show: (this.hasPermission('allocation.view_team')) || (this.hasPermission('company.change_employee', ['ownrestricteddetails'])) || (this.hasPermission('call.view_appointment')),
            elements: [
                {
                    name: t('nav.result.subnav.myResults'),
                    link: '/result/myResults/',
                    show: hasMyResults(this.props.viewStore.currentUser)
                },
                {
                    name: t('nav.result.subnav.teams'),
                    link: '/result/team/overview',
                    show: this.hasPermission('allocation.change_team', ['branch', 'all'])
                },
                {
                    name: t('nav.result.subnav.call'),
                    link: '/result/call/overview',
                    show: this.hasPermission('call.view_call', ['all'])
                },
                {
                    name: t('nav.result.subnav.callPlanning'),
                    link: '/result/call/planning',
                    show: this.hasPermission('call.add_bucket', ['all'])
                },
                {
                    name: t('nav.result.subnav.overview'),
                    link: '/result/subscription/overview',
                    show: this.hasPermission('subscription.view_subscription', ['branch', 'all'])
                },
                {
                    name: t('nav.result.subnav.creditationImporter'),
                    link: '/result/creditationImporter/overview',
                    show: DirectResult(true) && this.hasPermission('subscription.add_importedcreditationcsv', ['all', 'branch'])
                },
                {
                    name: t('nav.result.subnav.exportArchive'),
                    link: '/result/export/archive',
                    show: this.hasPermission('export.view_filebundle')
                },
                {
                    name: t('nav.result.subnav.purge'),
                    link: '/result/purge',
                    show: this.hasPermission('compliance.view_purge')
                },


            ]
        },
        {
            name: t('nav.client.overview'),
            link: '/client/overview',
            show: this.hasPermission('campaign.change_client'),
            elements: [
                {
                    name: t('nav.client.subnav.client'),
                    link: '/client/overview',
                    show: this.hasPermission('campaign.view_client', 'all')
                },
                {
                    name: t('nav.client.subnav.clientFile'),
                    link: '/client/clientFile/overview',
                    show: DirectResult(true) && this.hasPermission('campaign.view_clientfile', ['all', 'clients'])
                },
                {
                    name: t('nav.client.subnav.campaigne'),
                    link: '/client/campaign/overview',
                    show: this.hasPermission('campaign.change_campaign', 'all')
                },
                {
                    name: t('nav.client.subnav.bwCampaigne'),
                    link: '/client/bwCampaign/overview',
                    show: FEATURES.BW_INTEGRATION && this.hasPermission('campaign.change_campaign', 'all')
                },
                {
                    name: t('nav.client.subnav.contentbundle'),
                    link: '/client/contentbundle/overview',
                    show: this.hasPermission('campaign.change_contentbundle', 'all')
                },
                {
                    name: t('nav.client.subnav.products'),
                    link: '/client/product/overview',
                    show: this.hasPermission('campaign.change_product', 'all')
                },
                {
                    name: t('nav.client.subnav.bwProduct'),
                    link: '/client/bwProduct/overview',
                    show: FEATURES.BW_INTEGRATION && this.hasPermission('campaign.change_campaign', 'all')
                },
                {
                    name: t('nav.client.subnav.metafields'),
                    link: '/client/metafield/overview',
                    show: this.hasPermission('campaign.change_metafield', 'all')
                },
                {
                    name: t('nav.client.subnav.sequences'),
                    link: '/client/sequence/overview',
                    show: this.hasPermission('export.change_sequence', 'all')
                },
                {
                    name: t('nav.client.subnav.clientRefNr'),
                    link: '/client/clientRefNr/overview',
                    show: DirectResult(true) && this.hasPermission('campaign.add_clientrefnr')
                },
                {
                    name: t('nav.client.subnav.bwfailures'),
                    link: '/client/bwImportFailure/overview/',
                    show: FEATURES.BW_INTEGRATION && this.hasPermission('campaign.change_product', 'all')
                },
            ]
        },
        {
            name: t('nav.data.overview'),
            link: '/data/branch/overview',
            show: this.hasPermission('company.change_branch'),
            elements: [
                {
                    name: t('nav.data.subnav.location'),
                    link: '/data/branch/overview',
                    show: this.hasPermission('company.change_branch')
                },

                {
                    name: t('nav.client.subnav.bwCampaigne'),
                    link: '/client/bwCampaign/overview',
                    show: FEATURES.BW_INTEGRATION && this.hasPermission('campaign.change_campaign', 'all')
                },
                {
                    name: t('nav.data.subnav.groups'),
                    link: '/data/group/overview',
                    show: this.hasPermission('auth.change_group')
                },
                {
                    name: t('nav.data.subnav.job'),
                    link: '/data/job/overview',
                    show: FEATURES.BW_INTEGRATION && this.hasPermission('company.change_branch')
                },
                {
                    name: 'API Tokens',
                    link: '/data/token/overview',
                    show: this.hasPermission('auth.add_user', ['all'])
                },
                {
                    name: t('nav.data.subnav.metabase'),
                    link: '/data/metabase/overview',
                    show: this.hasPermission('company.manage_metabase')
                },
            ]
        },
        {
            name: t('nav.personal.overview'),
            link: '/myEmployees/employee/overview',
            show: this.hasPermission('company.view_jobapplication', ['branch', 'all']),
            elements: [
                {
                    name: t('jobApplication.nav.overview'),
                    link: '/myEmployees/jobApplication/overview',
                    show: this.hasPermission('company.change_jobapplication', 'all')
                },

                {
                    name: t('jobApplication.nav.interviews'),
                    link: '/myEmployees/jobapplication/interview/overview',
                    show: this.hasPermission('company.change_jobinterview', ['all', 'branch'])
                },
                {
                    name: t('traineeship.nav.overview'),
                    link: '/myEmployees/traineeship/progressOverview',
                    show: this.hasPermission('allocation.view_scan', ['locktraineeship'])
                },
                {
                    name: t('employee.nav.overview'),
                    link: '/myEmployees/employee/overview',
                    show: this.hasPermission('company.view_employee', ['all', 'branch'])
                },
                {
                    name: t('viaVia.nav.overview'),
                    link: '/myEmployees/viaVia/overview',
                    show: this.hasPermission('company.delete_viavia', ['all'])
                },
                {
                    name: t('employeeSettings.nav.label'),
                    link: '/myEmployees/settings/ReferralSource',
                    show: this.hasPermission('company.change_referralsource', ['all'])
                },
            ]
        },
        {
            name: t('nav.promoterApp.overview'),
            link: '/promoterApp/traineeship/overview',
            show: this.hasPermission('allocation.view_scan', ['lockmanagement']),
            elements: [
                {
                    name: t('nav.data.subnav.trainship'),
                    link: '/promoterApp/traineeship/overview',
                    show: this.hasPermission('traineeship.change_traineeship')
                },
                {
                    name: t('nav.data.subnav.tips'),
                    link: '/promoterApp/tip/overview',
                    show: this.hasPermission('company.change_tip')
                },
                {
                    name: t('nav.data.subnav.shop'),
                    link: '/promoterApp/shopItem/overview',
                    show: this.hasPermission('company.change_shopitem')
                },
                {
                    name: t('nav.data.subnav.contract'),
                    link: '/promoterApp/contract',
                    show: this.hasPermission('company.add_contract')
                },
                {
                    name: t('nav.data.subnav.documents'),
                    link: '/promoterApp/document/overview',
                    show: this.hasPermission('company.add_contract')
                },
                {
                    name: t('nav.data.subnav.videos'),
                    link: '/promoterApp/video/overview',
                    show: this.hasPermission('traineeship.change_traineeship')
                },
                {
                    name: t('nav.data.subnav.coins'),
                    link: '/promoterApp/coins',
                    show: this.hasPermission('traineeship.change_traineeship')
                },
                {
                    name: t('nav.data.subnav.news'),
                    link: '/promoterApp/news/overview',
                    show: this.hasPermission('compliance.view_purge')
                },
                {
                    name: t('nav.data.subnav.milestone'),
                    link: '/promoterApp/milestone/overview',
                    show: this.hasPermission('traineeship.change_traineeship')
                },
                {
                    name: t('nav.data.subnav.promotorIdea'),
                    link: '/promoterApp/PromotorIdea/overview',
                    show: this.hasPermission('company.change_shopitem')
                },
                {
                    name: t('nav.data.subnav.promotorAppMedia'),
                    link: '/promoterApp/promotorappmedia/overview',
                    show: this.hasPermission('traineeship.change_traineeship')
                },


            ]
        },
        {
            name: t('nav.tablets.overview'),
            link: '/tablets/device/overview',
            show: this.hasPermission('company.view_device', ['branch', 'all']),
            elements: [
                {
                    name: t('nav.tablets.overview'),
                    link: '/tablets/device/overview',
                    show: this.hasPermission('company.view_device', ['branch', 'all'])
                },
                {
                    name: t('nav.tablets.tariff.overview'),
                    link: '/tablets/tariff/overview',
                    show: this.hasPermission('company.view_tariffdocument')

                }
            ]
        },
        {
            name: t('nav.wages.overview'),
            link: '/wages/employees/overview',
            show: this.hasPermission('company.view_incedentalexpense', ['branch', 'all']),
            elements: [
                {
                    name: t('nav.wages.freelance'),
                    link: '/wages/freelance/overview',
                    show: this.hasPermission('company.view_periodearning', ['branch', 'all'])
                },
                {
                    name: t('nav.wages.employee'),
                    link: '/wages/employees/overview',
                    show: this.hasPermission('wagemanager.view_earning')
                },
                {
                    name: t('nav.wages.export'),
                    link: '/wages/employees/export',
                    show: this.hasPermission('wagemanager.view_earning', ['all'])
                },
                {
                    name: t('nav.wages.incidental'),
                    link: '/wages/incidental/overview',
                    show: this.hasPermission('company.view_incedentalexpense', ['branch', 'all'])
                },
                {
                    name: t('nav.wages.approval'),
                    link: '/wages/approval/overview',
                    show: this.hasPermission('company.delete_incedentalexpense', ['all'])
                },
            ]
        },
        {
            name: t('nav.account.label'),
            link: '/account/details',
            show: false,
            elements: [
                {
                    name: t('nav.account.details'),
                    link: '/account/details',
                    show: false
                },
                {
                    name: t('nav.account.password'),
                    link: '/account/password',
                    show: false
                },

            ]
        },


    ]

    hasPermission = (...args) => {
        return this.props.hasPermission(...args);
    };

    @observable currentMainChapterBriggs = "Account"
    @observable currentSubChapterBriggs = "Details"

    setMainChapter = (name) => {
        this.currentMainChapterBriggs = name

    }
    setSubChapter = (name) => {
        this.currentSubChapterBriggs = name
    }

    render() {
        const {
            currentUserName,
            isAuthenticated,
            viewStore,
            theme,
        } = this.props;


        if (!isAuthenticated) {
            return (
                <TopMenu>
                    <MenuRow>
                        <MyLogo />
                    </MenuRow>
                    <MenuRow />
                </TopMenu>
            );
        }

        const accountSubmenu = (
            <Route path="/account" render={this.renderAccount} />
        );

        // Hack for submenu's
        let clientSubMenu = null;
        if (window.location.href.indexOf('/client/') !== -1) {
            clientSubMenu = this.clientSubMenu();
        }

        let resultSubMenu = null;
        if (window.location.href.indexOf('/result/') !== -1) {
            resultSubMenu = this.resultSubMenu();
        }

        let dataSubMenu = null;
        if (window.location.href.indexOf('/data/') !== -1) {
            dataSubMenu = this.dataSubMenu();
        }

        let myEmployeesSubMenu = null;
        if (window.location.href.indexOf('/myEmployees/') !== -1) {
            myEmployeesSubMenu = this.myEmployeesSubMenu();
        }

        let promoterAppSubMenu = null;
        if (window.location.href.indexOf('/promoterApp/') !== -1) {
            promoterAppSubMenu = this.promoterAppSubMenu();
        }

        let dpnSubMenu = null;
        if (window.location.href.indexOf('/dpn/') !== -1) {
            dpnSubMenu = this.dpnSubMenu();
        }

        let tabletSubMenu = null;
        if (window.location.href.indexOf('/tablets/') !== -1) {
            tabletSubMenu = this.tabletSubMenu();
        }

        let wagesSubMenu = null;
        if (window.location.href.indexOf('/wages/') !== -1) {
            wagesSubMenu = this.wagesSubMenu();
        }
        let defaultTeamPath = "/result/team/overview"


        // Do not default to view team if the user does not have permission to view these
        if ((!this.hasPermission('allocation.view_team', ['branch', 'all']))) {
            defaultTeamPath = '/result/myResults/'
        } else {
            if((!this.hasPermission('allocation.change_team', ['branch', 'all'])) && this.hasPermission('subscription.view_subscription')){
                defaultTeamPath = "/result/subscription/overview"

            }
        }

        //List because of V1 stack yay
        let metabases = []
        if (viewStore && viewStore.currentUser && viewStore.currentUser.employee) {
            if (viewStore.currentUser.employee && viewStore.currentUser.employee.metabases && viewStore.currentUser.employee.metabases.length > 0) {
                viewStore.currentUser.employee.metabases.forEach((metabase) => {
                    metabases.push(metabase)
                })
            }
        }
        if (viewStore && viewStore.currentUser && viewStore.currentUser.groups && viewStore.currentUser.groups.length > 0) {
            viewStore.currentUser.groups.forEach((group) => {
                if (group.metabases.length > 0) {
                    group.metabases.forEach((metabase) => {
                        let add = true
                        metabases.forEach(metabaseFromList => {
                            if (metabaseFromList.id === metabase.id) {
                                add = false
                            }
                        })
                        if (add) {
                            metabases.push(metabase)
                        }
                    })
                }
            })
        }
        if (viewStore && viewStore.currentUser && viewStore.currentUser.clients && viewStore.currentUser.clients.length > 0) {
            viewStore.currentUser.clients.forEach((client) => {
                if (client.metabases.length > 0) {
                    client.metabases.forEach((metabase) => {
                        let add = true
                        metabases.forEach(metabaseFromList => {
                            if (metabaseFromList.id === metabase.id) {
                                add = false
                            }
                        })
                        if (add) {
                            metabases.push(metabase)
                        }
                    })
                }
            })
        }
        metabases.sort((a, b) => (a.sequenceNumber > b.sequenceNumber) ? 1 : ((b.sequenceNumber > a.sequenceNumber) ? -1 : 0))

        let dashboardSubMenu = null;
        if (window.location.href.indexOf('dashboard') !== -1) {
            dashboardSubMenu = this.dashboardSubMenu(metabases);
        }


        let briggs = false
        if ((window.location.href.indexOf("pttn.com") > -1) || (window.location.href.indexOf("briggsandwalker") > -1) || (window.location.href.indexOf("agency-test") > -1)) {
            briggs = true
        }

        const showTeamHeader = (this.hasPermission('allocation.view_team')) || (this.hasPermission('company.change_employee', ['ownrestricteddetails'])) || (this.hasPermission('call.view_appointment'))

        return (
            <React.Fragment>
                <Responsive maxWidth={Responsive.onlyComputer.minWidth}>
                    <StyledMenu icon inverted>
                        <Menu.Item
                            icon="bars"
                            onClick={() => {
                                console.log("Got inside trigger")
                                this.isMenuVisible = true
                                console.log(this.isMenuVisible)
                            }}
                        />
                        {viewStore.isLoading && (
                            <MenuLoaderContainer position="right">
                                <Loader active inline inverted fitted
                                    size="tiny" />
                            </MenuLoaderContainer>
                        )}
                    </StyledMenu>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        icon='labeled'
                        inverted
                        vertical
                        visible={this.isMenuVisible}
                        style={{display: 'flex', justifyContent: 'space-between', height:'100%'}}
                    >   
                        {this.hasPermission('allocation.view_team') && <Menu.Item
                            as={Link} to="/result/team/overview"
                            onClick={() => this.isMenuVisible = false}
                        >
                            <Icon name="users" />
                            Teams
                        </Menu.Item>}
                        {hasMyResults(this.props.viewStore.currentUser) && <Menu.Item
                            as={Link} to="/result/myResults/"
                            onClick={() => this.isMenuVisible = false}
                        >
                            <Icon name="euro sign" />
                            Results
                        </Menu.Item>}

                        {metabases.length > 0 &&
                            metabases.map(metabase => {
                                return (<Menu.Item
                                    as={Link}
                                    to={"/dashboard/overview/" + metabase.id.toString() + '/view'}
                                    onClick={() => {
                                        window.location.pathname = '/dashboard/overview/' + metabase.id.toString() + '/view'
                                        this.isMenuVisible = false
                                    }}
                                >
                                    <Icon name="clipboard" />
                                    {metabase.name}
                                </Menu.Item>)
                            })
                        }

                        <Menu.Item style={{margin: 0, marginTop: 'auto'}}
                            onClick={() => {
                                viewStore.performLogout();
                                this.isMenuVisible = false;
                            }}
                        >
                            <Icon name="sign-out" />
                            Logout
                        </Menu.Item>
                    </Sidebar>
                </Responsive>

                <Responsive minWidth={Responsive.onlyComputer.minWidth}>

                    {briggs && this.renderTopBarBriggs(this.MenuItems)}
                    {briggs && this.renderBriggsHeader()}
                    {!briggs && <TopMenu>
                        <MenuRow className="top" style={{
                            height: '45px',
                        }}>
                            <StyledTopNavMenu>
                                <Logo to="account/details" style={{ padding: 0 }}>
                                    <img src={theme.textLogo}
                                        style={{
                                            width: 'auto',
                                            height: 30,
                                            maxWidth: 250,
                                            alignSelf: 'center'
                                        }}
                                    />
                                </Logo>
                                <NavItem
                                    title={t('nav.result.overview')}
                                    to={defaultTeamPath}
                                    activePath="/result"
                                    path='/result'
                                    show={showTeamHeader || this.hasPermission('company.view_employee:scoreown')}
                                />
                                <NavItem
                                    title={t('nav.client.overview')}
                                    to={this.hasPermission('campaign.view_campaign') ? "/client/client/overview" : "/client/clientFile/overview"}
                                    path='/client'
                                    activePath="/client"
                                    show={this.hasPermission('campaign.change_client') || this.hasPermission('campaign.view_client', ['clients'])}
                                />
                                <NavItem
                                    title={t('nav.data.overview')}
                                    to={(CeoMarketing() && this.hasPermission('company.view_periodearning', ['all', 'branch'])) ? "/data/expenses/overview" : "/data/branch/overview"}
                                    activePath="/data"
                                    path="/data"
                                    className={checkActiveV1('#data') || checkActiveV2('/data') ? 'active' : ''}
                                    show={this.hasPermission('company.change_branch') || this.hasPermission('company.view_periodearning', ['all', 'branch'])}
                                />
                                <NavItem
                                    title={t('nav.personal.overview')}
                                    to="/myEmployees/employee/overview"
                                    path="/myEmployees"
                                    activePath="/myEmployees"
                                    className={checkActiveV1('#myEmployees') || checkActiveV2('/myEmployees') ? 'active' : ''}
                                    show={this.hasPermission('company.view_jobapplication', ['branch', 'all']) || this.hasPermission('company.view_employee', ['branch', 'all'])}
                                />
                                <NavItem
                                    title={t('nav.promoterApp.overview')}
                                    to="/promoterApp/traineeship/overview"
                                    path="/promoterApp"
                                    activePath="/promoterApp"
                                    className={checkActiveV1('#promoterApp') || checkActiveV2('/promoterApp') ? 'active' : ''}
                                    show={FEATURES.PROMOTER_APP && this.hasPermission('allocation.view_scan', ['lockmanagement'])}
                                />
                                <NavItem
                                    title={t('nav.tablets.overview')}
                                    to="/tablets/device/overview"
                                    path='/tablets'
                                    activePath="/tablets"
                                    show={this.hasPermission('company.view_device', ['branch', 'all'])}
                                />
                                <NavItem
                                    title={t('nav.wages.overview')}
                                    to="/wages/freelance/overview"
                                    path='/wages'
                                    activePath='/wages'
                                    className={checkActiveV2('/wages') ? 'active' : ''}
                                    show={DirectResult(true) && (this.hasPermission('company.view_incedentalexpense', ['branch', 'all']))}
                                />
                                {metabases.length > 0 &&
                                    <NavItem
                                        title={t('nav.dashboards.overview')}
                                        to={"/dashboard/overview/" + metabases[0].id.toString() + "/view"}
                                        path="/dashboard"
                                        activePath="/dashboard"
                                        show={true}
                                    />}
                                {/*
                                 <NavItem
                                    title={t('nav.dpn.overview')}
                                    to="/dpn/location"
                                    activePath="/dpn"
                                    show={this.hasPermission('company.view_employee')}
                                />
                                 <NavItem
                                    title={t('nav.office.overview')}
                                    to="/office"
                                    activePath="/office"
                                    show={this.hasPermission('company.view_employee')}
                                />

                                */}
                            </StyledTopNavMenu>
                            <SmallAvatar user={viewStore.currentUser} />
                            <User
                                viewStore={viewStore}
                                currentUserName={currentUserName}
                                to="/account/details"
                                activePath="/account"
                                show={this.hasPermission('auth.view_user')}
                            />
                            <Version />
                        </MenuRow>
                        <div style={{
                            height: '4px',
                            width: '100%',
                            backgroundColor: this.props.theme.primaryColor,
                        }} />
                        <MenuRow style={{
                            height: '45px',
                            backgroundColor: this.props.theme.secondaryColor,
                            color: getTheme().MENU_TEXT_COLOR
                        }}>
                            {accountSubmenu}
                            {resultSubMenu}
                            {clientSubMenu}
                            {dataSubMenu}
                            {myEmployeesSubMenu}
                            {promoterAppSubMenu}
                            {dpnSubMenu}
                            {tabletSubMenu}
                            {wagesSubMenu}
                            {dashboardSubMenu}
                        </MenuRow>
                    </TopMenu>
                    }
                </Responsive>
            </React.Fragment>
        );
    }

    renderAccount = () => {
        return (
            <StyledNavMenu
            >
                <NavItem
                    title={t('user.account.title')}
                    to="/account/details"
                    path="/account"
                    activePath='account/details'
                    show={this.hasPermission('auth.view_user')}
                />
            </StyledNavMenu>
        );
    }
        ;
    dashboardSubMenu = (metabases) => {
        if (metabases && metabases.length > 0) {
            return (<NavMenu>
                {metabases.map((metabase) => {
                    return (
                        <NavItem
                            title={metabase.name}
                            onClick={() => {
                                window.location.pathname = 'dashboard/overview/' + metabase.id.toString() + '/view'
                            }}

                            to={'/dashboard/overview/' + metabase.id.toString() + '/view'}
                            activePath={'/dashboard/overview/' + metabase.id.toString() + '/view'}
                            path='/dashboard'
                        />
                    )
                })}
            </NavMenu>

            )

        }


    }


    dpnSubMenu = () => {
        return (
            <StyledNavMenu>
                <NavItem
                    title={t('nav.dpn.subnav.location')}
                    to="/dpn/location"
                    path="/dpn"
                    show={this.hasPermission('auth.view_user')}
                />
                <NavItem
                    title={t('nav.dpn.subnav.backoffice')}
                    to="/dpn/backoffice"
                    path="/dpn"
                    show={this.hasPermission('auth.change_own_password_user')}
                />
                <NavItem
                    title={t('nav.dpn.subnav.frontend')}
                    to="/dpn/frontend"
                    path="/dpn"
                    show={this.hasPermission('auth.change_own_password_user')}
                />
            </StyledNavMenu>
        );
    }
        ;

    resultSubMenu() {
        return (
            <StyledNavMenu>
                <NavItem
                    title={t('nav.result.subnav.myResults')}
                    to="/result/myResults/"
                    activePath="/result/myResults"
                    path="/result"
                    show={hasMyResults(this.props.viewStore.currentUser)}
                />
                <NavItem
                    title={t('nav.result.subnav.teams')}
                    to="/result/team/overview"
                    activePath="/result/team"
                    path="/result"
                    show={this.hasPermission('allocation.change_team', ['branch', 'all'])}
                />
                <NavItem
                    title={t('nav.result.subnav.call')}
                    to="/result/call/overview"
                    activePath="/result/call/overview"
                    path="/result"
                    show={this.hasPermission('call.change_appointment', ['all'])}
                />
                <NavItem
                    title={t('nav.result.subnav.callPlanning')}
                    to="/result/call/planning"
                    activePath="/result/call/planning"
                    path="/result"
                    show={this.hasPermission('call.add_bucket', ['all'])}
                />
                <NavItem
                    title={t('nav.result.subnav.overview')}
                    to="/result/subscription/overview"
                    activePath="/result/subscription"
                    path="/result"
                    // TODO check this permission with ceom and hn to see if it works for them
                    show={this.hasPermission('subscription.view_subscription', ['branch', 'all', 'call_planning', 'in_bucket'])}
                />
                {/*<NavItem*/}
                {/*    title={t('nav.result.subnav.iban')}*/}
                {/*    to="/result/subscription/overview?limit=25&currentPage=1&order_by=status%2C-id&.issues.reason%3Ain=iban+missing%2Cknown+iban%2Cduplicate+iban"*/}
                {/*    path="/result"*/}
                {/*    //Weird permission below needed for external permissions third party users T27003*/}
                {/*    show={this.hasPermission('allocation.change_scan', ['branch'])}*/}
                {/*/>*/}
                <NavItem
                    title={t('nav.result.subnav.creditationImporter')}
                    to="/result/creditationImporter/overview"
                    activePath="/result/creditationImporter"
                    path="/result"
                    show={DirectResult(true) && this.hasPermission('subscription.add_importedcreditationcsv', ['all', 'branch'])}
                />
                <NavItem
                    title={t('nav.result.subnav.export')}
                    to="/result/export/overview"
                    activePath="/result/export/overview"
                    path="/result"
                    show={this.hasPermission('export.view_filebundle')}
                />
                <NavItem
                    title={t('nav.result.subnav.exportArchive')}
                    to="/result/export/archive"
                    activePath="/result/export/archive"
                    path="/result"
                    show={this.hasPermission('export.view_filebundle')}
                />
                {/*
                    <NavItem
                        title={t('nav.result.subnav.stats')}
                        to="/#result/stats"
                        path="/result"
                        show={this.hasPermission('allocation.change_team')}
                    />
                    <NavItem
                        title={t('nav.result.subnav.dashboard')}
                        to="/result/dashboard-overview"
                        path="/result"
                        show={this.hasPermission('allocation.change_team')}
                    />
                */}
                <NavItem
                    title={t('nav.result.subnav.purge')}
                    to="/result/purge"
                    activePath="/result/purge"
                    path="/result"
                    //Weird permission below needed for external permissions third party users T27003
                    show={this.hasPermission('compliance.view_purge')}
                />
            </StyledNavMenu>
        );
    }

    clientSubMenu() {
        return (
            <StyledNavMenu>
                <NavItem
                    title={t('nav.client.subnav.client')}
                    to="/client/client/overview"
                    activePath="/client/client/"
                    path="/client/client"
                    show={this.hasPermission('campaign.view_client', 'all')}
                />
                <NavItem
                    title={t('nav.client.subnav.clientFile')}
                    to="/client/clientFile/overview"
                    activePath="/client/clientFile/"
                    path="/client"
                    show={DirectResult(true) && this.hasPermission('campaign.view_clientfile', ['all', 'clients'])}
                />
                <NavItem
                    title={t('nav.client.subnav.campaigne')}
                    to="/client/campaign/overview"
                    activePath="/client/campaign"
                    path="/client"
                    show={this.hasPermission('campaign.change_campaign', 'all')}
                />
                <NavItem
                    title={t('nav.client.subnav.bwCampaigne')}
                    to="/client/bwCampaign/overview"
                    activePath="/client/bwCampaign"
                    path="/client"
                    show={FEATURES.BW_INTEGRATION && this.hasPermission('campaign.change_campaign', 'all')}
                />
                <NavItem
                    title={t('nav.client.subnav.contentbundle')}
                    activePath="/client/contentbundle"
                    to="/client/contentbundle/overview"
                    path="/client"
                    show={this.hasPermission('campaign.change_contentbundle', 'all')}
                />
                <NavItem
                    title={t('nav.client.subnav.products')}
                    to="/client/product/overview"
                    activePath="/client/product"
                    path="/client"
                    show={this.hasPermission('campaign.change_product', 'all')}
                />
                <NavItem
                    title={t('nav.client.subnav.bwProduct')}
                    to="/client/bwProduct/overview"
                    activePath="/client/bwProduct"
                    path="/client"
                    show={FEATURES.BW_INTEGRATION && this.hasPermission('campaign.change_campaign', 'all')}
                />
                <NavItem
                    title={t('nav.client.subnav.metafields')}
                    to="/client/metafield/overview"
                    activePath="/client/metafield"
                    path="/client"
                    show={this.hasPermission('campaign.change_metafield', 'all')}
                />
                <NavItem
                    title={t('nav.client.subnav.sequences')}
                    to="/client/sequence/overview"
                    activePath="/client/sequence"
                    path="/client"
                    show={this.hasPermission('export.change_sequence', 'all')}
                />
                <NavItem
                    title={t('nav.client.subnav.clientRefNr')}
                    to="/client/clientRefNr/overview"
                    activePath="/client/clientRefNr"
                    path="/client"
                    show={DirectResult(true) && this.hasPermission('campaign.view_clientrefnr')}
                />
                {/*<NavItem
                    title={t('nav.client.subnav.wagesTariff')}
                    to="/client/wagesTariff"
                    path="/client"
                    show={this.hasPermission('campaign.change_campaign', 'all')}
                />*/}
                <NavItem
                    title={t('nav.client.subnav.bwfailures')}
                    to="/client/bwImportFailure/overview/"
                    activePath="/client/bwImportFailure"
                    path="/client"
                    show={FEATURES.BW_INTEGRATION && this.hasPermission('campaign.change_product', 'all')}
                />
            </StyledNavMenu>
        );
    }

    dataSubMenu() {
        return (
            <StyledNavMenu>
                <NavItem
                    title={t('nav.data.subnav.location')}
                    to="/data/branch/overview"
                    activePath="/data/branch"
                    path="/data"
                    show={this.hasPermission('company.view_branch')}
                />
                <NavItem
                    title={t('nav.data.subnav.bwLocation')}
                    to="/data/bwBranch/overview"
                    activePath="/data/bwBranch"
                    path="/data"
                    show={FEATURES.BW_INTEGRATION && this.hasPermission('company.change_branch')}
                />
                <NavItem
                    title={t('nav.data.subnav.groups')}
                    to="/data/group/overview"
                    activePath="/data/group"
                    path="/data"
                    show={this.hasPermission('auth.change_group')}
                />
                <NavItem
                    title={t('nav.data.subnav.job')}
                    to="/data/job/overview"
                    activePath="/data/job"
                    path="/data"
                    show={this.hasPermission('company.change_job')}
                />
                <NavItem
                    title={t('nav.data.subnav.alphaWages')}
                    to='/data/alphaWages/overview'
                    activePath="/data/alphaWages"
                    path='/data'
                    show={CeoMarketing() && this.hasPermission('company.view_periodearning', ['all'])}
                />
                <NavItem
                    title={t('nav.data.subnav.expenses')}
                    to='/data/expenses/overview'
                    activePath="/data/expenses"
                    path='/data'
                    show={CeoMarketing() && this.hasPermission('company.view_periodearning', ['all', 'branch'])}
                />
                {DirectResult(true) && <NavItem
                    title='Backbone Importer'
                    to="/data/backbone/overview"
                    activePath="/data/backbone"
                    show={FEATURES.BACKBONE_IMPORTER}
                />}
                <NavItem
                    title='API tokens'
                    to="/data/token/overview"
                    activePath="/data/token"
                    show={this.hasPermission('auth.add_user', ['all'])}
                />
                <NavItem
                    title={t('nav.data.subnav.shiftType')}
                    to="/data/shiftType/overview"
                    activePath="/data/shiftType"
                    path="/data"
                    show={this.hasPermission('allocation.change_shifttype')}
                />
                <NavItem
                    title={t('nav.data.subnav.subscriptionReason')}
                    to="/data/subscriptionReason/overview"
                    activePath="/data/subscriptionReason"
                    path="/data"
                    show={FEATURES.CANCELATION_REASON && this.hasPermission('subscription.view_subscriptionreason')}
                />
                <NavItem
                    title={t('nav.data.subnav.metabase')}
                    to="/data/metabase/overview"
                    activePath="/data/metabase"
                    path="/data"
                    show={this.hasPermission('company.manage_metabase')}
                />
                <NavItem
                    title={t('nav.data.subnav.csv')}
                    to="/data/csv/overview"
                    activePath="/data/csv"
                    path="/data"
                    show={this.hasPermission('company.upload_csv')}
                />
            </StyledNavMenu>
        );
    }

    tabletSubMenu() {
        return (
            <StyledNavMenu>
                <NavItem
                    title={t('nav.tablets.overview')}
                    activePath="/tablets/device"
                    to="/tablets/device/overview"
                    path="/tablets"
                />
                <NavItem
                    title={t('nav.tablets.tariff.overview')}
                    activePath="/tablets/tariff"
                    to="/tablets/tariff/overview"
                    path="/tablets"
                    show={HotNetworkz()}
                />
                {/*Commented out because atm this does not work at all*/}
                {/*<NavItem*/}
                {/*    title={t('nav.tablets.map')}*/}
                {/*    activePath="/tablets/device/map"*/}
                {/*    to="/tablets/device/map"*/}
                {/*    path="/tablets"*/}
                {/*    show={this.hasPermission('company.view_employee', ['all'])}*/}
                {/*/>*/}
            </StyledNavMenu>
        );
    }

    wagesSubMenu() {
        return (
            <StyledNavMenu>
                <NavItem
                    title={t('nav.wages.freelance')}
                    activePath='/wages/freelance'
                    to="/wages/freelance/overview"
                    path="/wages"
                    show={this.hasPermission('company.view_periodearning', ['branch', 'all'])}
                />
                <NavItem
                    title={t('nav.wages.employee')}
                    activePath='/wages/employees/overview'
                    to="/wages/employees/overview"
                    path="/wages"
                    show={this.hasPermission('wagemanager.view_earning')}
                />
                <NavItem
                    title={t('nav.wages.export')}
                    activePath='/wages/employees/export'
                    to="/wages/employees/export"
                    path="/wages"
                    show={this.hasPermission('wagemanager.view_earning', ['all'])}
                />
                <NavItem
                    title={t('nav.wages.incidental')}
                    activePath='/wages/incidental'
                    to="/wages/incidental/overview"
                    path="/wages"
                    show={this.hasPermission('company.view_incedentalexpense', ['branch', 'all'])}
                />
                <NavItem
                    title={t('nav.wages.approval')}
                    activePath='/wages/approval'
                    to="/wages/approval/overview"
                    path="/wages"
                    show={this.hasPermission('company.delete_incedentalexpense', ['all'])}
                />
            </StyledNavMenu>
        );
    }

    promoterAppSubMenu() {
        return (
            <StyledNavMenu>
                <NavItem
                    title={t('nav.data.subnav.trainship')}
                    to="/promoterApp/traineeship/overview"
                    activePath="/promoterApp/traineeship"
                    path="/promoterApp"
                    show={this.hasPermission('traineeship.change_traineeship')}
                />
                <NavItem
                    title={t('nav.data.subnav.tips')}
                    to="/promoterApp/tip/overview"
                    activePath="/promoterApp/tip"
                    path="/promoterApp"
                    show={this.hasPermission('company.change_tip')}
                />
                <NavItem
                    title={t('nav.data.subnav.shop')}
                    to="/promoterApp/shopItem/overview"
                    activePath="/promoterApp/shopItem"
                    path="/promoterApp"
                    show={this.hasPermission('company.change_shopitem')}
                />
                <NavItem
                    title={t('nav.data.subnav.contract')}
                    to="/promoterApp/contract"
                    activePath="/promoterApp/contract"
                    path="/promoterApp"
                    show={this.hasPermission('company.add_contract')}
                />
                <NavItem
                    title={t('nav.data.subnav.documents')}
                    to="/promoterApp/document/overview"
                    activePath="/promoterApp/document"
                    path="/promoterApp"
                    show={this.hasPermission('company.add_contract')}
                />
                <NavItem
                    title={t('nav.data.subnav.videos')}
                    to="/promoterApp/video/overview"
                    activePath="/promoterApp/video"
                    path="/promoterApp"
                    show={this.hasPermission('traineeship.change_traineeship')}
                />
                <NavItem
                    title={t('nav.data.subnav.coins')}
                    to="/promoterApp/coins"
                    activePath="/promoterApp/coins"
                    path="/promoterApp"
                    show={this.hasPermission('company.view_coinaction')}
                />
                <NavItem
                    title={t('nav.data.subnav.news')}
                    to="/promoterApp/news/overview"
                    activePath="/promoterApp/news"
                    path="/promoterApp"
                    show={this.hasPermission('traineeship.change_traineeship')}
                />
                <NavItem
                    title={t('nav.data.subnav.milestone')}
                    to="/promoterApp/milestone/overview"
                    activePath="/promoterApp/milestone"
                    path="/promoterApp"
                    show={this.hasPermission('traineeship.change_traineeship')}
                />
                <NavItem
                    title={t('nav.data.subnav.promotorIdea')}
                    to="/promoterApp/promotorIdea/overview"
                    activePath="/promoterApp/promotorIdea"
                    path="/promoterApp"
                    show={this.hasPermission('company.change_shopitem')}
                />
                <NavItem
                    title={t('nav.data.subnav.promotorAppMedia')}
                    to="/promoterApp/promotorappmedia/overview"
                    activePath="/promoterApp/promotorappmedia"
                    path="/promoterApp"
                    show={this.hasPermission('traineeship.change_traineeship')}
                />
            </StyledNavMenu>
        );
    }

    myEmployeesSubMenu() {
        return (
            <StyledNavMenu>
                <NavItem
                    title={t('jobApplication.nav.overview')}
                    to="/myEmployees/jobApplication/overview"
                    activePath="/myEmployees/jobApplication"
                    path="/myEmployees"
                    show={this.hasPermission('company.change_jobapplication', ['all', 'basic', 'extensive'])}
                />
                <NavItem
                    title={t('jobApplication.nav.interviews')}
                    to="/myEmployees/jobapplication/interview/overview"
                    path="/myEmployees"
                    activePath="/myEmployees/jobapplication/interview"
                    show={this.hasPermission('company.change_jobinterview', ['all', 'branch'])}
                />
                <NavItem
                    title={t('traineeship.nav.overview')}
                    to="/myEmployees/traineeship/progressOverview"
                    activePath="/myEmployees/traineeship"
                    path="/myEmployees"

                    show={this.hasPermission('allocation.view_scan', ['locktraineeship'])}
                />
                <NavItem
                    title={t('employee.nav.overview')}
                    to="/myEmployees/employee/overview"
                    activePath="/myEmployees/employee"
                    path="/myEmployees"
                    show={this.hasPermission('company.view_employee', ['all', 'branch'])}
                />
                <NavItem
                    title={t('viaVia.nav.overview')}
                    to="/myEmployees/viaVia/overview"
                    activePath="/myEmployees/viaVia"
                    show={this.hasPermission('company.delete_viavia', ['all'])}
                />
                <NavItem
                    title={t('employeeSettings.nav.label')}
                    to="/myEmployees/settings/referralsource"
                    activePath="/myEmployees/settings"
                    show={this.hasPermission('company.change_referralsource', ['all'])}
                />
                <NavItem
                    title={t('nav.data.subnav.documents')}
                    to="/myEmployees/document/overview"
                    path="/myEmployees"
                    activePath="/myEmployees/document/"
                    show={DirectResult(true) && this.hasPermission('company.add_contract')}
                />
            </StyledNavMenu>
        );
    }

    renderTopBarBriggs = (MenuItems) => {

        const { viewStore, sidebarActive, toggleSideBar } = this.props
        return (<section id="menu">
            <BriggsPlusDiv active={sidebarActive} toggleActive={toggleSideBar} />

            <ul className="menu menu-left">
                {MenuItems.map(menu => {
                    if (menu.show) {
                        return <DropdownMenu menu={menu} setMainChapter={this.setMainChapter}
                            setSubChapter={this.setSubChapter} />
                    } else {
                        return null
                    }
                })}
            </ul>
            <ul className="menu menu-right">
                <li style={{ marginRight: -20 }}><a className="menu-item"
                    href="https://briggsandwalker.com/contact/">Help</a></li>
                <li><NavLink onClick={() => {
                    this.setMainChapter('Account')
                    this.setSubChapter('Details')
                }} className="menu-item" to={'/account/details'}>Settings</NavLink></li>
                <li className="profiel-item dropdown-item">
                    <img className="profiel" style={{ maxHeight: 32, maxWidth: 32 }}
                        src="https://mir-s3-cdn-cf.behance.net/projects/404/19101567.54b219260b073.png" />
                    <NavLink className="menu-item"
                        onClick={() => {
                            this.setMainChapter(t('nav.account.label'))
                            this.setSubChapter(t('nav.account.password'))
                        }}
                        to={'/account/password'}>{viewStore.currentUser.employee ? viewStore.currentUser.employee.firstName : 'Admin'}</NavLink>
                    <div className="sub-menu">
                        <div className="sub-menu-in">
                            <div className="profiel-icon">
                                <img className="profiel" style={{ maxHeight: 32, maxWidth: 32 }}
                                    src="https://mir-s3-cdn-cf.behance.net/projects/404/19101567.54b219260b073.png" />

                                <span>EMO</span>
                            </div>
                            <span
                                className="name">{viewStore.currentUser.employee ? viewStore.currentUser.employee.name : 'Baas boven baase'}</span>
                            <span
                                className="email">{viewStore.currentUser.email}</span>
                            <span className="company">Emo Life</span> <span
                                className="company-type">Haarlem</span>
                            <button onClick={() => {
                                viewStore.performLogout().then(() => {
                                    this.props.history.push('/');
                                })
                            }} type="button" className="knop knop-primary knop-sm">Uitloggen
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </section>);
    }

    renderBriggsHeader = () => {
        if (this.currentMainChapterBriggs == null || this.MenuItems === null) {
            return null
        } else {
            let mainLink = '/account/details'
            let subLink = '/account/details'
            let mainName = t('nav.account.label')
            let mainSubName = t('nav.account.details')
            if (!(this.MenuItems.find(item => item.name === this.currentMainChapterBriggs) === undefined)) {
                mainLink = this.MenuItems.find(item => item.name === this.currentMainChapterBriggs).link
                subLink = this.MenuItems.find(item => item.name === this.currentMainChapterBriggs).elements.find(element => element.name === this.currentSubChapterBriggs).link
                mainName = this.MenuItems.find(item => item.name === this.currentMainChapterBriggs).name
                mainSubName = this.MenuItems.find(item => item.name === this.currentMainChapterBriggs).elements[0].name

            }
            return (
                <section id="headertop" className="blue-bg">
                    <div className="bw-container">
                        <ul className="breadcrumbs">
                            <li><Link to={mainLink} onClick={() => {
                                this.setMainChapter(mainName)
                                this.setSubChapter(mainSubName)
                            }}>{this.currentMainChapterBriggs}</Link></li>
                            <li><Link to={subLink}>{this.currentSubChapterBriggs}</Link></li>
                        </ul>
                        <h1>{this.currentSubChapterBriggs}
                            <sub>Explanation here? </sub>
                        </h1>
                    </div>
                </section>
            )
        }
    }
}
