import {observable} from 'mobx';
import {Model, Store} from './Base';
import {Client} from './Client';
import {ProductStore} from './Product';
// import { BWProduct } from './Bwtranslation';
import {Contentbundle} from './Contentbundle';
import {BucketStore} from "./Bucket";
import {SubscriptionStore} from "./Subscription";
import {BranchStore} from "./Branch";
import {ActiveWageStructureStore} from "./Wages/activeWageStructure";
import {WageStructure} from './Wages/WageStructure';
import {ContentbundleAgeWildcardStore} from "./ContentbundleAgeWildcard"

export class Campaign extends Model {
    static backendResourceName = 'campaign';

    @observable id = null;
    @observable name = '';
    @observable unit = '€';
    @observable rexUsername = '';
    @observable rexPassword = '';
    @observable callScriptWelcome = '';
    @observable captainsheetContractText = '';
    @observable enableStartDate = false;
    @observable enableEmergencySignature = true;
    @observable enableGenderOther = false;
    @observable requireFirstName = false;
    @observable defaultPaymentInterval = 'P1M';
    @observable rexV2OutboundNumber = null;
    @observable sepa = '';

    relations() {
        return {
            client: Client,
            products: ProductStore,
            // bwIds: BWProduct,
            finalContentbundle: Contentbundle,
            draftContentbundle: Contentbundle,
            callbuckets: BucketStore,
            subscriptions: SubscriptionStore,
            activeWageStructures: ActiveWageStructureStore,
            branches: BranchStore,
            wageStructure: WageStructure,
            ageWildcards: ContentbundleAgeWildcardStore,
        };
    }
}

export class CampaignStore extends Store {
    Model = Campaign;
    static backendResourceName = 'campaign';
}
