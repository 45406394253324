import i18next from 'i18next';
import fr from './fr';
import nl from './nl';
import de from './de';
import en from './en';
import LanguageDetector from 'i18next-browser-languagedetector';

export const LANGUAGES = [
    {
        value: 'nl',
        text: 'Nederlands',
    },
    {
        value: 'fr',
        text: 'Français',
    },
    {
        value: 'de',
        text: 'Deutsch',
    },
    {
        value: 'en',
        text: 'English'
    }
]


const options = {
    // order and fren where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)


    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

}

export function getLanguage() {
    if (!localStorage) {
        return null
    }
    return localStorage.getItem('language');
}

export function setLanguage(language) {
    if (!localStorage) {
        return null;
    }
    return localStorage.setItem('language', language);
}



i18next.use(LanguageDetector).init({
    lng: getLanguage(),
    resources: {
        fr: {description: 'French', translation: fr},
        nl: {description: 'Nederlands', translation: nl},
        de: {description: 'Deutsch', translation: de},
        en: {description: 'English', translation: en},
    },
    fallbackLng: 'en',
    detection: options,
    interpolation: {
        escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
    defaultNS: 'translation',
    fallbackNS: 'spider',
});

export default i18next;
export const t = i18next.t.bind(i18next);

