import { observable } from 'mobx';
import { Store } from '../Base';
import { FileNode } from './FileNode';
import { FileBundle } from './FileBundle';
import { ValueNode } from './ValueNode';
import {
    pickBy,
    mapValues,
    isObject,
    isArray,
    forIn,
    keys,
    camelCase,
} from 'lodash';
import AbstractExportModel from './AbstractExportModel';

// return an object.
// for every val in the data parameter that is an object,
// include that key in the reponse.
// recurse that value
function getRelationTreeFromData(data) {
    const subRels = pickBy(data, val => {
        return isArray(val) || isObject(val);
    });
    return mapValues(subRels, (val, key) => {
        if (isArray(val)) {
            return val.map(getRelationTreeFromData);
        }

        return getRelationTreeFromData(val);
    });
}

// return an array
// recurse until you find a leaf
// from each leaf, report the trace until the root,
// remove duplicates (this step is done in fromBackend)
function getRelationArrayFromTree(tree, output, trace = []) {
    const isLeaf = keys(tree).length === 0;

    if (isLeaf) {
        output.push(trace.join('.'));
        return;
    }

    forIn(tree, (val, key) => {
        const deeperTrace = trace.slice(0);
        // A trace 1 level deeper in the tree
        deeperTrace.push(camelCase(key));

        if (isArray(val)) {
            if (val.length === 0) {
                // Ensure empty arrays are included
                output.push(deeperTrace.join('.'));
            } else {
                val.forEach(subVal =>
                    getRelationArrayFromTree(subVal, output, deeperTrace)
                );
            }
        } else {
            getRelationArrayFromTree(val, output, deeperTrace);
        }
    });
}

export class FileDefinition extends AbstractExportModel {
    static backendResourceName = 'file_definition';

    @observable id = null;
    @observable description = '';
    @observable format = '';
    @observable encoding = '';

    toBackend(options = {}) {
        // I don't know why this is not working, but attach the fileBundle id.
        let tmp = super.toBackend(options);
        if (this.fileBundle !== undefined) {
            tmp['file_bundle'] = this.fileBundle.id;
        }

        if (this.rootNode !== null) {
            tmp['root_node'] = this.rootNode.toBackend(options);
        }

        if (this.filenameValue !== null) {
            tmp['filename_value'] = this.filenameValue.toBackend(options);
        }

        return tmp;
    }

    // Create a new file definition, with everything linked, and a root node
    static newFileDefinitionFactory(fileBundle) {
        const fileDefinition = new FileDefinition({ id: null }, {});

        const rootNode = new FileNode();
        rootNode.elementName = 'root';
        rootNode.children = [];

        fileDefinition.fileBundle = fileBundle;
        fileDefinition.rootNode = rootNode;
        return fileDefinition;
    }

    relations() {
        return {
            fileBundle: FileBundle,
            rootNode: FileNode,
            filenameValue: ValueNode,
        };
    }

    fromBackend({ data, repos, relMapping }) {
        return super.fromBackend({ data, repos, relMapping });

        // TODO: This code is broken since the large mobx-spine refactoring
        /* eslint-disable */

        const relTree = getRelationTreeFromData(data);
        let rels = [];
        getRelationArrayFromTree(relTree, rels);

        this.__parseRelations(rels);

        // define relations
        return super.fromBackend({ data, repos, relMapping });
    }
}

export class FileDefinitionStore extends Store {
    Model = FileDefinition;
    static backendResourceName = 'file_definition';
}
