import { observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import {ShiftWageDefinition} from './ShiftWageDefinition'

export class ShiftWageRule extends Model {
    static backendResourceName = 'shift_wage_rule';

    @observable id = null;
    @observable age = null;
    @observable factor = null;
    @observable createdAt = null;
    @observable updatedAt = null;

     relations() {
        return {
            shiftWageDefinition: ShiftWageDefinition
        };
    }
    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class ShiftWageRuleStore extends Store {
    static backendResourceName = 'shift_wage_rule';

    Model = ShiftWageRule;
}
