import { observable, action } from 'mobx';
import { Store } from '../Base';
import { Filter } from './Filter';
import { ValueNode } from './ValueNode';
import { without } from 'lodash';
import AbstractExportModel from './AbstractExportModel';

export class FileNode extends AbstractExportModel {
    static backendResourceName = 'file_node';

    @observable id = null;
    @observable repeat = null;
    @observable elementName = '';
    @observable file = null;
    @observable formatOptions = {};

    //
    @observable _parentNode = null;

    relations() {
        return {
            value: ValueNode,
            children: FileNodeStore,
            filter: Filter,
        };
    }

    static create(name) {
        const fileNode = new FileNode();
        fileNode.elementName = name;
        fileNode.children = [];
        return fileNode;
    }

    @action
    __parseRelations(activeRelations) {
        // formatOptions is not a relation, but a nested JSON object.
        return super.__parseRelations(
            without(activeRelations, 'formatOptions')
        );
    }
}

export class FileNodeStore extends Store {
    Model = FileNode;
    static backendResourceName = 'file_node';
}
