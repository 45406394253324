import { observable } from 'mobx';
import { Model, Store } from './Base';
import {JobFactorStore} from './jobFactor';
import {PositionStore} from './Position';

export class Job extends Model {
    static backendResourceName = 'job';

    @observable id = null;
    @observable name = '';
    @observable isFreelance = false;
    @observable isTeamPosition = false;
    @observable isDefault = false;

    relations() {
        return {
            positions: PositionStore,
            factors: JobFactorStore
        };
    }
}

export class JobStore extends Store {
    Model = Job;
    static backendResourceName = 'job';
}
