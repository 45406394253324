import { observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Employee } from './Employee';
import { Job } from './Job';

export class Position extends Model {
    static backendResourceName = 'position';

    @observable id = null;
    @observable startDate = null;
    @observable createdAt = null;

    relations() {
        return {
            employee: Employee,
            job: Job,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            createdAt: Casts.date,
        };
    }
}

export class PositionStore extends Store {
    Model = Position;
    static backendResourceName = 'position';
}
