// import moment from 'moment';
import { each, get } from 'lodash';
import { observable } from 'mobx';

// Config set by bootstrap.
export let GOOGLE_MAPS_URL = '';
export let GOOGLE_MAPS_API_KEY = '';
export let PHONE_JOB_APPLICATION = {};
export let AUDIO_SERVER = {}
export let ENGINE_API_BASE_URL = ''
export let PHONE_SUBSCRIPTION = {};
export const FEATURES = observable({
    BACKBONE_IMPORTER: false,
    TEAM_OVERVIEW_COPY_BUTTON: false,
    BW_INTEGRATION: false,
    PROMOTER_APP: false,
    MY_RESULTS: false,
    CUSTOM_FORMS: false,
    COMPANY_NAME: '',
    COMPANY_OWNER: '',
    COMPANY_STREET_NUMBER: '',
    COMPANY_POSTCODE_CITY: '',
    AMOUNT_IN_SUBSCRIPTION_OVERVIEW: false,
    WAGE_PERIOD: 'month',
    HOLD_IN_FREELANCE_WAGES_PERCENTAGE: 0,
    EXTENSIVE_WAGES_OVERVIEW: false,
    FEATURE_CONTRACT_REQUIRED_JOB_APPLICATION_HIRE: true,
    SHOW_TEAM_CAPTAIN: true,
    SHOW_PAYMENT_METHOD: true,
    SHOW_PAYMENT_INTERVAL: true,
    REX_FEATURE: true,
    CANCELATION_REASON: false,
    FALCON_COUNTRY: '',
    DEFAULT_PRODUCT_BLOCKERS: [],
    START_TIME_TEAM_REQUIRED: false,
    SSN_REQUIRED: false,
    PAYROLL_SIGNED_DOCUMENT: ''
});

export let DEVICE_SETTINGS = {};

// TODO: make separate helper files categorized by theme, e.g. "money" and "date"
// This is insane at the moment, sorry man.

export const PUBLIC_URL = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_PUBLIC_URL : undefined;
export const BASE_NAME = process.env.REACT_APP_CY_FRONTEND_ROUTER_BASE_NAME || process.env.REACT_APP_PUBLIC_URL;

export const COMPANY_NAME = process.env.COMPANY_NAME || "CEO Marketing";
export const COMPANY_OWNER = process.env.COMPANY_OWNER || "T.a.v. Nyls Emmink";
export const COMPANY_STREET_NUMBER = process.env.COMPANY_STREET_NUMBER || "Maliebaan 51";
export const COMPANY_POSTCODE_CITY = process.env.COMPANY_POSTCODE_CITY || "3581 CD Utrecht";


export const IS_DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const IS_STAGE = window.location.href.includes('staging');
export const WARNING_REASONS = ['iban missing', 'duplicate iban', 'known iban', 'recruiter iban', 'duplicate email', 'recruiter email', 'duplicate phone', 'recruiter phone'];

export function floatToDecimal(value) {
    return value.toFixed(2).replace('.', ',');
}

export function decimalToFloat(value) {
    if (typeof value !== 'string') {
        return null;
    }
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export const DATE_FORMAT = 'DD-MM-YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_SHORT_FORMAT = 'DD-MM-YY';
export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const DATETIME_SHORT_FORMAT = 'DD-MM-YY HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_RANGE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

export function addDeletedModelInOptions(models, selectedModel) {
    const newModels = models.filter();
    if (selectedModel.deleted) {
        newModels.push(selectedModel);
    }
    return newModels;
}

// Accepts a request error, and transforms it into an array
// of notification messages.
export function formatCustomValidationErrors(err) {
    let output = [];

    each(get(err, 'response.data.errors'), (errors, resource) => {
        each((errors), (errors2, resource) => {
            each((errors2), (errors3, resource) => {
                output = output.concat(
                    errors3.map((e, i) => {
                        return {
                            key: `${resource}${i}`,
                            message: e.message,
                            dismissAfter: 4000,
                        };
                    })
                );
            })
        })
    });
    return output;
}

export function configOverride(bootstrap) {
    ENGINE_API_BASE_URL = bootstrap.engine_api_base_url;
    GOOGLE_MAPS_URL = bootstrap.google_maps_url;
    GOOGLE_MAPS_API_KEY = bootstrap.google_maps_api_key;
    PHONE_JOB_APPLICATION = bootstrap.phone_job_application;
    AUDIO_SERVER = bootstrap.audio_server;
    PHONE_SUBSCRIPTION = bootstrap.phone_subscription;
    DEVICE_SETTINGS = bootstrap.device;
    Object.keys(bootstrap.feature_flag).forEach(feature => FEATURES[feature.toUpperCase()] = bootstrap.feature_flag[feature]);
}

export const DEFAULT_LANGUAGE = process.env.CY_DEFAULT_LANGUAGE || 'nl';
export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const renderDocuments = (country, userLanguage) => {
    let documents = []

    if (country === 'be' && userLanguage === 'fr') {
        country = 'fr-be'
    }

    switch (country) {
        case 'nl':
            documents = [
                {
                    value: 0,
                    text: 'Nederlands paspoort',
                },
                {
                    value: 1,
                    text: 'Toeristenkaart (Jeugd)',
                },
                {
                    value: 2,
                    text: 'Gemeentelijke identiteitskaart',
                },
                {
                    value: 3,
                    text: 'Verblijfsdocument (A-E)',
                },
                {
                    value: 4,
                    text: 'Nieuw verblijfsdocument',
                },
                {
                    value: 5,
                    text: 'W-document',
                },
                {
                    value: 6,
                    text: 'Vreemdelingenpaspoort',
                },
                {
                    value: 7,
                    text: 'Vluchtelingenpaspoort',
                },
                {
                    value: 8,
                    text: 'Buitenlands paspoort met "verblijfsvergunning"',
                },
                {
                    value: 9,
                    text: 'Europese indentiteitskaart',
                },
            ]
            break;
        case 'be':
            documents = [
                {
                    value: 0,
                    text: 'Belgisch paspoort',
                },
                {
                    value: 1,
                    text: 'Verblijfsvergunning',
                },
                {
                    value: 2,
                    text: 'Rijbewijs',
                },
            ]
            break;
        case 'fr':
            documents = [
                {
                    value: 0,
                    text: 'Passeport',
                },
                {
                    value: 1,
                    text: 'Carte d\'identite',
                },
                {
                    value: 2,
                    text: 'Permis de séjour ou travail',
                },
            ]
            break;
        case 'fr-be':
            documents = [
                {
                    value: 0,
                    text: 'Passeport belge',
                },
                {
                   value: 1,
                    text: 'Permis de séjour',
                },
                {
                    value: 2,
                    text: 'Permis de conduire',
                },
            ]
            break;
        case 'de':
            documents = [
                {
                    value: 0,
                    text: 'Reisepass',
                },
                {
                    value: 1,
                    text: 'Personalausweis',
                },
                {
                    value: 2,
                    text: 'Aufenthalts- oder Arbeitserlaubnis',
                },
            ]
            break;
        default:
            documents = [
                {
                    value: 0,
                    text: 'Passport',
                },
                {
                    value: 1,
                    text: 'Identiy card',
                },
                {
                    value: 2,
                    text: 'Residence or work permit',
                },
            ]
            break;
    }
    return documents;
};

// Stolen from re-cy-cle
// lodash's `camelCase` method removes dots from the string; this breaks mobx-spine
export function snakeToCamel(s) {
    if (s.startsWith('_')) {
        return s
    }
    return s.replace(/_\w/g, (m) => m[1].toUpperCase())
}

// lodash's `snakeCase` method removes dots from the string; this breaks mobx-spine
export function camelToSnake(s) {
    return s.replace(/([A-Z])/g, ($1) => '_' + $1.toLowerCase())
}
