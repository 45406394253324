import { observable } from 'mobx';
import { Model, Store } from '../Base';
import { ValueNode } from './ValueNode';

export class FilterNode extends Model {
    static backendResourceName = 'filter';

    // group || valueComparison || valueNull
    @observable type = '';

    // todo different parsing for different types:
    relations() {
        return {
            subfilters: FilterNodeStore,
            value: ValueNode,
            lvalue: ValueNode,
            rvalue: ValueNode,
        };
    }
}

export class FilterNodeStore extends Store {
    Model = FilterNode;
    static backendResourceName = 'filter';
}
