import { action, observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Contentbundle } from './Contentbundle';
import { FormElementStore, TYPE_FILLER, FormElementFiller } from './FormElement';
import { last, uniq } from 'lodash';

export class Form extends Model {
    static backendResourceName = 'form';

    @observable id = null;
    @observable name = '';
    @observable backgroundImage = null;
    @observable _backgroundImage = null;
    @observable translations = '';
    @observable styles = '';
    @observable noPadding = false;

    relations() {
        return {
            contentbundle: Contentbundle,
            formElements: FormElementStore,
        };
    }

    casts() {
        return {
            backgroundImage: Casts.file,
        };
    }

    saveBackgroundImage() {
        if (!this._backgroundImage) {
            return;
        }

        const data = new FormData();

        data.append('background_image', this._backgroundImage, this._backgroundImage.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.api.post(`${this.url}background_image/`, data, { headers }).then(response => {
            this.parse(response.data);
        });
    }

    /**
     * The backend works with ordering and spands, while the UI works with x,y.
     * Here we inject fillers, to satisfy the backend API.
     *
     * Definitely needs improving...
     */
    @action
    injectFillers(cols) {
        this.formElements.remove(this.formElements.filter(fe => fe.type === TYPE_FILLER));
        const maxRow = Math.max(...uniq(this.formElements.map(fe => fe._y)));

        for (let row = 0; row <= maxRow; row++) {
            this.fillRow(cols, row);
            this.formElements.models.forEach((fe, i) => fe.setInput('ordering', i + 1));
        }
    }

    fillRow(cols, row) {
        let expectedX = 0;
        const fillers = [];

        this.formElements.sort();

        this.formElements.filter(fe => fe._y === row).forEach(fe => {
            if (expectedX !== fe._x) {
                // Add filler.
                fillers.push({
                    type: TYPE_FILLER,
                    ordering: fe.ordering,
                    span: fe._x - expectedX,
                    _x: expectedX,
                    _y: row,
                    before: fe.cid,
                    beforeId: fe.id,
                });
            }

            expectedX = fe._x + fe.span;
        });

        fillers.forEach(filler => {
            const cids = this.formElements.map(fe => fe.cid);

            this.formElements.models.splice(cids.indexOf(filler.before), 0, new FormElementFiller(filler));
        });

        if (expectedX !== cols) {
            const cids = this.formElements.map(fe => fe.cid);
            const lastElementThisRow = last(this.formElements.filter(fe => fe._y === row));
            const lastElementPrevRow = last(this.formElements.filter(fe => fe._y === row - 1));
            const lastElement = lastElementThisRow || lastElementPrevRow;
            const lastPos = lastElement ? cids.indexOf(lastElement.cid) : -1;

            this.formElements.models.splice(lastPos + 1, 0, new FormElementFiller({
                type: TYPE_FILLER,
                span: cols - expectedX,
                _x: expectedX,
                _y: row,
            }));
        }

        this.formElements.models.forEach((fe, i) => fe.setInput('ordering', i));
        this.formElements.sort();
    }
}

export class FormStore extends Store {
    Model = Form;
    static backendResourceName = 'form';
}
