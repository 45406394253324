import { observable } from 'mobx';
import { Model, Store } from './Base';
import { User } from './User';
import { Document } from './Document';
import { computed } from 'mobx'

export class SignedDocument extends Model {
    static backendResourceName = 'signed_document';

    @observable id = null;
    @observable signed = null;
    @observable approved = false;

    relations() {
        return {
            signedBy: User,
            document: Document,
            approvedBy: User
        };
    }

    @computed
    get done() {
        if (!this.document.required || this.document.status !== 'published') {
            return true
        } else {
            return this.approved
        }
    }
}

export class SignedDocumentStore extends Store {
    Model = SignedDocument;
    static backendResourceName = 'signed_document';
}
