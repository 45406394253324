import {Casts, Model, Store} from '../Base';
import {observable} from "mobx";
import {EarningStore} from "./Earning";

export class EarningPeriod extends Model {
    static backendResourceName = 'earning_period';

    @observable id = null;
    @observable startDate = null;
    @observable endDate = null;
    @observable frozen = null;
    @observable amountEarned = 0

    relations() {
        return {
            earnings: EarningStore
        }
    }


    casts() {
        return {
            endDate: Casts.date,
            startDate: Casts.date,
        };
    }

    freeze(definitive) {

        let freeze = false
        if (definitive) {
            freeze = true

        }
        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'mark_frozen/', {
                freeze: freeze
            }))
    }

    export() {
        return this.wrapPendingRequestCount(
            this.api.get(this.url + 'export/'))
    }
}

export class EarningPeriodStore extends Store {
    Model = EarningPeriod;
    static backendResourceName = 'earning_period';
}
