export default {
  "tariffDocument": {
    "edit": {
      "title": "Tarifdokument"
    },
    "field": {
      "name": {
        "label": "Name"
      },
      "description": {
        "label": "Beschreibung"
      },
      "file": {
        "label": "Datei"
      }
    }
  },
  "brandingMessage": "Angetrieben von Code Yellow",
  "nav": {
    "account": {
      "label": "Konto",
      "password": "Passwort",
      "details": "Einzelheiten"
    },
    "prompt": {
      "leave": "Sie haben die Änderungen des Eimers der Kampagne {{bucket}} nicht gespeichert, sind Sie sicher, dass Sie die Seite verlassen wollen?"
    },
    "dashboards": {
      "overview": "Dashboards"
    },
    "contentbundle": {
      "overview": "Registrierungsmodule"
    },
    "dpn": {
      "overview": "DPN",
      "subnav": {
        "location": "Standortleiter",
        "backoffice": "BackOffice",
        "frontend": "Frontend"
      }
    },
    "office": {
      "overview": "Büro"
    },
    "product": {
      "overview": "Produkte"
    },
    "client": {
      "overview": "Kunden",
      "subnav": {
        "client": "Kunden",
        "campaigne": "Kampagnen",
        "bwCampaigne": "BW-Kampagnen",
        "contentbundle": "Registrierungsmodule",
        "products": "Produkte",
        "metafields": "Metafields",
        "sequences": "Sequenzen",
        "wagesTariff": "Lohntarif",
        "bwProduct": "BW-Produkte",
        "bwfailures": "BW-Importfehler",
        "clientFile": "Kunden-Dateien",
        "clientRefNr": "Kundenreferenznummern"
      }
    },
    "data": {
      "overview": "Organisation",
      "subnav": {
        "user": "Benutzer",
        "location": "Zweigstellen",
        "bwLocation": "BW-Filialen",
        "groups": "Gruppen",
        "employees": "Mitarbeiter",
        "expenses": "Kosten",
        "job": "Funktionen",
        "devices": "Geräte",
        "trainship": "Praktika",
        "metabase": "Datenbanken",
        "contract": "Vertrag",
        "tips": "Tipps",
        "shop": "Shop",
        "handbook": "Handbuch",
        "privacy": "Erklärung zum Datenschutz",
        "videos": "Videos",
        "dashboard": "Dashboard der Promoter-App",
        "coins": "Münzen",
        "documents": "Dokumente",
        "news": "Nachrichten",
        "milestone": "Meilensteine",
        "promotorIdea": "Projektträger-Ideen",
        "promotorAppMedia": "Projektträger App Media",
        "csv": "CSV",
        "alphaWages": "Löhne alpha",
        "shiftType": "Schichttyp",
        subscriptionReason: 'Abonnement gründe',
      }
    },
    "result": {
      "overview": "Ergebnisse",
      "subnav": {
        "callPlanning": "Planung von Nabel",
        "teams": "Mannschaften",
        "data": "Eingabe",
        "call": "Rufen Sie  an.",
        "callPlan": "Planung von Nabel",
        "export": "Exportieren",
        "overview": "Abonnements",
        "exportArchive": "Archiv exportieren",
        "stats": "Statistiken",
        "dashboard": " Dashboard",
        "purge": "Bereinigen",
        "iban": "Ibanische Fehler",
        "myResults": "Meine Ergebnisse",
        "creditationImporter": "Kredite importieren"
      }
    },
    "personal": {
      "overview": "Mein Personal"
    },
    "promoterApp": {
      "overview": "Projektträger-App"
    },
    "tablets": {
      "overview": "Tabletten",
      "tariff": {
        "overview": "Tarif"
      },
      "map": "Karte"
    },
    "wages": {
      "export": "Exportieren",
      "freelance": "Selbstständige Löhne",
      "employee": "Lohnbuchhalterin",
      "approval": "Zulassungen",
      "overview": "Löhne",
      "incidental": "Übrigens"
    }
  },
  "metabase": {
    "createButton": "Metabase hinzufügen",
    "filter": {
      "both": "Beide",
      "yes": "Ja",
      "no": "Nein"
    },
    "field": {
      "acties": {
        "label": "Aktionen"
      },
      "groups": {
        "label": "Gruppen"
      },
      "sidebarEnabled": {
        "label": "Seitenleiste aktiv"
      },
      "enabled": {
        "label": "Aktiv"
      },
      "sequenceNumber": {
        "label": "Reihenfolge der Navigationsleiste",
        "info": "An welcher Stelle im Verhältnis zu anderen Metadatenbanken der Link zu dieser Metadatenbank in der Kopfzeile der Anwendung erscheinen soll. Eine niedrigere Zahl bedeutet, dass die Metadatenbank in der Computerversion weiter links und in der mobilen Version ganz oben erscheint."
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "dashboardId": {
        "label": "Dashboard-ID",
        "info": "Die Dashboard-ID der Metabase, die in der URL Ihres Dashboards in der Metabase zu finden ist, z. B. metabase-url.com/dashboard/ID"
      },
      "updatedAt": {
        "label": "Letzte Aktualisierung"
      },
      "employees": {
        "label": "Mitarbeiter"
      },
      "clients": {
        "label": "Kunden"
      },
      "params": {
        "label": "Parameter",
        "info": "Für die Parameter, die an das Metabase Dashboard gesendet werden, sollten folgende Namen für die Parameter im Dashboard verwendet werden:\n employee,\n main_branch,\n branches,\n all_branches, \n client",
        "value": {
          "employee": "Mitarbeiterin",
          "mainBranch": "Hauptgeschäftsstelle",
          "branches": "Zweigstellen",
          "all_branches": "Alle zweigstellen",
          "client": "Kunde"
        }
      }
    }
  },
  "form": {
    "startDate": "Anfangsdatum",
    "endDate": "Enddatum",
    "restoreButton": "Wiederherstellen",
    "no": "Nein",
    "yes": "Ja",
    "either": "Beide",
    "search": "Ich suche...",
    "jsonButton": "Import/Export",
    "historyButton": "Geschichte",
    "saveAndBackButton": "Speichern & Zurück",
    "saveAddAnotherButton": "Speichern & neu",
    "saveAndDownloadButton": "Speichern & Herunterladen",
    "copyButton": "Kopieren",
    "syncButton": "Sync",
    "downloadButton": "Herunterladen",
    "cancelButton": "Abbrechen",
    "backButton": "Zurück zu",
    "saveAndHireButton": "Speichern und akzeptieren",
    "saveAndRejectButton": "Speichern und verwerfen",
    "saveAndHireAndConvertButton": "Speichern, akzeptieren und in Mitarbeiter umwandeln",
    "acceptButton": "Akzeptieren",
    "rejectButton": "Abfälle",
    "pendingButton": "Im Vorgriff",
    "reExportButton": "Wiederausfuhr",
    "dateRangePlaceholder": "Wählen Sie...",
    "modalConfirmDelete": {
      "title": "Hartes Löschen",
      "content": "Sie sind dabei, diesen Artikel zu löschen. Sind Sie sicher?"
    },
    "reconnectButton": "Verbinden Sie sich mit dem Anrufserver",
    "autoFillButton": "Autofill",
    "fileType": {
      "image": "Bild",
      "any": "",
      "none": "Leere"
    },
    "validation": {
      "error": "Es wurden Validierungsfehler gefunden"
    },
    "check": "Kontrolliert",
    "addButton": "Hinzufügen",
    "multiPick": {
      "searchPlaceholder": "Wählen Sie...",
      "selectedText": "$1 ausgewählt aus den $2",
      "noneSelectedText": "Keine ausgewählt",
      "selectAllButton": "Alles",
      "selectNoneButton": "Nichts"
    },
    "importButton": "Importieren",
    "saveButton": "Speichern Sie",
    "undoRejectionButton": "Aufhebung der Ablehnung",
    "undoHireButton": "Aufhebung der Annahme",
    "closeButton": "Schließen Sie",
    "saveAndNewButton": "Speichern & neu",
    "saveLoadingButton": "Sparen...",
    "sendButton": "Senden Sie",
    "editButton": "Anpassen",
    "viewButton": "Siehe",
    "actions": "Aktionen",
    "deleteButton": "entfernen",
    "searchLabel": "Suche",
    "applyButton": "Bewerbung",
    "selectPlaceholder": "Wählen Sie...",
    "deleteConfirmation": "Sind Sie sicher, dass Sie {{name}} löschen wollen?",
    "restoreConfirmation": "Sind Sie sicher, dass Sie {{name}} wiederherstellen wollen?",
    "typeToSearchText": "Tippen Sie für die Suche...",
    "copyToClipboardTooltip": "In die Zwischenablage kopieren",
    "notifications": {
      "saveSuccess": "Erfolgreich gespeichert",
      "deleteSuccess": "Erfolgreich gelöscht",
      "restoreSuccess": "Erfolgreich wiederhergestellt",
      "saveError": "Fehler beim Speichern ({{status}})",
      "saveValError": "Nicht alle Informationen werden korrekt ausgefüllt",
      "saveAuthError": "Ungültige Berechtigungsnachweise, versuche erneutes einloggen",
      "newAppVersion": "Neue Version verfügbar, klicken Sie, um die Seite so schnell wie möglich neu zu laden",
      "blockedCustomer": "Der Kunde ist für einen Mitarbeiter gesperrt",
    },
    "validationErrors": {
      "blank": "Feld ist erforderlich",
      "null": "Feld ist erforderlich",
      "max_digits": "Maximaler Feldwert überschritten",
      "max_whole_digits": "Maximaler Feldwert überschritten",
      "max_value": "Höchstwert überschritten",
      "invalid": "Ungültiger Wert",
      "reset_code_invalid": "Ungültiger Reset-Code",
      "password_too_common": "Passwort ist zu häufig",
      "invalid_token": "Ungültiges Token",
      "unique": "Feld ist nicht eindeutig",
      "unique_together": "Feld ist nicht eindeutig",
      "unknown": "Unbekannter Fehler im Feld",
      "date_mismatch": "Enddatum darf nicht vor Startdatum liegen",
      "routing_error": "Die Route zwischen dieser und der vorherigen Aktivität konnte nicht berechnet werden.",
      "geo": {
        "failed-error": "Unbekannter Fehler bei der Standortsuche",
        "failed-no-results": "Konnte den Standort nicht finden",
        "failed-multiple-results": "Zu viele Standorte gefunden",
        "failed-no-housenumber": "Habe keine bestimmte Hausnummer gefunden",
        "not_precise": "Standort gefunden, aber nicht genau"
      },
      "invalid_file_type": "Falscher Dateityp des Dokuments. Akzeptierte Dateitypen: {{types}}."
    },
    "datePlaceholder": "Datum und Uhrzeit",
    "draftJs": {
      "style": {
        "BOLD": {
          "label": "Kühn"
        },
        "CODE": {
          "label": "Monospace"
        },
        "ITALIC": {
          "label": "Kursiv"
        },
        "UNDERLINE": {
          "label": "Unterstreichen Sie"
        },
        "blockquote": {
          "label": "Blockquote"
        },
        "code-block": {
          "label": "Code-Block"
        },
        "header-five": {
          "label": "H5"
        },
        "header-four": {
          "label": "H4"
        },
        "header-one": {
          "label": "H1"
        },
        "header-six": {
          "label": "H6"
        },
        "header-three": {
          "label": "H3"
        },
        "header-two": {
          "label": "H2"
        },
        "ordered-list-item": {
          "label": "OL"
        },
        "unordered-list-item": {
          "label": "UL"
        }
      }
    }
  },
  "purge": {
    "id": "Id",
    "name": "Name",
    "subscription_count": "Anzahl der Abonnements",
    "created_at": "Erstellt am",
    "permanently_deleted_at": "Dauerhaft gelöscht am",
    "download": "Herunterladen",
    "permenantly_deleted_at": "Vorübergehend gelöscht am"
  },
  "earningPeriod": {
    "modal": {
      "confirmExport": "Dies ist ein endgültiger Export und friert die Löhne der Periode mit dem Startdatum {{date}} ein, sind Sie sicher?"
    },
    "filter": {
      "yes": "Ja",
      "no": "Keine",
      "both": "Beide"
    },
    "field": {
      "frozen": {
        "label": "Löhne und Gehälter eingefroren"
      },
      "Acties": {
        "label": "Aktionen"
      },
      "id": {
        "label": "Id"
      },
      "endDate": {
        "label": "Enddatum des Zeitraums"
      },
      "amount": {
        "label": "Gesamter Zahlungszeitraum"
      },
      "startDate": {
        "label": "Startdatum Zeitraum"
      }
    }
  },
  "earning": {
    "inspection": {
      "dropOutReasons": "{{amount}} Ausfallgründe",
      "creditReasons": "{{amount}} Kreditgründe",
      "earned": "Verdient",
      "bruttoDeclarations": "Brutto-Erklärungen",
      "netDeclarations": "Netto-Erklärungen",
      "donors": "Spender",
      "scores": "Spielstände",
      "max": "Max",
      "average": "Durchschnittliche Spende",
      "enrollmentResults": "Ergebnisse der Registrierung",
      "week": "Woche",
      "date": "Datum",
      "client": "Kunde & Kampagne",
      "bruto": "Brutto",
      "attrition": "Versagen",
      "net": "Netz",
      "total": "Insgesamt",
      "credit": "Kredite",
      "incidental": "Nebenkosten",
      "reason": "Grund",
      "type": "Typ",
      "amount": "Betrag",
      "bonus": "Bonus"
    },
    "invoice": {
      "validDate": {
        "label": "Verfallsdatum:"
      },
      "approveEmployee": {
        "label": "Zeitraum ist korrekt"
      }
    },
    "week": {
      "label": "Woche"
    },
    "weekPeriod": {
      "label": "Woche {startWeekNumber} - %{endWeekNumber}"
    },
    "field": {
      "Acties": {
        "label": "Aktionen"
      },
      "id": {
        "label": "ID"
      },
      "amount": {
        "label": "Löhne"
      },
      "totalEarned": {
        "label": "Gesamtpreis"
      },
      "creditations": {
        "label": "Kredite"
      },
      "datetimeApprovedByEmployee": {
        "label": "Zugelassener Mitarbeiter"
      },
      "withheldEarningAmount": {
        "label": "Beibehalten"
      },
      "datetimeApprovedByCompany": {
        "label": "Zugelassenes Unternehmen"
      },
      "nettoSubscriptionAmount": {
        "label": "Nettozahl"
      },
      "sumSubscriptionPrices": {
        "label": "Anmeldegebühr"
      },
      "currentEmployeeFactor": {
        "label": "Faktor (aktuell)"
      },
      "sumCreditationPrices": {
        "label": "Kredit (Abonnement) Betrag"
      },
      "incidentalWages": {
        "label": "Entschädigung für Nebenkosten"
      },
      "earningWithoutVat": {
        "label": "Gesamtbetrag ohne MwSt."
      },
      "employeeId": {
        "label": "Mitarbeiter-ID"
      },
      "earning": {
        "label": "Verdienter Betrag"
      },
      "updatedAt": {
        "label": "Letzte Änderung"
      },
      "startedAt": {
        "label": "Zeitraum"
      },
      "startDate": {
        "label": "Beginnende Tagesperiode"
      },
      "contractSigned": {
        "label": "Vertrag unterzeichnet"
      }
    },
    "sync": "Berechnen Sie das Ergebnis für diesen Zeitraum neu",
    "syncRunning": "Die Löhne werden neu berechnet, aktualisieren Sie die Seite in ein paar Minuten",
    "filter": {
      "internalSignatureSigned": "Vertrag unterzeichnet internes System",
      "externalSignatureSigned": "Vertrag unterzeichnet externes System"
    }
  },
  "coin": {
    "field": {
      "id": "Id",
      "total": "Insgesamt",
      "coin": {
        "slug": "Schnecke",
        "task": "Münzaufgabe"
      },
      "employee": {
        "id": "id",
        "name": "Mitarbeiterin",
        "coins": "Verdiente Münzen",
        "points": "Verdiente Punkte",
        "createdAt": "Erstellt am"
      },
      "performedAt": {
        "label": "Verdient am"
      }
    }
  },
  "product": {
    "deleteTooltip": "Produkt entfernen",
    "restoreTooltip": "Produkt wiederherstellen",
    "viewTooltip": "Produkt ansehen",
    "editTooltip": "Produkt bearbeiten",
    "copyTooltip": "Produkt kopieren",
    "noBriggsAndWalkerConnected": "Keine IDs verknüpft",
    "nav": {
      "overview": "Produkte"
    },
    "page": {
      "overview": {
        "title": "Produkte"
      }
    },
    "createButton": "Produkt hinzufügen",
    "save": {
      "error": "Das Produkt konnte nicht gespeichert werden!",
      "success": "Das Produkt wird gelagert"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "factor": {
        "label": "Facteur"
      },
      "icon": {
        "label": "Icon",
        "helpText": "Ziehen Sie ein Bild oder klicken Sie hier, um es hochzuladen, mit einer maximalen Größe von 4096 Pixeln in beide Richtungen."
      },
      "blockers": {
        "label": "Blocker",
        "placeholder": "Blocker...",
        "selectedText": "$1 von $2 Blocker",
        "noneSelectedText": "Blocker auswählen",
        "checkAll": "Alle auswählen",
        "uncheckAll": "Alle abwählen"
      },
      "metafields": {
        "label": "Metafields",
        "placeholder": "Metafields...",
        "selectedText": "$1 von $2 metafields",
        "noneSelectedText": "metafields auswählen",
        "checkAll": "Alle auswählen",
        "uncheckAll": "Alle abwählen"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "min_age": {
        "label": "Erforderliches Mindestalter",
        "placeholder": "Mindestalter..."
      },
      "client": {
        "label": "Kunde",
        "placeholder": "Kunde..."
      },
      "type": {
        "label": "Typ",
        "placeholder": "Art der...",
        "donation": "Spende",
        "subscription": "Abonnement",
        "contract": "Vertrag"
      },
      "includeScanFiles": {
        "label": "TabletScans hinzufugen in Willkommensmail"
      },
      "welcomeEmailTemplate": {
        "label": "Willkommens-E-Mail-Vorlage",
        "placeholder": "Willkommens-E-Mail-Vorlage...",
        "help": "Die Vorlagen-ID einer mailjet-Transaktionsvorlage"
      },
      "textVerificationMessage": {
        "label": "Bestätigungsnachricht",
        "placeholder": "Bestätigungsnachricht...",
        "help": "Bestätigungsnachricht, die per SMS gesendet wird. Beispiel:\n  Ihr eindeutiger SMS bestätigungscode lautet {code}"
      },
      "max_start_date_delta": {
        "label": "Max. späterer Starttermin",
        "placeholder": "z.B. für 2 Monate: P2M",
        "error": {
          "incorrect": "Format ist nicht korrekt"
        }
      },
      "bw_id": {
        "label": "Briggs & Walker-Kupplung",
        "placeholder": "Briggs und Walker id..."
      },
      "paymentInterval": {
        "label": "Frequenz",
        "info": "Frequenz-Infos",
        "value": {
          "null": "Einmalig",
          "P1D": "Täglich",
          "P1W": "Wöchentlich",
          "P1M": "Monatlich",
          "P3M": "Jedes Quartal",
          "P6M": "Halbjährlich",
          "P1Y": "Jährlich"
        }
      },
      "paymentIntervalEnabled": {
        "label": "Spezifische Produkthäufigkeit"
      },
      "welcomeSmsEnabled":{
        label: 'Verwenden Sie eine Willkommens-SMS'
      },
      "welcomeSmsText":{
        label: 'Willkommen SMS-Text'
      },
      "welcomeSmsSender":{
        label: 'Willkommen SMS Absender'
      },
    },
    "create": {
      "title": "Ein Produkt erstellen"
    },
    "edit": {
      "title": "Produkt anpassen",
      "basicTitle": "Grundlegende Daten",
      "blockerTitle": "Blocker",
      "metafieldTitle": "Metafelder"
    }
  },
  "blocker": {
    "field": {
      "id": {
        "label": "ID"
      },
      "issue_model": {
        "label": "Modell",
        "placeholder": "Modell..."
      },
      "issue_reason": {
        "label": "Grund",
        "placeholder": "Der Grund..."
      },
      "is_export_blocking": {
        "label": "Sperrung der Ausfuhr?",
        "placeholder": "Sperrung der Ausfuhr..."
      }
    }
  },
  "fileDefinition": {
    "save": {
      "error": "Die Definition konnte nicht gespeichert werden!",
      "success": "Definition wird gespeichert"
    },
    "import": {
      "error": "Definition konnte nicht importiert werden!",
      "success": "Die Definition wurde importiert"
    },
    "choice_operator": {
      "exact": "ist gleich",
      "exact_not": "ist nicht gleich",
      "lt": "kleiner ist als",
      "lte": "ist kleiner/gleich",
      "gt": "größer ist als",
      "gte": "größer/gleich ist als"
    },
    "group_combinator": {
      "and": "und",
      "or": "oder"
    },
    "convertCase": {
      "uppercase": "Großbuchstaben",
      "lowercase": "Kleinschreibung",
      "titlecase": "Großbuchstaben pro Wort"
    },
    "location": {
      "left": "links",
      "right": "Rechts",
      "both": "Beide",
      "everywhere": "Überall"
    },
    "repeat": {
      "label": "Wiederholen Sie",
      "no": "Nein",
      "yes": "Ja"
    },
    "jsonModal": {
      "title": "Definition bearbeiten",
      "instructions": "Sie können den unten stehenden Text kopieren und in einen anderen Export einfügen. Klicken Sie auf \"Importieren\", um sich zu bewerben. Anschließend können Sie den neuen Export speichern."
    },
    "overview": {
      "title": "Datei-Definitionen"
    },
    "field": {
      "format": {
        "label": "Format"
      },
      "description": {
        "label": "Beschreibung"
      }
    },
    "edit": {
      "title": "Dateidefinition bearbeiten"
    }
  },
  "filterOption": {
    "save": {
      "error": "Filter kann nicht gespeichert werden!",
      "success": "Filter wird gespeichert"
    }
  },
  "hardDelete": {
    "header": "Wollen Sie sie wirklich löschen?",
    "content": "Diese Aktion kann nicht rückgängig gemacht werden!",
    "confirm": "Ja",
    "cancel": "Nein"
  },
  "subscription": {
    "edit": {
      "productInformation": "Informationen zum Produkt",
      "metafields": "Metafields",
      "cancelations": "Annullierungen",
      "enrollment": "Anmeldung",
      "others": "Sonstiges",
      "recruitInformation": "Informationen zur rekrutierung",
      "remarks": "Anmerkungen",
      "personalInformation": "Persönliche informationen",
      "addressInformation": "Informationen zur adresse",
      "financialInformation": "Finanzielle informationen",
      "contactInformation": "Kontakt information",
      "subscriptionId": "Abonnement ID"
    },
    "creditationImporter": {
      "title": "Gutschrift Importeur",
      "importer": {
        "fileButton": "Datei auswählen",
        "buttonTitle": "Beginn der Einfuhr",
        "dropFiles": "Hochladen von Dateien"
      }
    },
    "calls": {
      "modal": {
        "buttonTitle": "Telefonanrufe",
        "caller": "Anrufer",
        "start": "Start",
        "duration": "Dauer",
        "voicelogs": "Voicelogs"
      },
      "modalTitle": "Telefonanrufe an Teilnehmer"
    },
    "cancel": {
      "modalTitle": "Kündigung des Abonnements",
      "saveCancelation": "Annullierung Oplaan",
      "details": "Details zur Stornierung"
    },
    "termination": {
      "modalTitle": "Abonnement nach Erfolg kündigen",
      "saveTermination": "Beendigung der Auflösung",
      "title": "Abgeschlossen nach Auszahlung"
    },
    "nav": {
      "overview": "Abonnenten"
    },
    "page": {
      "overview": {
        "title": "Abonnenten"
      }
    },
    "overviewMock": {
      "title": "Test-Abonnenten"
    },
    "download": {
      "button": "CSV"
    },
    "error": {
      "cancelationWithoutReason": "Bitte geben Sie einen Grund für die Stornierung an"
    },
    "field": {
      "updatedAt": {
        "label": "Aktualisiert am"
      },
      "id": {
        "label": "ID"
      },
      "creditation": {
        "label": "Gutschrift",
        "options": {
          "both": "Beide",
          "false": "Nein",
          "true": "Ja"
        }
      },
      "systemStatus": {
        "label": "Systemstatus"
      },
      "appointmentStatus": {
        "label": "Termin status"
      },
      "subscriber": {
        "lastName": "Nachname"
      },
      "iban": {
        "label": "Iban"
      },
      "amount": {
        "label": "Betrag"
      },
      "quantity": {
        "label": "Anzahl der"
      },
      "total": {
        "label": "Insgesamt",
        "completed": "Bereit",
        "deleted": "Entfernt",
        "bucket": "In nabelrij",
        "validation": "Bereit für die Validierung",
        "queue": "Bereit für die Nabelreihe",
        "export": "Bereit für den Export",
        "attention": "Aufmerksamkeit erforderlich"
      },
      "product": {
        "label": "Produkt",
        "placeholder": "Produkt..."
      },
      "cancelPaymentRecruiter": {
        "label": "Stornierung der Zahlung an den Personalvermittler"
      },
      "status": {
        "label": "Status",
        "placeholder": "Status...",
        "value": {
          "needs validation": "bereit für die Validierung",
          "needs attention": "Aufmerksamkeit erforderlich",
          "deleted": "Gelöscht",
          "ready for export": "bereit zum Export",
          "in call bucket": "in der Nabelreihe",
          "completed": "bereit",
          "canceled": "abgesagt",
          "queue": "bereit für die Nabelreihe",
          "creditation": "Gutschrift"
        }
      },
      "cancelled": {
        "label": "Cancelled",
        "options": {
          "all": "All",
          "only": "Only cancelled",
          "without": "Without cancelled"
        }
      },
      "campaign": {
        "label": "Kampagne"
      },
      "client": {
        "label": "Kunde",
        "placeholder": "Kunde..."
      },
      "clientRefNr": {
        "label": "Kunde reference number",
        "placeholder": "Kunde reference number..."
      },
      "error": {
        "label": "Fehler",
        "subscription": "Abonnement-Fehler",
        "subscriber": "Fehler des Teilnehmers"
      },
      "date": {
        "label": "Datum",
        "placeholder": "Datum..."
      },
      "is_imported": {
        "label": "Wurde importiert",
        "placeholder": "Wurde importiert..."
      },
      "entryRemarks": {
        "label": "Eingabe beachten",
        "placeholder": "Eingabe beachten..."
      },
      "callRemarks": {
        "label": "Anmerkung nabel",
        "placeholder": "Anmerkung zum..."
      },
      "clientRemarks": {
        "label": "Kommentare der Kunden",
        "placeholder": "Kommentare von Kunden..."
      },
      "teamMember": {
        "label": "Projektträger",
        "placeholder": "Projektträger..."
      },
      "endDate": {
        "label": "Datum der Beendigung"
      },
      "paymentMethod": {
        "label": "Zahlungsmethode",
        "placeholder": "Art der Bezahlung...",
        "value": {
          "invoice": "Rechnung",
          "directDebit": "Lastschrifteinzug",
          "acceptgiro": "Lastschriftverfahren",
          "cash": "Bargeld",
          "direct debit": "Direkter Durchfluss"
        }
      },
      "datetimeOfRegistration": {
        "label": "Datum des Schreibens"
      },
      "datetimeOfTermination": {
        "label": "Datum des erfolgreichen Abschlusses des Abonnements"
      },
      "ignoreErrors":{
        "label": "Abonnementfehler bei Statusänderung ignorieren"
      },
      "datetimeOfCancelation": {
        "label": "Datum der Annullierung",
        "placeholder": "Datum der Annullierung..."
      },
      "reasonForCancelation": {
        "label": "Beschreibung für die Annullierung",
        "placeholder": "Beschreibung für die Annullierung..."
      },
      "overrideCallAmountCheck": {
        "label": "Überschreitung der maximalen Anzahl von Anrufversuchen"
      },
      "startDate": {
        "label": "Datum des Inkrafttretens",
        "error": {
          "exceeding": "Zu weit in der Zukunft"
        }
      },
      "employee": {
        "name": "Name des Mitarbeiters"
      },
      "paymentInterval": {
        "label": "Frequenz",
        "changedOneTimer": "Umgewandelt in einmalig",
        "value": {
          "null": "Einmalig",
          "P1D": "Täglich",
          "P1W": "Wöchentlich",
          "P1M": "Monatlich",
          "P3M": "Jedes Quartal",
          "P6M": "Halbjährlich",
          "P1Y": "Jährlich"
        },
      },
      subscriptionCode: {
        label: 'Stornierungsgrund',
        info: 'Optionaler Stornierungstyp, der die Art der Stornierung angibt.',
      },
      disableBlockers: {
        label: "Blocker deaktivieren",
      }
    },
    "button": {
      "add": "hinzufügen"
    },
    "createButton": "Abonnent hinzufügen",
    "terminationTooltip": "Abonnement nach Erfolg kündigen",
    "unterminateTooltop": "Abonnement nicht kündigen",
    "creditationProcessed": "Kredit als erhalten melden",
    "creditationNotProcessed": "Kreditbearbeitung rückgängig machen",
    "cancelTooltip": "Kündigung des Abonnements",
    "editTooltip": "Abonnement ändern",
    "uncancelTooltip": "Abbestellung des Abonnements",
    "deleteTooltip": "Registrierung löschen",
    "lookupTooltip": "Nachschlagen von Abonnements",
    "save": {
      "error": "Konnte Teilnehmer nicht retten!",
      "success": "Teilnehmer wird gespeichert"
    },
    "delete": {
      "error": "Teilnehmer konnte nicht entfernt werden!",
      "success": "Abonnent wurde entfernt"
    },
    "modal": {
      "view": {
        "title": "Anmeldung"
      },
      "delete": {
        "content": "Sie sind dabei, eine Registrierung zu löschen. Möchten Sie fortfahren?",
        "cancelButton": "Abbrechen",
        "deleteButton": "Stornierung"
      },
      "cancel": {
        "title": "Kündigung des Abonnements",
        "cancelButton": "Abbrechen",
        "saveButton": "Stornierung",
        "notification": "Das Abonnement wurde gekündigt"
      }
    },
    "changeStatus": {
      "error": "Kann den Status nicht ändern!",
      "success": "Der Status wurde aktualisiert.",
      "modalTitle": "Status des abonnements ändern ID",
      "modalTitleMultiple": "Status von {{amount}} Abonnements ändern",
      "changeButton": "Status ändern",
      "label": "Wählen sie den status des abonnements"
    },
    "reexport": {
      "error": "Kann nicht wieder exportiert werden!",
      "success": "Das Abonnement wurde wieder exportiert"
    },
    "uncancel": {
      "error": "Konnte nicht abbrechen!",
      "success": "Die Stornierung wird rückgängig gemacht."
    },
    "zsp": {
      "missingScan": "Es wurde noch kein Scan hochgeladen. Bitte laden Sie zuerst einen Scan hoch."
    },
    "resendEmail": {
      "button": "E-Mail erneut senden",
      "confirmationQuestion": "Sind Sie sicher, dass Sie die Bestätigungs-E-Mail erneut versenden möchten?",
      "emailToResend": "E-Mail-Adresse für die Bestätigungs-E-Mail",
      "resendConfirmation": "E-Mail-Adresse für die Bestätigungs-E-Mail",
      "sentConfirmation": "E-Mail erfolgreich gesendet",
      "invalidEmail": "E-Mail Adresse ungültig",
      "invalidInputEmail": "Bitte geben Sie eine gültige E-Mail Adresse ein",
      "sendAttachment": "Anhänge senden"
    }
  },
  "subscriber": {
    "noPicture": "Kein Bild verfügbar",
    "loading": "Laden",
    "field": {
      "updatedAt": {
        "label": "Aktualisiert am"
      },
      "gender": {
        "salutation": {
          "male": "Herr",
          "female": "Frau",
          "unknown": ""
        },
        "label": "Geschlecht",
        "value": {
          "male": "Mann",
          "female": "Frau",
          "unknown": "Unbekannt"
        }
      },
      "dateOfBirth": {
        "label": "Geburtsdatum"
      },
      spokenLanguage:{
        label: "Sprache"
      },
      "city": {
        "label": "Standort"
      },
      "street": {
        "label": "Straße"
      },
      "houseNumber": {
        "label": "Hausnummer"
      },
      "houseNumberSuffix": {
        "label": "Zusatz"
      },
      "zipCode": {
        "label": "Postleitzahl"
      },
      "iban": {
        "label": "Iban"
      },
      "mobile": {
        "label": "Nummer des Mobiltelefons"
      },
      "landline": {
        "label": "Feste Rufnummer"
      },
      "email": {
        "label": "E-Mail"
      },
      "lastName": {
        "label": "Nachname"
      },
      "firstName": {
        "label": "Vornamen"
      },
      "middleName": {
        "label": "Einfügungen"
      },
      "initials": {
        "label": "Initialen"
      },
      "date_of_birth": {
        "error": {
          "too_young": "Jünger als das Mindestalter."
        }
      },
      "country": {
        "label": "Land",
        "value": {
          "NL": "Niederlande",
          "DE": "Deutschland",
          "FR": "Frankreich",
          "BE": "Belgien",
          "GB": "England"
        }
      }
    }
  },
  "shopItem": {
    "nav": {
      "overview": "Shop"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "ordering": {
        "label": "Bestellung",
        "placeholder": "Bestellen..."
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "description": {
        "label": "Beschreibung",
        "placeholder": "Beschreibung..."
      },
      "price": {
        "label": "Münzen",
        "placeholder": "Münzen..."
      },
      "image": {
        "label": "Bild",
        "placeholder": "Bild..."
      }
    },
    "create": {
      "title": "Artikel hinzufügen"
    },
    "edit": {
      "title": "Artikel anpassen"
    },
    "createButton": "Artikel hinzufügen",
    "editTooltip": "Artikel bearbeiten",
    "filter": {
      "deleted": "Gelöschte Artikel",
      "onlyDeleted": "Nur gelöscht",
      "withoutDeleted": "Ohne gelöscht",
      "bothDeletedAndNot": "Beide"
    }
  },
  "promotorIdea": {
    "nav": {
      "overview": "Promoter-App Ideen"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "employee": {
        "label": "Mitarbeiter",
        "placeholder": "Mitarbeiter..."
      },
      "branch": {
        "label": "Zweigstelle",
        "placeholder": "Zweigstelle..."
      },
      "idea": {
        "label": "Idee",
        "placeholder": "Die Idee..."
      },
      "subject": {
        "label": "Thema",
        "placeholder": "Thema..."
      }
    },
    "create": {
      "title": "Idee hinzufügen"
    },
    "edit": {
      "title": "Idee anpassen"
    },
    "createButton": "Idee hinzufügen",
    "editTooltip": "Artikel bearbeiten"
  },
  "contactTab": {
    "noResults": "Nichts zum Nachrufen"
  },
  "sequence": {
    "editTooltip": "Artikel bearbeiten",
    "nav": {
      "overview": "Sequenzen"
    },
    "page": {
      "overview": {
        "title": "Sequenzen"
      }
    },
    "createButton": "Sequenz hinzufügen",
    "clearDefaultButton": "Standard löschen",
    "save": {
      "error": "Die Sequenz konnte nicht gespeichert werden!",
      "success": "Sequenz wird gespeichert"
    },
    "modal": {
      "delete": {
          "content": "Sie sind dabei, eine Sequenz zu löschen. Möchten Sie fortfahren?"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "value": {
        "label": "Wert"
      },
      "description": {
        "label": "Beschreibung"
      },
      "client": {
        "label": "Kunde"
      },
      "increment_per_export": {
        "placeholder": "Ja/Nein..."
      }
    }
  },
  "clientRefNr": {
    "page": {
      "overview": {
        "title": "Referenznummern"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Namen"
      },
      "referenceNumber": {
        "label": "Referenznummer"
      },
      "subscriptionId": {
        "label": "Registrierungs-ID"
      },
      "client": {
        "label": "Kunde"
      },
      "fromCsv": {
        "label": "CSV ID"
      }
    }
  },
  "softDelete": {
    "delete": {
      "error": "Element konnte nicht gelöscht werden!",
      "success": "Der Artikel wurde entfernt",
      "tooltip": "Artikel entfernen"
    },
    "restore": {
      "error": "Artikel kann nicht wiederhergestellt werden!",
      "success": "Artikel wurde zurückgesetzt",
      "tooltip": "Gegenstand zurücklegen"
    }
  },
  "segment": {
    "field": {
      "id": "ID",
      "name": "Name",
      "is_final": "Wurde veröffentlicht"
    },
    "attribute": {
      "field": {
        "id": "ID",
        "segment": "Segment",
        "name": "Name",
        "attribute_name": "Name des Attributs",
        "aggregation_type": "Art der Berichterstattung"
      }
    },
    "createButton": "Segment hinzufügen"
  },
  "valueNode": {
    "valueType": {
      "label": "Typ"
    },
    "nodeType": {
      "label": "Typ"
    },
    "choice": {
      "if": "Als",
      "else": "Ansonsten"
    },
    "type": {
      "fixed_value": "Fester Wert",
      "field_value": "Feldwert",
      "date_format": "Datumsformat",
      "current_date": "Heutiges Datum",
      "choice": "Auswahl",
      "metafield_value": "Metafield",
      "merge": "Zusammenführen",
      "dyadic_arithmetic_operator": "Arithmetische Verarbeitung",
      "convert_case": "Umwandlung von Großbuchstaben",
      "replace": "Ersetzen von Text",
      "fixed_width": "Feste Spaltenbreite",
      "date_component": "Teil des Datums",
      "date_truncate": "Abgekürztes Datum",
      "count": "Anzahl der",
      "sequence": "Sequenz",
      "substring": "Teilzeichenfolge",
      "assigned_field_value": "Zugewiesener Feldwert"
    },
    "dateComponentType": {
      "century": "Jahrhundert",
      "day": "hallo",
      "decade": "Jahrzehnt",
      "dow": "Tag der Woche",
      "doy": "Tag des Jahres",
      "epoch": "Sekunden seit 1-1-1970 00:00:00 UTC",
      "hour": "Stunde",
      "isodow": "Tag der Woche (ISO)",
      "isoyear": "Tag des Jahres (ISO)",
      "microseconds": "Mikrosekunde",
      "millenium": "Millennium",
      "milliseconds": "Millisekunde",
      "minute": "Minute",
      "month": "Monat",
      "quarter": "Quartal",
      "second": "zweite",
      "timezone": "UTC-Zeitzonenunterschied",
      "timezone_hour": "Stunden Unterschied zu UTC",
      "timezone_minute": "Minuten Unterschied zu UTC",
      "week": "Woche",
      "year": "Jahr",
      "millennium": "Millennium"
    },
    "mathOperator": {
      "add": "+",
      "sub": "-",
      "mul": "*"
    },
    "choiceOperator": {
      "exact": "ist gleich",
      "exact_not": "ist nicht gleich",
      "lt": "ist kleiner als",
      "lte": "ist kleiner/gleich",
      "gt": "größer ist als",
      "gte": "ist größer/gleich"
    },
    "groupCombinator": {
      "and": "und",
      "or": "oder"
    },
    "convertCase": {
      "uppercase": "Großbuchstaben",
      "lowercase": "Kleinschreibung",
      "titlecase": "Großbuchstaben pro Wort"
    },
    "location": {
      "left": "links",
      "right": "Rechts",
      "both": "Beide",
      "everywhere": "Überall"
    },
    "repeat": {
      "label": "Wiederholen Sie",
      "no": "Nein",
      "yes": "Ja"
    }
  },
  "pageNotFoundMessage": "Seite nicht gefunden :(",
  "paginator": {
    "previousText": "<",
    "nextText": ">"
  },
  "user": {
    "editTooltip": "Benutzer bearbeiten",
    "masqueradeTooltip": "Benutzer-Maskerading",
    "forgotPassword": "Haben Sie Ihr Passwort vergessen?",
    "sendResetLink": "Link zum Zurücksetzen senden",
    "backToLogin": "Zurück zur Anmeldung",
    "passwordPlaceholder": "neues Passwort",
    "passwordConfirmationPlaceholder": "Passwort wiederholen...",
    "sendResetCodeEmailSuccess": "Wenn Ihr Benutzername bekannt ist, wurde Ihnen ein Rücksetzungscode per E-Mail zugesandt.",
    "sendResetCodeEmailError": "Das Passwort konnte nicht zurückgesetzt werden. Bitte überprüfen Sie den Benutzernamen.",
    "editButton": "Anpassen",
    "saveButton": "Speichern Sie",
    "navProfile": "Profil",
    "navChangePassword": "Passwort ändern",
    "navLogout": "Abmeldung",
    "field": {
      "id": {
        "label": "ID"
      },
      "last_login": {
        "label": "Zuletzt eingeloggt"
      },
      "username": {
        "label": "Name des Benutzers",
        "placeholder": "Benutzername..."
      },
      "email": {
        "label": "E-Mail"
      },
      "password": {
        "label": "Passwort",
        "placeholder": "Passwort..."
      },
      "groups": {
        "label": "Gruppen",
        "multiselect": {
          "selectedText": "# von # ausgewählten Gruppen",
          "noneSelectedText": "Gruppen auswählen",
          "placeholder": "Ich suche..."
        }
      },
      "lastLogin": {
        "label": "Letzte Anmeldung"
      },
      "isActive": {
        "label": "Benutzer ist aktiv"
      }
    },
    "filter": {
      "search": {
        "label": "Suche",
        "placeholder": "Nutzername, Vorname, Nachname..."
      },
      "branches": {
        "label": "Zweigstelle"
      },
      "group": {
        "label": "Gruppe"
      },
      "status": {
        "label": "Status"
      },
      "deleted": "Entfernt"
    },
    "account": {
      "title": "Profil",
      "logoutButton": "Abmeldung",
      "unMasquerade": "unMasquerade",
      "language": "Sprache"
    },
    "changePassword": {
      "title": "Passwort ändern",
      "field": {
        "passwordOld": {
          "label": "Altes Passwort"
        },
        "passwordNew": {
          "label": "Neues Passwort"
        }
      },
      "saveButton": "Passwort ändern",
      "success": "Das Passwort wurde geändert",
      "error": "Ich konnte das Passwort nicht ändern!"
    },
    "passwordForgot": {
      "title": "Vergessenes Passwort",
      "requestButton": "Link zum Zurücksetzen senden",
      "requestedEmailText": "Wenn die E-Mail-Adresse korrekt ist, wurde eine E-Mail mit einem Link zum Zurücksetzen des Passworts gesendet."
    },
    "nav": {
      "overview": "Benutzer"
    },
    "login": {
      "title": "Anmeldung",
      "forgotPasswordLink": "Haben Sie Ihr Passwort vergessen?",
      "errors": {
        "invalidCredentials": "Benutzername oder Passwort falsch",
        "unknown": "Unbekannter Fehler, Statuscode: {{status}}."
      },
      "loginButton": "Anmeldung"
    },
    "save": {
      "error": "Benutzer konnte nicht gespeichert werden!",
      "success": "Benutzer wird gespeichert"
    },
    "view": {
      "create": {
        "title": "Benutzer erstellen"
      },
      "edit": {
        "title": "Benutzer anpassen"
      },
      "changePassword": {
        "title": "Passwort ändern",
        "newPassword": "Neues Passwort",
        "confirmedPassword": "bestätigtes Passwort"
      },
      "profile": {
        "title": "Ihr Profil"
      },
      "forgotPassword": {
        "title": "Haben Sie Ihr Passwort vergessen?"
      }
    },
    "groups": "Gruppen",
    "masqueradeButton": "Beginn der Maskerade",
    "unmasqueradeButton": "Maskerade stoppen",
    "resetPassword": {
      "resetButton": "Zurücksetzen",
      "saveSuccess": "Das Passwort wurde erfolgreich zurückgesetzt",
      "title": "Passwort zurücksetzen",
      "body": "Setzen Sie hier Ihr Passwort zurück.",
      "password": "Neues Passwort",
      "repeatPassword": "Neues Passwort wiederholen",
      "doesNotMatch": "Die Passwörter stimmen nicht überein!",
      "requirements": {
        "length": "Das Passwort muss mindestens 8 Zeichen lang sein.",
        "generic": "Das Passwort darf nicht zu allgemein gehalten sein.",
        "similar": "Das Passwort darf Ihren persönlichen Daten nicht zu ähnlich sein",
        "numeric": "Das Passwort darf nicht ausschließlich aus Zahlen bestehen.",
      },
    }
  },
  "contentbundle": {
    "copyTooltip": "Einschreibemodul kopieren",
    "editTooltip": "Modul Registrierung bearbeiten",
    "deleteTooltip": "Registrierungsmodul löschen",
    "createButton": "Neues Registrierungsmodul",
    "noPadding": "Keine Polsterung",
    "basicInfo": "Grundlegende Informationen",
    "layout": "Layout",
    "currentFormElementDetails": "Element-Details",
    "addElements": "Hinzufügen von Elementen",
    "removeElement": "entfernen",
    "addElementType": {
      "attest": "Zertifikat hinzufügen",
      "filler": "Hinzufügen von Füllmaterial",
      "text": "Text hinzufügen",
      "metafield": "Metafelder hinzufügen",
      "field": "Feld hinzufügen",
      "image": "Bild hinzufügen"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "client": {
        "label": "Kunde"
      },
      "products": {
        "label": "Kunde"
      },
      "show_recruiter_signature": {
        "label": "Unterschrift des Veranstalters?"
      },
      "campaign": {
        "label": "Kampagne",
        "placeholder": "Wählen Sie eine Kampagne..."
      },
      "translations": {
        "label": "Übersetzungen"
      },
      "styles": {
        "label": "Stile"
      },
      "logo": {
        "label": "Logo",
        "save": {
          "error": "Das Logo muss eine png-Datei sein"
        }
      }
    },
    "copy": {
      "success": "Die Kampagne ist kopiert worden.",
      "error": "Die Kampagne konnte nicht kopiert werden."
    },
    "edit": {
      "addForm": "Formular hinzufügen",
      "slides": "Bearbeitung von Folien",
      "addFolder": "Ordner hinzufügen",
      "addSlide": "Folie hinzufügen"
    },
    "slide": {
      "SlidesEdit": "Ordner"
    }
  },
  "formElement": {
    "field": {
      "label": {
        "label": "Etikett"
      },
      "textType": {
        "label": "Typ"
      },
      "text": {
        "label": "Text"
      },
      "metafield": {
        "label": "Metafield"
      },
      "thirdGenderEnabled": {
        "label": "Drittes Geschlecht ermöglicht"
      },
      "readOnly": {
        "label": "Nur lesen"
      },
      "autofill": {
        "label": "Autofill"
      },
      "field": {
        "label": "Feld",
        "value": {
          "referenceNr": "Referenznummer",
          "initials": "Initialen",
          "firstName": "Vornamen",
          "middleName": "Mittlerer Name",
          "lastName": "Nachname",
          "gender": "Geschlecht",
          "dateOfBirth": "Geburtsdatum",
          "email": "E-Mail",
          "street": "Straße",
          "houseNumber": "Hausnummer",
          "zipCode": "Postleitzahl",
          "city": "Stadt",
          "datetimeOfRegistration": "Datum der Registrierung",
          "startDate": "Anfangsdatum",
          "iban": "Kontonummer",
          "paymentInterval": "Frequenz",
          "houseNumberSuffix": "Hausnummer Suffix",
          "phonenumbers": "Telefon",
          "landline": "Festnetz",
          "mobile": "Mobil",
          "commencingDate": "Anfangsdatum",
          "clientRefNr": "Referenznummer des Kunden"
        }
      },
      "styles": {
        "label": "Stile"
      }
    }
  },
  "contentbundleAgeWildcard": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "minAge": {
        "label": "Minimales "
      },
      "maxAge": {
        "label": "Maximales Alter"
      },
      "renewEvery": {
        "label": "Jede {N} Untergruppe erneuern",
      },
      "default": {
        "label": "Standardmäßig hinzufügen?",
      },
      "employees": {
        "label": "Für Arbeitnehmer?"
      },
      "branches": {
        "label": "Für Zweigstellen?"
      },
    },
    "edit": {
      "title": "Edit joker"
    },
    "create": {
      "title": "Create joker"
    }
  },
  "contentbundleSlide": {
    "copyTooltip": "Einschreibemodul kopieren",
    "deleteSlideTooltip": "Dia entfernen",
    "editSlideTooltip": "Folie bearbeiten",
    "editTooltip": "Kapitel bearbeiten",
    "addHitboxButton": "Hitbox hinzufügen",
    "save": {
      "error": "Konnte Dia nicht speichern!",
      "overlapError": "Eine oder mehrere Hitboxen überschneiden sich. Dies ist nicht zulässig.",
      "duplicateNameError": "Es gibt bereits eine Folie mit diesem Namen!",
      "success": "Dia wurde gespeichert"
    },
    "delete": {
      "content": "Sie sind dabei, eine Folie zu löschen. Möchten Sie fortfahren?",
      "error": "Kann den Schlitten nicht entfernen!",
      "success": "Dia ist entfernt worden"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "final": {
        "label": "Von dieser Folie zum Anmeldeformular"
      },
      "file": {
        "label": "Dia"
      }
    }
  },
  "displayCondition": {
    "field": {
      "metafield": {
        "label": "Metafield"
      },
      "value": {
        "label": "Wert"
      },
      "type": {
        "label": "Typ",
        "value": {
          "standard": "Standardfeld",
          "metafield": "Metafield"
        }
      },
      "standardField": {
        "label": "Feld",
        "value": {
          "age": "Alter"
        }
      },
      "comparisonMethod": {
        "label": "Komparator",
        "value": {
          "=": "Gleichbedeutend mit",
          "<": "Weniger als",
          ">": "Mehr als"
        }
      }
    }
  },
  "importedCreditationCsv": {
    "field": {
      "csv": {
        "label": "CSV"
      },
      "importer": {
        "label": "Importeur"
      },
      "createdAt": {
        "label": "Datum"
      }
    },
    "actions": {
      "label": "Aktionen"
    }
  },
  "contentbundleSlideSettingsCondition": {
    "addConditionButton": "Bedingung hinzufügen",
    "addConditionButtonTitle": "Wenn eine der Bedingungen erfüllt ist, wird diese Folie angezeigt.",
    "field": {
      "productId": {
        "label": "Produkt"
      },
      "metafieldId": {
        "label": "Metafield"
      },
      "metafieldValue": {
        "label": "Wert"
      }
    }
  },
  "fileBundle": {
    "form": {
      "client": {
        "label": "Kunde"
      },
      "description": {
        "label": "Beschreibung"
      },
      "name": {
        "label": "Name"
      }
    },
    "create": {
      "title": "Export hinzufügen"
    },
    "edit": {
      "title": "Datei-Definition"
    },
    "modal": {
      "delete": {
        "content": "Sie sind dabei, eine Dateidefinition zu löschen. Zuvor erzeugte Exportdateien mit dieser Definition werden ebenfalls aus dem Archiv gelöscht. Möchten Sie fortfahren?",
        "cancelButton": "Abbrechen",
        "deleteButton": "Stornierung"
      }
    },
    "exportModal": {
      "showModalButton": "Ergebnisse exportieren",
      "title": "Optionen für den Export",
      "performExport": "Exportieren",
      "isDefinitive": "Endgültige Ausfuhr",
      "dateRange": "Datum",
      "confirmDefinitive": "Sind Sie sicher, dass Sie diesen Export endgültig machen wollen? Damit werden tatsächlich alle ausgewählten Abonnements bearbeitet."
    },
    "exportErrorModal": {
      "title": "Fehler beim Export",
      "fileDefitionHeader": "Fehler in der Dateidefinition",
      "unknownErrorMessage": "Beim Export ist ein unbekannter Fehler aufgetreten"
    },
    "metadata": {
      "title": "Metadaten",
      "fields": {
        "description": {
          "label": "Beschreibung"
        },
        "format": {
          "label": "Dateityp"
        },
        "encoding": {
          "label": "Dateikodierung"
        }
      }
    },
    "field": {
      "id": {
        "title": "ID"
      },
      "client": {
        "title": "Kunde",
        "placeholder": "Kunde..."
      },
      "description": {
        "title": "Beschreibung",
        "placeholder": "Beschreibung..."
      }
    },
    "save": {
      "error": "Konnte das Bündel nicht retten!",
      "success": "Bündel wird gespeichert"
    },
    "addInputNodeButton": "Knoten hinzufügen"
  },
  "fileNode": {
    "deleteConfirmation": "Sind Sie sicher, dass Sie das Element {{elementName}} löschen wollen?"
  },
  "generatedBundle": {
    "field": {
      "id": {
        "label": "ID"
      },
      "subscriptionId": {
        "label": "Registrierungs-ID"
      },
      "client": {
        "label": "Kunde"
      },
      "createdAt": {
        "label": "Erstellt"
      }
    }
  },
  "group": {
    "createButton": "Gruppe erstellen",
    "field": {
      "id": {
        "label": "ID"
      },
      "isDefault": {
        "label": "Standard-Mitarbeiter"
      },
      "isDefaultApplicant": {
        "label": "Standard-Antragsteller"
      },
      "isDefaultClient": {
        "label": "Standard-Kundenbenutzer"
      },
      "defaultClient": {
        "label": "Standard-Kundenbenutzer"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "permissions": {
        "label": "Erlaubnis",
        "placeholder": "Die Zustimmung..."
      },
      "default": {
        "label": "Standard-Mitarbeiter",
        "placeholder": "Standard..."
      },
      "defaultApplicant": {
        "label": "Standard-Antragsteller",
        "placeholder": "Standard-Bewerber..."
      }
    },
    "save": {
      "error": "Gruppe kann nicht gespeichert werden!",
      "success": "Gruppe wird gespeichert"
    },
    "editTooltip": "Gruppe bearbeiten",
    "nav": {
      "overview": "Gruppen"
    },
    "filter": {
      "permission": {
        "selectedText": "# Anzahl der ausgewählten Rechte",
        "noneSelectedText": "Rechte auswählen",
        "placeholder": "Ich suche..."
      }
    }
  },
  "issue": {
    "field": {
      "reason": {
        "value": {
          "start_date exceeds max delta": "Starttermin zu spät",
          "iban missing": "Fehlende IBAN",
          "quantity missing": "Nebelnummer",
          "amount missing": "Fehlende Menge",
          "email missing": "Fehlende E-Mail",
          "landline missing": "Fehlendes Festnetztelefon",
          "below minimum age": "Zu jung",
          "last_name missing": "Nachname Nebel",
          "gender unknown": "Fehlendes Geschlecht",
          "date_of_birth missing": "Fehlendes Geburtsdatum",
          "mobile missing": "Fehlendes Mobiltelefon",
          "phone number missing": "Fehlende Telefonnummer",
          "address invalid": "Adresse ungültig",
          "address incomplete": "Adresse unvollständig",
          "address unsure": "Adresse vage",
          "duplicate iban": "Doppelte IBAN für den Kunden",
          "known iban": "Bekannte IBAN",
          "recruiter iban": "IBAN eines Mitarbeiters",
          "duplicate email": "E-Mail-Kunde bereits im System bekannt",
          "recruiter email": "E-Mail von einem Mitarbeiter",
          "landline_invalid": "Ungültige Festnetzrufnummer",
          "mobile_invalid": "Ungültige Festnetztelefonnummer",
          "recruiter phone": 'Telefonnummer eines Personalvermittlers',
          "duplicate phone": 'Telefonnummer eines anderen Abonnements'
        }
      }
    }
  },
  "callPlanning": {
    "addSubModal": {
      "title": "Fügen Sie Abonnements für die Kampagne {{campaign}} hinzu."
    },
    language: "Sprache",
    spokenLanguage: "Sprache",
    "appointments": "Siehe",
    "employee": "Förster",
    "markAllDone": "Alles als erledigt markieren",
    "reachedDone": "'Erreicht' als erledigt markieren",
    "importSubscriptions": "Ausschreibungen hinzufügen",
    "confirmManuallyMark": "Bestätigen Sie, dass alle {{amount}} abonnements der kampagne {{campaign}} als erledigt markiert werden sollen",
    "confirmManuallyReached": "Bestätigen Sie, dass alle 'erreicht' abonnements der kampagne {{campaign}} als erledigt markiert werden sollen",
    "confirmImportHeader": "Bestätigung der Einfuhr",
    "confirmImport": "Sind Sie sicher, dass Sie die Registrierungen der Kampagne {{name}} importieren wollen?",
    "importRecords": "Registrierungen importieren",
    "amountOfDonors": "{{amount}} Einträge: {{reached}} erreicht, {{totalDone}} erledigt ({{percentage}} %).",
    "steps": {
      "importSubs": "Importieren",
      "assign": "Zuweisung von Mitarbeitern",
      "current": "Aktuelle Aufrufkampagnen"
    },
    "appointmentModal": {
      "subscriptionType": "Anmeldung",
      "bucketType": "Eimer",
      "title": "Abrufdaten {{type}} {{id}}"
    }
  },
  "jobApplication": {
    "nav": {
      "overview": "Anfrage",
      "interviews": "Anwendungen"
    },
    "add": "Antragsteller hinzufügen",
    "sendActivationEmailButton": "Aktivierungs-E-Mail (erneut) senden",
    "sendNoShowEmailButton": "(Wieder-)Versenden einer No-Show-E-Mail",
    "sendColdLeadEmailButton": "Kaltakquise-E-Mails (erneut) versenden",
    "sendActivationEmailSuccessMessage": "Erfolgreich gesendet",
    "filter": {
      "minCallAmount": "Minimal anzahl der Anrufe",
      "maxCallAmount": "Maximal anzahl der Anrufe",
      "corrected": "(korrigierte)"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "callBackAppointment": {
        "label": "Rückruf-Termin"
      },
      "nationalityInternal": {
        "label": "Staatsangehörigkeit"
      },
      "remarks": {
        "label": "Anmerkungen"
      },
      "viaVia": {
        "label": "ViaVia",
        "placeholder": "ViaVia..."
      },
      "employee": {
        "label": "Projektträger",
        "placeholder": "Projektträger..."
      },
      "last_sign_in": {
        "label": "Letzter Arbeitstag"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "avatar": {
        "label": "Profilbild",
        "placeholder": "Profilbild..."
      },
      "public_transport_student_license": {
        "label": "Hat Student OV",
        "placeholder": "Student OV...",
        "value": {
          "false": "Nein",
          "true": "Ja"
        }
      },
      "publicTransportStudentLicenseType": {
        "label": "Typ des Studenten OV",
        "value": {
          "no": "Nein",
          "week": "Woche",
          "weekend": "Wochenende"
        }
      },
      "mainBranch": {
        "label": "Hauptsitz",
        "placeholder": "Hauptsitz auswählen..."
      },
      "notInterested": {
        "info": "Dieser Antragsteller hat mitgeteilt, dass er das Antragsverfahren nicht fortsetzen möchte.",
        "label": "Kein Termin"
      },
      "travelingDistance": {
        "label": "Reiseentfernung"
      },
      "notInterestedReason": {
        "label": "Grund für die Ablehnung eines Termins"
      },
      "countryOfBirth": {
        "label": "Land der Geburt"
      },
      "cityOfBirth": {
        "label": "Geburtsort"
      },
      "branches": {
        "label": "Zweigstelle",
        "placeholder": "Zweigstelle...",
        "selectedText": "# Anzahl der Betriebe",
        "noneSelectedText": "Ort auswählen"
      },
      "initials": {
        "label": "Initialen",
        "placeholder": "Initialen..."
      },
      "firstName": {
        "label": "Vornamen",
        "placeholder": "Vornamen..."
      },
      "middleName": {
        "label": "Einfügung",
        "placeholder": "Einfügung..."
      },
      "lastName": {
        "label": "Nachname",
        "placeholder": "Nachname..."
      },
      "gender": {
        "label": "Geschlecht",
        "value": {
          "male": "Mann",
          "female": "Frau",
          "other": "Anderes",
          "unkown": "unbekannt"
        }
      },
      "onHold": {
        "labelDr": "Starten Sie noch nicht",
        "label": "In der Warteschleife",
        "value": {
          "true": "Ja",
          "false": "Nein",
          "all": "Alles"
        }
      },
      "onHoldReason": {
        "label": "Grund für die Unterbrechung"
      },
      "age": {
        "label": "Alter"
      },
      "dateOfBirth": {
        "label": "Geburtsdatum"
      },
      "phoneNumbers": {
        "label": "Telefonnummern",
        "placeholder": "Telefonnummern..."
      },
      "ssn": {
        "label": "BSN",
        "placeholder": "BSN..."
      },
      "dateStarted": {
        "label": "Datum der Inbetriebnahme"
      },
      "country": {
        "label": "Land",
        "placeholder": "Land...",
        "value": {
          "NL": "DE"
        }
      },
      "iban": {
        "label": "IBAN",
        "placeholder": "IBAN..."
      },
      "houseNumber": {
        "label": "Hausnummer",
        "placeholder": "Hausnummer..."
      },
      "houseNumberSuffix": {
        "label": "Hausnummer Zusatz",
        "placeholder": "Hausnummer Zusatz..."
      },
      "city": {
        "label": "Stadt",
        "placeholder": "Stadt..."
      },
      "email": {
        "label": "E-Mail",
        "placeholder": "E-Mail..."
      },
      "street": {
        "label": "Straße",
        "placeholder": "Straße..."
      },
      "zipCode": {
        "label": "Postleitzahl",
        "placeholder": "Postleitzahl..."
      },
      "employeeNumber": {
        "label": "Personalnummer/Referenznummer",
        "placeholder": "Kein Personal..."
      },
      "drivingLicence": {
        "label": "Hat einen Führerschein",
        "placeholder": "Führerschein...",
        "value": {
          "false": "Nein",
          "true": "Ja"
        }
      },
      "bankCard": {
        "label": "Bankkarte",
        "placeholder": "Bankkarte..."
      },
      "taxExemption": {
        "label": "Steuergutschrift",
        "placeholder": "Steuergutschriften..."
      },
      "canChangeTaxExemption": {
        "label": "Können sich Steuerbefreiungen ändern?",
        "placeholder": "Können sich Steuerbefreiungen ändern?"
      },
      "contractSignature": {
        "label": "Vertrag",
        "placeholder": "Vertrag..."
      },
      "identification": {
        "label": "Vorderseite der ID-Karte",
        "placeholder": "Vorderseite der ID-Karte..."
      },
      "identification_back": {
        "label": "Rückseite der ID-Karte",
        "placeholder": "ID-Karte zurück..."
      },
      "bw_id": {
        "label": "Briggs und Walker id",
        "placeholder": "Briggs und Walker id..."
      },
      "city_of_birth": {
        "label": "Geburtsort",
        "placeholder": "Geburtsort..."
      },
      "country_of_birth": {
        "label": "Land der Geburt",
        "placeholder": "Land der Geburt..."
      },
      "id_type": {
        "label": "ID-Typ",
        "placeholder": "ID-Typ..."
      },
      "id_number": {
        "label": "ID-Nummer",
        "placeholder": "ID-Nummer..."
      },
      "interviewer": {
        "label": "Interviewer?",
        "title": "Der Mitarbeiter kann dann aus der Liste der Interviewer für Vorstellungsgespräche ausgewählt werden."
      },
      "coins": {
        "label": "Münzen"
      },
      "blockedClients": {
        "label": "Gesperrte Kunden"
      },
      "referral_source": {
        "label": "Ursprung Lead"
      },
      "referralSource": {
        "label": "Ursprung Lead"
      },
      "referralSourceOther": {
        "label": "Andere Ursprung"
      },
      "legalGuardianName": {
        "label": "Name des Erziehungsberechtigten"
      },
      "legalGuardianPhone": {
        "label": "Telefon des Erziehungsberechtigten"
      },
      "legalGuardianEmail": {
        "label": "E-Mail Adresse des Erziehungsberechtigten"
      },
      "noShowSent": {
        "info": "Wenn ein Bewerber nicht erschienen ist (No-Show-Status) und mindestens einmal angerufen wurde, kann eine No-Show-E-Mail gesendet werden (in der Symbolleiste). Diese E-Mail enthält eine Erinnerung an den Bewerber, dass er zu einem Vorstellungsgespräch kommen kann.",
        "label": "E-Mail bei Nichterscheinen gesendet"
      },
      "legalGuardianDisabled": {
        "label": "Kein Erziehungsberechtigter erforderlich"
      },
      "coldLeadSent": {
        "info": "Wenn ein Bewerber sich nicht gemeldet hat (Status \"Cold Lead\") und mindestens fünfmal angerufen wurde, kann eine E-Mail an den Cold Lead gesendet werden (in der Symbolleiste). Diese E-Mail enthält eine letzte Erinnerung an den Bewerber, zu einem Vorstellungsgespräch zu erscheinen.",
        "label": "Kaltakquise-E-Mail gesendet"
      },
      "createdAt": {
        "label": "Created at"
      },
      "lastInterview": {
        "label": "Last interview"
      },
      "bic": {
        "label": "BIC",
        "placeholder": "BIC..."
      },
      "employeeNumbefr": {
        "label": "Referenznummer des Personals",
        "placeholder": "Kein Personal..."
      },
      dropoutReason: {
        label: "Abbruchgrund"
      },
    },
    "calls": {
      "modal": {
        "incoming": "Eingehende Anrufe",
        "outcoming": "Ausgehende Anrufe",
        "buttonTitle": "Telefonanrufe",
        "id": "ID",
        "caller": "Anrufer",
        "calledUser": "An",
        "start": "Start",
        "duration": "Dauer",
        "voicelogs": "Voicelogs",
        "listenCall": "Konversation nach dem Hören",
        "audioError": "Ihr Browser unterstützt das Audio-Tag nicht."
      },
      "modalTitle": "Telefonanrufe des Antragstellers"
    }
  },
  "referralSource": {
    create:{
      title: 'Quelle hinzufügen'
    },
    "createButton": "Quelle hinzufügen",
    "nav": {
      "overview": "Quelle"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "isDefault": {
        "label": "Standard?"
      },
      "isOther": {
        "label": "Anders?"
      },
      "name": {
        "label": "Name"
      }
    },
    filter: {
      "deleted": "Gelöschte Quellen",
      "onlyDeleted": "Nur gelöscht",
      "withoutDeleted": "Ohne gelöscht",
      "bothDeletedAndNot": "Beide"
    },
    edit: {
      title: "Bearbeitung der Verweisquelle"
    }
  },
  "job": {
    "editTooltip": "Funktion bearbeiten",
    "nav": {
      "overview": "Funktionen"
    },
    "createButton": "Funktion hinzufügen",
    "save": {
      "error": "Funktion konnte nicht gespeichert werden!",
      "success": "Funktion wird gespeichert"
    },
    "field": {
      "id": {
        "label": "ID",
        "placeholder": "AUSWEIS..."
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "isTeamPosition": {
        "label": "Position im Team?",
        "placeholder": "Position im Team..."
      },
      "isFreelance": {
        "label": "Freiberuflich?",
        "placeholder": "Freiberuflich..."
      },
      "isDefault": {
        "label": "Standardposition?",
        "placeholder": "Standardposition..."
      },
      "baseFactor": {
        "label": "Basisfaktor"
      }
    },
    "application": {
      "createButton": "Antragsteller hinzufügen",
      "action": {
        "waitForContractUpload": "Ich warte darauf, dass der Vertrag hochgeladen wird",
        "readyToConvertToEmployee": "Bereit zur Umwandlung in einen aktiven Mitarbeiter",
        "waitForInterviewApproval": "Warten auf Terminbestätigung",
        "checkInterview": "Termin prüfen",
        "makeAppointment": "Vereinbaren Sie einen Termin",
        "appointmentMade": "Warten auf das Vorstellungsgespräch"
      },
      "field": {
        "amountofcalls": {
          "label": "Anzahl der Anrufe",
          lastCall: "Letzter Aufruf",
          hasCalls: "Hat Anrufe?",
          corrected: "Korrigierte Anzahl von Anrufen"
        },
        callAppointment: {
          label: "Status anruftermin"
        },
        "dateofinterview": {
          "label": "Datum Interview"
        },
        "whatsappStatus": {
          "label": "Whatsapp Status",
          "value": {
            "to_call": "Anforderung eines Anrufs",
            "reminder_appointment": "Terminerinnerung",
            "congratulations_hired": "Herzlichen Glückwunsch zur Zulassung"
          },
          "save": "Whatsapp Status Gesichert",
          "saveError": "Whatsapp Status nicht gespeichert"
        },
        "id": {
          "label": "ID"
        },
        "name": {
          "label": "Name"
        },
        "remarks": {
          "label": "Anmerkungen"
        },
        "age": {
          "label": "Alter",
          "placeholder": "Zeitalter der..."
        },
        "status": {
          "label": "Status",
          "multiselect": {
            "selectedText": "# Status der Status",
            "noneSelectedText": "Status auswählen"
          },
          "multiselectFilter": {
            "placeholder": "Suche..."
          },
          "value": {
            "undecided": "Noch zu entscheiden",
            "hired": "Angenommen",
            "rejected": "Abgelehnt",
            "on_hold": "In der Warteschleife",
            "no-show": "Keine Vorstellung",
            "to-plan": "Vereinbaren Sie einen Termin",
            "planned": "Interview geplant",
            "awaiting-interview": "Warten auf das Interview",
            "completed-interview": "Interview abgeschlossen",
            "to-plan-no-show": "Vereinbaren Sie einen Termin nach No Show",
            "to-plan-cold-lead": "Terminvereinbarung nach Cold Lead",
            "not-interested": "Kein Termin"
          }
        },
        "createdAt": {
          "label": "Zeit seit Antragstellung"
        },
        "previous_call_attempts": {
          "label": "Frühere Anrufversuche"
        },
        "referralSource": {
          "label": "Quelle"
        },
        "branch": {
          "label": "Zweigstelle"
        },
        "email": {
          "label": "E-Mail"
        },
        "phoneNumber": {
          "label": "Telefon"
        },
        "referral_source": {
          "label": "Quelle"
        },
        "viaVia": {
          "label": "ViaVia Quelle"
        }
      },
      "filter": {
        "search": {
          "label": "Suche",
          "placeholder": "Suche nach Vornamen, Nachnamen, E-Mail..."
        },
        "active": {
          "label": "Aktiv?",
          "value": {
            "all": "Alles",
            "active-only": "Nur aktive Projektträger",
            "non-active-only": "Noch keine aktiven Projektträger"
          }
        }
      },
      "page": {
        "overview": {
          "title": "Bewerbungen"
        },
        "edit": {
          "title": "Anmeldung"
        }
      },
      "plan": "Interview hinzufügen",
      "modal": {
        "delete": {
          "content": "Sie sind dabei, einen Bewerber zu löschen. Möchten Sie fortfahren?",
          "cancelButton": "Nein",
          "deleteButton": "entfernen"
        },
        "deleteFile": {
          "content": "Sind Sie sicher, dass Sie diese Datei löschen wollen?",
          "cancelButton": "Nein",
          "deleteButton": "entfernen"
        }
      },
      "save": {
        "success": "Gespeicherte Anfrage",
        "error": "Fehler beim Speichern der Anwendung"
      },
      "delete": {
        "error": "Element konnte nicht gelöscht werden!",
        "success": "Der Artikel wurde entfernt"
      }
    },
    "callbackAppointment": {
      "options": {
        "none": "ohne",
        "pending": "Demnächst",
        "done": "Bereit",
        "notReached": "Nicht erreicht"
      },
      "modalCallButton": "Geplante Interviews",
      "callBackAppointment": "Geplantes Gespräch",
      "callbackAppointments": "Geplante Interviews",
      "callAppointmentStatus": "Status",
      "deleteDialog": {
        "title": "Sind Sie sicher, dass Sie den Rückruftermin löschen wollen?",
        "confirm": {
          "label": "entfernen"
        }
      },
      "save": {
        "success": "Rückruftermin gespeichert",
        "error": "Fehler beim Speichern des Rückruftermins"
      },
      "header": "Rückruftermine",
      "createButton": "Rückruftermin hinzufügen",
      "field": {
        "status": {
          "label": "Status"
        },
        "date": {
          "label": "Datum und Uhrzeit"
        }
      }
    },
    "interview": {
      "createButton": "Interview hinzufügen",
      "hireButton": "annehmen.",
      "rejectButton": "Ablehnen",
      "noShowButton": "Keine Vorstellung",
      "deleteDialog": {
        "title": "Sind Sie sicher, dass Sie das Interview löschen wollen?",
        "confirm": {
          "label": "entfernen"
        }
      },
      "button": {
        "hired": "annehmen.",
        "rejected": "Ablehnen",
        "no-show": "Keine Vorstellung",
        "undecided": "Noch zu entscheiden"
      },
      "field": {
        "id": {
          "label": "ID"
        },
        "name": {
          "label": "Name"
        },
        "interviewer": {
          "label": "Interviewer",
          "placeholder": "Interviewer auswählen..."
        },
        "branch": {
          "label": "Zweigstelle"
        },
        "date": {
          "label": "Datum und Uhrzeit"
        },
        "time": {
          "label": "Zeit",
          "none": "Wählen Sie zunächst eine Branche und ein Datum",
          "placeholder": "Wählen Sie die Zeit der..."
        },
        "status": {
          "label": "Status"
        }
      },
      "status": {
        "pending": "Noch zu entscheiden",
        "accepted": "Angenommen",
        "rejected": "Abgelehnt",
        "make-new-appointment": "Neuen Termin vereinbaren",
        "rejectedRemark": "Wünscht keine neue Ernennung"
      },
      "page": {
        "overview": {
          "title": "Interviews"
        }
      },
      "save": {
        "success": "Interview gespeichert",
        "error": "Fehler beim Speichern des Interviews"
      },
      "empty": "Keine Interviews beantragt",
      "multiselect": {
        "placeholder": "Interviewer finden...",
        "selectedText": "# Anzahl der ausgewählten Interviewer",
        "noneSelectedText": "Interviewer auswählen"
      }
    }
  },
  "client": {
    "addUserButton": "Kundenbenutzer hinzufügen",
    "headerUserTable": "Kunden-Benutzer",
    "user": {
      "header": "Kunde Benutzer",
      "emptyMail": "Eingabe einer E-Mail Adresse",
      "emptyFirstName": "Geben Sie den Vornamen des Kundenbenutzers ein",
      "emptyLastName": "Geben Sie den Nachnamen des Kundenbenutzers ein",
      "incorrectMail": "Der eingegebene Wert ist keine gültige E-Mail Adresse",
      "email": "E-Mail"
    },
    "editButton": "Kunde bearbeiten",
    "deleteButton": "Kunde löschen",
    "view": {
      "title": "Kunden"
    },
    "edit": {
      "title": "Kunde bearbeiten"
    },
    "create": {
      "title": "Kunden erstellen"
    },
    "overview": {
      "title": "Kunden",
      "createButton": "Neuer Kunde"
    },
    "field": {
      "Acties": {
        "label": "Aktionen"
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "campaigns": {
        "label": "Anzahl der Kampagnen"
      },
      "showMaxAmountOfCalls": {
        "label": "Maximale Anzahl von Anrufversuchen verwenden"
      },
      "maxAmountOfCalls": {
        "label": "Maximale Anzahl von Anrufversuchen"
      },
      "users": {
        "label": "Anzahl der Nutzer"
      },
      "max_start_date_delta": {
        "label": "Max. späterer Starttermin",
        "placeholder": "z.B. für 2 Monate: P2M",
        "error": {
          "incorrect": "Format ist nicht korrekt"
        }
      },
      "doubleConfigured": {
        "label": "Konfiguration mit zwei Tabletten",
        "helpText": "Für diesen Kunden kann ein Tablet zweimal am selben Tag eingeplant werden. In diesem Fall wird das Team mit der niedrigsten ID-Nummer zuerst benutzt. Sobald dieses Team abgemeldet ist, wird automatisch zum nächsten Team gewechselt."
      },
      "sendEncryptedSubscriberMails": {
        "label": "Verschlüsselte E-Mails versenden",
        "helpText": "Mails von diesem Kunden werden verschlüsselt, bevor der Abonnent seine Daten erhält. Dies geschieht mit den letzten vier Ziffern der IBAN des Kunden."
      },
      "maximumStorageTerm": {
        "label": "Maximale Aufbewahrungsfrist",
        "placeholder": "z.B. für 2 Monate: P2M",
        "helpText": "Eine Aufbewahrungsfrist, die im Format der ISO 8601 festgehalten wird, Beispiel: P2M = 2 Monate, P84D = 84 Tage, P10W = 10 Wochen"
      },
      "products": {
        "label": "Produkte"
      },
      maxSubAmountWages: {
        label: "Maximaler Auszahlungsbetrag"
      }
    },
    "deleteTooltip": "Kunde löschen",
    "restoreTooltip": "Kunde wiederherstellen",
    "editTooltip": "Kundenanpassung",
    "modal": {
      "delete": {
        "content": "Sie sind dabei, einen Kunden zu löschen. Möchten Sie fortfahren?",
        "cancelButton": "Nein",
        "deleteButton": "entfernen"
      }
    },
    "save": {
      "error": "Konnte den Kunden nicht retten!",
      "success": "Kunde wird gespeichert"
    }
  },
  "wagesTariff": {
    "title": "Lohntarif PDF",
    "edit": "Löhne Tarif bearbeiten",
    "upload": "PDF-Datei hier ablegen!"
  },
  "contract": {
    "title": "Vertrag",
    "field": {
      "id": {
        "label": "ID"
      },
      "label": {
        "label": "Etikett"
      },
      "content": {
        "label": "Inhalt"
      },
      "draft": {
        "label": "Entwurf?"
      }
    }
  },
  "document": {
    "title": "Dokumente",
    "createButton": "Dokument hinzufügen",
    "field": {
      "id": {
        "label": "ID"
      },
      "icon": {
        "label": "Icon"
      },
      "type": {
        "label": "Typ",
        "value": {
          "employment contract": "Arbeitsvertrag",
          "contract": "Vertrag",
          "manual": "Handbuch"
        }
      },
      "userType": {
        "label": "Gezeigt für",
        "value": {
          "basic": "Alle",
          "new_employees": "Nur aktive Mitarbeiter",
          "new_job_applications": "Nur Stellenbewerber"
        }
      },
      "label": {
        "label": "Etikett"
      },
      "required": {
        "label": "Erforderlich",
        "autoAssigning": "Automatisch zuweisen"
      },
      "content": {
        "label": "Inhalt"
      },
      "status": {
        "label": "Status",
        "value": {
          "draft": "Entwurf",
          "published": "Veröffentlicht",
          "archived": "Archiviert"
        }
      },
      "isPayroll": {
        "label": "Payroll Dokument",
      },
    }
  },
  "handbook": {
    "title": "Handbuch"
  },
  "privacy": {
    "title": "Erklärung zum Datenschutz"
  },
  "captainsheetContractText": {
    "title": "Kapitänsbuch Vertrag bearbeiten"
  },
  "team": {
    "totalRecords": "Mannschaften",
    "totalTeamMembers": "Projektträger",
    "totalSubscriptionCount": "Abonnements",
    "totalTeamMembersWithSignIn": "Eintragen",
    "totalTeamMembersWithSignOut": "Abmelden",
    "totalAverage": "A.P.P.",
    "totalAverageDR": "Avg. score",
    "totalSubscriptionsFactored": "Abonnements insgesamt",
    "clear": "Leeres Feld",
    "nav": {
      "overview": "Mannschaften"
    },
    "title": "Planung im Team",
    "page": {
      "overview": {
        "title": "Mannschaften",
        "day": [
          "Also",
          "Ma",
          "Di",
          "Wo",
          "Do",
          "Fr",
          "Za"
        ]
      }
    },
    "createButton": "Team planen",
    "importFromBriggsAndWalkersButton": "Schwarzweiß importieren",
    "prevButton": "Vorherige",
    "nextButton": "Weiter",
    "toFlushViewButton": "Flush-Teams",
    "downloadButton": "Mannschaft CSV",
    "form": {
      "dateRangePlaceholder": "Datumsbereich wählen"
    },
    "editTooltip": "Team anpassen",
    "viewTooltip": "Team ansehen",
    "viewSubscriptionsTooltip": "Abonnements anzeigen",
    "noSubscriptionsTooltip": "Keine Anmeldungen",
    "batchTooltip": "Abonnenten dieses Teams anzeigen",
    "deleteTooltip": "Team entfernen",
    "copyTooltip": "Team kopieren",
    "flushTooltip": "Gesperrte Scans freigeben",
    "uploadTooltip": "Scans hochladen",
    "addLocationButton": "Standort hinzufügen",
    "save": {
      "error": "Das Team konnte nicht gerettet werden!",
      "success": "Team ist gerettet"
    },
    "delete": {
      "confirm": "Sie wirklich entfernen?",
      "error": "Team kann nicht gelöscht werden!",
      "success": "Team wurde entfernt"
    },
    "filter": {
      "deleted": "Gelöschte teams",
      "onlyDeleted": "Nur gelöscht",
      "withoutDeleted": "Ohne gelöscht",
      "bothDeletedAndNot": "Beide",
      "today": "Heute"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "client": {
        "label": "Kunde"
      },
      "source": {
        "label": "Quelle der Daten",
        "options": {
          "incenova": "Nur Incenova",
          "bw": "Nur B&W",
          "all": "Alles"
        }
      },
      "subscriptionsCount": {
        "label": "Mannschaftsergebnis"
      },
      "day": {
        "label": "Tag"
      },
      "week": {
        "label": "Woche"
      },
      "attachement": {
        "label": "Ergebnis",
        "verify": "Überprüfen Sie"
      },
      "members": {
        "label": "Mitglieder"
      },
      "date": {
        "label": "Datum",
        "placeholder": "Datum..."
      },
      "startTime": {
        "label": "Startzeit"
      },
      "endTime": {
        "label": "Endzeit"
      },
      "status": {
        "label": "Status",
        "placeholder": "Status...",
        "value": {
          "planned": "Geplant",
          "uploaded": "Hochgeladen",
          "entry": "eingeben.",
          "completed": "Abgewickelt"
        }
      },
      "branch": {
        "label": "Zweigstelle",
        "placeholder": "Zweigstelle...",
        "permission": {
          "allocation": {
            "modify_team": "Anpassbar an alle Branchen, auch wenn der Benutzer nicht Mitglied dieser Branche ist.",
            "modify_team:branch": "Kann nur in Zweigen bearbeitet werden, in denen der Benutzer Mitglied ist. In der Auswahlliste werden auch nur die Zweige angezeigt, in denen sie Mitglied ist."
          }
        }
      },
      "campaign": {
        "label": "Kampagne",
        "placeholder": "Kampagne..."
      },
      "driver": {
        "label": "Treiber",
        "placeholder": "Fahrer..."
      },
      "location": {
        "label": "Standorte",
        "placeholder": "Standort...",
        "add": "Standort hinzufügen"
      },
      "teamMember": {
        "add": "Teammitglied hinzufügen"
      },
      "remarks": {
        "label": "Allgemeiner Hinweis"
      },
      "teamCaptain": {
        "label": "Mannschaftskapitän"
      },
      "shiftType": {
        "label": "Schichttyp"
      },
      "carDamageStart": {
        "label": "Car damage at the start"
      },
      "carDamageEnd": {
        "label": "Car damage at the end"
      },
      "carMileageStart": {
        "label": "Car mileage at the start"
      },
      "carMileageEnd": {
        "label": "Car mileage at the end"
      },
      "createdAt": {
        "label": "Created at"
      },
      "deleted": {
        "label": "Deleted"
      },
      "externalId": {
        "label": "External ID"
      },
      "licensePlate": {
        "label": "License plate"
      },
      "updatedAt": {
        "label": "Last updated at"
      }
    },
    "empty": {
      "label": "Leere Teams?",
      "only": "Nur leere Teams",
      "none": "Keine leeren Teams",
      "both": "Alle Teams"
    },
    "amount": {
      "label": "Anzahl der",
      "placeholder": "Anzahl der..."
    },
    "membersCount": {
      "label": "Mitglieder",
      "placeholder": "Mitglieder...",
      "empty": "Noch keine Teammitglieder..."
    },
    "batchUpload": {
      "error": "Ich konnte keine Scans hochladen!",
      "success": "Die Scans wurden hochgeladen",
      "errorUnknownFileType": "Wählen Sie eine ZIP-, PDF- oder JPEG-Datei."
    },
    "sheetToScan": {
      "error": "Ich konnte das Blatt nicht bewegen!",
      "success": "Das Blatt wurde verschoben"
    },
    "scanToSheet": {
      "error": "Der Scan konnte nicht bewegt werden!",
      "success": "Der Scan wurde verschoben"
    },
    "saveStatusEntry": {
      "error": "Kann den Status nicht ändern!",
      "success": "Der Status wurde aktualisiert"
    },
    "lockScan": {
      "error": "Scan kann nicht gesperrt werden!",
      "errorNoEmployee": "Scan kann nicht gesperrt werden! Bitte prüfen Sie, ob Ihr Login mit einem Benutzer verknüpft ist.",
      "noScans": "Keine Scans gefunden."
    },
    "modal": {
      "delete": {
        "content": "Sie sind dabei, ein Team zu löschen. Möchten Sie fortfahren?",
        "cancelButton": "Nein",
        "deleteButton": "entfernen"
      }
    },
    "member": {
      "delete": {
        "tooltip": "Teammitglied löschen"
      },
      "restore": {
        "tooltip": "Mitglied des Erholungsteams"
      }
    }
  },
  "teamLocation": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Standort",
        "placeholder": "Standort..."
      }
    }
  },
  "teamMember": {
    "field": {
      "employeeID": {
        "label": "Mitarbeiter-ID"
      },
      "plannedBrach": {
        "label": "Branche, für die er eingestellt wurde"
      },
      "tabletBranch": {
        "label": "Filialtablette"
      },
      "location": {
        "label": "Standort der Werft"
      },
      "campaign": {
        "label": "Kampagne"
      },
      "date": {
        "label": "Datum"
      },
      "id": {
        "label": "ID"
      },
      "team_member": {
        "label": "Teammitglied",
        "placeholder": "Teammitglied...",
      },
      "employee": {
        "label": "Teammitglied",
        "placeholder": "Teammitglied..."
      },
      "device": {
        "label": "Gerät",
        "placeholder": "Gerät..."
      },
      "function": {
        "label": "Funktion",
        "placeholder": "Funktion...",
        "info": "Die Funktion des Mitarbeiters am Tag dieses Teams"
      },
      "note": {
        "this_is_old": "nicht verwenden",
        "label": "Hinweis"
      },
      "remarks": {
        "label": "Hinweis"
      },
      "sign": {
        "in": "Eintragen",
        "out": "Abmelden"
      },
      "driver": {
        "label": "Ist Fahrer"
      },
      "subscriptionsCount": {
        "label": "Ergebnis"
      },
      "quantitiesCount": {
        "label": "Anzahl der"
      },
      "shiftType": {
        "label": "Schichttyp"
      },
      "autoSignedOut": {
        "label": "Automatically signed out"
      },
      "createdAt": {
        "label": "Created at"
      },
      "damageSignIn": {
        "label": "Damage at sign in"
      },
      "damageSignOut": {
        "label": "Damage at sign out"
      },
      "deleted": {
        "label": "Deleted"
      },
      "fieldDay": {
        "label": "Field day"
      },
      "functionOverridden": {
        "label": "Function overridden"
      },
      "shiftTypeOverride": {
        "label": "Shift type overridden"
      },
      "signIn": {
        "label": "Signed in"
      },
      "signInLat": {
        "label": "Latitude sign in"
      },
      "signInLng": {
        "label": "Longitude sign in"
      },
      "signInTime": {
        "label": "Time at sign in"
      },
      "signOut": {
        "label": "Signed out"
      },
      "signOutLat": {
        "label": "Latitude sign out"
      },
      "signOutLng": {
        "label": "Longitude sign out"
      },
      "signOutTime": {
        "label": "Time at sign out"
      },
      "team": {
        "label": "Team ID"
      },
      "updatedAt": {
        "label": "Last updated at"
      }
    },
    "duplicate": {
      "error": "Teammitglied bereits hinzugefügt. Bitte wählen Sie ein anderes Teammitglied."
    }
  },
  "traineeship": {
    "nav": {
      "overview": "Praktika"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "title": {
        "label": "Titel",
        "placeholder": "Titel..."
      },
      "slug": {
        "label": "Schnecke",
        "placeholder": "Schnecke..."
      },
      "ordering": {
        "label": "Bestellung",
        "placeholder": "Bestellen..."
      },
      "autoAssign": {
        "label": "Automatisch zuweisen",
        "placeholder": "Automatische Zuweisung...",
        "labelEdit": "Automatisch allen zuweisen?",
        "autoAssigning": "Automatisch zuweisen"
      },
      "video": {
        "label": "Video",
        "placeholder": "Video..."
      }
    },
    "create": {
      "title": "Praktikum hinzufügen"
    },
    "delete": {
      "error": "Praktikum kann nicht gelöscht werden!",
      "success": "Das Praktikum wurde gestrichen"
    },
    "edit": {
      "pagesTitle": "Seiten",
      "title": "Personalisierung des Praktikums"
    },
    "createButton": "Praktikum hinzufügen",
    "editTooltip": "Artikel bearbeiten",
    "statusSave": {
      "error": "Bei der Statusänderung ist etwas schief gelaufen.",
      "success": "Der Status wurde erfolgreich geändert."
    },
    "assignmentCreate": {
      "error": "Bei der Bereitstellung des Praktikumsplatzes ist etwas schief gelaufen.",
      "success": "Der Praktikumsplatz wurde erfolgreich zur Verfügung gestellt."
    },
    "contract": {
      "overview": "Vertrag"
    },
    "page": {
      "traineeshipEdit": "Personalisierung des Praktikums",
      "traineeshipPageEdit": "Ändern Sie die Seite {{id}}.",
      "traineeshipPageAdd": "Seite hinzufügen"
    }
  },
  "traineeshipProgress": {
    "employeeTitle": "Mitarbeiterin",
    "traineeshipTitle": "Praktikumsplatz",
    "traineeshipsTitle": "Praktika",
    "statusTitle": "Status",
    "statusUnopened": "Noch nicht geöffnet",
    "questionTitle": "Frage",
    "answerTitle": "Antwort",
    "noQuestions": "Keine Fragen",
    "noAnswer": "Nicht ausgefüllt",
    "errorOpening": "Praktikum konnte nicht geöffnet werden",
    "errorApproving": "Praktikum konnte nicht genehmigt werden"
  },
  "traineeshipPage": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "text": {
        "label": "Text",
        "placeholder": "Text..."
      },
      "textPreview": {
        "label": "Textvorschau"
      },
      "video": {
        "label": "Video"
      },
      "questions": {
        "label": "Fragen"
      },
      "type": {
        "label": "Typ",
        "placeholder": "Art der...",
        "value": {
          "text": "Text",
          "questions": "Fragen",
          "video": "Film",
          "end-evaluation": "Bewertung beenden"
        }
      },
      "ordering": {
        "label": "Bestellung"
      }
    },
    "create": {
      "title": "Seite hinzufügen"
    },
    "edit": {
      "title": "Seite anpassen"
    },
    "createButton": "Seite hinzufügen",
    "save": {
      "success": "Die Seite wurde gespeichert",
      "error": "Beim Speichern der Seite ist ein Fehler aufgetreten"
    },
    "upload": {
      "error": "Beim Hochladen des Videos ist etwas schiefgegangen"
    }
  },
  "traineeshipQuestion": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "type": {
        "label": "Typ",
        "value": {
          "input": "Freies Eingabefeld",
          "choice": "Mehrfachauswahl"
        }
      },
      "ordering": {
        "label": "Bestellung"
      },
      "question": {
        "label": "Frage",
        "placeholder": "Frage..."
      }
    },
    "createButton": "Frage hinzufügen",
    "newQuestion": "Frage hinzufügen",
    "editQuestion": "Frage {{id}} ändern",
    "selectQuestionType": "Wählen Sie den Fragetyp"
  },
  "traineeshipChoice": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "ordering": {
        "label": "Bestellung",
        "placeholder": "Bestellen..."
      },
      "answer": {
        "label": "Antwort",
        "placeholder": "Antwort..."
      }
    },
    "createButton": "Option hinzufügen"
  },
  "promotorAppMedia": {
    "nav": {
      "overview": "Projektträger App Media"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id"
      },
      "name": {
        "label": "Typ",
        "placeholder": "Name...",
        "value": {
          "ViaVia": "ViaVia",
          "to-chose": "Wählen Sie einen Wert"
        }
      },
      "enabled": {
        "label": "Aktiv",
        "placeholder": "Aktiv...",
        "value": {
          "true": "Ja",
          "false": "Nein"
        }
      },
      "description": {
        "label": "Beschreibung",
        "placeholder": "Beschreibung..."
      },
      "ViaViaImage": {
        "label": "Bild",
        "placeholder": "Bild...",
        "helpText": "Ziehen Sie ein Bild oder klicken Sie hier links, um es hochzuladen."
      },
      "textField": {
        "label": "Projektträger-App Medientext",
        "placeholder": "Promoter App Media Text..."
      }
    },
    "create": {
      "title": "Promoter-App Medien hinzufügen"
    },
    "edit": {
      "title": "Promoter-App Medien anpassen"
    },
    "createButton": "Promoter-App Medien hinzufügen",
    "editTooltip": "Promoter App Medienbearbeitung",
    "empty_image": "Leere"
  },
  "metafield": {
    "nav": {
      "overview": "Metafields"
    },
    "copyTooltip": "Artikel kopieren",
    "editTooltip": "Artikel bearbeiten",
    "page": {
      "overview": {
        "title": "Metafields"
      }
    },
    "createButton": "Metafelder hinzufügen",
    "clearDefaultButton": "Standard löschen",
    "save": {
      "error": "Metafeld konnte nicht gespeichert werden!",
      "success": "Metafelder werden gespeichert"
    },
    "modal": {
      "delete": {
        "header": "Metafeld löschen",
        "content": "Wenn Sie dieses Metafeld löschen, wird es auch aus den folgenden Produkten gelöscht:",
        "confirmation": "Sind Sie sicher, dass Sie dieses Metafeld löschen wollen?"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "slug": {
        "label": "Schnecke"
      },
      "label": {
        "label": "Etikett"
      },
      "controlledField": {
        "label": "Registrierungsfeld überschreiben",
        "info": "Der hier eingetragene Wert überschreibt den Standardwert eines Abonnements, subscriber. Derzeit wird nur der Betrag unterstützt. In diesem Fall wird der Wert dieses Feldes für das Betragsfeld des Teilnehmers verwendet"
      },
      "type": {
        "label": "Typ",
        "placeholder": "Art der...",
        "value": {
          "choice": "Auswahlfeld",
          "text": "Freies Eingabefeld",
          "boolean": "Wahr/Falsch",
          "multichoice": "Mehrfachauswahl",
          "suggestions": "Vorschläge"
        }
      },
      "valueType": {
        "label": "Wert Typ",
        "placeholder": "Wert dieses Typs",
        "value": {
          "text": "Text",
          "integer": "Ganzzahl",
          "float": "Schwimmer",
          "boolean": "boolean",
          "date": "Datum",
          "datetime": "datetime"
        }
      },
      "description": {
        "label": "Beschreibung",
        "placeholder": "Beschreibung..."
      },
      "is_preset": {
        "label": "Voreinstellung",
        "placeholder": "Voreinstellung..."
      },
      "ordering": {
        "label": "Bestellung",
        "placeholder": "Bestellen..."
      },
      "showInConfirm": {
        "label": "Zur Bestätigung anzeigen",
        "placeholder": "In der Bestätigung anzeigen..."
      },
      "required": {
        "label": "Erforderlich",
        "placeholder": "Erforderlich..."
      },
      "forTeamCsv": {
        "label": "Gruppierung für Team csv?"
      },
      "default_values": {
        "label": "Standardwert",
        "placeholder": "Standard..."
      },
      "metadata": {
        "amount": {
          "label": "Betrag"
        },
        "label": {
          "label": "Etikett"
        },
        "value": {
          "label": "Wert"
        },
        "default": {
          "label": "Standard"
        }
      }
    }
  },
  "viaVia": {
    "edit": {
      "title": "Bearbeiten viaVia"
    },
    "editTooltip": "Zweigstelle bearbeiten",
    "approveButton": "Zustimmen",
    "rejectButton": "Ablehnen",
    "nav": {
      "overview": "Via Via"
    },
    "unmatchButton": "Trennen Sie die Verbindung",
    "showMatchModalButton": "Verlinkung",
    "matchModal": {
      "title": "Manueller Abgleich",
      "search": "Suche nach Bewerbern"
    },
    "page": {
      "overview": {
        "title": "Über über"
      }
    },
    "save": {
      "error": "Konnte nicht via via speichern!",
      "success": "Via via wird gespeichert"
    },
    "detele": {
      "error": "Konnte nicht gelöscht werden via via!",
      "success": "Via via wird gelöscht"
    },
    "match": {
      "error": "Das Spiel konnte nicht gespeichert werden!",
      "success": "Spiel wird gespeichert"
    },
    "unmatch": {
      "error": "Konnte Unmatch nicht speichern!",
      "success": "Nichtübereinstimmung wird gespeichert"
    },
    "filter": {
      "search": {
        "label": "Suche",
        "placeholder": "Name..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "auto_matched": {
        "label": "AM?",
        "placeholder": "Automatisch...",
        "title": "Automatisch abgeglichen?"
      },
      "firstName": {
        "label": "Vornamen",
        "placeholder": "Vornamen..."
      },
      "middleName": {
        "label": "Einfügung",
        "placeholder": "Einfügung..."
      },
      "fullName": {
        "label": "Vor- und Nachname",
        "placeholder": "Vor- und Nachname..."
      },
      "lastName": {
        "label": "Nachname",
        "placeholder": "Nachname..."
      },
      "email": {
        "label": "E-Mail",
        "placeholder": "E-Mail..."
      },
      "status": {
        "label": "Status",
        "placeholder": "Status...",
        "value": {
          "open": "Öffnen Sie",
          "matched": "Verknüpft",
          "approved": "Abgewickelt"
        }
      },
      "phoneNumbers": {
        "label": "Rufnummer",
        "placeholder": "Rufnummer..."
      },
      "created_at": {
        "label": "Erstellt am",
        "placeholder": "Erstellt am..."
      },
      "branch": {
        "label": "Zweigstelle",
        "placeholder": "Zweigstelle..."
      },
      "job_application": {
        "label": "Antragsteller",
        "placeholder": "Antragsteller..."
      },
      "created_by": {
        "label": "Beigesteuert von",
        "placeholder": "Beigesteuert von..."
      }
    }
  },
  "companyVideo": {
    "field": {
      "id": {
        "label": "ID"
      },
      "ordering": {
        "label": "Bestellung",
        "placeholder": "Bestellen..."
      },
      "title": {
        "label": "Titel",
        "placeholder": "Titel..."
      },
      "description": {
        "label": "Beschreibung",
        "placeholder": "Beschreibung..."
      },
      "thumb": {
        "label": "Bild",
        "placeholder": "Bild..."
      },
      "open": {
        "label": "Öffentlich",
        "title": "Alle Nutzer können öffentliche Videos sehen."
      },
      "visibleToApplicants": {
        "label": "Für die Bewerber sichtbar",
      },
      "visibleToEmployees": {
        "label": "Für die Mitarbeiter sichtbar",
      },
      "video": {
        "label": "Video",
        "placeholder": "Video..."
      }
    }
  },
  "video": {
    "editTooltip": "Videobearbeitung",
    "createButton": "Video hinzufügen",
    "nav": {
      "overview": "Video"
    },
    "overview": {
      "title": "Videos",
      "addButton": "Video hinzufügen"
    },
    "edit": {
      "title": "Video anpassen"
    },
    "create": {
      "title": "Video hinzufügen"
    },
    "save": {
      "error": "Video kann nicht gespeichert werden!",
      "success": "Video wird gespeichert"
    },
    "delete": {
      "error": "Video kann nicht gelöscht werden!",
      "success": "Das Video wurde entfernt"
    },
    "filter": {
      "search": {
        "label": "Suche",
        "placeholder": "Name..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "ordering": {
        "label": "Bestellung",
        "placeholder": "Bestellen..."
      },
      "title": {
        "label": "Titel",
        "placeholder": "Titel..."
      },
      "description": {
        "label": "Beschreibung",
        "placeholder": "Beschreibung..."
      },
      "thumb": {
        "label": "Bild",
        "placeholder": "Bild..."
      },
      "open": {
        "label": "Öffentlich",
        "title": "Alle Nutzer können öffentliche Videos sehen."
      },
      "video": {
        "label": "Video",
        "placeholder": "Video..."
      }
    },
    "modal": {
      "delete": {
        "content": "Sie sind dabei, ein Video zu löschen. Möchten Sie fortfahren?",
        "cancelButton": "Nein",
        "deleteButton": "entfernen"
      }
    }
  },
  "device": {
    "buttons": {
      "duplicate": "Zusammenführen von Duplikaten",
      "qrCode": "Qr-Code anzeigen"
    },
    "logs": "Protokolle ansehen",
    "mergeButton": "Zusammenführen",
    "nav": {
      "overview": "Geräte"
    },
    "overview": {
      "title": "Geräte"
    },
    "modalMerge": {
      "title": "Duplikat wählen",
      "noDuplicatesFound": "Keine Duplikate gefunden"
    },
    "editTooltip": "Gerät anpassen",
    "historyTooltip": "Geschichte ansehen",
    "singular": "Gerät",
    "createButton": "Gerät hinzufügen",
    "platform": {
      "ios": "ios",
      "android": "android"
    },
    "statuses": {
      "active": "Aktiv",
      "unknown": "Unbekannt",
      "disabled": "Behinderte"
    },
    "save": {
      "error": "Gerät konnte nicht gespeichert werden!",
      "success": "Gerät wird gespeichert"
    },
    "filter": {
      "search": {
        "label": "Suche",
        "placeholder": "Gerätecode, Beschreibung..."
      },
      "branch": {
        "label": "Zweigstelle"
      },
      "status": {
        "label": "Status",
        "multiselect": {
          "selectedText": "Status von # #",
          "noneSelectedText": "Status auswählen"
        },
        "multiselectFilter": {
          "placeholder": "Suche..."
        }
      }
    },
    "field": {
      "identifier": {
        "label": "Gerätecode"
      },
      "description": {
        "label": "Beschreibung",
        "placeholder": "Beschreibung..."
      },
      "status": {
        "label": "Status",
        "value": {
          "active": "Aktiv",
          "unknown": "Unbekannt",
          "disabled": "Behinderte"
        }
      },
      "branch": {
        "label": "Standort",
        "placeholder": "Standort..."
      },
      "subBranches": {
        "label": "Standorte",
        "placeholder": "Standorte..."
      },
      "owner": {
        "label": "Eigentümer",
        "placeholder": "Inhaberin von..."
      },
      "employee": {
        "label": "Mitarbeiterin"
      },
      "mode": {
        "label": "Modus"
      },
      "platform": {
        "label": "Plattform"
      },
      "apiUrl": {
        "label": "CY_API_URL"
      },
      "referer": {
        "label": "CY_REFER"
      },
      "websocketUrl": {
        "label": "CY_WEBSOCKET_URL"
      },
      "apiKey": {
        "label": "CY_API_KEY"
      },
      "certName": {
        "label": "CY_CERT_NAME"
      },
      "googleMapsApiKey": {
        "label": "CY_GOOGLE_MAPS_API_KEY"
      },
      "sentryDsn": {
        "label": "CY_SENTRY_DSN"
      },
      "deviceEndpoint": {
        "label": "CY_DEVICE_ENDPOINT"
      },
      "employeesForDebug": {
        "label": "CY_MITARBEITER_FÜR_DEBUG"
      },
      "featureCaptainsheetDamage": {
        "label": "Merkmal Kapitänsblatt Schaden an?"
      },
      "featureRechtVanBezwaar": {
        "label": "Feature Recht auf Einspruch url?"
      },
      "deviceCode": {
        "label": "CY_DEVICE_CODE"
      },
      "lastUsed": {
        "label": "Zuletzt Verwendeten",
        "placeholder": "Zuletzt verwendet..."
      },
    },
    "duplicate": {
      "error": "Das Gerät ist bereits zugewiesen. Wählen Sie ein anderes Gerät."
    },
    "history": {
      "title": "Übersicht der Benutzer"
    },
    "total": {
      "label": "Insgesamt"
    },
  },
  "deviceLocation": {
    "field": {
      "moment": {
        "label": "Gemessen am"
      },
      "point": {
        "label": "Standort",
        "address": "Adresse"
      }
    }
  },
  "dashboard": {
    "nav": {
      "overview": "Dashboard"
    }
  },
  "position": {
    "field": {
      "startDate": {
        "label": "Anfangsdatum"
      },
      "job": {
        "label": "Funktion"
      }
    }
  },
  "incedentalExpense": {
    "button": {
      "true": "Genehmigen Sie",
      "false": "Ablehnen"
    },
    "form": {
      "either": "Beide",
      "true": "Genehmigt",
      "false": "Abgelehnt"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "compensationAmount": {
        "label": "Wert"
      },
      "employee": {
        "label": "Mitarbeiterin"
      },
      "month": {
        "label": "Datum"
      },
      "reason": {
        "label": "Grund"
      },
      "by": {
        "label": "Unter"
      },
      "bulkEmployees": {
        "label": "Mitarbeiter mit Problem"
      },
      "approved": {
        "label": "Genehmigt"
      },
      "action": {
        "label": "Aktion"
      },
      "approvedBy": {
        "label": "Zertifiziert durch"
      },
      "rejectionReason": {
        "label": "Grund der Missbilligung"
      },
      "type": {
        "label": "Typ",
        "value": {
          "gross": "Brutto",
          "net": "Neto"
        }
      },
      "category": {
        "label": "Kategorie",
        "netCatagory": {
          "consumption": "Verbrauch",
          "officeSupplies": "Büromaterial",
          "travel": "Reisen",
          "accommodation": "Unterkunft",
          "carRelatedExpenses": "Tanken / Parken",
          "other": "Ansonsten"
        },
        "grosCatagory": {
          "recruitment": "Rekrutierung",
          "bonus": "Bonus",
          "lead": "Blei",
          "other": "Ansonsten",
          "headquarter": "Hoofdkantoor",
          "call_center": 'Callcenter',
        }
      },
      "receipt": {
        "modal": {
          "title": "Entschädigung"
        },
        "label": "Quittung"
      }
    }
  },
  "factor": {
    "field": {
      "factor": {
        "label": "Faktor"
      },
      "startDate": {
        "label": "Anfangsdatum"
      },
      "endDate": {
        "label": "Enddatum"
      },
      "reason": {
        "label": "Grund"
      },
      "by": {
        "label": "Unter"
      },
      "action": {
        "label": "Aktion"
      }
    }
  },
  "employee": {
    "walkinModal": {
      "title": "Antragsteller kommt herein"
    },
    "earning": {
      "buttonTitle": "Löhne",
      "modalTitle": "Löhne im Zeitverlauf",
      "field": {
        "earning": {
          "label": "Löhne",
          "tag": "Löhne"
        },
        "period": {
          "label": "Zeitraum",
          "tag": "Zeitraum"
        }
      }
    },
    "period": "Zeitraum:",
    "periodWeek": "Zeitraum {{periodNumber}}:",
    "creditations": "Gutschrift",
    "incedentals": "Entschädigung für Nebenkosten",
    "invoiceNumber": "Rechnungsnummer",
    "compensation": {
      "modalTitle": "Sonstige Vergütungen Arbeitnehmer",
      "buttonTitle": "Entlohnung der Mitarbeiter",
      "bulkButtonTitle": "Erstellen von Massenkompensationen",
      "clearCompensation": "Einstellung der Kompensation auf Null",
      "saveCompensation": "Ausgleich speichern",
      "editCompensation": "Entschädigung für die Bearbeitung"
    },
    "factor": {
      "modalTitle": "Faktor Mitarbeiter",
      "buttonTitle": "Faktor Mitarbeiter",
      "clearCompensation": "Faktor auf Null setzen",
      "saveCompensation": "Faktor speichern",
      "editCompensation": "Editierfaktor"
    },
    "edit": {
      "title": "Mitarbeiter bearbeiten",
      "interviewTitle": "Interviews",
      "callBackTitle": "Rückruftermine",
      "attachmentTitle": "Anhänge",
      "positionTitle": "Positionen",
      intentionDeclaration:{
        download: "Int. decl.",
        french: "Französisch",
        dutch: "Niederländisch"
      },
      "disableSaveDR": "Du musst Vorder- und Rückseite deines Ausweises Hochladen bevor du speichern kannst."
    },
    "modal": {
      "viewID": {
        "title": "Mitarbeiterin",
        "toolTip": "ID-Mitarbeiter",
        "noID": "Keine ID für diesen Mitarbeiter verfügbar"
      }
    },
    "actions": "Aktionen",
    "editTooltip": "Mitarbeiter bearbeiten",
    "createButton": "Mitarbeiter hinzufügen",
    "attachment": "Anhang",
    "attachmentLabel": "Anhang",
    "attachmentEmpty": "Nicht hochgeladen",
    "uploadIDFirst": "Bitte laden Sie zuerst beide ID-Bilder hoch und speichern Sie sie",
    "nav": {
      "applicants": "Bewerben Sie sich für eine Stelle",
      "interviews": "Anwendungen",
      "traineeships": "Fortschritte des Praktikums",
      "overview": "Mitarbeiter"
    },
    "save": {
      "error": "Benutzer konnte nicht gespeichert werden!",
      "success": "Benutzer wird gespeichert"
    },
    "filter": {
      "group": {
        "selectedText": "# von # ausgewählten Gruppen",
        "noneSelectedText": "Gruppen auswählen",
        "placeholder": "Ich suche..."
      },
      "deleted": "Gestrichen medewerkers",
      "onlyDeleted": "Nur gestrichen",
      "withoutDeleted": "Ohne entfernt",
      "bothDeletedAntNot": "Alles"
    },
    "currentTraineeship": "Status eines Praktikums",
    "showTraineeshipButton": "Praktika anzeigen",
    "showTraineeshipButtonTooltip": "Praktika anzeigen",
    "showUserButton": "Benutzer anzeigen",
    "showUserButtonTooltip": "Benutzer anzeigen",
    "sendAppInviteEmail": {
      "tooltip": "Einladungs-E-Mail an den Projektträger senden",
      "success": "E-Mail wurde gesendet",
      "error": "E-Mail kann nicht gesendet werden"
    },
    "rotateEmployeeAvatar": {
      "success": "Avatar wird gedreht",
      "error": "Avatar kann nicht gedreht werden"
    },
    "traineeship": {
      "approveButton": "Genehmigen Sie",
      "reset": {
        "title": "Zurücksetzen",
        "confirm": "Sind Sie sicher, dass Sie das Praktikum dieses Mitarbeiters auf den Standardwert zurücksetzen wollen? Alle Antworten werden gelöscht.",
      }
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "employee": {
        "label": "Projektträger",
        "placeholder": "Projektträger..."
      },
      "adres": {
        "label": "Adresse"
      },
      "last_sign_in": {
        "label": "Letzter Arbeitstag"
      },
      "compensationAmount": {
        "label": "Gelegentlicher finanzieller Ausgleich"
      },
      "nationalityInternal": {
        "label": "Staatsangehörigkeit"
      },
      "firstName": {
        "label": "Vornamen",
        "placeholder": "Vornamen..."
      },
      "middleName": {
        "label": "Einfügung",
        "placeholder": "Einfügung..."
      },
      "lastName": {
        "label": "Nachname",
        "placeholder": "Nachname..."
      },
      "dateOfBirth": {
        "label": "Geburtsdatum"
      },
      "phoneNumbers": {
        "label": "Telefonnummern",
        "placeholder": "Telefonnummern..."
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "can_change_own_campaign": {
        "label": "Kann eigene Kampagne auswählen",
        "placeholder": "Sie können Ihre eigene Kampagne auswählen..."
      },
      "factor": {
        "label": "Faktor",
        "placeholder": "Der Faktor..."
      },
      "avatar": {
        "label": "Profilbild",
        "placeholder": "Profilbild..."
      },
      "mainBranch": {
        "label": "Haupt Niederlassung",
        "placeholder": "Hauptsitz auswählen..."
      },
      "main_branch": {
        "label": "Haupt Niederlassung",
        "placeholder": "Hauptsitz auswählen..."
      },
      "branches": {
        "label": "Niederlassungen",
        "placeholder": "Zweigstelle...",
        "selectedText": "# Anzahl der Betriebe",
        "noneSelectedText": "Ort auswählen"
      },
      "initials": {
        "label": "Initialen",
        "placeholder": "Initialen..."
      },
      "travelingDistance": {
        "label": "Reiseentfernung"
      },
      "first_name": {
        "label": "Vornamen",
        "placeholder": "Vornamen..."
      },
      "middle_name": {
        "label": "Einfügung",
        "placeholder": "Einfügung..."
      },
      "last_name": {
        "label": "Nachname",
        "placeholder": "Nachname..."
      },
      "gender": {
        "label": "Geschlecht",
        "value": {
          "male": "Mann",
          "female": "Frau",
          "other": "Anderes",
          "unkown": "unbekannt"
        }
      },
      "age": {
        "label": "Angegebenes Alter"
      },
      "date_of_birth": {
        "label": "Geburtsdatum"
      },
      "phone_numbers": {
        "label": "Telefonnummern",
        "placeholder": "Telefonnummern..."
      },
      "ssn": {
        "label": "Sozialversicherungsnummer",
        "placeholder": "BSN..."
      },
      "ssnBE": {
        "label": "Sozialversicherungsnummer",
        "placeholder": "Sozialversicherungsnummer..."
      },
      "isFreelance": {
        "label": "Selbstständig?"
      },
      "companyName": {
        "label": "Name des Unternehmens"
      },
      "dateStarted": {
        "label": "Anfangsdatum"
      },
      "vatNumber": {
        "label": "UMSATZSTEUERIDENTIFIKATIONSNUMMER"
      },
      "cocNumber": {
        "label": "KVK-Nummer"
      },
      "referralSource": {
        "label": "Ursprung Lead"
      },
      "country": {
        "label": "Land",
        "placeholder": "Land...",
        "value": {
          "NL": "DE",
          "BE": "BE",
          "FR": "FR",
          "DE": "DIE",
          "OTHER": "Ansonsten"
        }
      },
      "iban": {
        "label": "IBAN",
        "placeholder": "IBAN..."
      },
      "house_number": {
        "label": "Hausnummer",
        "placeholder": "Hausnummer..."
      },
      "houseNumber": {
        "label": "Hausnummer",
        "placeholder": "Hausnummer..."
      },
      "house_number_suffix": {
        "label": "Hausnummer Zusatz",
        "placeholder": "Hausnummer Zusatz..."
      },
      "houseNumberSuffix": {
        "label": "Hausnummer Zusatz",
        "placeholder": "Hausnummer Zusatz..."
      },
      "city": {
        "label": "Stadt",
        "placeholder": "Stadt..."
      },
      "city_of_residence": {
        "label": "Standort"
      },
      "email": {
        "label": "E-Mail",
        "placeholder": "E-Mail..."
      },
      "street": {
        "label": "Straße",
        "placeholder": "Straße..."
      },
      "zip_code": {
        "label": "Postleitzahl",
        "placeholder": "Postleitzahl..."
      },
      "zipCode": {
        "label": "Postleitzahl",
        "placeholder": "Postleitzahl..."
      },
      "employee_number": {
        "label": "Kein Personal.",
        "placeholder": "Kein Personal..."
      },
      "employeeNumber": {
        "label": "Kein Personal.",
        "placeholder": "Kein Personal..."
      },
      "driving_licence": {
        "label": "Hat einen Führerschein",
        "placeholder": "Führerschein...",
        "value": {
          "false": "Nein",
          "true": "Ja"
        }
      },
      "public_transport_student_license": {
        "label": "Hat Student OV",
        "placeholder": "Student OV...",
        "value": {
          "false": "Nein",
          "true": "Ja"
        }
      },
      "publicTransportStudentLicenseType": {
        "label": "Typ des Studenten OV",
        "value": {
          "no": "Nein",
          "week": "Woche",
          "weekend": "Wochenende"
        }
      },
      "drivingLicence": {
        "label": "Hat einen Führerschein",
        "placeholder": "Führerschein...",
        "value": {
          "false": "Nein",
          "true": "Ja"
        }
      },
      "canCall": {
        "label": "Callcenter-Mitarbeiter",
        "value": {
          "false": "Nein",
          "true": "Ja",
          "both": "Beide"
        }
      },
      "bank_card": {
        "label": "Bankkarte",
        "placeholder": "Bankkarte..."
      },
      "bankCard": {
        "label": "Bankkarte",
        "placeholder": "Bankkarte..."
      },
      "tax_exemption": {
        "label": "Steuergutschrift",
        "placeholder": "Steuergutschriften..."
      },
      "taxExemption": {
        "label": "Steuergutschrift",
        "placeholder": "Steuergutschriften..."
      },
      "can_change_tax_exemption": {
        "label": "Können sich Steuerbefreiungen ändern?",
        "placeholder": "Können sich Steuerbefreiungen ändern?"
      },
      "canChangeTaxExemption": {
        "label": "Können sich Steuerbefreiungen ändern?",
        "placeholder": "Können sich Steuerbefreiungen ändern?"
      },
      "contract_signature": {
        "label": "Vertrag",
        "placeholder": "Vertrag..."
      },
      "contractSignature": {
        "label": "Vertrag",
        "placeholder": "Vertrag..."
      },
      "identification": {
        "label": "Vorderseite der ID-Karte",
        "placeholder": "Vorderseite der ID-Karte..."
      },
      "identification_back": {
        "label": "Rückseite der ID-Karte",
        "placeholder": "ID-Karte zurück..."
      },
      "identificationBack": {
        "label": "Rückseite der ID-Karte",
        "placeholder": "ID-Karte zurück..."
      },
      "bw_id": {
        "label": "Briggs und Walker id",
        "placeholder": "Briggs und Walker id..."
      },
      "bwID": {
        "label": "Briggs und Walker id",
        "placeholder": "Briggs und Walker id..."
      },
      "city_of_birth": {
        "label": "Geburtsort",
        "placeholder": "Geburtsort..."
      },
      "cityOfBirth": {
        "label": "Geburtsort",
        "placeholder": "Geburtsort..."
      },
      "country_of_birth": {
        "label": "Land der Geburt",
        "placeholder": "Land der Geburt..."
      },
      "countryOfBirth": {
        "label": "Land der Geburt",
        "placeholder": "Land der Geburt..."
      },
      "id_type": {
        "label": "ID-Typ",
        "placeholder": "ID-Typ..."
      },
      "idType": {
        "label": "ID-Typ",
        "placeholder": "ID-Typ..."
      },
      "id_number": {
        "label": "ID-Nummer",
        "placeholder": "ID-Nummer..."
      },
      "idNumber": {
        "label": "ID-Nummer",
        "placeholder": "ID-Nummer..."
      },
      "interviewer": {
        "label": "Interviewer?",
        "title": "Der Mitarbeiter kann dann aus der Liste der Interviewer für Vorstellungsgespräche ausgewählt werden."
      },
      "coins": {
        "label": "Münzen"
      },
      "blockedClients": {
        "label": "Gesperrte Kunden"
      },
      status: {
        label: 'Status',
        value: {
          active: "Aktiv",
          inactive: "Inaktiv",
          outflow: "Abfluss",
        }
      },
      "referralSourceOther": {
        "label": "Andere Ursprung"
      },
      "points": {
        "label": "Punkte"
      },
      "legalGuardianName": {
        "label": "Name des Erziehungsberechtigten"
      },
      "legalGuardianPhone": {
        "label": "Telefon des Erziehungsberechtigten"
      },
      "legalGuardianEmail": {
        "label": "E-Mail Adresse des Erziehungsberechtigten"
      },
      "externalContractSigned": {
        "label": "Vertrag außerhalb des Systems unterzeichnet"
      },
      "bic": {
        "label": "BIC",
        "placeholder": "BIC..."
      },
      "canSelectOwnCampaign": {
        "label": "Kann eigene Kampagne auswählen",
        "info": "Dieser Freiberufler kann wählen, für welche Kampagnen er oder sie rekrutiert (dies geschieht über die Außendienst-App)"
      }
    },
    "isActive": "Benutzer ist aktiviert",
    status: {
      missingDocument: "fehlendes Dokument"
    },
    "isNotActive": "Benutzerkonto noch nicht aktiviert, siehe Schaltfläche \"Aktivierungsmail erneut senden\" am unteren Bildschirmrand, um eine neue E-Mail zu senden",
    "calls": {
      "modal": {
        "incoming": "Eingehende Anrufe",
        "outcoming": "Ausgehende Anrufe",
        "buttonTitle": "Telefonanrufe",
        "id": "ID",
        "caller": "Anrufer",
        "calledUser": "An",
        "start": "Start",
        "duration": "Dauer",
        "voicelogs": "Voicelogs",
        "listenCall": "Konversation nach dem Hören",
        "audioError": "Ihr Browser unterstützt das Audio-Tag nicht."
      },
      "modalTitle": "Telefonanrufe von Mitarbeitern"
    },
    "credits": "Kredit",
    "anonymizeModal": {
      'title': 'Anonymisieren',
      'description': 'Sind Sie sicher, dass Sie diesen Mitarbeiter anonymisieren wollen {{fullName}}? Alle persönlichen Informationen werden überschrieben. Dies kann nicht rückgängig gemacht werden.',
      'anonymizeButton': 'Anonymize',
    }
  },
  "export": {
    "createButton": "Exportieren Hinzufügen",
    "editTooltip": "Anpassung der Ergebnisse",
    "exportTooltip": "Ergebnisse exportieren",
    "save": {
      "error": "Die Definition konnte nicht gespeichert werden!",
      "success": "Definition wird gespeichert"
    },
    "download": {
      "error": "Export konnte nicht heruntergeladen werden!"
    },
    "value_null": {
      "null": "ist gleich null",
      "not_null": "nicht gleich Null ist"
    },
    "date_component_truncate": {
      "century": "Jahrhundert",
      "day": "hallo",
      "decade": "Jahrzehnt",
      "dow": "Tag der Woche",
      "doy": "Tag des Jahres",
      "epoch": "Sekunden seit 1-1-1970 00:00:00 UTC",
      "hour": "Stunde",
      "isodow": "Tag der Woche (ISO)",
      "isoyear": "Tag des Jahres (ISO)",
      "microseconds": "Mikrosekunde",
      "millenium": "Millennium",
      "milliseconds": "Millisekunde",
      "minute": "Minute",
      "month": "Monat",
      "quarter": "Quartal",
      "second": "zweite",
      "timezone": "UTC-Zeitzonenunterschied",
      "timezone_hour": "Stunden Unterschied zu UTC",
      "timezone_minute": "Minuten Unterschied zu UTC",
      "week": "Woche",
      "year": "Jahr",
      "millennium": "Millennium"
    }
  },
  "branch": {
    "textInputExample": "Die verfügbaren Zeiten für diesen Zweig im Popup-Fenster des Interviewplans bei der Anfrage. Eingabe pro Zeiteinheit. Z.B. 10:00 AM oder 01:30 PM",
    "interviewSlots": "Interview-Slots",
    "interviewTimes": "Nach Tag, in Zeitfenstern z. B. 10:00 Uhr",
    "createButton": "Zweigstelle erstellen",
    "editTooltip": "Zweigstelle bearbeiten",
    "nav": {
      "overview": "Zweigstellen"
    },
    "create": {
      "title": "Zweigstelle erstellen"
    },
    "overview": {
      "createButton": "Zweigstelle hinzufügen"
    },
    "edit": {
      "title": "Standort wechseln"
    },
    "save": {
      "error": "Konnte die Einrichtung nicht retten!",
      "success": "Niederlassung wird gespeichert"
    },
    "filter": {
      "search": {
        "label": "Suche",
        "placeholder": "Name..."
      }
    },
    "field": {
      "mailjetTemplateWelcome": {
        "label": "Vorlage ID Willkommens-E-Mail",
        "info": "Die Willkommensnachricht, die neue Anfragen aus dieser Zweigstelle erhalten, eine mailjet transactional id"
      },
      "mailjetTemplateActivation": {
        "info": "Die Nachricht zur Aktivierung des Kontos, die neue Bewerber/Beschäftigte von dieser Zweigstelle erhalten, eine mailjet-Transaktions-ID",
        "label": "E-Mail zur Aktivierung der Vorlagen-ID"
      },
      "mailjetTemplatePasswordReset": {
        "info": "Die Vorlage zum Zurücksetzen des Kennworts für diese Zweigstelle, eine mailjet-Transaktions-ID",
        "label": "Vorlage ID Passwort zurücksetzen E-Mail"
      },
      "mailjetTemplateAccountEdited": {
        "info": "Die Mailjet-Vorlage für die E-Mail, die Mitarbeiter erhalten, wenn ihre Daten im System aktualisiert werden, eine Mailjet-Transaktionskennung",
        "label": "Vorlage ID-Konto benutzerdefinierte E-Mail"
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "branch": {
        "label": "Zweigstelle",
        "placeholder": "Zweigstelle..."
      },
      "code": {
        "label": "Code",
        "placeholder": "Code..."
      },
      "avatar": {
        "label": "Avatar",
        "placeholder": "Avatar..."
      },
      "address": {
        "label": "Adresse",
        "placeholder": "Adresse..."
      },
      "phoneNumber": {
        "label": "Rufnummer",
        "placeholder": "Rufnummer..."
      },
      "email": {
        "label": "E-Mail",
        "placeholder": "E-Mail..."
      },
      "manager": {
        "label": "Manager",
        "placeholder": "Manager auswählen..."
      },
      "nmbrsCodeCostplace": {
        "label": "Nmbrs Code Costcenter",
        "placeholder": "Nmbrs Code Costcenter..."
      },
      "nmbrsCodeDepartment": {
        "label": "Abteilung Nmbrs Code",
        "placeholder": "Nmbrs Code Abteilung..."
      },
      "public": {
        "label": "Öffentlich?",
        "info": "Alle Benutzer können öffentliche Festungen sehen.",
        "title": "Öffentlich?"
      }
    },
    "multiselect": {
      "placeholder": "Zweigstelle suchen...",
      "selectedText": "Ausgewählte Betriebe # der # Betriebe",
      "noneSelectedText": "Ort auswählen"
    }
  },
  "bwBranchTranslation": {
    "editTooltip": "Zweigstelle bearbeiten",
    "createButton": "Zweigstelle erstellen",
    "nav": {
      "overview": "Zweigstellen"
    },
    "create": {
      "title": "Zweigstelle erstellen"
    },
    "overview": {
      "createButton": "Zweigstelle hinzufügen",
      "title": "BW-Filialen"
    },
    "edit": {
      "title": "Standort wechseln"
    },
    "save": {
      "error": "Konnte die Einrichtung nicht retten!",
      "success": "Niederlassung wird gespeichert"
    },
    "filter": {
      "search": {
        "label": "Suche",
        "placeholder": "Name..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "officeCode": {
        "label": "Bürokodex"
      },
      "branch": {
        "label": "Zweigstelle",
        "placeholder": "Zweigstelle..."
      },
      "code": {
        "label": "Code",
        "placeholder": "Code..."
      },
      "avatar": {
        "label": "Avatar",
        "placeholder": "Avatar..."
      },
      "address": {
        "label": "Adresse",
        "placeholder": "Adresse..."
      },
      "phone_number": {
        "label": "Rufnummer",
        "placeholder": "Rufnummer..."
      },
      "email": {
        "label": "E-Mail",
        "placeholder": "E-Mail..."
      },
      "manager": {
        "label": "Manager",
        "placeholder": "Manager auswählen..."
      },
      "nmbrs_code_costplace": {
        "label": "Nmbrs Code Costcenter",
        "placeholder": "Nmbrs Code Costcenter..."
      },
      "nmbrs_code_department": {
        "label": "Abteilung Nmbrs Code",
        "placeholder": "Nmbrs Code Abteilung..."
      },
      "public": {
        "label": "Öffentlich?",
        "title": "Alle Benutzer können öffentliche Festungen sehen."
      }
    },
    "multiselect": {
      "placeholder": "Zweigstelle suchen...",
      "selectedText": "Ausgewählte Betriebe # der # Betriebe",
      "noneSelectedText": "Ort auswählen"
    }
  },
  "exportArchive": {
    "title": "Archiv exportieren",
    "download": "Herunterladen",
    "field": {
      "id": {
        "label": "ID"
      },
      "file": {
        "label": "Herunterladen"
      },
      "bundleDescription": {
        "label": "Exportieren"
      },
      "definitionDescription": {
        "label": "Datei"
      },
      "createdAt": {
        "label": "Exportiert am"
      }
    }
  },
  "bucket": {
    "save": {
      "error": "Konnte Eimer nicht retten!",
      "success": "Eimer wird gespeichert"
    },
    "field": {
      "callers": {
        "label": "Nabellers",
        "placeholder": "Anrufer hinzufügen..."
      }
    }
  },
  "call": {
    "dropdownError": "Terminstatus festlegen!",
    "resultOptions": {
      "reachedChanged": "Abonnement geändert",
      "reachedThrough": "Implementierung des abonnements",
      "reachedCancelled": "Abonnement gekündigt",
      "reachedLater": "Abonnement später",
      "reachedCallback": "Rückruftermin (T.B.A.) vereinbart",
      "notReachedPhone": "Telefonnummer falsch/nicht vom kunden",
      "notReachedCallBack": "Nicht aufgezeichnet, morgen wieder",
      "notReachedCallBackSoon": "Nicht aufgezeichnet, wieder in fünf minuten",
      "notReachedNoAnswer": "Nicht enthalten, weiter für den export"
    },
    "modal": {
      "anonymous": "Vermittler",
      "downloadButton": "Voicelog herunterladen"
    },
    "mobile": "Mobil",
    "landLine": "Festgelegt",
    "attempts": "Anrufversuche",
    "maxAttempts": "Maximale Anzahl",
    "statistics": {
      "amount": "Anzahl der Datensätze",
      "target": "Noch zu erreichen",
      "reached": "Erreicht",
      "percentage": "Prozentuale Reichweite",
      "not_reachable": "Unerreichbar",
      "cancelations": "Stornierung",
      "not_reached": "Nicht erreicht",
      "call_later": "Später zurückrufen",
      "label": "Statistik"
    },
    "toggleScanButton": {
      "show": "Scan anzeigen",
      "hide": "Scan ausblenden"
    },
    "confirmModal": {
      "unsavedChanges": "Der aktuelle termin enthält ungespeicherte änderungen. Möchten Sie wirklich wechseln",
      "cancelSub": "Sind sie sicher, dass Sie dieses abonnement kündigen möchten?",
      "callTomorrow": "Möchten sie diese anmeldung morgen wirklich zurückrufen?",
      "callFiveMin": "Sind sie sicher, dass sie diese registrierung in fünf minuten zurückrufen möchten?"
    },
    "newAppointment": {
      "modalHeader": "Neuen termin planen",
      "header": "Datum und uhrzeit des neuen termins auswählen"
    },
  },
  "campaign": {
    "editTooltip": "Artikel bearbeiten",
    "nav": {
      "overview": "Kampagnen"
    },
    "page": {
      "overview": {
        "title": "Kampagne"
      }
    },
    "createButton": "Kampagne hinzufügen",
    "save": {
      "error": "Die Kampagne konnte nicht gespeichert werden!",
      "success": "Kampagne wird gespeichert"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "rexV2OutboundNumber": {
        "label": "Ausgewählte Nummer Rex",
        "options": {
          "anonymous": "Anonym"
        }
      },
      "branches": {
        "label": "Zweigstelle",
        "placeholder": "Zweigstelle...",
        "selectedText": "# Anzahl der Betriebe",
        "noneSelectedText": "Ort auswählen",
        "info": "Welche Filialen diese Aktion nutzen können, kann auch von den ZZP-Mitarbeitern in ihrer (Haupt-)Filiale eingeschränkt werden"
      },
      "products": {
        "label": "Produkte",
        "placeholder": "Produkte und...",
        "selectedText": "# von # Produkten",
        "noneSelectedText": "Produkt auswählen"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "captainsheetContractText": {
        "label": "Kapitänsbuch Vertrag"
      },
      "callScriptWelcome": {
        "label": "Belscript",
        "placeholder": "Belscript..."
      },
      "client": {
        "label": "Kunde",
        "placeholder": "Kunde..."
      },
      "draftContentbundle": {
        "label": "Entwurf eines Inhaltsbündels",
        "placeholder": "Entwurf eines Inhaltsbündels...",
        "info": "Wenn sich eine Tabelle im Entwurfsmodus (Testmodus) befindet, wird dieses Inhaltsbündel mitgesendet"
      },
      "finalContentbundle": {
        "label": "Endgültiges Inhaltsbündel",
        "placeholder": "Endgültiges Inhaltsbündel...",
        "info": "Wenn sich eine Tabelle im endgültigen (Produktions-)Modus befindet, wird dieses Inhaltsbündel mit ihr verschickt"
      },
      "unit": {
        "label": "Preisangebot",
        "info": "Der Zeitraum, für den die Gebühr auf dem Produktregistrierungsbildschirm angezeigt wird, z. B. monatlich, wöchentlich oder täglich"
      },
      "rexUsername": {
        "label": "REX-Erweiterung Benutzernamen",
        "info": "Der Benutzername der spezifischen Rufnummer (aus rex V1), die für diese spezifische Kampagne angerufen werden soll"
      },
      "rexPassword": {
        "label": "REX-Erweiterungspasswort",
        "info": "das Passwort der spezifischen Telefonnummer (aus rex V1), die für diese spezifische Kampagne verwendet werden soll"
      },
      "enableStartDate": {
        "label": "Startdatum verwenden",
        "info": "Verwenden Sie für diese Kampagne das Startdatum, das für das zugehörige Produkt konfiguriert wurde."
      },
      "bwIds": {
        "label": "Briggs und Walker id",
        "placeholder": "Briggs und Walker id..."
      },
      "enableEmergencySignature": {
        "label": "Unterschrift als Nachweis akzeptieren",
        "info": "Auf dem Registrierungsbildschirm des Tablets wird die Möglichkeit angezeigt, die Unterschriftenverifizierung anstelle der SMS-Verifizierung zu verwenden."
      },
      "enableGenderOther": {
        "label": "Geschlecht als 'Andere' akzeptieren",
        "info": "Auf dem Registrierungsbildschirm des Tablets wird die Möglichkeit angezeigt, das Geschlecht als 'Andere' zu verwenden."
      },
      "requireFirstName": {
        "label": "Vorname muss festgelegt werden",
        "info": "Fordern auf dem Registrierungsbildschirm des Tablets an, dass der Vorname festgelegt ist"
      },
      "defaultPaymentInterval": {
        "label": "Standardfrequenz",
        "info": "Der Standard-Zahlungsrhythmus, der für alle Registrierungen dieser Kampagne verwendet wird, wenn der Personalvermittler/Kunde keinen bestimmten Zahlungszeitraum ausgewählt hat"
      },
      "sepa": {
        "label": "SEPA",
        "placeholder": "SEPA"
      },
      "configuration": {
        "label": "Konfiguration",
      },
      "wageStructure": {
        "label": "Aktive Bonusstruktur",
        "info": "Die derzeitige Bonusstruktur zur Belohnung von Mitarbeitern, die Ergebnisse im Rahmen dieser Kampagne erzielt haben"
      },
      "ageWildcards": {
        "label": "Jokers",
      }
    }
  },
  "csv": {
    "csvMissing": "Hochladen einer csv-Datei",
    "createButton": "Csv-Upload",
    "field": {
      "id": {
        "label": "Id"
      },
      "createdAt": {
        "label": "Datum des Hochladens"
      },
      "updatedAt": {
        "label": "Letzte Aktualisierung"
      },
      "uploader": {
        "label": "Uploader"
      },
      "csv": {
        "label": "Datei"
      },
      "type": {
        "label": "Typ",
        "options": {
          "coin_addition": "Zusatz von münzen",
          "external_sub_to_call": "Externe registrierung nach der aufforderung",
          "client_ref_nrs": "Kunden referenznummer ",
          "job_applications": "Bewerbungen",
          "azg_subscriptions": "AzG ausschreibungen",
        },
        "example": {
          "title": "Erläuterung",
          "downloadExampleButton": "Beispieldatei herunterladen",
          "coin_addition": "Die CSV-Datei besteht aus 2 spalten: ID des Mitarbeiters und Anzahl der Münzen in ganzen Zahlen. Siehe auch die Beispieldatei mit 2 Arbeitnehmern. Die erste Zeile ist für den Mitarbeiter mit der Kennung 1, der 25 Münzen zusätzlich erhält. Die zweite Zeile ist für Mitarbeiter 2, der 13 Münzen zusätzlich erhält.",
          "external_sub_to_call": "Die CSV-Datei besteht aus mindestens 26 Spalten: external_id, status, employee_id, recruit_date, start_date, frequency, datetime_of_cancellation, date_of_birth, first_name, middle_name, last_name, gender, street, house_no, house_no_suffix, city, zipcode, country, email, mobile, landline, iban, product_id, campaign_id, amount, quantity, *custom. Das folgende Beispiel zeigt eine csv-Datei mit einer Registrierung. Die letzte Spalte bedeutet, dass jede Spalte nach der 21. Spalte als Satz in das Kommentarfeld der Ausschreibung eingefügt wird.",
          "client_ref_nrs": "Die CSV-Datei besteht aus 2 spalten: referenznummer und kunden id.",
          "job_applications": "Die CSV-Datei besteht aus 8 spalten: creation date, first_name, initials, last_name, email, phone_number, referral_source, referral_source_other.",
          "azg_subscriptions": "The CSV file is unchanged format CSV form AzG. Columns which are processed are: id_werving, qual_id, opmerking, bedrag NIEUW, Frequentie NIEUW (columns number 7,9,16,18 respectively)."
        }
      },
      "status": {
        "label": "Status",
        "options": {
          "uploaded": "Hochgeladen",
          "processed": "Verarbeitet"
        }
      }
    }
  },
  "bwProductTranslation": {
    "edit": {
      "b&w": "SCHWARZWEISS",
      "sms": "Incenova",
      "metafields": "Metafields"
    },
    "field": {
      "id": {
        "label": "id"
      },
      "bwCampaign": {
        "label": "B&W-Kampagne"
      },
      "bwProductId": {
        "label": "Briggs & Walker Id"
      },
      "accountDomain": {
        "label": "Konto Bereich B&W"
      },
      "projectCode": {
        "label": "Projekt Code B&W"
      },
      "productCode": {
        "label": "Produkt-Code B&W"
      },
      "product": {
        "label": "Produkt Incenova"
      },
      "quantity": {
        "label": "Anzahl der"
      },
      "amount": {
        "label": "Preis pro Stück"
      }
    }
  },
  "ContentbundleTranslation": {
    "edit": {
      "Contentbundle": "Modul Registrierung"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "name": {
        "label": "Name"
      }
    }
  },
  "bwCampaignTranslation": {
    "nav": {
      "overview": "BwCampagnes"
    },
    "page": {
      "overview": {
        "title": "BW-Kampagne"
      }
    },
    "createButton": "Kampagne hinzufügen",
    "save": {
      "error": "Die Kampagne konnte nicht gespeichert werden!",
      "success": "Kampagne wird gespeichert"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "branches": {
        "label": "Zweigstelle",
        "placeholder": "Zweigstelle...",
        "selectedText": "# Anzahl der Betriebe",
        "noneSelectedText": "Ort auswählen"
      },
      "campaign": {
        "label": "Incenova-Kampagne"
      },
      "products": {
        "label": "Produkte",
        "placeholder": "Produkte und...",
        "selectedText": "# von # Produkten",
        "noneSelectedText": "Produkt auswählen"
      },
      "accountDomain": {
        "label": "Konto Bereich B&W"
      },
      "projectCode": {
        "label": "Projekt Code B&W"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "callScriptWelcome": {
        "label": "Belscript",
        "placeholder": "Belscript..."
      },
      "client": {
        "label": "Kunde",
        "placeholder": "Kunde..."
      },
      "draftContentbundle": {
        "label": "Entwurf eines Inhaltsbündels",
        "placeholder": "Entwurf eines Inhaltsbündels..."
      },
      "finalContentbundle": {
        "label": "Endgültiges Inhaltsbündel",
        "placeholder": "Endgültiges Inhaltsbündel..."
      },
      "unit": {
        "label": "Preisangebot"
      },
      "enableStartDate": {
        "label": "Startdatum verwenden"
      },
      "bwIds": {
        "label": "Briggs und Walker id",
        "placeholder": "Briggs und Walker id..."
      },
      "captainsheetContract": {
        "label": "Kapitänsbuch Vertrag",
        "placeholder": "Vertrag mit dem Kapitänsblatt..."
      },
      "importStatus": {
        "label": "Status des Abonnementimports"
      }
    }
  },
  "bwProductDefaultMetafieldValue": {
    "field": {
      "value": {
        "label": "Wert"
      }
    }
  },
  "chapter": {
    "createButton": "Neues Kapitel",
    "save": {
      "error": "Das Kapitel konnte nicht gespeichert werden!",
      "duplicateNameError": "Es gibt bereits ein Kapitel mit diesem Namen!",
      "noNameError": "Sie haben keinen Namen eingegeben!",
      "success": "Kapitel wird gespeichert"
    },
    "delete": {
      "content": "Sie sind dabei, ein Kapitel zu löschen. Möchten Sie fortfahren?",
      "error": "Kapitel kann nicht gelöscht werden!",
      "success": "Das Kapitel wurde gelöscht"
    }
  },
  "permission": {
    "showDescriptionButton": "Beschreibung der Rechte anzeigen",
    "hideDescriptionButton": "Beschreibung der Rechte ausblenden",
    "allocation": {
      "modify_team": {
        "label": "Teams erstellen, aktualisieren und löschen",
        "description": "Anpassbar an alle Branchen, auch wenn der Benutzer nicht Mitglied dieser Branche ist."
      },
      "modify_team:branch": {
        "label": "Teams für die eigene Niederlassung erstellen, aktualisieren und löschen",
        "description": "Kann nur in Zweigen bearbeitet werden, in denen der Benutzer Mitglied ist."
      }
    }
  },
  "qrCodeGenerator": {
    "title": "Qr-Code-Generator",
    "field": {
      "CY_API_URL": "CY_API_URL",
      "CY_REFERER": "CY_REFER",
      "CY_WEBSOCKET_URL": "CY_WEBSOCKET_URL",
      "CY_API_KEY": "CY_API_KEY",
      "CY_CERT_NAME": "CY_CERT_NAME",
      "CY_GOOGLE_MAPS_API_KEY": "CY_GOOGLE_MAPS_API_KEY",
      "CY_SENTRY_DSN": "CY_SENTRY_DSN",
      "CY_DEVICE_ENDPOINT": "CY_DEVICE_ENDPOINT",
      "CY_EMPLOYEES_FOR_DEBUG": "CY_MITARBEITER_FÜR_DEBUG",
      "CY_DEVICE_CODE": "CY_DEVICE_CODE"
    }
  },
  "tooltips": {
    "delete": "entfernen",
    "restore": "Wiederherstellen",
    "edit": "bearbeiten",
    "view": "Siehe",
    "rotate": "Wenden"
  },
  "bwImportFailure": {
    "field": {
      "trash": {
        "label": "Fehler beseitigen"
      },
      "id": {
        "label": "ID"
      },
      "bwSubscriptionId": {
        "label": "B&W-Registrierungsreferenz"
      },
      "failure": {
        "label": "Fehler"
      },
      "bwData": {
        "label": "Daten"
      },
      "createdAt": {
        "label": "Fehlerhafter Moment"
      }
    }
  },
  "news": {
    "createButton": "Nachrichten erstellen",
    "saveAndPublishButton": "Speichern und veröffentlichen",
    "field": {
      "id": {
        "label": "ID"
      },
      "title": {
        "label": "Titel"
      },
      "image": {
        "label": "Bild"
      },
      "status": {
        "label": "Status",
        "value": {
          "draft": "Konzept",
          "published": "Veröffentlicht"
        }
      },
      "content": {
        "label": "Nachricht",
        "preview": "Beispiel",
        "markdown": "Markdown-Formatierung"
      },
      "createdBy": {
        "label": "Erstellt von"
      },
      "createdAt": {
        "label": "Erstellt am"
      },
      "publishedBy": {
        "label": "Herausgegeben von"
      },
      "publishedAt": {
        "label": "Veröffentlicht am"
      }
    }
  },
  "milestone": {
    "createButton": "Meilenstein erstellen",
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "description": {
        "label": "Beschreibung"
      },
      "points": {
        "label": "Punkte"
      },
      "icon": {
        "label": "Icon"
      }
    }
  },
  "backbone": {
    "importer": {
      "employees": {
        "title": "Mitarbeiter & Standorte",
        "buttonTitle": "Mitarbeiter und Niederlassungen importieren"
      },
      "batches": {
        "title": "Chargen importieren",
        "buttonTitle": "Chargen importieren"
      },
      "customer": {
        "title": "Kunden",
        "buttonTitle": "Kunden importieren"
      },
      "subscription": {
        "title": "Abonnements",
        "buttonTitle": "Registrierungen importieren"
      },
      "databaseReset": {
        "title": "Datenbank zurücksetzen",
        "buttonTittle": "DB zurücksetzen"
      }
    }
  },
  "token": {
    "createButton": "Token herstellen",
    "field": {
      "id": {
        "label": "ID"
      },
      "token": {
        "label": "Token"
      },
      "createdAt": {
        "label": "Erstellt am"
      },
      "lastUsedAt": {
        "label": "Zuletzt verwendet am"
      },
      "expiresAt": {
        "label": "Läuft ab am"
      },
      "expired": {
        "label": "Abgelaufen"
      },
      "content": {
        "markdown": "Markdown-Formatierung"
      }
    }
  },
  "tip": {
    "overview": {
      "createButton": "Einen Tipp erstellen"
    },
    "type": {
      "basic": "Allgemein",
      "new_employees": "Neue Mitarbeiter",
      "new_job_applications": "Neue Anträge"
    },
    "nav": {
      "overview": "Tipps"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "type": {
        "label": "Typ",
        "placeholder": "Art der...",
        "value": {
          "basic": "Standard",
          "new_job_applications": "Neue Bewerber",
          "new_employees": "Neue Mitarbeiter"
        }
      },
      "text": {
        "label": "Text",
        "placeholder": "Text..."
      }
    },
    "create": {
      "title": "Einen Tipp hinzufügen"
    },
    "edit": {
      "title": "Tipp anpassen"
    },
    "createButton": "Einen Tipp hinzufügen",
    "editTooltip": "Artikel bearbeiten"
  },
  "history": {
    "modal": {
      "buttonTitle": "History",
      "subscription": {
        "title": "History of this subscription"
      },
      "team": {
        "title": "History of this team"
      }
    },
  },
  "clientFile": {
    "createButton": "Kundendatei hinzufügen",
    "field": {
      "id": {
        "label": "ID"
      },
      "client": {
        "label": "Kunde"
      },
      "name": {
        "label": "Name der Datei"
      },
      "createdAt": {
        "label": "Datum des Hochladens"
      },
      "deletionDate": {
        "label": "Datum löschen"
      },
      "uploader": {
        "label": "Uploader"
      },
      "file": {
        "label": "Datei"
      }
    }
  },
  "Appointment": {
    "toggleLegend": "Legende",
    "field": {
      "id": {
        "label": "ID"
      },
      "status": {
        "label": "Status",
        "value": {
          "queued": "Nach dem Aufruf",
          "assigned": "Zugewiesen",
          "reached": "Erreicht",
          "not reachable": "Unerreichbar",
          "not reached": "Nicht erreicht"
        },
        "legend": {
          "carryThrough": "Unverändert fortgesetzt",
          "carryThroughChanged": "Geänderte Übermittlung",
          "phoneIncorrect": "Telefon falsch",
          "notAnswered": "Nicht enthalten",
          "cancelled": "Abgesagt",
          "callBackSoonNoTime": "Rufen sie bald ohne termin zurück",
          "callBackSoon": "Baldiger Rückruf",
          "callBackNow": "Rückruftermin jetzt",
          "callBackToday": "Rückruftermin heute",
          "callBackFuture": "Rückruftermin an einem anderen Tag"
        }
      },
      "remarks": {
        "label": "Anmerkungen",
        "value": {
          "": "",
          "changed": "Abonnement geändert",
          "carry through": "Implementierung des abonnements",
          "canceled": "Abonnement gekündigt",
          "carry through later": "Abonnement später",
          "call appointment": "Rückruftermin (T.B.A.) vereinbart",
          "phonenumber not correct": "Telefonnummer falsch/nicht vom kunden",
          "not answered call back": "Nicht aufgezeichnet, morgen wieder",
          "not answered call back soon": "Nicht aufgezeichnet, wieder in fünf minuten",
          "not answered": "Nicht enthalten, weiter für den export"
        }
      },
      "subscription": {
        "label": "Anmeldung"
      },
      "bucket": {
        "label": "Eimer"
      },
      "callAt": {
        "label": "Aufruf von"
      },
      "caller": {
        "label": "Nabeller"
      }
    }
  },
  "shiftType": {
    "createButton": "Schichttyp hinzufügen",
    "edit": {
      "title": "Einstellung der Schaltungsart"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "name": {
        "label": "Name"
      },
      "description": {
        "label": "Beschreibung"
      },
      "isDefault": {
        "label": "Standard?"
      },
      "baseWage": {
        "label": "Grundlohnverschiebung"
      }
    }
  },
  subscriptionReason: {
    createButton: 'Grund hinzufügen',
    edit: {
      title: 'Grund bearbeiten',
    },
    field: {
      id: {
        label: 'ID',
      },
      code: {
        label: 'Code',
      },
      description: {
        label: 'Beschreibung',
      },
        type: {
            label: 'Typ',
            value: {
                cancelled: 'Abgesagt',
                completed: 'Bereit',
                general: 'Allgemein',
            },
        },
    }
  },
  "signedDocument": {
    "confirm": "Sind Sie sicher, dass Sie die Datei {{fileName}} löschen wollen?",
    "notificationModel": "Ihr Dokument wurde hochgeladen"
  },
  "shiftWageDefinition": {
    "edit": {
      "title": "Definition der Löhne"
    },
    "modal": {
      "buttonTitle": "Hinzufügen von Lohnvorschriften",
      "modalTitle": "Definition der Lohnregel"
    },
    "field": {
      "name": {
        "label": "Name der Lohndefinition"
      },
      "startDate": {
        "label": "Datum des Inkrafttretens"
      }
    }
  },
  "shiftWageRule": {
    "field": {
      "age": {
        "label": "Alter"
      },
      "factor": {
        "label": "Faktor"
      }
    }
  },
  "jobFactor": {
    "field": {
      "startDate": {
        "label": "Datum des Inkrafttretens"
      },
      "factor": {
        "label": "Faktor"
      }
    }
  },
  "alphaWage": {
    "field": {
      "id": {
        "label": "ID"
      },
      "employee": {
        "label": "Mitarbeiterin"
      },
      "date": {
        "label": "Datum"
      },
      "basePay": {
        "label": "Grundgehalt"
      },
      "factorPay": {
        "label": "Bonus"
      },
      "creditAmount": {
        "label": "Kredite"
      },
      "finalPay": {
        "label": "Letztes Gehalt"
      }
    }
  },
  "alphaPeriodWage": {
    "field": {
      "id": {
        "label": "ID"
      },
      "employee": {
        "label": "Mitarbeiterin"
      },
      "startDate": {
        "label": "Zeitraum"
      },
      "amountEarned": {
        "label": "Lohnsumme"
      },
      factorWage: {
        label: "Bonus"
      },
      baseWage: {
        label: "Grundlohn"
      },
      "incidentalExpenses": {
        "label": "Nebenkostenabgeltung"
      },
    }
  },
  "contentbundleFolder": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      }
    }
  },
  "contentbundleCondition": {
    "field": {
      "product": {
        "label": "Produkt"
      },
      "metafield": {
        "label": "Metafield"
      },
      "metafieldValue": {
        "label": "Metafeld-Wert"
      }
    }
  },
  "contentbundleHitbox": {
    "field": {
      "nextScreen": {
        "label": "Gehen Sie zum Registrierungsbildschirm"
      },
      "slideshow": {
        "label": "Zu einem anderen Ordner wechseln"
      },
      "link": {
        "label": "Besuchen Sie eine Website"
      },
      "product": {
        "label": "Setzen Sie das nächste Produkt für dieses Abonnement"
      },
      "metafield": {
        "label": "Wählen Sie ein Metafeld, um einen Wert voranzustellen"
      },
      "metafieldValue": {
        "label": "Den Wert für dieses Metafeld festlegen"
      },
      standardField:{
        'label': "Festzulegende variable",
        value: {
          'spokenLanguage': "Sprache"
        }

      },
      standardFieldValue:{
        label: "Value fur diesese Variable",
        spokenLanguageOptions: {
            value:{
            NL: 'Niederländisch',
            FR: "Franzosisch",
            DE: "Deutsch",
            EN: "Anglish"
          }
        }
    }
  }
  },
  bwMetafieldTranslation: {
    field: {
      bwField: {
        label: "Briggs and Walker eingabefeld"
      },
      standardField: {
        label: "Incenova eingabefeld"
      }
    },
  },
  employeeSettings: {
    nav: {
      label: "Einstellungen"
    },
    general: {
      settings: {
        label: "Einstellungen"
      }
    },
    options: {
      referralSource: {
        label: "Quelle"
      },
      whatsappStatus: {
        label: "Whatsapp-Status"
      },
      employeeReason: {
        label: "Mitarbeitergrund"
      },


    }
  },
  whatsappStatus: {
    create: {
      title: 'WhatsApp-Status hinzufügen'
    },
    edit: {
      title: 'WhatsApp-Status bearbeiten'
    },
    field: {
      id: {
        label: "ID"
      },
      title: {
        label: "Name"
      },
    },
    filter: {
      "deleted": "Gelöschte Status",
      "onlyDeleted": "Nur gelöscht",
      "withoutDeleted": "Ohne gelöscht",
      "bothDeletedAndNot": "Beide"
    }
  },
  employeeReason: {
    create: {
      title: 'Mitarbeitergrund hinzufügen'
    },
    edit: {
      title: 'Mitarbeitergrund bearbeiten'
    },
    field: {
      id: {
        label: "ID"
      },
      name: {
        label: "Name"
      },
      type: {
        label: "Typ",
        value: {
          "rejection-company": "Ablehnung des Unternehmens",
          "rejection-applicant": "Ablehnung des Bewerbers",
          general: "Allgemein",
        }
      }
    },
    filter: {
      "deleted": "Gelöschte mitarbeitergründe",
      "onlyDeleted": "Nur gelöscht",
      "withoutDeleted": "Ohne gelöscht",
      "bothDeletedAndNot": "Beide"
    }
  },
  interview: {
    dropoutReasonModal: {
      title: 'Ablehnung bestätigen {{name}}',
      confirm: {
        rejected: 'Aus welchem Grund wurde {{name}} abgelehnt?',
        'not-interested': 'Aus welchem Grund ist {{name}} nicht interessiert?'
      }
    },
  },
  recruiterOverview: {
    week: 'Woche {{number}}',
    client: 'Kunde',
    brutto: 'Brutto',
    netto: 'Netto',
    cancelled: 'Abgebrochen',
    registrationFee: 'Registrierungsgebühr',
    baseSalary: 'Grundgehalt',
    bonus: 'Bonus',
    total: 'Gesamt',
    price: 'Preis',
    cancelReason: 'Stornierungsgrund',
    registrationDate:'Registrierungsdatum',
    processingDate: 'Verarbeitungsdatum',
    date: 'Datum',
    reason: 'Grund',
    amount: 'Betrag',
    totalAmount: 'Gesamtbetrag',
    totalBase: 'Grundlohn',
    totalBonus: 'Bonus',
    creditations: 'Gutschriften',
    incidentals: 'Nebenkostenabgeltung',
    subtotalNoVAT: 'Zwischensumme ohne MwSt.',
    vat: 'Mehrwertsteuer',
    subtotalWithVAT: 'Zwischensumme mit MwSt.',
  }
}
