import { observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Employee } from './Employee';
import { TeamMemberStore, TeamMember } from './TeamMember';
import { Branch } from './Branch';
import { Campaign } from './Campaign';
import { TeamLocationStore } from './TeamLocation';
import { ShiftType } from './ShiftType';

export const SOURCE_BRIGGS_AND_WALKER = 'briggs_and_walker';

export class Team extends Model {
    static backendResourceName = 'team';

    @observable id = null;
    @observable status = 'planned';
    @observable remarks = '';
    @observable licensePlate = '';
    @observable carMileageStart = 0;
    @observable carMileageEnd = 0;
    @observable carDamageEnd = '';
    @observable carDamageStart = '';
    @observable date = null;
    @observable startTime = null;
    @observable endTime = null;
    @observable scans = [];
    @observable source = 'sms';
    @observable deleted = false;
    @observable subscriptionsCount = 0;
    @observable scansCount = 0;
    @observable sheetsCount = 0;

    relations() {
        return {
            teamCaptain: Employee,
            employee: Employee,
            teamMembers: TeamMemberStore,
            driver: TeamMember,
            branch: Branch,
            campaign: Campaign,
            teamLocations: TeamLocationStore,
            shiftType: ShiftType,
        };
    }

    casts() {
        return {
            date: Casts.date,
        };
    }

    pushToBriggsAndWalkers() {
        this.__pendingRequestCount++;

        return this.api.post(`/team/${this.id}/bw_export/`).catch(() => {}).then(() => {
            this.__pendingRequestCount--;
        });
    }

    importTeamMembers(otherTeamId) {
        this.__pendingRequestCount += 1;

        const p = this.api.post(this.url + 'import_team_members/' + otherTeamId + '/');

        p.catch(() => {}).then(() => {
            this.__pendingRequestCount -= 1;
        });

        return p;

    }
}


export class TeamStore extends Store {
    Model = Team;
    static backendResourceName = 'team';

    importFromBriggsAndWalkers() {
        return this.wrapPendingRequestCount(
            this.api.post(this.url() + 'bw_team_import/')
        );
    }
}
