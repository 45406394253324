import { action, observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Metafield } from './Metafield';

export const TYPE_CONTAINER = 'container';
export const TYPE_TEXT = 'text';
export const TYPE_FILLER = 'filler';
export const TYPE_FIELD = 'field';
export const TYPE_METAFIELD = 'metafield';
export const TYPE_ATTEST = 'attest';
export const TYPE_IMAGE = 'image';

export const TARGET_SUBSCRIPTION = 'subscription';
export const TARGET_SUBSCRIBER = 'subscriber';

export class FormElement extends Model {
    static backendResourceName = 'form_element';

    @observable id = null;
    @observable type = null;
    @observable span = 1;
    @observable ordering = 0;
    @observable background = null;
    @observable _background = null;
    // @observable required = false;
    @observable readOnly = false;
    @observable autofill = '';
    @observable country = '';
    @observable thirdGenderEnabled = false;

    @observable _x = 0;
    @observable _y = 0;

    @observable validation = {};
    @observable styles = '';

    relations() {
        return {
            metafield: Metafield,
        };
    }

    casts() {
        return {
            background: Casts.file,
        };
    }

    saveBackground() {
        if (!this._background) {
            return;
        }

        const data = new FormData();

        data.append('background', this._background, this._background.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        return this.api.post(`${this.url}background/`, data, { headers }).then(response => {
            this.parse(response.data);
        });
    }
}

export class FormElementContainer extends FormElement {
    @observable type = TYPE_CONTAINER;
}

export class FormElementFiller extends FormElement {
    @observable type = TYPE_FILLER;
}

export class FormElementText extends FormElement {
    @observable type = TYPE_TEXT;
    @observable label = '';
    @observable text = '';
    @observable textType = 'p';
}

export class FormElementImage extends FormElement {
    @observable type = TYPE_IMAGE;
}

export class FormElementField extends FormElement {
    @observable type = TYPE_FIELD;
    @observable target = '';
    @observable field = 'first_name';
    @observable fieldType = '';
    @observable label = '';
}

export class FormElementMetafield extends FormElement {
    @observable type = TYPE_METAFIELD;
}

export class FormElementAttest extends FormElement {
    @observable type = TYPE_ATTEST;
    @observable text = '';
}

export class FormElementStore extends Store {
    Model = FormElement;
    static backendResourceName = 'form_element';
    comparator = 'ordering';

    @action
    fromBackend({ data, repos, relMapping }) {
        this.models.replace(
            data.map(record => {
                // TODO: I'm not happy at all about how this looks.
                // We'll need to finetune some things, but hey, for now it works.
                const model = this._newModel(record);
                model.fromBackend({
                    data: record,
                    repos,
                    relMapping,
                });
                return model;
            })
        );
        this.sort();
    }

    _newModel(model = null) {
        let M = this.Model;

        if (model) {
            switch (model.type) {
                case TYPE_CONTAINER:
                    M = FormElementContainer;
                    break;
                case TYPE_FILLER:
                    M = FormElementFiller;
                    break;
                case TYPE_TEXT:
                    M = FormElementText;
                    break;
                case TYPE_IMAGE:
                    M = FormElementImage;
                    break;
                case TYPE_FIELD:
                    M = FormElementField;
                    break;
                case TYPE_METAFIELD:
                    M = FormElementMetafield;
                    break;
                case TYPE_ATTEST:
                    M = FormElementAttest;
                    break;
                default:
                    throw new Error(`Unkown type: ${model.type}`);
            }
        }

        return new M(model, {
            store: this,
            relations: this.__activeRelations,
        });
    }
}
