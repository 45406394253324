import Logo from '../../image/logo/ceom.png'
import LoginBackground from '../../image/login-ceo.jpg'
import CYLogo from '../../image/logo/cy.png'

export const COLOR_THIRD= '#42485E'
export const COLOR_PRIMARY= '#0079aa'
export const COLOR_SECONDARY= '#3296AF';
export const COLOR_TEXT = 'rgba(0, 0, 0, 0.7)'
export const TAB_TITLE = "CEO Marketing";

// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export default {
    primaryColor: COLOR_PRIMARY,
    secondaryColor: COLOR_SECONDARY,
    thirdColor: COLOR_THIRD,
    buttonPrimaryColor: COLOR_PRIMARY,
    buttonPrimaryTextColor: '#fff',
    MENU_TEXT_COLOR: '#f1f1f1',
    textColor: COLOR_TEXT,
    cyLogo: CYLogo,
    textLogo: Logo,
    logo: Logo,
    sidebarLogo: Logo,
    loginBackground: LoginBackground,
    loginBackgroundColor: COLOR_PRIMARY,
    sidebar: false,
    tabTitlePrefix: TAB_TITLE,
    lightColor: '#F5F5F5'
}
