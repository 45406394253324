import { observable } from 'mobx';
import { Model, Store } from './Base';

export class SubscriberIssue extends Model {
    static backendResourceName = 'subscriber_issue';

    @observable id = null;
    @observable reason = '';
    @observable createdAt = '';

}

export class SubscriberIssueStore extends Store {
    Model = SubscriberIssue;
    static backendResourceName = 'subscriber_issue';
}
