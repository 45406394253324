import { observable } from 'mobx';
import { Model, Store } from './Base';

export class BWProduct extends Model {
    static backendResourceName = 'bwproduct_translation';

    @observable id = null;
    @observable bwProductId = '';
    @observable bw_campaign_id = '';

    relations() {
        return {
            //
        };
    }
}

export class BWProductStore extends Store {
    Model = BWProduct;
    static backendResourceName = 'bwproduct_translation';
}
