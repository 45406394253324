import { observable } from 'mobx';
import {Casts, Model, Store} from './Base';
import {Subscription} from "./Subscription";
import {Employee} from "./Employee";
import {Appointment} from "./Appointment";
import {User} from "./User";

export class Call extends Model {
    static backendResourceName = 'call';

    @observable id = null;
    @observable status = null
    @observable uuid = null
    @observable toPhonenumber = null;
    @observable startedAt = null
    @observable answeredAt = null;
    @observable endedAt = null;
    @observable enableStartDate = false;
    @observable branches = [];
    @observable recordings = []
    @observable rexCallId = null;

    relations() {
        return {
            subscription: Subscription,
            caller: Employee,
            appointment: Appointment,
            calledUser: User,
        };
    }

    casts() {
        return {
            startedAt: Casts.datetime,
            answeredAt: Casts.datetime,
            endedAt: Casts.datetime,
        };
    }

}

export class CallStore extends Store {
    Model = Call;
    static backendResourceName = 'call';
}
