import {Model, Store} from "./Base";
import {observable} from "mobx";
import {Subscription} from "./Subscription";

export class File extends Model {
    static backendResourceName = 'file';

    @observable id = null;
    @observable type = '';
    @observable file = null;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            subscription: Subscription
        };
    }

}

export class FileStore extends Store {
    Model = File;
    static backendResourceName = 'file';
}
