import {observable} from 'mobx';
import {Casts, Model, Store} from './Base';
import {Employee, EmployeeStore} from './Employee'
import moment from 'moment';
import {t} from 'i18n'

export const INCIDENTALWAGETYPES = [
    {value: 'gross', text: t('incedentalExpense.field.type.value.gross')},
    {value: 'net', text: t('incedentalExpense.field.type.value.net')},
]
export const INCIDENTALWAGETYPESBULK = [
    {value: 'gross', text: t('incedentalExpense.field.type.value.gross')},
]
export const GROSCATAGORIES = [
    {value: 'recruitment', text: t('incedentalExpense.field.category.grosCatagory.recruitment')},
    {value: 'bonus', text: t('incedentalExpense.field.category.grosCatagory.bonus')},
    {value: 'lead', text: t('incedentalExpense.field.category.grosCatagory.lead')},
    {value: 'call_center', text: t('incedentalExpense.field.category.grosCatagory.call_center')},
    {value: 'headquarter', text: t('incedentalExpense.field.category.grosCatagory.headquarter')},
    {value: 'other', text: t('incedentalExpense.field.category.grosCatagory.other')},
]
export const NETCATAGORIES = [
    {value: 'consumption', text: t('incedentalExpense.field.category.netCatagory.consumption')},
    {value: 'office_supplies', text: t('incedentalExpense.field.category.netCatagory.officeSupplies')},
    {value: 'travel', text: t('incedentalExpense.field.category.netCatagory.travel')},
    {value: 'accommodation', text: t('incedentalExpense.field.category.netCatagory.accommodation')},
    {value: 'car_related_expenses', text: t('incedentalExpense.field.category.netCatagory.carRelatedExpenses')},
    {value: 'other', text: t('incedentalExpense.field.category.netCatagory.other')},
]

export class IncedentalExpense extends Model {
    static backendResourceName = 'incedental_expense';

    @observable id = null;
    @observable compensationAmount = null;
    @observable month = moment();
    @observable reason = null;
    @observable approved = null;
    @observable rejectionReason = null;
    @observable type = null;
    @observable category = null;
    @observable receipt = null;
    @observable finalized = false;


    relations() {
        return {
            employee: Employee,
            lastEdited: Employee,
            approvedBy: Employee,
            bulkEmployees: EmployeeStore
        };
    }

    setStatus(status) {
        const expense = new IncedentalExpense({
            id: this.id
        });
        let data
        if (status) {
            data = {
                approval: status
            }
        } else {
            data = {
                approval: status,
                reason: this.rejectionReason
            }
        }

        return expense.api.post(expense.url + 'approve_or_deny_expense/',
            data);
    }

    casts() {
        return {
            month: Casts.date,
        };
    }


}

export class IncedentalExpenseStore extends Store {
    Model = IncedentalExpense;
    static backendResourceName = 'incedental_expense';
}
