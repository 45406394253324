import Logo from '../../image/logo/cy.png'
import LoginBackground from '../../image/login-cy.jpg'
import textCY from '../../image/logo/cy.png'
import CYLogo from '../../image/logo/cy.png'

export const COLOR_PRIMARY = '#ebbb12'
export const COLOR_SECONDARY = '#fadc73'
export const COLOR_TEXT = 'rgba(0, 0, 0, 0.7)'
export const TAB_TITLE = "SMS CY"
// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export default {
    primaryColor: COLOR_PRIMARY,
    secondaryColor: COLOR_SECONDARY,
    buttonPrimaryColor: COLOR_SECONDARY,
    buttonPrimaryTextColor: '#fff',
    textColor: COLOR_TEXT,
    cyLogo: CYLogo,
    logo: Logo,
    textLogo: textCY,
    loginBackground: LoginBackground,
    loginBackgroundColor: COLOR_PRIMARY,
    tabTitlePrefix: TAB_TITLE,
    lightColor: '#F5F5F5'
}
