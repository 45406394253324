import { observable } from 'mobx';
import { Model, Store } from './Base';
import {MetafieldvalueStore} from "./MetafieldValue";
import {ProductMetafieldStore} from "./ProductMetafield";
import {t} from '../i18n';

export const METAFIELD_OVERRIDE_OPTIONS_DR = [
    {
        value: 'amount',
        text: t('subscription.field.amount.label')
    },
    {
        value: 'payment_interval',
        text: t('subscription.field.paymentInterval.label')
    },
    {
        value: 'subscriber_language',
        text: t('subscriber.field.spokenLanguage.label')
    }
]

export const METAFIELD_OVERRIDE_OPTIONS = [
    {
        value: 'amount',
        text: t('subscription.field.amount.label')
    },
    {
        value: 'payment_interval',
        text: t('subscription.field.paymentInterval.label')
    }
]

export const TYPE_CHOICE = 'choice';

export class Metafield extends Model {
    static backendResourceName = 'metafield';

    static TYPES = ['choice', 'text', 'boolean', 'multichoice', 'suggestions']

    @observable id = null;
    @observable slug = '';
    @observable label = '';
    @observable description = '';
    @observable type = null;
    @observable valueType = null;
    @observable defaultValues = [];
    @observable choices = [];
    @observable other = false;
    @observable controlledField = '';
    @observable showInConfirm = false;
    @observable metadata = {};
    @observable required = true;
    @observable forTeamCsv = false;

    relations() {
        return {
            metafieldvalues: MetafieldvalueStore,
            productMetafields: ProductMetafieldStore
        }
    }

    deleteAllInstances() {
        return this.api.post(`/metafield/${this.id}/delete_all_instances/`)
    }
}

export class MetafieldStore extends Store {
    Model = Metafield;
    static backendResourceName = 'metafield';
}
