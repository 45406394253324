import DrTextLogo from '../../image/logo/dr_text.svg'
import CYLogo from "../../image/logo/cy.png";
import Logo from '../../image/logo/dr.svg'
import logoSidebar from '../../image/logo/dr_white.png'
import LoginBackground from '../../image/login-dr.png'


// export const COLOR_PRIMARY = '#1E1F60'
// export const COLOR_SECONDARY = '#304FFE'
// export const COLOR_THIRD = '#2E2E3B';

export const COLOR_PRIMARY = '#4A54C9';
export const COLOR_SECONDARY = '#C9D8F0'
export const COLOR_THIRD = '#2E2E3B';
export const COLOR_TEXT = 'rgba(0, 0, 0, 0.7)';
export const TAB_TITLE = "Direct Result";


// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export default {
    primaryColor: COLOR_PRIMARY,
    secondaryColor: COLOR_SECONDARY,
    thirdColor: COLOR_THIRD,
    buttonPrimaryColor: COLOR_PRIMARY,
    buttonPrimaryTextColor: '#fff',
    textColor: COLOR_TEXT,
    cyLogo: CYLogo,
    textLogo: DrTextLogo,
    logo: Logo,
    sidebarLogo:logoSidebar,
    loginBackground: LoginBackground,
    loginBackgroundColor: COLOR_PRIMARY,
    sidebar: true,
    tabTitlePrefix: TAB_TITLE,
    lightColor: '#F5F5F5'
}
