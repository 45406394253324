import CYLogo from "../../image/logo/cy.png";
import asLogo from '../../image/logo/as.png';
import asBackground from '../../image/login-as.jpg'

export const COLOR_PRIMARY = '#32324B';
export const COLOR_SECONDARY = '#32324B';
export const COLOR_THIRD = '#32324B';
export const COLOR_TEXT = '#f1f1f1';
export const TAB_TITLE = "Alpha Society";
// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export default {
    primaryColor: COLOR_PRIMARY,
    secondaryColor: COLOR_SECONDARY,
    thirdColor: COLOR_THIRD,
    buttonPrimaryColor: COLOR_PRIMARY,
    buttonPrimaryTextColor: '#f1f1f1',
    MENU_TEXT_COLOR: '#f1f1f1',
    textColor: COLOR_TEXT,
    cyLogo: CYLogo,
    textLogo: asLogo,
    logo: asLogo,
    sidebarLogo:asLogo,
    loginBackground: asBackground,
    loginBackgroundColor: COLOR_PRIMARY,
    sidebar: true,
    tabTitlePrefix: TAB_TITLE,
    lightColor: '#F5F5F5'
};
