import {Model, Store} from '../Base';
import {observable} from "mobx";
import {EarningComponentStore} from "./EarningComponent";
import {WageStructure} from "./WageStructure";

export class WageComponent extends Model {
    static backendResourceName = 'wage_component';

    @observable id = null;
    @observable name = '';


    relations() {
        return {
            wageStructure: WageStructure,
            earningComponents: EarningComponentStore
        };
    }
}

export class WageComponentStore extends Store {
    Model = WageComponent;
    static backendResourceName = 'earning_component';
}
