import {computed, observable} from 'mobx';
import {isArray, isPlainObject, pick} from 'lodash';
import {Casts, Model, Store} from './Base';
import {Employee} from './Employee';
import {SignedDocumentStore} from './SignedDocument';
import UserPermissionMixin from './UserPermissionsMixin';
import {MetabaseStore} from "./Metabase";
import {ClientStore} from './Client'
import {CallStore} from "./Call";

export class Permission extends Model {
    static backendResourceName = 'permission';

    @observable id = null;
    @observable name = '';
    @observable codename = '';
}

export class PermissionStore extends Store {
    Model = Permission;
    static backendResourceName = 'permission';
}

export class Group extends Model {
    static backendResourceName = 'group';

    @observable id = null;
    @observable name = '';
    @observable isDefault = false;
    @observable isDefaultApplicant = false;
    @observable isDefaultClient = false;

    relations() {
        return {
            permissions: PermissionStore,
            metabases: MetabaseStore
        };
    }
}

export class GroupStore extends Store {
    Model = Group;
    static backendResourceName = 'group';
}

class NullableEmployee extends Employee {
    parse(data) {
        if (!isPlainObject(data)) {
            return super.parse({});
        }

        return super.parse(data);
    }
}

export class User extends UserPermissionMixin(Model) {
    static backendResourceName = 'user';

    @observable id = null;
    // TODO: username and email are always the same. Hopefully we can remove username soon.
    @observable username = '';
    @observable email = '';
    @observable firstName = '';
    @observable lastName = '';
    @observable password = '';
    @observable groupNames = [];
    @observable permissions = [];
    @observable isSuperuser = false;
    @observable lastLogin = null;
    @observable isActive = null;
    @observable userTypeName = null;
    @observable deleted = false;

    @computed
    get fullName() {
        if (this.employee && this.employee.id > 0) {
            return this.employee.name;
        }

        if (this.firstName || this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        if (this.username && this.username.length > 0) {
            return this.username;
        }
        return this.email;
    }

    // Get the client metabases
    getClientMetabases(metabases = []) {
        if (this.clients) {
            this.clients.forEach((client) => {
                if (client.metabases.length > 0) {
                    client.metabases.forEach((metabase) => {
                        let add = true
                        metabases.forEach(metabaseFromList => {
                            if (metabaseFromList.id === metabase.id) {
                                add = false
                            }
                        })
                        if (add) {
                            metabases.push(metabase)
                        }
                    })
                }
            })
        }
        metabases.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        return metabases
    }

    relations() {
        return {
            groups: GroupStore,
            employee: NullableEmployee,
            signedDocuments: SignedDocumentStore,
            clients: ClientStore,
            receivedCalls: CallStore,
        };
    }

    casts() {
        return {
            lastLogin: Casts.datetime,
        };
    }

    toBackend(options) {
        const output = super.toBackend(options);
        const newData = pick(output, [
            'username',
            'id',
            'email',
            'first_name',
            'last_name',
            'groups',
            'user_type_name'
        ]);

        if (output.password) {
            newData.password = output.password;
        }

        return newData;
    }

    masquerade() {
        return this.api.post(`/user/${this.id}/masquerade/`).then(() => {
            window.location = '/';
        });
    }

    resetRequest(username) {
        return this.api.post(`/user/reset_request/`, {username});
    }

    changePassword({passwordOld, passwordNew}) {
        return this.api.put(`/user/change_password/`, {
            old_password: passwordOld,
            new_password: passwordNew,
        });
    }

    resetPassword({id, password, resetCode}) {
        return this.api
            .put(`/user/${id}/reset_password/`, {
                password,
                reset_code: resetCode,
            })
            .then(() => {
                window.location = '/';
            });
    }

    inGroup(groups) {
        if (!isArray(groups)) {
            return this.groupNames.includes(groups);
        }
        return this.groupNames.some(groupName => groups.includes(groupName));
    }

    sendActivationEmail() {
        return this.wrapPendingRequestCount(
            this.api.put('user/send_activation_email/', {"email": this.username})
        );
    }
}

export class UserStore extends Store {
    Model = User;
    static backendResourceName = 'user';

    @observable
    params = {
        order_by: 'last_name,first_name,username',
    };
}
