export const localDev = () => {
    if (window.location.hostname === 'localhost' || window.location.hostname === 'nginx') {
        return true
    } else {
        return false
    }
}

export const DirectResult = (ignoreLocal = false) => {
    const domains = ['react', 'th-nl', 'direct-result']
    let isDr = false
    domains.forEach(domain => {
        if (window.location.href.includes(domain)) {
            isDr = true
        }
    })

    if (ignoreLocal) {
        return isDr
    } else {
        return (isDr || localDev())
    }
}

export const CeoMarketing = (ignoreLocal = false) => {
    const domains = ['ceomarketing', 'alphasociety']
    let isCeom = false
    domains.forEach(domain => {
        if (window.location.href.includes(domain)) {
            isCeom = true
        }
    })
    // In some cypress tests we need to ignore this ceo marketing check
    if (ignoreLocal) {
        return (isCeom)
    } else {
        return (isCeom || localDev())
    }
}

export const HotNetworkz = (ignoreLocal = false) => {
    const domains = ['hn', 'hn2', 'zetespee', 'zsp', 'zsp2']
    let isHN = false
    domains.forEach(domain => {
        if (window.location.href.includes(domain)) {
            isHN = true
        }
    })
    if (ignoreLocal) {
        return (isHN)
    } else {
        return (isHN || localDev())
    }
}
