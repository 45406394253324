import {Casts, Model, Store} from '../Base';
import {observable} from "mobx";
import {Earning} from "./Earning";
import {WageComponent} from "./WageComponent";

export class EarningComponent extends Model {
    static backendResourceName = 'earning_component';

    @observable id = null;
    @observable amount = 0;
    @observable date = null;

    casts() {
        return {
            date: Casts.date,
        };
    }


    relations() {
        return {
            wageComponent: WageComponent,
            earning: Earning
        };
    }
}

export class EarningComponentStore extends Store {
    Model = EarningComponent;
    static backendResourceName = 'earning_component';
}
