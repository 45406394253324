import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const AppVersion = styled.div`
    position: absolute;
    right: 20px;
    top: 35px;
    font-size: 12px;
    color: ${props => props.theme.primaryColor};
`;

@observer
export default class Version extends Component {
    static propTypes = {}

    render() {
		return <AppVersion>{process.env.CY_FRONTEND_VERSION}</AppVersion>
    }

}
