import {observer} from "mobx-react";
import React, {Component} from 'react';
import styled from "styled-components";
import getTheme from "../../style/theme";
import briggsLogo from './images/briggsandwalker_logo_rgb_nightblue.png'
import './sidebar.css'
import PropTypes from "prop-types";

const SideBar = styled.div`
    position: absolute;
    padding-top: 54px;
    width: 300px;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
    background-color: ${getTheme().sidebarColor};
  left: -300px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: .3s ease all;
  transition: .3s ease all;
  &.open {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }
`


@observer
export default class BriggsSideBar extends Component {
    static propTypes = {
        innerRef: PropTypes.object.isRequired,
        open: PropTypes.bool.isRequired
    }


    render() {

        const {innerRef, open} = this.props
        return (
            <SideBar className={
                open
                    ? `div open`
                    : `div closed`
            } innerRef={innerRef}>
                <header style={{
                    display: 'flex',
                    flex: '0 0 auto',
                    padding: '2rem 1rem 1rem 1rem'
                }}>
                    <figure style={{padding: '1rem'}}>
                        <img src={briggsLogo} style={{
                            maxWidth: '100%',
                            height: 'auto',
                            border: 0,
                            verticalAlign: 'middle'
                        }}/>
                    </figure>
                </header>
                <content style={{
                    display: 'flex',
                    flex: '1 1 auto',
                    overflow: 'auto',
                    flexDirection: 'column',
                    padding: '0.8rem'
                }}>
                    <p style={{
                        fontFamily: '\'Montserrat\', sans-serif',
                        padding: '0 1.4rem',
                        color: 'white'
                    }}>
                        Game-changing tools to make instant impact Briggs + Walker makes it easier for you to run +
                        manage field sales, stay in line with privacy + compliancy standards, and grow your bottom line.
                    </p>
                    <p/>
                    <p style={{
                        fontFamily: '\'Montserrat\', sans-serif',
                        padding: '0 1.4rem',
                        color: 'white',
                        cursor: 'pointer'
                    }}>
                        <a className="txt-link" href="https://briggsandwalker.com/">Chat with us</a>
                    </p>
                </content>
                <footer style={{
                    display: 'flex',
                    flex: '0 0 auto',
                    flexDirection: 'column',
                    padding: '0.8rem',
                    position: 'absolute',
                    bottom: 0,
                    width: '100%'
                }}>
                    <p style={{
                        fontFamily: '\'Montserrat\', sans-serif',
                        padding: '0 1.4rem',
                        color: 'white',
                        cursor: 'pointer',
                        paddingBottom: 10
                    }}>
                        <
                            a
                            className="txt-link-logo"
                            href="https://locationmanager.pttn.com/">
                            <div className="logotext">
                                <span className="c-white font-monserrat">Location</span> <span
                                className="c-green font-sanchez">Manager</span>
                            </div>
                        < /a>
                    </p>
                    <p style={{
                        fontFamily: '\'Montserrat\', sans-serif',
                        padding: '0 1.4rem',
                        color: 'white',
                        cursor: 'pointer',
                        paddingBottom: 20
                    }}>
                        <a className="txt-link-logo" href="https://backoffice.pttn.com/">
                            <div className="logotext">
                                <span className="c-white font-monserrat">Campaign</span> <span
                                className="c-green font-sanchez">Manager</span>
                            </div>
                        </a>
                    </p>

                    <button type="button" className="knop knop-primary-opp knop-lg" onClick={() => {
                        window.location.href = 'https://briggsandwalker.com/';
                    }}>Bezoek de website!
                    </button>
                </footer>

            </SideBar>

        )
    }
}
