import {observable} from 'mobx';
import {Model, Store} from './Base';
import { DeviceStore } from './Device';
import {Employee} from "./Employee";

export class Branch extends Model {
    static backendResourceName = 'branch';

    @observable id = null;
    @observable name = '';
    @observable code = '';
    @observable address = '';
    @observable phoneNumber = '';
    @observable email = '';
    @observable nmbrsCodeDepartment = '';
    @observable nmbrsCodeCostplace = '';
    @observable avatar = null;
    @observable public = false;
    @observable interviewTimes = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: []
    };

    @observable mailjetTemplateWelcome = '';
    @observable mailjetTemplateActivation = '';
    @observable mailjetTemplatePasswordReset = '';
    @observable mailjetTemplateAccountEdited = '';

    relations() {
        return {
            manager: Employee,
            subBranchDevices: DeviceStore,
        };
    }
}

export class BranchStore extends Store {
    Model = Branch;
    static backendResourceName = 'branch';
}
