import { observable } from 'mobx';
import { Casts, Model, Store } from '../../Base';
import { Metafield } from '../../Metafield';
import { Product } from '../../Product';
import { ContentbundleSlide } from './ContentbundleSlide';
import { t } from 'i18n'

export const CONTENTBUNDLE_HITBOX_STANDARD_FIELD_OPTIONS = [
    {
        value: 'spoken_language',
        text: t('contentbundleHitbox.field.standardField.value.spokenLanguage'),
    },
]
export const CONTENTBUNDLE_HITBOX_STANDARD_FIELD_OPTION_FIELD_TYPES = {
    'spoken_language': {
        type: 'selection',
        options: [
            {
                value: 'NL',
                text: t('contentbundleHitbox.field.standardFieldValue.spokenLanguageOptions.value.NL'),
            },
            {
                value: 'FR',
                text: t('contentbundleHitbox.field.standardFieldValue.spokenLanguageOptions.value.FR'),
            },
        ]
    }
}



export class ContentbundleHitbox extends Model {
    static backendResourceName = 'contentbundle_hitbox';

    @observable id = null;

    @observable top = null;
    @observable left = null;
    @observable width = null;
    @observable height = null;

    @observable slideshow = null;
    @observable nextScreen = false;
    @observable link = '';

    @observable metafieldValue = null;

    @observable standardField = null;
    @observable standardFieldValue = null;

    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            slide: ContentbundleSlide,
            product: Product,
            metafield: Metafield
        }

    }
}

export class ContentbundleHitboxStore extends Store {
    Model = ContentbundleHitbox;
    static backendResourceName = 'contentbundle_hitbox';
}

