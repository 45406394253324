import { observable } from 'mobx';
import { Model, Store } from './Base';
import { SubscriptionStore } from './Subscription';
import {t} from '../i18n'

export const TYPES = [
    {
        value: 'cancelled',
        text: t('subscriptionReason.field.type.value.cancelled'),
    },
    {
        value: 'completed',
        text: t('subscriptionReason.field.type.value.completed'),
    },
    {
        value: 'general',
        text: t('subscriptionReason.field.type.value.general'),
    },
]
export class SubscriptionReason extends Model {
    static backendResourceName = 'subscription_reason';

    @observable id = null;
    @observable code = '';
    @observable description = '';
    @observable type = '';

    relations() {
        return {
            subscriptions: SubscriptionStore,
        }
    }
}

export class SubscriptionReasonStore extends Store {
    Model = SubscriptionReason;
    static backendResourceName = 'subscription_reason';
}
