import { observable } from 'mobx';
import {Casts, Model, Store} from './Base';
import {Employee} from './Employee'
import {SubscriptionStore} from "./Subscription";

export class ImportedCreditationCsv extends Model {
    static backendResourceName = 'imported_creditation_csv';

    @observable id = null;
    @observable csv = '';
    @observable processed = false;
    @observable createdAt = null;


    relations() {
        return {
            importer: Employee,
            subscriptions: SubscriptionStore
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
        };
    }



}

export class ImportedCreditationCsvStore extends Store {
    Model = ImportedCreditationCsv;
    static backendResourceName = 'imported_creditation_csv';
}
