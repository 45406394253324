import {computed, observable} from 'mobx';
import {Casts, Model, Store} from './Base';
import {Subscriber} from './Subscriber';
import {Product} from './Product';
import {TeamMember} from './TeamMember';
import {SubscriptionIssueStore} from './SubscriptionIssue';
import {SubscriptionReason} from './SubscriptionReason';
import {FileStore} from "./File";
import {MetafieldvalueStore} from "./MetafieldValue";
import {CallStore} from "./Call";
import {AppointmentStore} from "./Appointment";
import {ImportedCreditationCsv} from "./ImportedCreditationCsv";
import {ClientRefNr} from "./ClientRefNr";
import {t} from '../i18n'

export const PaymentIntervalOptions = [
    {
        value: null,
        text: t('subscription.field.paymentInterval.value.null'),
    },
    {
        value: 'P1D',
        text: t('subscription.field.paymentInterval.value.P1D'),
    },
    {
        value: 'P1W',
        text: t('subscription.field.paymentInterval.value.P1W'),
    },
    {
        value: 'P1M',
        text: t('subscription.field.paymentInterval.value.P1M'),
    },
    {
        value: 'P3M',
        text: t('subscription.field.paymentInterval.value.P3M'),
    },
    {
        value: 'P6M',
        text: t('subscription.field.paymentInterval.value.P6M'),
    },
    {
        value: 'P1Y',
        text: t('subscription.field.paymentInterval.value.P1Y'),
    },
]

export class Subscription extends Model {
    static backendResourceName = 'subscription';



    @observable id = null;
    @observable status = 'needs validation';
    @observable quantity = 1;
    @observable amount = null;
    @observable iban = '';
    @observable datetimeOfCancelation = null;
    @observable reasonForCancelation = '';
    @observable datetimeOfRegistration = null;
    @observable datetimeOfTermination = null;
    @observable startDate = null;
    @observable endDate = null;
    @observable paymentInterval = null;
    @observable paymentMethod = 'direct debit';
    @observable paymentReference = '';
    @observable entryRemarks = '';
    @observable callRemarks = '';
    @observable clientRemarks = '';
    @observable clientRefCode = null;
    @observable creditation = false;
    @observable amountOfTimesCalled = 0;
    @observable overrideCallAmountCheck = false;
    @observable cancelPaymentRecruiter = false;
    @observable disableBlockers = false;

    @observable lat = null;
    @observable lon = null;

    @computed get cancelPayment() {
        return (this.datetimeOfCancelation !== null && !this.creditation) || this.cancelPaymentRecruiter || this.paymentInterval === null;
    }


    casts() {
        return {
            datetimeOfRegistration: Casts.datetime,
            datetimeOfCancelation: Casts.date,
            datetimeOfTermination: Casts.date,
            startDate: Casts.date,
            endDate: Casts.date,
        };
    }

    relations() {
        return {
            subscriber: Subscriber,
            product: Product,
            metafieldvalues: MetafieldvalueStore,
            teamMember: TeamMember,
            issues: SubscriptionIssueStore,
            files: FileStore,
            calls: CallStore,
            appointments: AppointmentStore,
            csvImported: ImportedCreditationCsv,
            clientRefNr: ClientRefNr,
            subscriptionCode: SubscriptionReason,
        };
    }

    allIssues() {
        return this.issues.models.concat(this.subscriber.issues.models);
    }

    resendEmail(email, sendAttachment) {
        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'resend_email/', {
                email: email,
                send_attachment: sendAttachment,
            })
        )
    }

    readyForExport() {
        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'reexport/', {
                disable_blockers: this.disableBlockers,
            })
        )
    }

    readyForValidation() {
        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'revalidate/', {
                disable_blockers: this.disableBlockers,
            })
        )
    }
}

export class SubscriptionStore extends Store {
    Model = Subscription;
    static backendResourceName = 'subscription';

    cloneTargetSubscriptionsAsMock(targetIds) {
        return this.api.post(`${this.url()}clone_as_mock/`, {
            subscriptions: targetIds,
        });
    }

    fetchWithMock(options = {}) {
        options.url = 'subscription/include_mocked/';
        return this.fetch(options);
    }
}
