import CYLogo from "../../image/logo/cy.png";
import Logo from '../../image/logo/zsp.png'
import LoginBackground from '../../image/login-zsp.jpg'

export const COLOR_THIRD= '#393939'
export const COLOR_PRIMARY= '#393939'
export const COLOR_SECONDARY= '#a6fff5'
export const COLOR_TEXT = '#393939'
export const MENU_TEXT_COLOR = 'rgba(255, 255, 255, 0.9)'
export const TAB_TITLE = "ZSP Netwerk"

// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export default {
    primaryColor: COLOR_PRIMARY,
    secondaryColor: COLOR_PRIMARY,
    thirdColor: COLOR_THIRD,
    buttonPrimaryColor: COLOR_PRIMARY,
    buttonPrimaryTextColor: '#fff',
    textColor: COLOR_TEXT,
    MENU_TEXT_COLOR: MENU_TEXT_COLOR,
    cyLogo: CYLogo,
    textLogo: Logo,
    logo: Logo,
    sidebarLogo: Logo,
    loginBackground: LoginBackground,
    loginBackgroundColor: COLOR_PRIMARY,
    sidebar: false,
    tabTitlePrefix: TAB_TITLE,
    lightColor: '#F5F5F5'
}
