import { observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import { SubscriptionStore } from './Subscription';
import { Employee } from './Employee';
import { Device } from './Device';
import { Job } from './Job';
import { Team } from './Team';
import { omit } from 'lodash';
import { ShiftType } from './ShiftType';

export class TeamMember extends Model {
    static backendResourceName = 'team_member';

    @observable id = null;
    @observable signIn = null;
    @observable signOut = null;
    @observable signInTime = null;
    @observable signOutTime = null;
    @observable damage = '';
    @observable licensePlate = '';
    @observable carMileageStart = '';
    @observable carMileageEnd = '';
    @observable remarks = '';
    @observable deleted = null;
    @observable subscriptionsCount = 0;
    @observable quantitiesCount = 0;
    @observable updated = false;

    relations() {
        return {
            subscriptions: SubscriptionStore,
            employee: Employee,
            device: Device,
            function: Job,
            team: Team,
            shiftTypeOverride: ShiftType,
        };
    }

    casts() {
        return {
            signInTime: Casts.datetime,
            signOutTime: Casts.datetime,
        };
    }

    toBackend(options = {}) {
        return omit(super.toBackend(options), 'subscriptions');
    }

    cancelSignIn() {
        return this.api.post(this.url + 'cancel_sign_in/').then(() => this.signIn = null);
    }

    cancelSignOut() {
        return this.api.post(this.url + 'cancel_sign_out/').then(() => this.signOut = null);
    }
}

export class TeamMemberStore extends Store {
    Model = TeamMember;
    static backendResourceName = 'team_member';
}
