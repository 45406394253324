import {observable} from 'mobx';
import {Casts, Model, Store} from './Base';
import {Employee} from './Employee'
import moment from 'moment';

export class PeriodEarning extends Model {
    static backendResourceName = 'period_earning';

    @observable id = null;
    @observable earning = null;
    @observable startDate = moment();
    @observable endDate = null;
    @observable createdAt = null;
    @observable updatedAt = null;

    @observable datetimeApprovedByEmployee = null;
    @observable datetimeApprovedByCompany = null;

    @observable creditations = 0;
    @observable earningWithoutVat = 0;
    @observable incidentalWages = 0;
    @observable totalEarned = 0;
    @observable withheldEarningAmount = 0

    @observable nettoSubscriptionAmount = 0
    @observable sumSubscriptionPrices = 0
    @observable sumCreditationPrices = 0


    relations() {
        return {
            employee: Employee,
            lastEdited: Employee
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            endDate: Casts.date,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            datetimeApprovedByEmployee: Casts.datetime,
            datetimeApprovedByCompany: Casts.datetime
        };
    }


}

export class PeriodEarningStore extends Store {
    Model = PeriodEarning;
    static backendResourceName = 'period_earning';
}
