import {observable} from 'mobx';
import {Casts, Model, Store} from './Base';
import {Campaign} from './Campaign'
import {EmployeeStore} from "./Employee";
import {AppointmentStore} from "./Appointment";

export class Bucket extends Model {
    static backendResourceName = 'bucket';

    @observable id = null;
    @observable status = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable counts = null;
    @observable spokenLanguage = null;

    relations() {
        return {
            campaign: Campaign,
            callers: EmployeeStore,
            appointments: AppointmentStore
        };
    }

    casts() {
        return {
            updatedAt: Casts.datetime,
            createdAt: Casts.datetime,
        };
    }

    create() {
        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'from_queue/', {
                campaign_id: this.campaign.id

            })
        );
    }


    manuallyComplete() {
        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'manual_complete/')
        );
    }

    manuallyCompleteReached() {
        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'manual_complete_reached/')
        );
    }

    assignEmployees(employeeStore) {

        let arrayEmployeeIDs = []

        employeeStore.models.forEach(caller => {
            arrayEmployeeIDs.push(caller.id)
        })

        return this.api.post(this.url + 'assign_employees/', {
                employee_ids: arrayEmployeeIDs
            }).then(()=> {return Promise.resolve()})
        ;

    }

    unassignEmployees(employeeStore) {

        let arrayEmployeeIDs = []

        employeeStore.models.forEach(caller => {
            arrayEmployeeIDs.push(caller.id)
        })

        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'unassign_employees/' + this.id + '/', {
                employee_ids: arrayEmployeeIDs
            })
        );

    }
    assignSubscriptions(arraySubIDs) {


        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'assign_subscriptions/', {
                subscription_ids: arraySubIDs
            })
        );

    }


}

export class BucketStore extends Store {
    Model = Bucket;
    static backendResourceName = 'bucket';
}
