import { observable } from 'mobx';
import { Model, Store } from './Base';
import { Team } from './Team';
export class TeamLocation extends Model {
    static backendResourceName = 'team_location';

    @observable id = null;
    @observable remarks = '';
    @observable clientRef = '';
    @observable name = '';

    relations() {
        return {
            team: Team,
        };
    }
}

export class TeamLocationStore extends Store {
    Model = TeamLocation;
    static backendResourceName = 'team_location';
}
