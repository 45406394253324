import {
    BinderApi,
    Casts as BCasts,
    Model as BModel,
    Store as BStore,
} from 'mobx-spine';
import { action, toJS } from 'mobx';
import { omit, result, uniq } from 'lodash';
import moment from 'moment';
import URI from 'urijs';
import { PUBLIC_URL } from "../helpers";


// Function ripped from Django docs.
// See: https://docs.djangoproject.com/en/dev/ref/csrf/#ajax
/* function csrfSafeMethod(method) {
    // These HTTP methods do not require CSRF protection.
    return /^(GET|HEAD|OPTIONS|TRACE)$/i.test(method);
} */

class MyApi extends BinderApi {

    // hardcoded for now becuase of T35087, can be put back once V1 has been totally removed
    baseUrl = '/api/'
    // baseUrl = process.env.REACT_APP_CY_FRONTEND_API_BASE_URL;

    fetchStore({ url, data, requestOptions }) {
        return this.get(url, data, requestOptions).then(res => {
            return {
                response: res,
                data: res.data,
                repos: res.with,
                relMapping: res.with_mapping,
                totalRecords: res.meta ? res.meta.total_records : res.data.length,
            };
        });
    }
    getFileUrlRex(url) {
        if (url instanceof Blob || url instanceof File) {
            return Promise.resolve(URL.createObjectURL(url));
        } else if (typeof url !== 'string' || !url.startsWith('/api/')) {
            return Promise.resolve(url);
        }

        // Strip leading '/api'
        url = url.slice(4);


        const key = url;
        if (cache[key] !== undefined) {
            return cache[key];
        }

        // Parse params
        const params = {};
        const searchPos = url.indexOf('?');
        if (searchPos !== -1) {
            // eslint does not support for in loops...
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] in new URLSearchParams(url.slice(searchPos)).entries()) {
                // eslint-disable-next-line no-unused-vars
                params[key] = value;
            }
            url = url.slice(0, searchPos);
        }

        cache[key] = (
            this.get(url, params, { responseType: 'blob' })
                .then((res) => URL.createObjectURL(res))
        );
        return cache[key];
    }
}

export const myApi = new MyApi();


export class Model extends BModel {
    api = myApi;

    restore() {
        return this.api.post(this.url);
    }

    getAllFlattenedWildcardErrors() {
        let errors = this.getWildcardErrors();

        this.__activeCurrentRelations.forEach(attr => {
            errors = errors.concat(this[attr].getWildcardErrors());
        });
        return uniq(errors);
    }

    getWildcardErrors() {
        return toJS(this.backendValidationErrors['*']) || [];
    }

    reFresh = param => {
        return this.api.post(param);
    }

    restore = param => {
        return this.api.post(this.url);
    }

    validationErrorFormatter(obj) {
        return obj.message || obj.code;
    }

    snakeToCamel(s) {
        if (s.startsWith('_')) {
            return s;
        }
        return s.replace(/_\w/g, m => m[1].toUpperCase());
    }
}

export class Store extends BStore {
    api = myApi;

    @action
    fetch(options = {}) {
        this.__pendingRequestCount += 1;
        const data = Object.assign(
            this.__getApi().buildFetchStoreParams(this),
            this.params,
            options.data
        );
        return this.__getApi()
            .fetchStore({
                url: options.url || result(this, 'url'),
                data,
                requestOptions: omit(options, 'data'),
            })
            .then(
                action(res => {
                    this.__pendingRequestCount -= 1;
                    this.__state.totalRecords = res.totalRecords;
                    this.fromBackend(res);
                    return res.response;
                })
            ).catch(action(err => {
                this.__pendingRequestCount -= 1;

                throw err;
            })
            );
    }

    getWildcardErrors() {
        let errors = [];
        this.models.forEach(model => {
            errors = errors.concat(model.getWildcardErrors());
        });
        return errors;
    }

    wrapPendingRequestCount(callback) {
        this.__pendingRequestCount++;

        const p = callback();

        p.catch(() => {
        }).then(() => {
            this.__pendingRequestCount--;
        })

        return p;
    }
}

const cache = {};

class EngineApi extends BinderApi {
    baseUrl = null;
    socketUrl = `${PUBLIC_URL || ''}/ws/`;

    constructor(...args) {
        super(...args);
        this.getFileUrl = this.getFileUrl.bind(this);
    }

    getFileUrl(url) {
        if (url instanceof Blob || url instanceof File) {
            return Promise.resolve(URL.createObjectURL(url));
        } else if (typeof url !== 'string' || !url.startsWith('/api/')) {
            return Promise.resolve(url);
        }

        const key = url;
        if (cache[key] !== undefined) {
            return cache[key];
        }

        // Parse params
        const params = {};
        const searchPos = url.indexOf('?');
        if (searchPos !== -1) {
            // eslint does not support for in loops...
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] in new URLSearchParams(url.slice(searchPos)).entries()) {
                // eslint-disable-next-line no-unused-vars
                params[key] = value;
            }
            url = url.slice(0, searchPos);
        }

        cache[key] = (
            this.get(url, params, { responseType: 'blob' })
                .then((res) => URL.createObjectURL(res))
        );
        return cache[key];
    }

    fetchStore({ url, data, requestOptions }) {
        return this.get(url, data, requestOptions).then(res => {
            return {
                response: res,
                data: res.data,
                repos: res.with,
                relMapping: res.with_mapping,
                reverseRelMapping: res.with_related_name_mapping,
                totalRecords: res.meta.total_records,
                meta: res.meta,
            };
        });
    }
}

export const engineApi = new EngineApi();

export class EngineModel extends Model {
    api = engineApi;
}

export class EngineStore extends Store {
    api = engineApi;
}


export const api = myApi;

export const Casts = {
    ...BCasts,
    decimal: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return value.replace(/,/g, '').replace('.', ',');
        },
        toJS(attr, value) {
            if (value === null || value === '') {
                return null;
            }
            return value.replace(/\./g, '').replace(',', '.');
        },
    },
    durationMinutes: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return moment.duration(value, 'minutes');
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }
            // https://github.com/CodeYellowBV/mobx-spine/issues/57
            if (value === 0) {
                return 0;
            }
            return value.asMinutes();
        },
    },
    file: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }

            return URI(value)
                .absoluteTo(myApi.baseUrl)
                .toString();
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }

            return value;
        },
    },
};

export function subscribe(room, callback) {
    const result = api.socket.subscribe({
        onPublish: callback,
        room,
    });

    result.unsubscribe = function() {
        // Fix a rare bug where cypress switches to another view before we are
        // subscribed to this view which sometimes causes cypress to crash
        if (result !== undefined) {
            api.socket.unsubscribe(result);
        }
    }

    return result;
}
