import styled from 'styled-components';
import LoginImageHN from 'image/login-hn.jpg';
import LoginImageZSP from 'image/login-zsp.jpg';
import LoginImageCY from 'image/login-cy.jpg';
import LoginImageCEO from 'image/login-ceo.jpg';
import LoginImageDR from 'image/login-dr.png';
import LoginImageBriggs from 'image/background-briggs.jpg';
import LoginImageAS from 'image/login-as.jpg';

// {theme-select-copy-pasta}
const parser = document.createElement('a');
// Compatibilty with http://localhost/hn.sms.test/
parser.href = window.location.href.replace('localhost', '');
const hostname = parser.hostname.split('.');
const subdomain = hostname.length === 4 ? hostname[1] : hostname[0];

function getLoginImage() {
    switch (subdomain) {
        case 'hn':
            return LoginImageHN;
        case 'zsp':
        case 'zetespee':
            return LoginImageZSP;
        case 'ceomarketing':
            return LoginImageCEO;
        case 'cy':
            return LoginImageCY;
        case 'direct-result':
        case 'react':
            return LoginImageDR;
        case 'briggsandwalker':
            return LoginImageBriggs
        case 'alphasociety':
            return LoginImageAS;
        default:
            return LoginImageCY;
    }
}
const LoginImage = getLoginImage();

export default styled.div`
    flex: 1;
    display: flex;

    background-image: url(${LoginImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;
