import { observable } from 'mobx';
import { Model, Store } from './Base';
import {SignedDocumentStore} from './SignedDocument'

export const TYPE_EMPLOYEMENT_EMPLOYMENT_CONTRACT = 'employment contract';
export const TYPE_EMPLOYEMENT_CONTRACT = 'contract';
export const TYPE_EMPLOYEMENT_MANUAL = 'manual';
export const TYPES = [TYPE_EMPLOYEMENT_EMPLOYMENT_CONTRACT, TYPE_EMPLOYEMENT_CONTRACT, TYPE_EMPLOYEMENT_MANUAL];

export const TYPE_USER_BASIC = 'basic';
export const TYPE_USER_NEW_EMPLOYEES = 'new_employees';
export const TYPE_USER_NEW_JOB_APPLICATION = 'new_job_applications';
export const USER_TYPES = ['basic', 'new_employees', 'new_job_applications']

export const STATUS_DRAFT = 'draft';
export const STATUS_PUBLISHED = 'published';
export const STATUS_ARCHIVED = 'archived';
export const STATUSES = [STATUS_DRAFT, STATUS_PUBLISHED, STATUS_ARCHIVED];


export class Document extends Model {
    static backendResourceName = 'document';

    @observable id = null;
    @observable type = TYPE_EMPLOYEMENT_CONTRACT;
    @observable userType = 'basic';
    @observable status = STATUS_PUBLISHED;
    @observable label = '';
    @observable icon = '';
    @observable content = '';
    @observable required = false;
    @observable isPayroll = false;

    relations(){
        return{
            signedDocuments: SignedDocumentStore,
        }
    }
}

export class DocumentStore extends Store {
    Model = Document;
    static backendResourceName = 'document';
}
