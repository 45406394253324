import { observable, computed } from 'mobx';
import { Model, Store } from './Base';
import { Branch, BranchStore } from './Branch';
import { DeviceLocation, DeviceLocationStore } from './DeviceLocation';
import { Employee } from './Employee';
import { encode } from 'helpers/qr';
import { DEVICE_SETTINGS } from 'helpers';

export const STATUS_UNKNOWN = 'unknown';
export const STATUS_ACTIVE = 'active';
export const STATUS_DISABLED = 'disabled';

export class Device extends Model {
    static backendResourceName = 'device';

    @observable id = null;
    @observable appliedTeamMember = null;
    @observable identifier = '';
    @observable lastKnownVersion = null;
    @observable description = '';
    @observable mode = 'final';
    @observable status = STATUS_UNKNOWN;
    @observable log = null;
    @observable apiUrl = '';
    @observable referer = '';
    @observable websocketUrl = '';
    @observable apiKey = '';
    @observable certName = '';
    @observable googleMapsApiKey = '';
    @observable deviceEndpoint = '';
    @observable employeesForDebug = '';
    @observable featureCaptainsheetDamage = true;
    @observable featureRechtVanBezwaar = '';
    @observable lastUsed = '';

    relations() {
        return {
            branch: Branch,
            owner: Employee,
            locations: DeviceLocationStore,
            currentLocation: DeviceLocation,
            subBranches: BranchStore,
        };
    }

    @computed
    get qr() {
        const qr = {
            CY_API_URL: this.apiUrl,
            CY_REFERER: this.referer,
            CY_WEBSOCKET_URL: this.websocketUrl,
            CY_API_KEY: this.apiKey,
            CY_CERT_NAME: this.certName,
            CY_GOOGLE_MAPS_API_KEY: this.googleMapsApiKey,
            CY_DEVICE_ENDPOINT: this.deviceEndpoint,
            CY_EMPLOYEES_FOR_DEBUG: this.employeesForDebug,
            CY_DEVICE_CODE: this.identifier,
            CY_FEATURE_CAPTAINSHEET_DAMAGE: this.featureCaptainsheetDamage,
            CY_FEATURE_RECHT_VAN_BEZWAAR: this.featureRechtVanBezwaar,

            // This can cause different results based on if a owner is loaded or
            // not... This could be an argument to qr, but then it cannot be
            // a computed value anymore...
            CY_IS_FREELANCE: this.owner && this.owner.isFreelance,
        };

        return encode(JSON.stringify(qr));
    }

    deleteDuplicate(duplicateDevice) {
        return this.api.post(`${this.url}delete_duplicate/`, {
            duplicate: duplicateDevice.id,
        });
    }

    applyCurrentServerSettings() {
        console.log(DEVICE_SETTINGS)
        this.setInput('apiUrl', window.location.origin);
        this.setInput('referer', window.location.origin);
        this.setInput('websocketUrl', window.location.origin.replace('http', 'ws'));
        this.setInput('apiKey', DEVICE_SETTINGS.apiKey);
        this.setInput('certName', DEVICE_SETTINGS.certName);
        this.setInput('googleMapsApiKey', DEVICE_SETTINGS.googleMapsApiKey);
    }

    applyDebugServerSettings() {
        const origin = `${window.location.protocol}//10.0.2.2:${window.location.port}`;

        this.setInput('apiUrl', origin);
        this.setInput('referer', origin);
        this.setInput('websocketUrl', origin.replace('http', 'ws'));
        this.setInput('apiKey', DEVICE_SETTINGS.apiKey);
        this.setInput('certName', DEVICE_SETTINGS.certName);
        this.setInput('googleMapsApiKey', DEVICE_SETTINGS.googleMapsApiKey);
    }
}

export class DeviceStore extends Store {
    Model = Device;
    static backendResourceName = 'device';
}
