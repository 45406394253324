import { observable } from 'mobx';
import { Model, Store } from './Base';
import { SubscriptionStore } from './Subscription';

export class SubscriptionIssue extends Model {
    static backendResourceName = 'subscription_issue';

    @observable id = null;
    @observable reason = '';
    @observable createdAt = '';

    relations() {
        return {
            subscription: SubscriptionStore,
        };
    }

}

export class SubscriptionIssueStore extends Store {
    Model = SubscriptionIssue;
    static backendResourceName = 'subscription_issue';
}
