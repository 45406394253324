import { Model, Store } from '../Base';

export default class AbstractExportModel extends Model {
    toBackend(options = {}) {
        const output = {};
        // By default we'll include all fields (attributes+relations), but sometimes you might want to specify the fields to be included.
        const fieldFilter = field => {
            if (options.fields) {
                return options.fields.includes(field);
            }
            if (!this.isNew && options.onlyChanges) {
                const forceFields = options.forceFields || [];
                return (
                    forceFields.includes(field) ||
                    this.__changes.includes(field)
                );
            }
            return true;
        };
        this.__attributes.filter(fieldFilter).forEach(attr => {
            if (!attr.startsWith('_')) {
                output[
                    this.constructor.toBackendAttrKey(attr)
                ] = this.__toJSAttr(attr, this[attr]);
            }
        });

        // Primary key is always forced to be included.
        output[this.constructor.primaryKey] = this[this.constructor.primaryKey];

        // Add active relations as id.
        this.__activeCurrentRelations
            .filter(fieldFilter)
            .forEach(currentRel => {
                const rel = this[currentRel];
                const relBackendName = this.constructor.toBackendAttrKey(
                    currentRel
                );
                if (rel instanceof Model) {
                    output[relBackendName] = rel.toBackend(options);
                }
                if (rel instanceof Store) {
                    output[relBackendName] = rel.map(x => x.toBackend(options));
                }
            });
        return output;
    }
}
