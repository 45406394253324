import { observable } from 'mobx';
import { Model, Store } from './Base';
import { SubscriberIssueStore } from './SubscriberIssue';
import {Casts} from "mobx-spine";

export class Subscriber extends Model {
    static backendResourceName = 'subscriber';

    @observable id = null;
    @observable initials = '';
    @observable firstName = '';
    @observable middleName = '';
    @observable lastName = '';
    @observable gender = '';
    @observable dateOfBirth = null;
    @observable landline = '';
    @observable mobile = '';
    @observable email = '';
    @observable zipCode = '';
    @observable street = '';
    @observable houseNumber = '';
    @observable houseNumberSuffix = '';
    @observable city = '';
    @observable country = '';
    @observable spokenLanguage = '';
    @observable bic = ''

    relations() {
        return {
            issues: SubscriberIssueStore,
        };
    }

     casts() {
        return {
            dateOfBirth: Casts.date,
        };
    }
}

export class SubscriberStore extends Store {
    Model = Subscriber;
    static backendResourceName = 'subscriber';
}
