import {Model, Store} from '../Base';
import {observable} from "mobx";
import {Employee} from "../Employee";
import {EarningPeriod} from "./EarningPeriod";
import {WageStructure} from "./WageStructure";
import {EarningComponentStore} from "./EarningComponent";

export class Earning extends Model {
    static backendResourceName = 'earning';

    @observable id = null;
    @observable needsRecalculation = false;
    @observable amountEarned = 0


    relations() {
        return {
            wageStructure: WageStructure,
            employee: Employee,
            earningPeriod: EarningPeriod,
            earningComponents: EarningComponentStore
        };
    }
}

export class EarningStore extends Store {
    Model = Earning;
    static backendResourceName = 'earning';
}
