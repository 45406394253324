import CYLogo from "../../image/logo/cy.png";
import Logo from '../../image/logo/fym.png'
import LoginBackground from '../../image/login-cy.jpg'

export const COLOR_PRIMARY = '#393939'
export const COLOR_SECONDARY = '#0074a4'
export const COLOR_TEXT = 'rgba(0, 0, 0, 0.7)'

// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export default {
    primaryColor: COLOR_PRIMARY,
    secondaryColor: COLOR_SECONDARY,
    buttonPrimaryColor: COLOR_SECONDARY,
    buttonPrimaryTextColor: '#fff',
    textColor: COLOR_TEXT,
    cyLogo: CYLogo,
    logo: Logo,
    loginBackground: LoginBackground,
    loginBackgroundColor: COLOR_PRIMARY,
    lightColor: '#F5F5F5'
}
