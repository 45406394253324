import {observable} from 'mobx';
import {Casts, Model, Store} from './Base';
import {EmployeeStore} from "./Employee";
import {omit} from "lodash";
import {GroupStore} from "./User";
import {ClientStore} from "./Client";
import {t} from '../i18n'

export const METABASE_PARAM_OPTIONS = [
    {
        value: 'employee',
        text: t('metabase.field.params.value.employee')
    },
    {
        value: 'main_branch',
        text: t('metabase.field.params.value.mainBranch')
    },
    {
        value: 'branches',
        text: t('metabase.field.params.value.branches')
    },
    {
        value: 'all_branches',
        text: t('metabase.field.params.value.all_branches')
    },
    {
        value: 'client',
        text: t('metabase.field.params.value.client')
    },
]

export class Metabase extends Model {
    static backendResourceName = 'metabase';

    @observable id = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable dashboardId = null;
    @observable enabled = false;
    @observable name = '';
    @observable sidebarEnabled = false;
    @observable metabaseUrl = null;
    @observable sequenceNumber = null;
    @observable params = []

    relations() {
        return {
            employees: EmployeeStore,
            groups: GroupStore,
            clients: ClientStore
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
    toBackend(options = {}) {
        return omit(super.toBackend(options), 'metabase_url');
    }

}

export class MetabaseStore extends Store {
    Model = Metabase;
    static backendResourceName = 'metabase';
}
