import { observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Job } from './Job'

export class JobFactor extends Model {
    static backendResourceName = 'job_factor';

    @observable id = null;
    @observable factor = null;
    @observable startDate = null;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            job: Job,
        };
    }

    casts() {
        return {
            startDate: Casts.date,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class JobFactorStore extends Store {
    Model = JobFactor;
    static backendResourceName = 'job_factor';
}
