import DrTextLogo from '../../image/logo/briggsandwalker_light.png'
import CYLogo from "../../image/logo/cy.png";
import Logo from '../../image/logo/briggs-logo.png'
import logoSidebar from '../../image/logo/briggs-logo.png'
import LoginBackground from '../../image/background-briggs.jpg'


// export const COLOR_PRIMARY = '#1E1F60'
// export const COLOR_SECONDARY = '#304FFE'
// export const COLOR_THIRD = '#2E2E3B';

export const COLOR_PRIMARY = '#C5C3D3';
export const COLOR_SECONDARY = '#6AF476'
export const COLOR_THIRD = '#C5C3D3';
export const COLOR_TEXT = 'rgba(0, 0, 0, 0.7)';


// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export default {
    sidebarColor: '#17104F',
    primaryColor: COLOR_PRIMARY,
    secondaryColor: COLOR_SECONDARY,
    thirdColor: COLOR_THIRD,
    buttonPrimaryColor: COLOR_PRIMARY,
    buttonPrimaryTextColor: '#C5C3D3',
    textColor: COLOR_TEXT,
    cyLogo: CYLogo,
    textLogo: DrTextLogo,
    logo: Logo,
    sidebarLogo:logoSidebar,
    loginBackground: LoginBackground,
    loginBackgroundColor: COLOR_PRIMARY,
    sidebar: false,
    lightColor: '#F5F5F5'
}
