import { observable } from 'mobx';
import { Model, Store } from './Base';
import { Metafield } from './Metafield';
import {Subscription} from "./Subscription";

export class Metafieldvalue extends Model {
    static backendResourceName = 'metafieldvalue';

    @observable id = null;
    @observable value;

    relations() {
        return {
            metafield: Metafield,
            subscription: Subscription,
        };
    }
}

export class MetafieldvalueStore extends Store {
    static backendResourceName = 'metafieldvalue';

    Model = Metafieldvalue;
}
