import { observable } from 'mobx';
import {Casts, Model, Store} from './Base';
import {Employee} from './Employee'
import moment from 'moment';

export class Factor extends Model {
    static backendResourceName = 'factor';

    @observable id = null;
    @observable factor = null;
    @observable startDate = moment();
    @observable reason = null;


    relations() {
        return {
            employee: Employee,
            lastEdited: Employee
        };
    }

     casts() {
        return {
            startDate : Casts.date,
        };
    }


}

export class FactorStore extends Store {
    Model = Factor;
    static backendResourceName = 'factor';
}
