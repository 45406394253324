import {observable} from 'mobx';
import {Casts, Model, Store} from '../../Base';
import {Metafield} from '../../Metafield';
import {Product} from '../../Product';
import {ContentbundleSlide} from './ContentbundleSlide';

export class ContentbundleCondition extends Model {
    static backendResourceName = 'contentbundle_condition';

    @observable id = null;
    @observable metafieldValue = null;

    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            slide: ContentbundleSlide,
            product: Product,
            metafield: Metafield,
        }
    }
}

export class ContentbundleConditionStore extends Store {
    Model = ContentbundleCondition;
    static backendResourceName = 'contentbundle_condition';
}

