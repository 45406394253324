import { observable } from 'mobx';
import { Model, Store } from '../Base';
import { FilterNode } from './FilterNode';

export class Filter extends Model {
    static backendResourceName = 'filter';

    @observable completeSubscriptions = null;
    @observable remarks = '';

    relations() {
        return {
            filter: FilterNode,
        };
    }
}

export class FilterStore extends Store {
    Model = Filter;
    static backendResourceName = 'filter';
}
