import { observable } from 'mobx';
import { Model, Store } from './Base';
import { CampaignStore } from './Campaign';
import { UserStore } from './User';
import { ProductStore } from './Product';
import { MetabaseStore } from './Metabase';
import { ClientRefNrStore } from './ClientRefNr';

export class Client extends Model {
    static backendResourceName = 'client';

    @observable id = null;
    @observable name = '';
    @observable maximumStorageTerm = null;
    @observable doubleConfigured = false;
    @observable sendEncryptedSubscriberMails = false;
    @observable maxAmountOfCalls = null;
    @observable maxSubAmountWages = null;

    relations() {
        return {
            campaigns: CampaignStore,
            products: ProductStore,
            users: UserStore,
            metabases: MetabaseStore,
            clientRefNrs: ClientRefNrStore,
        };
    }

    casts() {
        return {
            maximumStorageTerm: {
                toJS(attr, val) {
                    if (!val) {
                        return null;
                    }

                    return val;
                },
                parse(attr, val) {
                    return val;
                }
            }
        };
    }

    createUser(email, firstName, Lastname) {
        return this.api.post(`${this.url}user/`, {
            email: email,
            first_name: firstName,
            last_name: Lastname
        });
    }
}

export class ClientStore extends Store {
    Model = Client;
    static backendResourceName = 'client';
}
