import { observable } from 'mobx';
import { Model, Store } from './Base';
import {ShiftWageDefinitionStore} from './ShiftWageDefinition';
import { TeamStore } from './Team';
import { TeamMemberStore } from './TeamMember';

export class ShiftType extends Model {
    static backendResourceName = 'shift_type';

    @observable id = null;
    @observable name = '';
    @observable isDefault = false;
    @observable description = '';

     relations() {
        return {
            teamShifts: TeamStore,
            memberShifts: TeamMemberStore,
            definitions: ShiftWageDefinitionStore
        };
    }
}

export class ShiftTypeStore extends Store {
    static backendResourceName = 'shift_type';

    Model = ShiftType;
}
