import hn from './theme/hn.js';
import zsp from './theme/zsp.js';
import cy from './theme/cy.js';
import fym from './theme/fym.js';
import ceom from './theme/ceom.js'
import dr from './theme/dr.js';
import briggs from './theme/briggs.js';
import thnl from './theme/th-nl.js';
import upmarketing from './theme/upmarketing.js';
import as from './theme/as.js';
import uphillmarketing from './theme/uphillmarketing.js';

import faviconHn from '../image/favicon-hn.ico';
import faviconZsp from '../image/favicon-zsp.ico';
import faviconCoem from '../image/favicon-ceom.ico';
import faviconDr from '../image/favicon-dr.ico';
import faviconBriggs from '../image/favicon-briggs.ico'
import faviconTHNL from '../image/favicon-th-nl.ico'
import faviconUpmarketing from '../image/favicon-upmarketing.ico'
import faviconUphillmarketing from '../image/favicon-uphill-new.ico'
// {theme-select-copy-pasta}
const parser = document.createElement('a');

// Compatibilty with http://localhost/hn.sms.test/
parser.href = window.location.href.replace('localhost', '');

const hostname = parser.hostname.split('.');
const subdomain = hostname.length === 4 ? hostname[1] : hostname[0];

export default function getTheme() {
    switch (subdomain) {
        case 'hn':
        case 'hn2':
                document.getElementById('favicon').setAttribute('href', faviconHn);

                // v1 compatibiltiy.
                document.getElementsByTagName('body')[0].setAttribute('class', subdomain);
            return hn;
        case 'zsp':
        case 'zsp2':
        case 'zetespee':
                document.getElementById('favicon').setAttribute('href', faviconZsp);

                // v1 compatibiltiy.
                // const a = document.getElementsByTagName('body')[0].setAttribute('class', subdomain);
            return zsp;
        case '4ym':
                //document.getElementById('favicon').setAttribute('href', faviconZsp);

                // v1 compatibiltiy.
                //const a = document.getElementsByTagName('body')[0].setAttribute('class', subdomain);
            return fym;
        case 'ceomarketing':
            document.getElementsByTagName('body')[0].setAttribute('class', 'ceomarketing');
            document.getElementById('favicon').setAttribute('href', faviconCoem);
            return ceom;
        case 'cy':
            return cy;
        case 'direct-result':
            document.getElementsByTagName('body')[0].setAttribute('class', 'dr');
            document.getElementById('favicon').setAttribute('href', faviconDr);
            return dr;
        case 'dr-react':
        case 'react':
            document.getElementsByTagName('body')[0].setAttribute('class', 'dr');
            document.getElementById('favicon').setAttribute('href', faviconDr);
            return dr;
        case 'briggsandwalker':
        case 'agency-test':
            document.getElementsByTagName('body')[0].setAttribute('class', 'briggs');
            document.getElementById('favicon').setAttribute('href', faviconBriggs);
            return briggs
        case 'th-nl':
            document.getElementsByTagName('body')[0].setAttribute('class', 'th-nl');
            document.getElementById('favicon').setAttribute('href', faviconTHNL);
            return thnl;
        case 'alphasociety':
            document.getElementsByTagName('body')[0].setAttribute('class', 'alphasociety');
            return as;
        case 'upmarketing':
            document.getElementsByTagName('body')[0].setAttribute('class', 'upmarketing');
            document.getElementById('favicon').setAttribute('href', faviconUpmarketing);
            return upmarketing;
        case 'uphill-marketing':
            document.getElementsByTagName('body')[0].setAttribute('class', 'uphill-marketing');
            document.getElementById('favicon').setAttribute('href', faviconUphillmarketing);
            return uphillmarketing;
        default:
            return cy;
    }
};
