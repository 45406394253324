export default {
  "tariffDocument": {
    "edit": {
      "title": "Document tarifaire"
    },
    "field": {
      "name": {
        "label": "Nom"
      },
      "description": {
        "label": "Description"
      },
      "file": {
        "label": "Fichier"
      }
    }
  },
  "brandingMessage": "Powered by Code Yellow",
  "nav": {
    "contentbundle": {
      "overview": "Modules d'inscription"
    },
    "dpn": {
      "overview": "DPN",
      "subnav": {
        "location": "Location Manager",
        "backoffice": "BackOffice",
        "frontend": "Frontend"
      }
    },
    "office": {
      "overview": "Office"
    },
    "product": {
      "overview": "Produits"
    },
    "client": {
      "overview": "Clients",
      "subnav": {
        "client": "Clients",
        "campaigne": "Campagnes",
        "bwCampaigne": "BW Campagnes",
        "contentbundle": "Modules d'inscription",
        "products": "Produits",
        "metafields": "Metafields",
        "sequences": "Séquences",
        "wagesTariff": "Tarif des salaires",
        "bwProduct": "BW Produits",
        "bwfailures": "BW Erreures d’importation",
        "clientFile": "Fichiers clients",
        "clientRefNr": "Numéros de référence client "
      }
    },
    "data": {
      "overview": "Organisation",
      "subnav": {
        "user": "Utilisateurs",
        "location": "Bureaux",
        "bwLocation": "BW Bureaux",
        "groups": "Groupes",
        "employees": "Employés",
        "job": "Fonctions",
        "devices": "Appareils",
        "trainship": "Stages",
        "contract": "Contrat",
        "tips": "Conseils",
        "shop": "Boutique",
        "handbook": "Manuel",
        "privacy": "Déclaration de confidentialité",
        "videos": "Vidéos",
        "dashboard": "Promoteur app dashboard",
        "coins": "Pièces",
        "documents": "Documents",
        "news": "Actualités",
        "milestone": "Jalons",
        "promotorIdea": "Les idées des promoteurs",
        "promotorAppMedia": "Promotor App Media",
        "expenses": "Côuts",
        "metabase": "Métabases",
        "csv": "CSV",
        "alphaWages": "Salaires alpha",
        "shiftType": "Type d'équipe",
        subscriptionReason: "Motifs d'abonnement",
      }
    },
    "result": {
      "overview": "Résultats",
      "subnav": {
        "teams": "Teams",
        "data": "Entrer",
        "call": "Rappeler",
        "callPlan": "Planification de rappel",
        "callPlanning": "Planification de rappel",
        "overview": "Aperçu",
        "export": "Exportation",
        "exportArchive": "Archive d’exportation",
        "stats": "Statistiques",
        "dashboard": "Dashboard",
        "purge": "Purge",
        "iban": "Erreurs d’IBAN",
        "myResults": "Mes résultats",
        "creditationImporter": "Importer des crédits"
      }
    },
    "personal": {
      "overview": "Mon personnel"
    },
    "promoterApp": {
      "overview": "Promoteur app"
    },
    "tablets": {
      "overview": "Tablettes",
      "tariff": {
        "overview": "Tarif"
      },
      "map": "Carte"
    },
    "wages": {
      "approval": "Approbations",
      "overview": "Salaires",
      "incidental": "Occasionnel",
      "export": "Exporter",
      "freelance": "Salaires des indépendants",
      "employee": "Salaires des employés"
    },
    "account": {
      "label": "Responsable de compte",
      "password": "Mot de passe",
      "details": "Détails"
    },
    "prompt": {
      "leave": "Vous n'avez pas enregistré les modifications apportées au seau de la campagne {{bucket}}, êtes-vous sûr de vouloir quitter la page ?"
    },
    "dashboards": {
      "overview": "Dashboards"
    }
  },
  "form": {
    "startDate": "Date de début",
    "endDate": "Date de fin",
    "no": "Non",
    "yes": "Oui",
    "either": "Les deux",
    "search": "Chercher...",
    "jsonButton": "Importation/Exportation",
    "historyButton": "L’histoire",
    "saveAndBackButton": "Sauvegarder et retourner",
    "saveAddAnotherButton": "Sauvegarder et nouveau",
    "saveAndDownloadButton": "Sauvegarder et télécharger",
    "copyButton": "Copier",
    "syncButton": "Sync",
    "downloadButton": "Télécharger",
    "cancelButton": "Annuler",
    "backButton": "Retour",
    "saveAndHireButton": "Sauvegarder et accepter",
    "saveAndRejectButton": "Sauvegarder et refuser",
    "saveAndHireAndConvertButton": "Sauvegarder, accepter et convertir en employé",
    "acceptButton": "Accepter",
    "rejectButton": "Refuser",
    "pendingButton": "En attente",
    "reExportButton": "Réexportation",
    "dateRangePlaceholder": "Selectionnez",
    "modalConfirmDelete": {
      "title": "Suppression en dur",
      "content": "Êtes-vous sûr de supprimer ce point ?"
    },
    "reconnectButton": "Se connecter au serveur d'appel",
    "autoFillButton": "Remplissage automatique",
    "fileType": {
      "image": "Image",
      "any": "",
      "none": "Vide"
    },
    "validation": {
      "error": "Des erreurs de validation ont été constatées"
    },
    "check": "Contrôlé",
    "addButton": "Ajouté",
    "multiPick": {
      "searchPlaceholder": "Selectionnez…",
      "selectedText": "$1 des $2 sélectionné",
      "noneSelectedText": "Aucun sélectionné",
      "selectAllButton": "Tout",
      "selectNoneButton": "Rien"
    },
    "importButton": "Importer",
    "saveButton": "Sauvegarder",
    "undoRejectionButton": "Annuler le refus",
    "undoHireButton": "Annuler le acceptation",
    "closeButton": "Fermer",
    "saveAndNewButton": "Sauvegarder et nouveau",
    "saveLoadingButton": "En sauvegardant…",
    "sendButton": "Envoyer",
    "editButton": "Modifier",
    "viewButton": "Voir",
    "actions": "Actions",
    "deleteButton": "Supprimer",
    "searchLabel": "Rechercher",
    "applyButton": "Appliquer",
    "selectPlaceholder": "Selectionner…",
    "deleteConfirmation": "Êtes-vous sûr de vouloir supprimer {{name}}?",
    "restoreConfirmation": "Êtes-vous sûr de vouloir restaurer {{name}}?",
    "typeToSearchText": "Tapez pour rechercher…",
    "copyToClipboardTooltip": "Copier dans le presse-papier",
    "notifications": {
      "saveSuccess": "Sauvegardé avec succès",
      "deleteSuccess": "Supprimé avec succès",
      "restoreSuccess": "Restauré avec succès",
      "saveError": "Erreur de sauvegarde ({{status}})",
      "saveValError": "Toutes les infos ne sont pas remplies correctement",
      "saveAuthError": "Des pouvoirs non valables, essayez de vous reconnecter",
      "newAppVersion": "Nouvelle version disponible, cliquez pour recharger la page dès que possible",
      "blockedCustomer": "Le client est bloqué pour certains employés",
    },
    "validationErrors": {
      "blank": "Champ obligatoire",
      "null": "Champ obligatoire",
      "max_digits": "Valeur maximale du champ a été dépassée",
      "max_whole_digits": "Valeur maximale du champ a été dépassée",
      "max_value": "Valeur maximale a été dépassée",
      "invalid": "Valeur non valable",
      "reset_code_invalid": "Code de réinitialisation non valable",
      "password_too_common": "Mot de passe est trop courant",
      "invalid_token": "Token non valable",
      "unique": "Champ n’est pas unique",
      "unique_together": "Champ n’est pas unique",
      "unknown": "Erreur inconnue dans le champ",
      "date_mismatch": "La date de fin ne peut pas être antérieure à la date de début",
      "routing_error": "Ne pouvait pas calculer l’itinéraire entre cette activité et la précédente",
      "geo": {
        "failed-error": "Erreur de localisation inconnue",
        "failed-no-results": "Ne pouvait pas trouver le lieu",
        "failed-multiple-results": "Trop de lieux ont été trouvé",
        "failed-no-housenumber": "Le numéro de maison spécifique n’a pas été trouvé",
        "not_precise": "Localisation trouvée mais non précise"
      },
      "invalid_file_type": "Le type de fichier du document est incorrect. Types de fichiers acceptés : {{types}}."
    },
    "restoreButton": "Réparer",
    "datePlaceholder": "Date et heure",
    "draftJs": {
      "style": {
        "BOLD": {
          "label": "Gras"
        },
        "CODE": {
          "label": "Monospace"
        },
        "ITALIC": {
          "label": "Italique"
        },
        "UNDERLINE": {
          "label": "Souligner"
        },
        "blockquote": {
          "label": "Citation en bloc"
        },
        "code-block": {
          "label": "Bloc de code"
        },
        "header-five": {
          "label": "H5"
        },
        "header-four": {
          "label": "H4"
        },
        "header-one": {
          "label": "H1"
        },
        "header-six": {
          "label": "H6"
        },
        "header-three": {
          "label": "H3"
        },
        "header-two": {
          "label": "H2"
        },
        "ordered-list-item": {
          "label": "OL"
        },
        "unordered-list-item": {
          "label": "UL"
        }
      }
    }
  },
  "purge": {
    "id": "ID",
    "name": "Nom",
    "subscription_count": "Nombre d’inscriptions",
    "created_at": "Créé à",
    "permanently_deleted_at": "Supprimé définitivement à",
    "download": "Télécharger",
    "permenantly_deleted_at": "Supprimé de façon permanente à"
  },
  "coin": {
    "field": {
      "id": "ID",
      "total": "Total",
      "coin": {
        "slug": "slug",
        "task": "Coin tâche"
      },
      "employee": {
        "id": "ID",
        "name": "Employé",
        "coins": "coins gagnés",
        "points": "Points gagnés",
        "createdAt": "Créé à"
      },
      "performedAt": {
        "label": "Réalisée à"
      }
    }
  },
  "product": {
    "deleteTooltip": "Supprimer le produit",
    "restoreTooltip": "Réparer le produit",
    "viewTooltip": "Voir le produit",
    "editTooltip": "Modifier le produit",
    "copyTooltip": "Copier le produit",
    "noBriggsAndWalkerConnected": "Pas d’identification liéé",
    "nav": {
      "overview": "Produits"
    },
    "page": {
      "overview": {
        "title": "Produits"
      }
    },
    "createButton": "Ajouter produit",
    "save": {
      "error": "N’a pas pu sauvegarder le produit !",
      "success": "Le produit est sauvegardé"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "factor": {
        "label": "Facteur"
      },
      "icon": {
        "label": "Icone",
        "helpText": "Glissez l’image ou clickez ici pour téléverser"
      },
      "includeScanFiles": {
        "label": "Attacher tablet scan au mail de welcome"
      },
      "blockers": {
        "label": "Bloqueurs",
        "placeholder": "Bloqueurs...",
        "selectedText": "$1 de $2 bloqueurs",
        "noneSelectedText": "Selectionnez bloqueur",
        "checkAll": "Sélectionnez tout",
        "uncheckAll": "Désélectionner tout"
      },
      "metafields": {
        "label": "Metafields",
        "placeholder": "Metafields...",
        "selectedText": "$1 de $2 metafields",
        "noneSelectedText": "Selectionnez metafields",
        "checkAll": "Sélectionnez tout",
        "uncheckAll": "Désélectionner tout"
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom…"
      },
      "min_age": {
        "label": "L’âge minimum requis",
        "placeholder": "L’âge minimum..."
      },
      "client": {
        "label": "Client",
        "placeholder": "Client..."
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "donation": "Donation",
        "subscription": "Inscription",
        "contract": "Contrat"
      },
      "welcomeEmailTemplate": {
        "label": "Modèle de courriel de bienvenue",
        "placeholder": "Modèle de courriel de bienvenue…",
        "help": "L'ID d'un modèle transactionnel de mailjet"
      },
      "textVerificationMessage": {
        "label": "Message de vérification",
        "placeholder": "Message de vérification...",
        "help": "Message de vérification qui sera envoyé par SMS. Exemple :\n Votre code de vérification SMS unique est {code}"
      },
      "max_start_date_delta": {
        "label": "Max. Date de début ultérieure",
        "placeholder": "p. ex. pendant 2 mois: P2M",
        "error": {
          "incorrect": "Format n’est pas correcte"
        }
      },
      "bw_id": {
        "label": "Briggs & Walker connexion",
        "placeholder": "Briggs & walker ID..."
      },
      "paymentInterval": {
        "label": "Fréquence",
        "info": "Infos sur la fréquence",
        "value": {
          "null": "Ponctuel",
          "P1D": "Quotidiennement",
          "P1W": "Hebdomadaire",
          "P1M": "Mensuel",
          "P3M": "Chaque trimestre",
          "P6M": "Semestriel",
          "P1Y": "Annuel"
        }
      },
      "paymentIntervalEnabled": {
        "label": "Fréquence spécifique des produits"
      },
      "welcomeSmsEnabled":{
        label: 'Utilisez un SMS de bienvenue'
      },
      "welcomeSmsText":{
        label: 'Sms de bienvenue'
      },
      "welcomeSmsSender":{
        label: 'Expéditeur de SMS de bienvenue'
      },
    },
    "create": {
      "title": "Créer un produit"
    },
    "edit": {
      "title": "Modifier un produit",
      "basicTitle": "Données de base",
      "blockerTitle": "Bloqueurs",
      "metafieldTitle": "Metafields"
    }
  },
  "blocker": {
    "field": {
      "id": {
        "label": "ID"
      },
      "issue_model": {
        "label": "Modèle",
        "placeholder": "Modèle..."
      },
      "issue_reason": {
        "label": "Raison",
        "placeholder": "Raison..."
      },
      "is_export_blocking": {
        "label": "Blocage des exportations?",
        "placeholder": "Blocage des exportations..."
      }
    }
  },
  "fileDefinition": {
    "save": {
      "error": "N’a pas pu sauvegarder la définition !",
      "success": "La définition est sauvegardée"
    },
    "import": {
      "error": "Ne pouvait pas importer la définition !",
      "success": "La definition est importée"
    },
    "choice_operator": {
      "exact": "équivaut à",
      "exact_not": "n’équivaut pas à",
      "lt": "est plus petit que",
      "lte": "est plus petit que / équivaut à",
      "gt": "est plus grand que",
      "gte": "est plus grand que / équivaut à"
    },
    "group_combinator": {
      "and": "et",
      "or": "ou"
    },
    "convertCase": {
      "uppercase": "Lettres majuscules",
      "lowercase": "Lettres minuscules",
      "titlecase": "Lettre majuscule par mot"
    },
    "location": {
      "left": "Gauche",
      "right": "Droit",
      "both": "Les deux",
      "everywhere": "Partout"
    },
    "repeat": {
      "label": "Répéter",
      "no": "Non",
      "yes": "Oui"
    },
    "jsonModal": {
      "title": "Modifier la définition",
      "instructions": "Le texte ci-dessous peut être copié et collé dans une autre exportation. En cliquant sur \"importer\", cela sera appliqué. Vous pouvez ensuite enregistrer la nouvelle exportation."
    },
    "overview": {
      "title": "Définitions de fichiers"
    },
    "field": {
      "format": {
        "label": "Format"
      },
      "description": {
        "label": "Déscription"
      }
    },
    "edit": {
      "title": "Modifiez la definition du fichier"
    }
  },
  "filterOption": {
    "save": {
      "error": "N’a pas pu sauvegarder le filtre !",
      "success": "Le filtre est sauvegardé"
    }
  },
  "hardDelete": {
    "header": "Vous voulez vraiment le supprimer?",
    "content": "Cette action ne peut pas être annulée !",
    "confirm": "Oui",
    "cancel": "Non"
  },
  "subscription": {
    "creditationImporter": {
      "title": "Importateur de crédits",
      "importer": {
        "fileButton": "Selectionner le fichier",
        "buttonTitle": "Commencez l’importation",
        "dropFiles": "Téléverser des fichiers"
      }
    },
    "calls": {
      "modal": {
        "buttonTitle": "Appels téléphoniques",
        "caller": "Appelant",
        "start": "Début",
        "duration": "Durée",
        "voicelogs": "Voicelogs"
      },
      "modalTitle": "Appels téléphoniques avec l’abonné"
    },
    "cancel": {
      "modalTitle": "Annuler l’abonnement",
      "saveCancelation": "Sauvegarder l’annulation",
      "details": "Détails d'annulation"
    },
    "nav": {
      "overview": "Abonnés"
    },
    "page": {
      "overview": {
        "title": "Abonnés"
      }
    },
    "overviewMock": {
      "title": "Test Abonnés"
    },
    "download": {
      "button": "CSV"
    },
    "field": {
      "updatedAt": {
        "label": "Mise à jour le"
      },
      "id": {
        "label": "ID"
      },
      "creditation": {
        "label": "Créditation",
        "options": {
          "both": "L'un et l'autre",
          "false": "Non",
          "true": "Oui"
        }
      },
      "systemStatus": {
        "label": "État du système"
      },
      "appointmentStatus": {
        "label": "Etat du rendez-vous"
      },
      "subscriber": {
        "lastName": "Nom de famille"
      },
      "iban": {
        "label": "Iban"
      },
      "amount": {
        "label": "Montant"
      },
      "quantity": {
        "label": "Quantité"
      },
      "total": {
        "label": "Total",
        "completed": "Complété",
        "deleted": "Supprimé",
        "bucket": "Dans la liste des appels",
        "validation": "Prêt pour validation",
        "queue": "Prêt pour la liste des appels",
        "export": "Prêt pour exportation",
        "attention": "Attention nécessaire"
      },
      "product": {
        "label": "Produit",
        "placeholder": "Produit..."
      },
      "status": {
        "label": "Etat",
        "placeholder": "Etat...",
        "value": {
          "needs validation": "Prêt pour validation",
          "needs attention": "Attention nécessaire",
          "deleted": "Supprimé",
          "ready for export": "Prêt pour exportation",
          "in call bucket": "Dans la liste des appels",
          "completed": "Prêt",
          "canceled": "Annulé",
          "queue": "Prêt pour la liste des appels",
          "creditation": "Créditation"
        }
      },
      "cancelled": {
        "label": "Cancelled",
        "options": {
          "all": "All",
          "only": "Only cancelled",
          "without": "Without cancelled"
        }
      },
      "client": {
        "label": "Client",
        "placeholder": "Client..."
      },
      "clientRefNr": {
        "label": "Numéro de référence du client  ",
        "placeholder": "Numéro de référence du client ..."
      },
      "error": {
        "label": "Erreur",
        "subscription": "ABONNEMENT ERRONÉ",
        "subscriber": "ABONNÉ ERRONÉ"
      },
      "date": {
        "label": "Date",
        "placeholder": "Date..."
      },
      "entryRemarks": {
        "label": "Remarque d’entrée",
        "placeholder": "Remarque d’entrée..."
      },
      "callRemarks": {
        "label": "Remarque d’appel",
        "placeholder": "Remarque d’appel..."
      },
      "clientRemarks": {
        "label": "Remarque du client",
        "placeholder": "Remarque du client..."
      },
      "teamMember": {
        "label": "Promoteur",
        "placeholder": "Promoteur..."
      },
      "endDate": {
        "label": "Date de fin"
      },
      "paymentMethod": {
        "label": "Mode de paiement",
        "placeholder": "Mode de paiement...",
        "value": {
          "invoice": "Facture",
          "direct debit": "Débit direct",
          "acceptgiro": "Virement bancaire",
          "cash": "cash",
          "directDebit": "Domiciliation"
        }
      },
      "datetimeOfRegistration": {
        "label": "Date d’écriture"
      },
      "datetimeOfCancelation": {
        "label": "Date d’annulation",
        "placeholder": "Date d’annulation..."
      },
      "reasonForCancelation": {
        "label": "Raison d’annulation",
        "placeholder": "Raison d’annulation..."
      },
      "startDate": {
        "label": "Date d’effet",
        "error": {
          "exceeding": "Trop loin dans l’avenir"
        }
      },
      "employee": {
        "name": "Nom d’employé"
      },
      "cancelPaymentRecruiter": {
        "label": "Annuler le paiement au recruteur"
      },
      "campaign": {
        "label": "Campagne"
      },
      "is_imported": {
        "label": "a été importé",
        "placeholder": "a été importé..."
      },
      "datetimeOfTermination": {
        "label": "Date de terminaison de la souscription"
      },
      "ignoreErrors":{
        "label": "Ignorer les erreurs d'abonnement pour le changement de statut"
      },
      "overrideCallAmountCheck": {
        "label": "Dépassement du nombre maximum de tentatives d'appel"
      },
      "paymentInterval": {
        "label": "Fréquence",
        "changedOneTimer": "Convertie en ponctuelle",
        "value": {
          "null": "Don unique",
          "P1D": "Du quotidien",
          "P1W": "Hebdomadaire",
          "P1M": "Mensuel",
          "P3M": "Chaque trimestre",
          "P6M": "Chaque semestre",
          "P1Y": "Annuel"
        },
      },
      subscriptionCode: {
        label: "Description d'annulation",
        info: "Description d'annulation facultatif...",
      },
      disableBlockers: {
        label: "Désactiver les bloqueurs ",
      }
    },
    "button": {
      "add": "Ajouter"
    },
    "createButton": "Ajouter abonné",
    "cancelTooltip": "Se désabonner",
    "creditationProcessed": "Indiquer le credit tel qu’il a été reçu",
    "creditationNotProcessed": "Annuler le traitement du crédit",
    "editTooltip": "Modifier l’abonnement",
    "uncancelTooltip": "Annuler l’annulation de l’abonnement",
    "deleteTooltip": "Supprimer l’enregistrement",
    "lookupTooltip": "Chercher l’abonnement",
    "save": {
      "error": "N’a pas pu sauvegarder l’abonné !",
      "success": "Abonné est sauvegardé"
    },
    "delete": {
      "error": "N’a pas pu supprimer l’abonné !",
      "success": "L’abonné est supprimé"
    },
    "modal": {
      "view": {
        "title": "L’inscription"
      },
      "delete": {
        "content": "Vous êtes sur le point de supprimer une entrée. Voulez-vous continuer ?",
        "cancelButton": "Annuler",
        "deleteButton": "Supprimer"
      },
      "cancel": {
        "title": "Se désabonner",
        "cancelButton": "Annuler",
        "saveButton": "Sauvegarder",
        "notification": "L'abonnement a été annulé"
      }
    },
    "changeStatus": {
      "error": "N’a pas pu modifier le statut !",
      "success": "Le statut a été modifié.",
      "modalTitle": "Modifier le statut de l'abonnement ID",
      "modalTitleMultiple": "Modifier le statut de {{amount}} abonnements",
      "changeButton": "Modifier le statut",
      "label": "Choisir le statut de l'abonnement"
    },
    "reexport": {
      "error": "Ne pouvait pas réexporter !",
      "success": "L’abonnement a été réexporté"
    },
    "uncancel": {
      "error": "Ne pouvait pas annuler l’annulation !",
      "success": "L’annulation a été annulé."
    },
    "zsp": {
      "missingScan": "Aucun scan n’a encore été téléchargé. Veuillez d’abord téléverser un scan."
    },
    "edit": {
      "productInformation": "Informations sur le produit",
      "metafields": "Métafields",
      "cancelations": "Annulations",
      "enrollment": "Inscription",
      "others": "Divers",
      "recruitInformation": "Informations de recrutement",
      "remarks": "Remarques",
      "personalInformation": "Renseignements personnels",
      "addressInformation": "Informations d'adresse",
      "financialInformation": "L'information financière",
      "contactInformation": "Coordonnées de contact",
      "subscriptionId": "ID d'enregistrement"
    },
    "termination": {
      "modalTitle": "Terminer l'abonnement après succéder",
      "saveTermination": "Sauvegarder la terminaison",
      "title": "Terminé après le décaissement"
    },
    "error": {
      "cancelationWithoutReason": "Indiquez le motif de l'annulation"
    },
    "terminationTooltip": "Terminer l'abonnement après succéder",
    "unterminateTooltop": "Ne pas annuler l’abonnement de toute façon",
    "resendEmail": {
      "button": "Renvoyer l'e-mail",
      "confirmationQuestion": "Êtes-vous sûr de vouloir envoyer à nouveau l'e-mail de confirmation ?",
      "emailToResend": "Adresse e-mail pour l'e-mail de confirmation",
      "resendConfirmation": "Adresse e-mail pour l'e-mail de confirmation",
      "sentConfirmation": "Courriel envoyé avec succès",
      "invalidEmail": "Adresse e-mail non valide",
      "invalidInputEmail": "Veuillez saisir une adresse électronique valide",
      "sendAttachment": "Envoyer des pièces jointes"
    }
  },
  "subscriber": {
    "noPicture": "Aucune image disponible",
    "loading": "Chargement",
    "field": {
      "updatedAt": {
        "label": "Mise à jour sur"
      },
      "gender": {
        "salutation": {
          "male": "M.",
          "female": "Mme.",
          "unknown": ""
        },
        "label": "Genre",
        "value": {
          "male": "Homme",
          "female": "Femme",
          "unknown": "Inconnu"
        }
      },
      "dateOfBirth": {
        "label": "Date de naissance"
      },
      spokenLanguage:{
        label: 'Langue'
      },
      "city": {
        "label": "Lieu"
      },
      "street": {
        "label": "Rue"
      },
      "houseNumber": {
        "label": "Numéro de la maison"
      },
      "houseNumberSuffix": {
        "label": "Suffixe"
      },
      "zipCode": {
        "label": "Code postal"
      },
      "iban": {
        "label": "Iban"
      },
      "mobile": {
        "label": "Numéro de téléphone portable"
      },
      "landline": {
        "label": "Numéro de téléphone fixe"
      },
      "email": {
        "label": "Courrier électronique"
      },
      "lastName": {
        "label": "Nom de famille"
      },
      "firstName": {
        "label": "Prénom"
      },
      "middleName": {
        "label": "Insertions"
      },
      "initials": {
        "label": "Initiales"
      },
      "date_of_birth": {
        "error": {
          "too_young": "En-dessous de l’âge minimum."
        }
      },
      "country": {
        "label": "Pays",
        "value": {
          "NL": "Les Pays-Bas",
          "DE": "L'Allemagne",
          "FR": "La France",
          "BE": "La Belgique",
          "GB": "L'Angleterre"
        }
      }
    }
  },
  "shopItem": {
    "nav": {
      "overview": "Boutique"
    },
    "field": {
      "id": {
        "label": "ID",
        "placeholder": "ID..."
      },
      "ordering": {
        "label": "Séquence",
        "placeholder": "Séquence..."
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom..."
      },
      "description": {
        "label": "Déscription",
        "placeholder": "Déscription..."
      },
      "price": {
        "label": "Coins",
        "placeholder": "Coins..."
      },
      "image": {
        "label": "Image",
        "placeholder": "Image..."
      }
    },
    "create": {
      "title": "Ajouter l’article"
    },
    "edit": {
      "title": "Modifier l’article"
    },
    "createButton": "Ajouter l’article",
    "editTooltip": "Modifier l’article",
    "filter": {
      "deleted": "Articles supprimées",
      "onlyDeleted": "Supprimé uniquement",
      "withoutDeleted": "Sans supprimé",
      "bothDeletedAndNot": "Tous les deux"
    }
  },
  "promotorIdea": {
    "nav": {
      "overview": "Promoteur app Idées"
    },
    "field": {
      "id": {
        "label": "ID",
        "placeholder": "ID..."
      },
      "employee": {
        "label": "Employé",
        "placeholder": "Employé..."
      },
      "branch": {
        "label": "Lieu",
        "placeholder": "Lieu..."
      },
      "idea": {
        "label": "Idée",
        "placeholder": "Idée..."
      },
      "subject": {
        "label": "Sujet",
        "placeholder": "Sujet..."
      }
    },
    "create": {
      "title": "Ajouter idée"
    },
    "edit": {
      "title": "Modifier idée"
    },
    "createButton": "Ajouter idée",
    "editTooltip": "Modifier l’article"
  },
  "contactTab": {
    "noResults": "Rien à appeler"
  },
  "sequence": {
    "editTooltip": "Modifier l’article",
    "nav": {
      "overview": "Séquences"
    },
    "page": {
      "overview": {
        "title": "Séquences"
      }
    },
    "createButton": "Ajouter séquence",
    "clearDefaultButton": "Supprimer le défaut",
    "save": {
      "error": "N’as pas pu sauvegarder la séquence !",
      "success": "La sequence a été sauvegardée"
    },
    "modal": {
      "delete": {
        "content": "Vous êtes sur le point de supprimer une séquence. Voulez-vous continuer?"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom"
      },
      "value": {
        "label": "Valeur"
      },
      "description": {
        "label": "Déscription"
      },
      "client": {
        "label": "Client"
      },
      "increment_per_export": {
        "placeholder": "Oui/Non..."
      }
    }
  },
  "clientRefNr": {
    "page": {
      "overview": {
        "title": "Numéros de référence"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom"
      },
      "referenceNumber": {
        "label": "Numéro de référence"
      },
      "subscriptionId": {
        "label": "Enregistrement ID"
      },
      "client": {
        "label": "Client"
      },
      "fromCsv": {
        "label": "CSV ID"
      }
    }
  },
  "softDelete": {
    "delete": {
      "error": "N’a pas pu supprimer l’article !",
      "success": "L’article a été supprimé",
      "tooltip": "Supprimer l’article"
    },
    "restore": {
      "error": "N’a pas pu restaurer l’article !",
      "success": "L’article a été restauré",
      "tooltip": "Restaurer l’article"
    }
  },
  "segment": {
    "field": {
      "id": "ID",
      "name": "Nom",
      "is_final": "Est publié"
    },
    "attribute": {
      "field": {
        "id": "ID",
        "segment": "Segment",
        "name": "Nom",
        "attribute_name": "Nom d’attribut",
        "aggregation_type": "Type de rapport"
      }
    },
    "createButton": "Ajouter segment"
  },
  "valueNode": {
    "valueType": {
      "label": "Type"
    },
    "nodeType": {
      "label": "Type"
    },
    "choice": {
      "if": "Si",
      "else": "Sinon"
    },
    "type": {
      "fixed_value": "Valeur fixe",
      "field_value": "Valeur du champ",
      "date_format": "Date format",
      "current_date": "Date d’aujourd’hui",
      "choice": "Choix",
      "metafield_value": "Metafield",
      "merge": "Merge",
      "dyadic_arithmetic_operator": "Opération arithmétique",
      "convert_case": "Conversion en lettre majuscule",
      "replace": "Remplacer le texte",
      "fixed_width": "Largeur de colonne fixe",
      "date_component": "Partie de la date",
      "date_truncate": "Date abrégée",
      "count": "Montant",
      "sequence": "Séquence",
      "substring": "Substring",
      "assigned_field_value": "Valeur attribuée au champ"
    },
    "dateComponentType": {
      "century": "Siècle",
      "day": "Jour",
      "decade": "décennie",
      "dow": "Jour de la semaine",
      "doy": "Jour de l’année",
      "epoch": "secondes depuis 1-1-1970 00:00:00 UTC",
      "hour": "Heure",
      "isodow": "Jour de la semaine (ISO)",
      "isoyear": "Jour de l’année (ISO)",
      "microseconds": "Microseconde",
      "millenium": "Millénaire",
      "milliseconds": "Milliseconde",
      "minute": "Minute",
      "month": "Mois",
      "quarter": "Trimestre",
      "second": "Seconde",
      "timezone": "Différence de fuseau horaire UTC",
      "timezone_hour": "Différence d’heures par rapport à UTC",
      "timezone_minute": "Différence de minutes par rapport à UTC",
      "week": "Semaine",
      "year": "Année",
      "millennium": "Millénaire"
    },
    "mathOperator": {
      "add": "+",
      "sub": "-",
      "mul": "*"
    },
    "choiceOperator": {
      "exact": "Equivaut à",
      "exact_not": "N’équivaut pas à",
      "lt": "Est plus petit que",
      "lte": "Est plus petit que / équivaut à",
      "gt": "Est plus grand que",
      "gte": "Est plus grand que / équivaut à"
    },
    "groupCombinator": {
      "and": "Et",
      "or": "Ou"
    },
    "convertCase": {
      "uppercase": "Lettres majuscules",
      "lowercase": "Lettres minuscules",
      "titlecase": "Lettre majuscule par mot"
    },
    "location": {
      "left": "Gauche",
      "right": "Droit",
      "both": "Les deux",
      "everywhere": "Partout"
    },
    "repeat": {
      "label": "Répéter",
      "no": "Non",
      "yes": "Oui"
    }
  },
  "pageNotFoundMessage": "Page non trouvée :(",
  "paginator": {
    "previousText": "<",
    "nextText": ">"
  },
  "user": {
    "editTooltip": "Modifier l’utilisateur",
    "masqueradeTooltip": "Masquer l’utilisateur",
    "forgotPassword": "Mot de passe oublié ?",
    "sendResetLink": "Envoyer le lien de réinitialisation",
    "backToLogin": "Retour au login",
    "passwordPlaceholder": "Nouveau mot de passe",
    "passwordConfirmationPlaceholder": "Répéter le mot de passe...",
    "sendResetCodeEmailSuccess": "Si votre nom d’utilisateur est connu, un de code de réinitialisation a été envoyé à votre adresse électronique.",
    "sendResetCodeEmailError": "N’as pas pu réinitaliser le mot de passe. Vérifiez le nom d’utilisateur.",
    "editButton": "Modifier",
    "saveButton": "Sauvegarder",
    "navProfile": "Profile",
    "navChangePassword": "Modifier le mot de passe",
    "navLogout": "Déconnecter",
    "field": {
      "id": {
        "label": "ID"
      },
      "last_login": {
        "label": "Dernière connexion"
      },
      "username": {
        "label": "Nom d’utilisateur",
        "placeholder": "Nom d’utilisateur..."
      },
      "email": {
        "label": "Courrier électronique"
      },
      "password": {
        "label": "Mot de passe",
        "placeholder": "Mot de passe..."
      },
      "groups": {
        "label": "Groupes",
        "multiselect": {
          "selectedText": "# de # groupes sélectionné",
          "noneSelectedText": "Choisir des groupes",
          "placeholder": "Chercher..."
        }
      },
      "lastLogin": {
        "label": "Dernière connexion"
      },
      "isActive": {
        "label": "Utilisateur est actif"
      }
    },
    "filter": {
      "search": {
        "label": "Chercher",
        "placeholder": "Nom d’utilisateur, prénom, nom de famille..."
      },
      "branches": {
        "label": "Siège"
      },
      "group": {
        "label": "Groupe"
      },
      "status": {
        "label": "Etat"
      },
      "deleted": "Supprimé"
    },
    "account": {
      "title": "Profile",
      "logoutButton": "Déconnexer",
      "unMasquerade": "Démasquer",
      "language": "Langue"
    },
    "changePassword": {
      "title": "Modifier le mot de passe",
      "field": {
        "passwordOld": {
          "label": "Vieux mot de passe"
        },
        "passwordNew": {
          "label": "Nouveau mot de passe"
        }
      },
      "saveButton": "Modifier mot de passe",
      "success": "Le mot de passe est modifié",
      "error": "N’a pas pu modifier le mot de passe!"
    },
    "passwordForgot": {
      "title": "Mot de passe oublié",
      "requestButton": "Envoyer lien de réinitialisation",
      "requestedEmailText": "Si l’adresse électronique est correcte, un courriel contenant le lien pour réinitialiser le mot de passe a été envoyé"
    },
    "nav": {
      "overview": "Utilisateurs"
    },
    "login": {
      "title": "Se connecter",
      "forgotPasswordLink": "Mot de passe oublié ?",
      "errors": {
        "invalidCredentials": "Nom d’utilisateur/mot de passe n’est pas correcte",
        "unknown": "Erreur inconnue, code de statut : {{status}}"
      },
      "loginButton": "Se connecter"
    },
    "save": {
      "error": "N’a pas pu sauvegarder l’utilisateur!",
      "success": "Utilisateur a été sauvegardé"
    },
    "view": {
      "create": {
        "title": "Créer utilisateur"
      },
      "edit": {
        "title": "Modifier utilisateur"
      },
      "changePassword": {
        "title": "Modifier mot de passe",
        "newPassword": "Nouveau mot de passe",
        "confirmedPassword": "Mot de passe confirmé"
      },
      "profile": {
        "title": "Votre profil"
      },
      "forgotPassword": {
        "title": "Mot de passe oublié ?"
      }
    },
    "groups": "Groupes",
    "masqueradeButton": "Commencer masquerade",
    "unmasqueradeButton": "Arrêter masquerade",
    "resetPassword": {
      "resetButton": "Réinitialiser",
      "saveSuccess": "Le mot de passe a été réinitialisé avec succès",
      "title": "Réinitialisation du mot de passe",
      "body": "Réinitialisez votre mot de passe ici.",
      "password": "Nouveau mot de passe",
      "repeatPassword": "Répétez le nouveau mot de passe",
      "doesNotMatch": "Les mots de passe ne correspondent pas !",
      "requirements": {
        "length": "Le mot de passe doit comporter au moins 8 caractères.",
        "generic": "Le mot de passe ne doit pas être trop générique.",
        "similar": "Le mot de passe ne doit pas être trop similaire à vos données personnelles.",
        "numeric": "Le mot de passe ne peut pas être entièrement numérique.",
      },
    }
  },
  "contentbundle": {
    "copyTooltip": "Copier module d’enregistrement",
    "editTooltip": "Modifier module d’enregistrement",
    "deleteTooltip": "Supprimer module d’enregistrement",
    "createButton": "Nouveau module d’enregistrement",
    "noPadding": "Aucun rembourrage",
    "basicInfo": "Information de base",
    "layout": "Mise en page",
    "currentFormElementDetails": "Détails des éléments",
    "addElements": "Ajouter des éléments",
    "removeElement": "Supprimer",
    "addElementType": {
      "attest": "Joindre attestation",
      "filler": "Ajouter du rembourrage",
      "text": "Ajouter du texte",
      "metafield": "Ajouter Metafield",
      "field": "Ajouter champ",
      "image": "Ajouter image"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom"
      },
      "client": {
        "label": "Client"
      },
      "products": {
        "label": "Client"
      },
      "show_recruiter_signature": {
        "label": "Signature du promoteur ?"
      },
      "campaign": {
        "label": "Campagne",
        "placeholder": "Selectionner une campagne…"
      },
      "translations": {
        "label": "Traductions"
      },
      "styles": {
        "label": "Styles"
      },
      "logo": {
        "label": "Logo",
        "save": {
          "error": "Logo doit être un fichier png"
        }
      }
    },
    "copy": {
      "success": "La campagne a été copiée.",
      "error": "La campagne n’a pas pu être copiée."
    },
    "edit": {
      "addForm": "Ajouter formulaire",
      "slides": "Modifier diapositives",
      "addFolder": "Ajouter un dossier",
      "addSlide": "Ajouter une diapositive"
    },
    "slide": {
      "SlidesEdit": "Dossiers"
    }
  },
  "formElement": {
    "field": {
      "label": {
        "label": "Label"
      },
      "textType": {
        "label": "Type"
      },
      "text": {
        "label": "Texte"
      },
      "metafield": {
        "label": "Metafield"
      },
      "thirdGenderEnabled": {
        "label": "Troisième genre activé"
      },
      "readOnly": {
        "label": "En lecture seule"
      },
      "autofill": {
        "label": "Autofill"
      },
      "field": {
        "label": "Field",
        "value": {
          "referenceNr": "Numéro de référence",
          "initials": "Initiales",
          "firstName": "Prénom",
          "middleName": "Deuxième prénom",
          "lastName": "Nom de famille",
          "gender": "Genre",
          "dateOfBirth": "Date de naissance",
          "email": "Adresse électronique",
          "street": "Rue",
          "houseNumber": "Numéro de la maison",
          "zipCode": "Code postal",
          "city": "Ville",
          "datetimeOfRegistration": "Date d’enregistrement",
          "startDate": "Date de début",
          "iban": "Numéro de compte",
          "paymentInterval": "Fréquence",
          "houseNumberSuffix": "Suffixe du numéro de la maison",
          "phonenumbers": "Téléphone",
          "landline": "Téléphone fixe",
          "mobile": "Téléphone mobile",
          "commencingDate": "Date de début",
          "clientRefNr": "Numéro de reference du client"
        }
      },
      "styles": {
        "label": "Styles"
      }
    }
  },
  "contentbundleAgeWildcard": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom"
      },
      "minAge": {
        "label": "Âge minimum"
      },
      "maxAge": {
        "label": "Âge maximum"
      },
      "renewEvery": {
        "label": "Renouvellement tous les {N} sous",
      },
      "default": {
        "label": "Ajouter par défaut ?",
      },
      "employees": {
        "label": "Pour les employés"
      },
      "branches": {
        "label": "Pour les succursales"
      },
    },
    "edit": {
      "title": "Modifier le joker"
    },
    "create": {
      "title": "Créer un joker"
    }
  },
  "contentbundleSlide": {
    "copyTooltip": "Copier module d’enregistrement",
    "deleteSlideTooltip": "Supprimer diapositive",
    "editSlideTooltip": "Modifier diapositive",
    "editTooltip": "Modifier chapitre",
    "addHitboxButton": "Ajouter hitbox",
    "save": {
      "error": "N’a pas pu sauvegarder la diapositive !",
      "overlapError": "Un ou plusieurs hitbox se chevauchent. Cela n’est pas autorisé.",
      "duplicateNameError": "Il existe déjà une diapositive portant ce nom!",
      "success": "La diapositive est sauvegardée"
    },
    "delete": {
      "content": "Vous êtes sur le point de supprimer une diapositive. Voulez-vous continuer ?",
      "error": "N’a pas pu supprimer la diapositive !",
      "success": "La diapositive est supprimée"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom"
      },
      "final": {
        "label": "De cette diapositive au formulaire d'inscription"
      },
      "file": {
        "label": "Diapositive"
      }
    }
  },
  "displayCondition": {
    "field": {
      "metafield": {
        "label": "Metafield"
      },
      "value": {
        "label": "Valeur"
      },
      "type": {
        "label": "Type",
        "value": {
          "standard": "Champ standard",
          "metafield": "Metafield"
        }
      },
      "standardField": {
        "label": "Champ",
        "value": {
          "age": "Âge"
        }
      },
      "comparisonMethod": {
        "label": "Comparateur",
        "value": {
          "=": "Égal à",
          "<": "Moins de",
          ">": "Plus de"
        }
      }
    }
  },
  "importedCreditationCsv": {
    "field": {
      "csv": {
        "label": "CSV"
      },
      "importer": {
        "label": "Importeur"
      },
      "createdAt": {
        "label": "Date"
      }
    },
    "actions": {
      "label": "Actions"
    }
  },
  "contentbundleSlideSettingsCondition": {
    "addConditionButton": "Ajouter condition",
    "addConditionButtonTitle": "Si l’une des conditions est remplie, cette diapositive s’affiche.",
    "field": {
      "productId": {
        "label": "Produit"
      },
      "metafieldId": {
        "label": "Metafield"
      },
      "metafieldValue": {
        "label": "Valeur"
      }
    }
  },
  "fileBundle": {
    "form": {
      "client": {
        "label": "Client"
      },
      "description": {
        "label": "Description"
      },
      "name": {
        "label": "Nom"
      }
    },
    "create": {
      "title": "Ajouter exportation"
    },
    "edit": {
      "title": "Définition du fichier"
    },
    "modal": {
      "delete": {
        "content": "Vous êtes sur le point de supprimer une définition de fichier. Cela permettra également de supprimer de l’archive les fichiers d’exportation de cette définition générés précédemment. Voulez-vous continuer ?",
        "cancelButton": "Annuler",
        "deleteButton": "Supprimer"
      }
    },
    "exportModal": {
      "showModalButton": "Exporter les résultats",
      "title": "Options d’exportation",
      "performExport": "Exporter",
      "isDefinitive": "L’exportation définitive",
      "dateRange": "Date",
      "confirmDefinitive": "Êtes-vous sur de vouloir exporter cette exportation finale? Tous les enregistrements sélectionnés seront gérés et effectués."
    },
    "exportErrorModal": {
      "title": "Erreur lors de l’exportation",
      "fileDefitionHeader": "Erreurs dans la définition du fichier",
      "unknownErrorMessage": "Une erreur inconnue s'est produite lors de l'exportation"
    },
    "metadata": {
      "title": "Metadata",
      "fields": {
        "description": {
          "label": "Description"
        },
        "format": {
          "label": "Type de fichier"
        },
        "encoding": {
          "label": "Encodage des fichiers"
        }
      }
    },
    "field": {
      "id": {
        "title": "ID"
      },
      "client": {
        "title": "Client",
        "placeholder": "Client..."
      },
      "description": {
        "title": "Description",
        "placeholder": "Description..."
      }
    },
    "save": {
      "error": "Impossible de sauvegarder le bundle!",
      "success": "Le bundle a été sauvegardée"
    },
    "addInputNodeButton": "Ajouter un noued"
  },
  "fileNode": {
    "deleteConfirmation": "Êtes-vous sûr de vouloir supprimer l'élément \"{{elementName}}\"?"
  },
  "generatedBundle": {
    "field": {
      "id": {
        "label": "ID"
      },
      "subscriptionId": {
        "label": "Enregistrement ID"
      },
      "client": {
        "label": "Client"
      },
      "createdAt": {
        "label": "Créé le"
      }
    }
  },
  "group": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom..."
      },
      "permissions": {
        "label": "Permission",
        "placeholder": "Permission..."
      },
      "default": {
        "label": "Employé par défaut",
        "placeholder": "Par défaut..."
      },
      "defaultApplicant": {
        "label": "Candidat par défaut",
        "placeholder": "Demandeur par défaut..."
      },
      "isDefault": {
        "label": "Employé par défaut"
      },
      "isDefaultApplicant": {
        "label": "Candidat par défaut"
      },
      "isDefaultClient": {
        "label": "Utilisateur client par défaut"
      },
      "defaultClient": {
        "label": "Utilisateur client par défaut"
      }
    },
    "save": {
      "error": "Impossible de sauvegarder le groupe!",
      "success": "Le groupe a été sauvegardé"
    },
    "editTooltip": "Modifier groupe",
    "createButton": "Nouveau groupe",
    "nav": {
      "overview": "Groupes"
    },
    "filter": {
      "permission": {
        "selectedText": "# des # droits sélectionnés",
        "noneSelectedText": "Choisir des droits",
        "placeholder": "Recherche..."
      }
    }
  },
  "issue": {
    "field": {
      "reason": {
        "value": {
          "start_date exceeds max delta": "Date de début trop tard",
          "iban missing": " IBAN manquant ",
          "quantity missing": "Nombre manquant",
          "amount missing": " Montant manquant ",
          "email missing": "Adresse électronique manquant",
          "landline missing": "Numéro de téléphone fixe manquant",
          "below minimum age": "Trop jeune",
          "last_name missing": "Nom de famille manquant",
          "gender unknown": "Sexe manquant",
          "date_of_birth missing": "Date de naissance manquante",
          "mobile missing": "Numéro de mobile manquant",
          "phone number missing": "Numéro de téléphone manquant",
          "address invalid": "Adresse non valable",
          "address incomplete": "Adresse incomplète",
          "address unsure": "Adresse vague",
          "duplicate iban": "IBAN double pour client",
          "known iban": "IBAN connu",
          "recruiter iban": "IBAN d’un employé",
          "duplicate email": "Adresse électronique du client déjà connue dans le système.",
          "recruiter email": "Courriel d'un employé",
          "landline_invalid": "Numéro de téléphone fixe non valide",
          "mobile_invalid": "Numéro de téléphone fixe non valide",
          "recruiter phone": "Numéro de téléphone d'un recruteur",
          "duplicate phone": "Numéro de téléphone d'un autre abonnement"
        }
      }
    }
  },
  "jobApplication": {
    "add": "Ajouter candidat",
    "sendActivationEmailButton": "(R)envoyer courriel d’activation",
    "sendNoShowEmailButton": "(R)envoyer courriel de no show",
    "sendColdLeadEmailButton": "(R)envoyer courriel de cold lead",
    "sendActivationEmailSuccessMessage": "Envoyé avec succès",
    "filter": {
      "minCallAmount": "Minimal nombre d’appels",
      "maxCallAmount": "Maximal nombre d’appels",
      corrected: "(corrigés)"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "viaVia": {
        "label": "ViaVia",
        "placeholder": "ViaVia..."
      },
      "employee": {
        "label": "Promoteur",
        "placeholder": "Promoteur..."
      },
      "last_sign_in": {
        "label": "Dernier jour ouvrable"
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom..."
      },
      "avatar": {
        "label": "Photo de profil",
        "placeholder": "Photo de profil..."
      },
      "mainBranch": {
        "label": "Siège social",
        "placeholder": "Choisir siège social..."
      },
      "notInterested": {
        "label": "Pas de rendez-vous",
        "info": "Ce candidat a indiqué qu'il ne souhaite pas poursuivre la procédure de candidature."
      },
      "notInterestedReason": {
        "label": "Raison pas de rendez-vous"
      },
      "branches": {
        "label": "Succursale",
        "placeholder": "Succursale...",
        "selectedText": "# des # Succursales",
        "noneSelectedText": "Choisir Succursale"
      },
      "initials": {
        "label": "Initiales",
        "placeholder": "Initiales..."
      },
      "firstName": {
        "label": "Prénom",
        "placeholder": "Prénom..."
      },
      "middleName": {
        "label": "Interjection",
        "placeholder": "Interjection..."
      },
      "lastName": {
        "label": "Nom de famille",
        "placeholder": "Nom de famille..."
      },
      "gender": {
        "label": "Sexe",
        "value": {
          "male": "Homme",
          "female": "Femme",
          "other": "Autre",
          "unkown": "inconnu"
        }
      },
      "onHold": {
        "label": "En attente",
        "value": {
          "true": "Oui",
          "false": "Non",
          "all": "Tous"
        },
        "labelDr": "Ne commencez pas toute de suite"
      },
      "onHoldReason": {
        "label": "Raison de l'attente"
      },
      "age": {
        "label": "Âge"
      },
      "dateOfBirth": {
        "label": "Date de naissance"
      },
      "phoneNumbers": {
        "label": "Numéros de téléphone",
        "placeholder": "Numéros de téléphone..."
      },
      "ssn": {
        "label": "BSN",
        "placeholder": "BSN..."
      },
      "dateStarted": {
        "label": "Date de mise en service"
      },
      "country": {
        "label": "Pays",
        "placeholder": "Pays...",
        "value": {
          "NL": "NL"
        }
      },
      "iban": {
        "label": "IBAN",
        "placeholder": "IBAN..."
      },
      "houseNumber": {
        "label": "Numéro de maison",
        "placeholder": "Numéro de maison..."
      },
      "houseNumberSuffix": {
        "label": "Suffixe de numéro de téléphone",
        "placeholder": "Suffixe de numéro de téléphone..."
      },
      "city": {
        "label": "Ville",
        "placeholder": "Ville..."
      },
      "email": {
        "label": "Adresse électronique",
        "placeholder": "Adresse électronique..."
      },
      "street": {
        "label": "Rue",
        "placeholder": "Rue..."
      },
      "zipCode": {
        "label": "Code postal",
        "placeholder": "Code postal..."
      },
      "employeeNumber": {
        "label": "Numéro d’employé/de référence",
        "placeholder": "Nr d’employé/de réf..."
      },
      "drivingLicence": {
        "label": "A un permis de conduire",
        "placeholder": "Permis de conduire...",
        "value": {
          "false": "Non",
          "true": "Oui"
        }
      },
      "bankCard": {
        "label": "Carte bancaire",
        "placeholder": "Carte bancaire..."
      },
      "taxExemption": {
        "label": "Crédit d’impôt",
        "placeholder": "Crédit d’impôt..."
      },
      "canChangeTaxExemption": {
        "label": "L'exonération fiscale peut-elle être modifiée?",
        "placeholder": "L'exonération fiscale peut-elle être modifiée?"
      },
      "contractSignature": {
        "label": "Contrat",
        "placeholder": "Contrat..."
      },
      "identification": {
        "label": "Carte d’identité face",
        "placeholder": "Carte d’identité face..."
      },
      "identification_back": {
        "label": "Carte d’identité inversée",
        "placeholder": "Carte d’identité inversée..."
      },
      "bw_id": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "city_of_birth": {
        "label": "Lieu de naissance",
        "placeholder": "Lieu de naissance"
      },
      "country_of_birth": {
        "label": "Pays de naissance",
        "placeholder": "Pays de naissance..."
      },
      "id_type": {
        "label": "Type ID",
        "placeholder": "Type ID..."
      },
      "id_number": {
        "label": "Numéro ID",
        "placeholder": "Numéro ID..."
      },
      "interviewer": {
        "label": "Recruteur?",
        "title": "L'employé peut alors être choisi dans la liste des recruteurs pour les entretiens d'embauche."
      },
      "coins": {
        "label": "Coins"
      },
      "blockedClients": {
        "label": "Clients bloqués"
      },

      "referral_source": {
        "label": "Source"
      },
      "referralSource": {
        "label": "Source"
      },
      "referralSourceOther": {
        "label": "Source autre"
      },
      "legalGuardianName": {
        "label": "Nom du tuteur légal"
      },
      "legalGuardianPhone": {
        "label": "Numéro de téléphone du tuteur légal"
      },
      "legalGuardianEmail": {
        "label": "Adresse électronique du tuteur légal"
      },
      "noShowSent": {
        "label": "Courriel de No show envoyé",
        "info": "Si un candidat ne s'est pas présenté (statut de non-présentation) et qu'il a été appelé au moins une fois, il est possible d'envoyer un courriel de non-présentation (dans la barre d'outils). Ce courriel contient un rappel au candidat qu'il peut se présenter pour un entretien."
      },
      "legalGuardianDisabled": {
        "label": "Tuteur légal ne pas nécessaire"
      },
      "coldLeadSent": {
        "label": "Courriel de cold lead envoyé",
        "info": "Si un candidat ne s'est pas présenté (statut de prospect froid) et qu'il a été appelé au moins cinq fois, un courriel de prospect froid peut être envoyé (dans la barre d'outils). Ce courriel contient un dernier rappel au candidat de se présenter pour un entretien."
      },
      "createdAt": {
        "label": "Created at"
      },
      "lastInterview": {
        "label": "Last interview"
      },
      "callBackAppointment": {
        "label": "Heure du rendez-vous de rappel"
      },
      "remarks": {
        "label": "Remarques"
      },
      "public_transport_student_license": {
        "label": "Transports publics pour les étudiants",
        "placeholder": "Transports publics pour les étudiants...",
        "value": {
          "false": "Non",
          "true": "Oui"
        }
      },
      "publicTransportStudentLicenseType": {
        "label": "Genre de transport public pour les étudiants",
        "value": {
          "no": "Aucun",
          "week": "Semaine",
          "weekend": "Week-end"
        }
      },
      "travelingDistance": {
        "label": "Distance de déplacement"
      },
      "countryOfBirth": {
        "label": "Pays de naissance"
      },
      "cityOfBirth": {
        "label": "Ville de naissance"
      },
      "nationalityInternal": {
        "label": "Nationalité"
      },
      "bic": {
        "label": "BIC",
        "placeholder": "BIC..."
      },
      "employeeNumbefr": {
        "label": "Numéro de référence du personnel",
        "placeholder": "Personnel non..."
      },
      "dropoutReason": {
        "label": "Raison d'abandon",
      }
    },
    "nav": {
      "overview": "Requêtes",
      "interviews": "Candidatures"
    },
    "calls": {
      "modal": {
        "incoming": "Appels entrants",
        "outcoming": "Appels sortants",
        "buttonTitle": "Appels téléphoniques",
        "id": "ID",
        "caller": "Appelant",
        "calledUser": "A",
        "start": "Début",
        "duration": "Durée",
        "voicelogs": "Voicelogs",
        "listenCall": "Conversation après écoute",
        "audioError": "Votre navigateur ne prend pas en charge la balise audio."
      },
      "modalTitle": "Appels téléphoniques du demandeur"
    }
  },
  "referralSource": {
    create:{
      title: 'Ajouter source'
    },
    "createButton": "Ajouter source",
    "nav": {
      "overview": "Source"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "isDefault": {
        "label": "Défaut?"
      },
      "isOther": {
        "label": "Autre?"
      },
      "name": {
        "label": "Nom"
      }
    },
    filter: {
      "deleted": "Sources supprimées",
      "onlyDeleted": "Supprimé uniquement",
      "withoutDeleted": "Sans supprimé",
      "bothDeletedAndNot": "Tous les deux"
    },
    edit: {
      title: "modification de la source de référence"
    }
  },
  "job": {
    "editTooltip": "Modifier fonction",
    "nav": {
      "overview": "Fonctions"
    },
    "createButton": "Ajouter fonction",
    "save": {
      "error": "Impossible de sauvegarder la fonction!",
      "success": "La fonction a été sauvegardée"
    },
    "field": {
      "id": {
        "label": "ID",
        "placeholder": "ID..."
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom..."
      },
      "isTeamPosition": {
        "label": "Position de l’équipe?",
        "placeholder": "Position de l’équipe..."
      },
      "isFreelance": {
        "label": "Freelance?",
        "placeholder": "Freelance..."
      },
      "isDefault": {
        "label": "Position par défaut ?",
        "placeholder": "Position par défaut..."
      },
      "baseFactor": {
        "label": "Facteur de base"
      }
    },
    "application": {
      "createButton": "Ajouter candidat",
      "action": {
        "waitForContractUpload": "Attente du téléchargement du contrat",
        "readyToConvertToEmployee": "Prêt pour convertir en employé actif",
        "waitForInterviewApproval": "Attente de la confirmation du rendez-vous",
        "checkInterview": "Vérifier rendez-vous",
        "makeAppointment": "Fixer rendez-vous",
        "appointmentMade": "En attente d’un entretien"
      },
      "field": {
        "amountofcalls": {
          "label": "Nombre d’appels",
          lastCall: "Dernier appel",
          hasCalls: "A des appels?",
          corrected: "Nombre d'appels corrigés"
        },
        callAppointment: {
          label: "État de l'appel"
        },
        "dateofinterview": {
          "label": "Date d’entretien"
        },
        "whatsappStatus": {
          "label": "Statut WhatsApp",
          "value": {
            "to_call": "Demande d’appel",
            "reminder_appointment": "Rappel de rendez-vous",
            "congratulations_hired": "Félicitations pour l’embauche"
          },
          "save": "Statut WhatsApp sauvegardé",
          "saveError": "Statut WhatsApp ne pas sauvegardé"
        },
        "id": {
          "label": "ID"
        },
        "name": {
          "label": "Nom"
        },
        "remarks": {
          "label": "Remarques"
        },
        "age": {
          "label": "Âge",
          "placeholder": "Âge..."
        },
        "status": {
          "label": "Statut",
          "multiselect": {
            "selectedText": "# des # statuts",
            "noneSelectedText": "Choisir statut"
          },
          "multiselectFilter": {
            "placeholder": "Recherche..."
          },
          "value": {
            "undecided": "à décider",
            "hired": "Embauché",
            "rejected": "Rejeté",
            "on_hold": "En attente",
            "no-show": "No Show",
            "to-plan": "Fixer rendez-vous",
            "planned": "Entretien prévu",
            "awaiting-interview": "En attente d’un entretien",
            "completed-interview": "Entretien terminée",
            "to-plan-no-show": "Fixer un rendez-vous après le No Show",
            "to-plan-cold-lead": "Fixer rendez-vous après le Cold Lead",
            "not-interested": "Pas de rendez-vous"
          }
        },
        "createdAt": {
          "label": "Temps depuis la demande"
        },
        "previous_call_attempts": {
          "label": "Tentatives d’appel précédentes"
        },
        "referralSource": {
          "label": "Source"
        },
        "branch": {
          "label": "Succursale"
        },
        "email": {
          "label": "Adresse électronique"
        },
        "phoneNumber": {
          "label": "Numéro de téléphone"
        },
        "referral_source": {
          "label": "Source"
        },
        "viaVia": {
          "label": "ViaVia Source"
        }
      },
      "filter": {
        "search": {
          "label": "Recherche",
          "placeholder": "Recherche par le prénom, le nom de famille, l'adresse électronique..."
        },
        "active": {
          "label": "Actif?",
          "value": {
            "all": "Tous",
            "active-only": "Seulement les promoteurs actifs",
            "non-active-only": "Pas encore de promoteurs actifs"
          }
        }
      },
      "page": {
        "overview": {
          "title": "Candidatures"
        },
        "edit": {
          "title": "Candidature"
        }
      },
      "plan": "Ajouter entretien",
      "modal": {
        "delete": {
          "content": "Vous êtes sur le point de supprimer un candidat. Voulez-vous continuer?",
          "cancelButton": "Non",
          "deleteButton": "Supprimer"
        },
        "deleteFile": {
          "content": "Êtes-vous sûr de vouloir supprimer ce fichier?",
          "cancelButton": "Non",
          "deleteButton": "Supprimer"
        }
      },
      "save": {
        "success": "La demande a été sauvegardée",
        "error": "Erreur en sauvegardant la demande"
      },
      "delete": {
        "error": "Impossible de supprimer l’article!",
        "success": "L’article a été supprimé"
      }
    },
    "interview": {
      "createButton": "Ajouter entretien",
      "hireButton": "Embaucher",
      "rejectButton": "Rejeter",
      "noShowButton": "No Show",
      "button": {
        "hired": "Embaucher",
        "rejected": "Rejeter",
        "no-show": "No show",
        "undecided": "à déterminer"
      },
      "field": {
        "id": {
          "label": "ID"
        },
        "name": {
          "label": "Nom"
        },
        "interviewer": {
          "label": "Recruteur",
          "placeholder": "Choisir recruteur..."
        },
        "branch": {
          "label": "Succursale"
        },
        "date": {
          "label": "Date et Heure"
        },
        "time": {
          "label": "Heure",
          "none": "Choisissez d’abord une succursale et une date",
          "placeholder": "Choisir temps..."
        },
        "status": {
          "label": "Statut invitation d’entretien"
        }
      },
      "status": {
        "pending": "à déterminer",
        "accepted": "Embauché",
        "rejected": "Rejeté",
        "make-new-appointment": "Fixer nouveau rendez-vous",
        "rejectedRemark": "Ne veut pas d'un nouveau rendez-vous"
      },
      "page": {
        "overview": {
          "title": "Entretiens"
        }
      },
      "save": {
        "success": "L’entretien a été sauvegardé",
        "error": "Erreur en sauvegardant l’entretien"
      },
      "empty": "Aucun entretien demandé",
      "multiselect": {
        "placeholder": "Chercher recruteur...",
        "selectedText": "# des # recruteurs sélectionnés",
        "noneSelectedText": "Sélectionner recruteur"
      },
      "deleteDialog": {
        "title": "Êtes-vous sûr de vouloir supprimer l'interview ?",
        "confirm": {
          "label": "Supprimer"
        }
      }
    },
    "callbackAppointment": {
      "options": {
        "none": "Sans",
        "pending": "En attente",
        "done": "Prêt",
        "notReached": "N'a pas pu contacter"
      },
      "modalCallButton": "Appels planifiés",
      "callBackAppointment": "Appel planifié",
      "callbackAppointments": "Appels planifiés",
      "callAppointmentStatus": "État",
      "deleteDialog": {
        "title": "Êtes-vous sur que vous voulez supprimer l'appel planifié?",
        "confirm": {
          "label": "Supprimer"
        }
      },
      "save": {
        "success": "Rendez-vous de rappel sauvegardé",
        "error": "Erreur de sauvegarde du rendez-vous de rappel"
      },
      "header": "Rendez-vous de rappel",
      "createButton": "Ajouter rendez-vous de rappel",
      "field": {
        "status": {
          "label": "État"
        },
        "date": {
          "label": "La date et l'heure"
        }
      }
    }
  },
  "client": {
    "editButton": "Modifier client",
    "deleteButton": "Supprimer client",
    "view": {
      "title": "Clients"
    },
    "edit": {
      "title": "Modifier client"
    },
    "create": {
      "title": "Créer client"
    },
    "overview": {
      "title": "Clients",
      "createButton": "Nouveau client"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom"
      },
      "campaigns": {
        "label": "Nombre de campagnes"
      },
      "users": {
        "label": "Nombre d’utilisateurs"
      },
      "max_start_date_delta": {
        "label": "Max. date de début plus tard",
        "placeholder": "e.g. pour 2 mois: P2M",
        "error": {
          "incorrect": "Format est incorrect"
        }
      },
      "doubleConfigured": {
        "label": "Configuration de tablette double",
        "helpText": "Pour ce client, une tablette peut être programmée deux fois le même jour. Dans ce cas, l'équipe ayant le numéro d'identification le plus bas sera utilisée en premier. Dès que cette équipe sera déconnectée, l'équipe suivante sera automatiquement sélectionnée."
      },
      "sendEncryptedSubscriberMails": {
        "label": "Envoyer des courriels électroniques cryptés",
        "helpText": " Les courriels électroniques de ce client seront cryptés avant que l'abonné ne reçoive ses données. Cela se fait avec les quatre derniers chiffres de l'IBAN du client."
      },
      "maximumStorageTerm": {
        "label": "Durée maximale de stockage",
        "placeholder": " e.g. pour 2 mois: P2M",
        "helpText": " Exemple: P2M = 2 mois, P84J = 84 jours, P10S = 10 semaines"
      },
      "products": {
        "label": "Produits"
      },
      "Acties": {
        "label": "Actions"
      },
      "showMaxAmountOfCalls": {
        "label": "Utiliser le nombre maximum de tentatives d'appel"
      },
      "maxAmountOfCalls": {
        "label": "Nombre total maximum de tentatives d'appel du client"
      },
      maxSubAmountWages: {
        label: "Montant maximum de paiement"
      }
    },
    "deleteTooltip": "Supprimer client",
    "restoreTooltip": "Restaurer client",
    "editTooltip": "Modifier client",
    "modal": {
      "delete": {
        "content": " Vous êtes sur le point de supprimer un client. Vous souhaitez continuer?",
        "cancelButton": "Non",
        "deleteButton": "Supprimer"
      }
    },
    "save": {
      "error": "Impossible de sauvegarder le client!",
      "success": "Le client a été sauvegardé"
    },
    "addUserButton": "Ajouter utilisateur client",
    "headerUserTable": "Utilisateurs client",
    "user": {
      "header": "Utilisateur client",
      "emptyMail": "Entrez une adresse e-mail",
      "emptyFirstName": "Entrez le prénom de l'utilisateur client",
      "emptyLastName": "Entrez le nom de l'utilisateur client",
      "incorrectMail": "La valeur saisie n'est pas une adresse électronique valide",
      "email": "Courriel"
    }
  },
  "wagesTariff": {
    "title": "Tarif des salaires PDF",
    "edit": "Modifier tarif des salaires",
    "upload": "Déposez le fichier PDF ici!"
  },
  "contract": {
    "title": "Contrat",
    "field": {
      "id": {
        "label": "ID"
      },
      "label": {
        "label": "Label"
      },
      "content": {
        "label": "Contenu"
      },
      "draft": {
        "label": "Draft?"
      }
    }
  },
  "document": {
    "title": "Documents",
    "createButton": "Ajouter document",
    "field": {
      "id": {
        "label": "ID"
      },
      "icon": {
        "label": "Icône"
      },
      "type": {
        "label": "Type",
        "value": {
          "employment contract": "Contrat de travail",
          "contract": "Contrat",
          "manual": "Manuel"
        }
      },
      "userType": {
        "label": "Montré pour",
        "value": {
          "basic": "Tous",
          "new_employees": "Seulement employés actifs",
          "new_job_applications": "Seulement les candidats à un emploi"
        }
      },
      "label": {
        "label": "Label"
      },
      "required": {
        "label": "Obligatoire",
        "autoAssigning": "Attribution automatique"
      },
      "content": {
        "label": "Contenu"
      },
      "status": {
        "label": "Statut",
        "value": {
          "draft": "Draft",
          "published": "Publié",
          "archived": "Archivé"
        }
      },
      "isPayroll": {
        "label": "Payroll document",
      },
    }
  },
  "handbook": {
    "title": "Manuel"
  },
  "privacy": {
    "title": "Déclaration de confidentialité"
  },
  "captainsheetContractText": {
    "title": "Captainsheet Modifier Contrat"
  },
  "team": {
    "totalRecords": "équipes",
    "totalTeamMembers": "Promoteurs",
    "totalSubscriptionCount": "Enregistrements",
    "totalTeamMembersWithSignIn": "Se connecter",
    "totalTeamMembersWithSignOut": "Se déconnecter",
    "totalAverage": "A.P.P.",
    "totalAverageDR": "Score moyen",
    "totalSubscriptionsFactored": "Total des enregistrements",
    "clear": "Vider champ",
    "nav": {
      "overview": "équipes"
    },
    "title": "Planification des équipes",
    "page": {
      "overview": {
        "title": "équipes",
        "day": [
          "Alors",
          "Ma",
          "Di",
          "Wo",
          "Faites",
          "Fri",
          "Za"
        ]
      }
    },
    "createButton": "Plan équipe",
    "importFromBriggsAndWalkersButton": "Importer B&W",
    "prevButton": "Précédent",
    "nextButton": "Suivant",
    "toFlushViewButton": "Flush équipes",
    "downloadButton": "équipe csv",
    "form": {
      "dateRangePlaceholder": "Choisir la plage de dates"
    },
    "editTooltip": "Modifier équipe",
    "viewTooltip": "Voir équipe",
    "viewSubscriptionsTooltip": "Voir les enregistrements",
    "noSubscriptionsTooltip": "Pas d’enregistrements",
    "batchTooltip": "Afficher les abonnés de cette équipe",
    "deleteTooltip": "Supprimer équipe",
    "copyTooltip": "Copier équipe",
    "flushTooltip": "Libérer les scans verrouillés",
    "uploadTooltip": "Télécharger les scans",
    "addLocationButton": "Ajouter un lieu",
    "save": {
      "error": "Impossible de sauvegarder l’équipe!",
      "success": "L’équipe a été sauvegardée"
    },
    "delete": {
      "confirm": "Voulez-vous vraiment supprimer?",
      "error": "Impossible de supprimer l’équipe!",
      "success": "L’équipe a été supprimée"
    },
    "filter": {
      "deleted": "Équipes supprimées",
      "onlyDeleted": "Supprimé uniquement",
      "withoutDeleted": "Sans supprimé",
      "bothDeletedAndNot": "Tous les deux",
      "today": "Aujourd'hui",
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "client": {
        "label": "Client"
      },
      "source": {
        "label": "Source de données",
        "options": {
          "incenova": "Uniquement Incenova",
          "bw": "Uniquement B&W",
          "all": "Tous"
        }
      },
      "subscriptionsCount": {
        "label": " Score de l’équipe "
      },
      "day": {
        "label": "Jour"
      },
      "week": {
        "label": "Semaine"
      },
      "attachement": {
        "label": "Résultat",
        "verify": "Vérifier"
      },
      "members": {
        "label": "Membres"
      },
      "date": {
        "label": "Date",
        "placeholder": "Date..."
      },
      "startTime": {
        "label": "Heure de début"
      },
      "endTime": {
        "label": "Heure de fin"
      },
      "status": {
        "label": "Statut",
        "placeholder": "Statut...",
        "value": {
          "planned": "Planifié",
          "uploaded": "Téléchargé",
          "entry": "Entrer",
          "completed": "Terminé"
        }
      },
      "branch": {
        "label": "Succursale",
        "placeholder": "Succursale...",
        "permission": {
          "allocation": {
            "modify_team": "A modifier dans toutes les succursales, même si l'utilisateur n'est pas membre de cette succursale.",
            "modify_team:branch": " Uniquement modifiable dans les succursales dont l'utilisateur est membre. Le menu déroulant n'affichera également que les succursales dont l'utilisateur est membre."
          }
        }
      },
      "campaign": {
        "label": "Campagne",
        "placeholder": "Campagne..."
      },
      "driver": {
        "label": "Driver",
        "placeholder": "Driver..."
      },
      "location": {
        "label": "Lieux",
        "placeholder": "Lieu...",
        "add": "Ajouter lieu"
      },
      "teamMember": {
        "add": "Ajouter membre d’équipe"
      },
      "remarks": {
        "label": "Remarque générale"
      },
      "teamCaptain": {
        "label": "Capitaine d’équipe"
      },
      "shiftType": {
        "label": "Type d'équipe"
      },
      "carDamageStart": {
        "label": "Car damage at the start"
      },
      "carDamageEnd": {
        "label": "Car damage at the end"
      },
      "carMileageStart": {
        "label": "Car mileage at the start"
      },
      "carMileageEnd": {
        "label": "Car mileage at the end"
      },
      "createdAt": {
        "label": "Created at"
      },
      "deleted": {
        "label": "Deleted"
      },
      "externalId": {
        "label": "External ID"
      },
      "licensePlate": {
        "label": "License plate"
      },
      "updatedAt": {
        "label": "Last updated at"
      }
    },
    "empty": {
      "label": "Equipes vides?",
      "only": "Seulement les équipes vides",
      "none": "Pas d’équipes vides",
      "both": "Toutes les équipes"
    },
    "amount": {
      "label": "Nombre",
      "placeholder": "Nombre..."
    },
    "membersCount": {
      "label": "Membres",
      "placeholder": "Membres...",
      "empty": "Pas encore de membres de l’équipe..."
    },
    "batchUpload": {
      "error": "Impossible de télécharger les scans!",
      "success": "Les scans ont été téléchargés",
      "errorUnknownFileType": "Choisir un fichier ZIP, PDF ou JPEG."
    },
    "sheetToScan": {
      "error": "Impossible de déplacer la sheet!",
      "success": "La sheet a été déplacée"
    },
    "scanToSheet": {
      "error": "Impossible de déplacer le scan!",
      "success": "Le scan a été déplacé"
    },
    "saveStatusEntry": {
      "error": "Impossible de modifier le statut!",
      "success": "Le statut a été modifié"
    },
    "lockScan": {
      "error": "Impossible de verrouiller le scan!",
      "errorNoEmployee": " Impossible de verrouiller le scan! Veuillez vérifier si votre login est lié à un utilisateur",
      "noScans": "Aucun scan trouvé."
    },
    "modal": {
      "delete": {
        "content": "Vous êtes sur le point de supprimer une équipe. Voulez-vous continuer?",
        "cancelButton": "Non",
        "deleteButton": "Supprimer"
      }
    },
    "member": {
      "delete": {
        "tooltip": "Supprimer membre d’équipe"
      },
      "restore": {
        "tooltip": "Restaurer membre d’équipe"
      }
    }
  },
  "teamLocation": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Lieu",
        "placeholder": "Lieu..."
      }
    }
  },
  "teamMember": {
    "field": {
      "employeeID": {
        "label": "ID employé"
      },
      "plannedBrach": {
        "label": "Succursale pour laquelle recruté"
      },
      "tabletBranch": {
        "label": "Succursale de la tablette"
      },
      "location": {
        "label": "Lieu de recruter"
      },
      "campaign": {
        "label": "Campagne"
      },
      "date": {
        "label": "Date"
      },
      "id": {
        "label": "ID"
      },
      "team_member": {
        "label": "Membre d’équipe",
        "placeholder": "Membre d’équipe...",
      },
      "employee": {
        "label": "Membre d’équipe",
        "placeholder": "Membre d’équipe..."
      },
      "device": {
        "label": "Appareil",
        "placeholder": "Appareil..."
      },
      "function": {
        "label": "Fonction",
        "placeholder": "Fonction...",
        "info": "La fonction de l'employé le jour de cette équipe"
      },
      "note": {
        "this_is_old": "Ne pas utiliser",
        "label": "Remarque"
      },
      "remarks": {
        "label": "Remarque"
      },
      "sign": {
        "in": "Se connecter",
        "out": "Se déconnecter"
      },
      "driver": {
        "label": "Est driver"
      },
      "subscriptionsCount": {
        "label": "Score"
      },
      "quantitiesCount": {
        "label": "Nombre"
      },
      "shiftType": {
        "label": "Type d'équipe"
      },
      "autoSignedOut": {
        "label": "Automatically signed out"
      },
      "createdAt": {
        "label": "Created at"
      },
      "damageSignIn": {
        "label": "Damage at sign in"
      },
      "damageSignOut": {
        "label": "Damage at sign out"
      },
      "deleted": {
        "label": "Deleted"
      },
      "fieldDay": {
        "label": "Field day"
      },
      "functionOverridden": {
        "label": "Function overridden"
      },
      "shiftTypeOverride": {
        "label": "Shift type overridden"
      },
      "signIn": {
        "label": "Signed in"
      },
      "signInLat": {
        "label": "Latitude sign in"
      },
      "signInLng": {
        "label": "Longitude sign in"
      },
      "signInTime": {
        "label": "Time at sign in"
      },
      "signOut": {
        "label": "Signed out"
      },
      "signOutLat": {
        "label": "Latitude sign out"
      },
      "signOutLng": {
        "label": "Longitude sign out"
      },
      "signOutTime": {
        "label": "Time at sign out"
      },
      "team": {
        "label": "Team ID"
      },
      "updatedAt": {
        "label": "Last updated at"
      }
    },
    "duplicate": {
      "error": "Membre d’équipe a déjà été ajouté. Choisissez un autre membre d’équipe."
    }
  },
  "traineeship": {
    "nav": {
      "overview": "Stages"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "title": {
        "label": "Titre",
        "placeholder": "Titre..."
      },
      "slug": {
        "label": "Slug",
        "placeholder": "Slug..."
      },
      "ordering": {
        "label": "Séquence",
        "placeholder": "Séquence..."
      },
      "autoAssign": {
        "label": "Attribuer automatiquement",
        "placeholder": "Attribuer automatiquement...",
        "labelEdit": "Attribuer automatiquement à tout le monde ?",
        "autoAssigning": "Attribution automatique"
      },
      "video": {
        "label": "Vidéo",
        "placeholder": "Vidéo..."
      }
    },
    "create": {
      "title": "Ajouter stage"
    },
    "delete": {
      "error": "Impossible de supprimer le stage!",
      "success": "Le stage a été supprimé"
    },
    "edit": {
      "pagesTitle": "Pages",
      "title": "Modifier stage"
    },
    "createButton": "Ajouter Stage",
    "editTooltip": " Modifier item ",
    "statusSave": {
      "error": "Quelque chose s'est mal passé en changeant le statut.",
      "success": "Le changement de statut a été réussi."
    },
    "assignmentCreate": {
      "error": "Il y a eu un problème pour la mise à disposition du stage.",
      "success": "Le stage a été mis à disposition avec succès."
    },
    "contract": {
      "overview": "Contrat"
    },
    "page": {
      "traineeshipEdit": "Personnalisation du stage",
      "traineeshipPageEdit": "Modifier la page {{id}}.",
      "traineeshipPageAdd": "Ajouter une page"
    }
  },
  "traineeshipProgress": {
    "employeeTitle": "Employé",
    "traineeshipTitle": "Stage",
    "traineeshipsTitle": "Stage",
    "statusTitle": "Statut",
    "statusUnopened": "Ne pas encore ouvert",
    "questionTitle": "Question",
    "answerTitle": "Réponse",
    "noQuestions": "Pas de question",
    "noAnswer": "Pas rempli",
    "errorOpening": "Impossible de ouvrir stage",
    "errorApproving": "Impossible d’accorder stage"
  },
  "traineeshipPage": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "text": {
        "label": "Texte",
        "placeholder": "Texte..."
      },
      "textPreview": {
        "label": "Prévisualisation du texte"
      },
      "video": {
        "label": "Vidéo"
      },
      "questions": {
        "label": "Questions"
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "value": {
          "text": "Texte",
          "questions": "Questions",
          "video": "Vidéo",
          "end-evaluation": "Evaluation finale"
        }
      },
      "ordering": {
        "label": "Commandez"
      }
    },
    "create": {
      "title": "Ajouter page"
    },
    "edit": {
      "title": "Modifier page"
    },
    "createButton": "Ajouter page",
    "save": {
      "success": "La page a été sauvée",
      "error": "Quelque chose s'est mal passé lors de l'enregistrement de la page"
    },
    "upload": {
      "error": "Quelque chose s'est mal passé lors du téléchargement de la vidéo"
    }
  },
  "traineeshipQuestion": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "type": {
        "label": "Type",
        "value": {
          "input": "Champ de saisie libre",
          "choice": "Choix multiple"
        }
      },
      "ordering": {
        "label": "Commander"
      },
      "question": {
        "label": "Question",
        "placeholder": "Question..."
      }
    },
    "createButton": "Ajouter question",
    "newQuestion": "Ajouter une question",
    "editQuestion": "Question {{id}} modifier",
    "selectQuestionType": "Sélectionnez le type de question"
  },
  "traineeshipChoice": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "ordering": {
        "label": "Ordre",
        "placeholder": "Ordre..."
      },
      "answer": {
        "label": "Réponse",
        "placeholder": "Réponse..."
      }
    },
    "createButton": "Ajouter option"
  },
  "promotorAppMedia": {
    "nav": {
      "overview": "Promoteur App Media"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id"
      },
      "name": {
        "label": "Sorte",
        "placeholder": "Nom...",
        "value": {
          "ViaVia": "ViaVia",
          "to-chose": "Choisir une valeur"
        }
      },
      "enabled": {
        "label": "Actif",
        "placeholder": "Actif...",
        "value": {
          "true": "Oui",
          "false": "Non"
        }
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "ViaViaImage": {
        "label": "Image",
        "placeholder": "Image...",
        "helpText": "Faites glisser une image ou cliquez ici à gauche pour la télécharger."
      },
      "textField": {
        "label": "Promoteur App Media texte",
        "placeholder": "Promoteur App Media texte..."
      }
    },
    "create": {
      "title": "Ajouter promoteur App Media"
    },
    "edit": {
      "title": "Modifier promoteur App Media"
    },
    "createButton": "Ajouter promoteur App Media",
    "editTooltip": "Modifier promoteur App Media",
    "empty_image": "Vide"
  },
  "metafield": {
    "nav": {
      "overview": "Metafields"
    },
    "copyTooltip": " Copier item ",
    "editTooltip": "Modifier item ",
    "page": {
      "overview": {
        "title": "Metafields"
      }
    },
    "createButton": "Ajouter metafield",
    "clearDefaultButton": "Supprimer default",
    "save": {
      "error": "Impossible de sauvegarder metafield!",
      "success": "Metafield a été sauvegardé"
    },
    "modal": {
      "delete": {
        "header": "Supprimer metafield",
        "content": "Si vous supprimez ce metafield il sera également supprimé des produits suivants:",
        "confirmation": "Êtes-vous sûr de vouloir supprimer ce metafield?"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "slug": {
        "label": "Slug"
      },
      "label": {
        "label": "Label"
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "value": {
          "choice": "Champs de choix",
          "text": "Boîte de recherche libre",
          "boolean": "Vrai/faux",
          "multichoice": "Choix multiple",
          "suggestions": "Suggestions"
        }
      },
      "valueType": {
        "label": "Valeur Type",
        "placeholder": "Valeur de ce type",
        "value": {
          "text": "texte",
          "integer": "entier",
          "float": "float",
          "boolean": "boolean",
          "date": "date",
          "datetime": "datetime"
        }
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "is_preset": {
        "label": "Préréglage",
        "placeholder": "Préréglage..."
      },
      "ordering": {
        "label": "Ordre",
        "placeholder": "Ordre..."
      },
      "showInConfirm": {
        "label": "Afficher en confirmation",
        "placeholder": "Afficher en confirmation..."
      },
      "required": {
        "label": "Requis",
        "placeholder": "Requis..."
      },
      "forTeamCsv": {
        "label": "Grouper pour équipe csv?"
      },
      "default_values": {
        "label": "Valeur par défaut",
        "placeholder": "Défaut..."
      },
      "metadata": {
        "amount": {
          "label": "Montant"
        },
        "label": {
          "label": "Label"
        },
        "value": {
          "label": "Valeur"
        },
        "default": {
          "label": "Défaut"
        }
      },
      "controlledField": {
        "label": "Écraser le champ d'enregistrement",
        "info": "La valeur remplie ici écrase la valeur par défaut d'un abonnement, l'abonné. Actuellement, seul le montant est pris en charge. Dans ce cas, la valeur de ce champ est utilisée pour le champ montant de l'abonné."
      }
    }
  },
  "viaVia": {
    "edit": {
      "title": "Modifier ViaVia"
    },
    "editTooltip": " Modifier succursale ",
    "approveButton": "Approuver",
    "rejectButton": "Rejeter",
    "nav": {
      "overview": "Via Via"
    },
    "unmatchButton": "Découpler",
    "showMatchModalButton": "coupler",
    "matchModal": {
      "title": "Correspondance manuelle",
      "search": "Recherche d’un candidat"
    },
    "page": {
      "overview": {
        "title": "Via via "
      }
    },
    "save": {
      "error": "Impossible de sauvegarder via via!",
      "success": "Via via a été supprimé"
    },
    "detele": {
      "error": "Impossible de sauvegarder via via!",
      "success": "Via via a été supprimé"
    },
    "match": {
      "error": "Impossible de sauvegarder match!",
      "success": "Match a été sauvegardé"
    },
    "unmatch": {
      "error": "Impossible de sauvegarder unmatch!",
      "success": "Unmatch a été sauvegardé"
    },
    "filter": {
      "search": {
        "label": "Recherche",
        "placeholder": "Nom..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "auto_matched": {
        "label": "AM?",
        "placeholder": "Match automatique...",
        "title": "Match automatique mis en correspondance?"
      },
      "firstName": {
        "label": "Prénom",
        "placeholder": "Prénom..."
      },
      "middleName": {
        "label": "Interjection",
        "placeholder": "Interjection..."
      },
      "fullName": {
        "label": "Nom et prénom",
        "placeholder": "Nom et prénom..."
      },
      "lastName": {
        "label": "Nom de famille",
        "placeholder": "Nom de famille..."
      },
      "email": {
        "label": "Adresse électronique",
        "placeholder": "Adresse électronique..."
      },
      "status": {
        "label": "Statut",
        "placeholder": "Statut...",
        "value": {
          "open": "Ouvert",
          "matched": "Lié",
          "approved": "Terminé"
        }
      },
      "phoneNumbers": {
        "label": "Numéro de téléphone",
        "placeholder": "Numéro de téléphone..."
      },
      "created_at": {
        "label": "Créé le",
        "placeholder": "Créé le..."
      },
      "branch": {
        "label": "Succursale",
        "placeholder": "Succursale..."
      },
      "job_application": {
        "label": "Candidat",
        "placeholder": "Candidat..."
      },
      "created_by": {
        "label": "Contribué par",
        "placeholder": "Contribué par..."
      }
    }
  },
  "companyVideo": {
    "field": {
      "id": {
        "label": "ID"
      },
      "ordering": {
        "label": "Ordre",
        "placeholder": "Ordre..."
      },
      "title": {
        "label": "Titre",
        "placeholder": "Titre..."
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "thumb": {
        "label": "Image",
        "placeholder": "Image..."
      },
      "open": {
        "label": "Public",
        "title": "Tous les utilisateurs sont autorisés à voir les vidéos publiques."
      },
      "visibleToApplicants": {
        "label": "Visible pour les candidats",
      },
      "visibleToEmployees": {
        "label": "Visible pour les employés",
      },
      "video": {
        "label": "Vidéo",
        "placeholder": "Vidéo..."
      }
    }
  },
  "video": {
    "editTooltip": " Modifier vidéo ",
    "createButton": "Ajouter vidéo",
    "nav": {
      "overview": "Vidéo"
    },
    "overview": {
      "title": "Vidéos",
      "addButton": "Ajouter vidéo"
    },
    "edit": {
      "title": "Modifier vidéo"
    },
    "create": {
      "title": "Ajouter vidéo"
    },
    "save": {
      "error": "Impossible de sauvegarder vidéo!",
      "success": "Vidéo a été sauvegardée"
    },
    "delete": {
      "error": "Impossible de supprimer vidéo!",
      "success": "Vidéo a été supprimée"
    },
    "filter": {
      "search": {
        "label": "Recherche",
        "placeholder": "Nom..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "ordering": {
        "label": "Ordre",
        "placeholder": "Ordre..."
      },
      "title": {
        "label": "Titre",
        "placeholder": "Titre..."
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "thumb": {
        "label": "Image",
        "placeholder": "Image..."
      },
      "open": {
        "label": "Public",
        "title": "Tous les utilisateurs sont autorisés à voir les vidéos publiques."
      },
      "video": {
        "label": "Vidéo",
        "placeholder": "Vidéo..."
      }
    },
    "modal": {
      "delete": {
        "content": "Vous êtes sur le point de supprimer une vidéo. Voulez-vous continuer?",
        "cancelButton": "Non",
        "deleteButton": "Supprimer"
      }
    }
  },
  "device": {
    "logs": "Voir logs",
    "mergeButton": "Fusionner",
    "nav": {
      "overview": "Appareils"
    },
    "overview": {
      "title": "Appareils"
    },
    "modalMerge": {
      "title": "Choisir double",
      "noDuplicatesFound": "Aucun double trouvé"
    },
    "editTooltip": "Modifier appareil",
    "historyTooltip": "Afficher histoire",
    "singular": "Appareil",
    "createButton": "Ajouter appareil",
    "platform": {
      "ios": "ios",
      "android": "android"
    },
    "statuses": {
      "active": "Actif",
      "unknown": "Inconnu",
      "disabled": "Désactivé"
    },
    "save": {
      "error": "Impossible de sauvegarder appareil!",
      "success": "Appareil a été sauvegardé"
    },
    "filter": {
      "search": {
        "label": "Recherche",
        "placeholder": "Code de l’appareil, description..."
      },
      "branch": {
        "label": "Succursale"
      },
      "status": {
        "label": "Statut",
        "multiselect": {
          "selectedText": "# des # statuts",
          "noneSelectedText": "Choisir statut"
        },
        "multiselectFilter": {
          "placeholder": "Recherche..."
        }
      }
    },
    "field": {
      "identifier": {
        "label": "Code de l’appareil"
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "status": {
        "label": "Statut",
        "value": {
          "active": "Actif",
          "unknown": "Inconnu",
          "disabled": "Désactivé"
        }
      },
      "branch": {
        "label": "Lieu",
        "placeholder": "Lieu..."
      },
      "subBranches": {
        "label": "Succursale",
        "placeholder": "Succursale..."
      },
      "owner": {
        "label": "Propriétaire",
        "placeholder": "Propriétaire..."
      },
      "employee": {
        "label": "Employé"
      },
      "mode": {
        "label": "Mode"
      },
      "platform": {
        "label": "Plateforme"
      },
      "apiUrl": {
        "label": "CY_API_URL"
      },
      "referer": {
        "label": "CY_REFERER"
      },
      "websocketUrl": {
        "label": "CY_WEBSOCKET_URL"
      },
      "apiKey": {
        "label": "CY_API_KEY"
      },
      "certName": {
        "label": "CY_CERT_NAME"
      },
      "googleMapsApiKey": {
        "label": "CY_GOOGLE_MAPS_API_KEY"
      },
      "sentryDsn": {
        "label": "CY_SENTRY_DSN"
      },
      "deviceEndpoint": {
        "label": "CY_DEVICE_ENDPOINT"
      },
      "employeesForDebug": {
        "label": "CY_EMPLOYEES_FOR_DEBUG"
      },
      "featureCaptainsheetDamage": {
        "label": "Feature captainsheet damage aan?"
      },
      "featureRechtVanBezwaar": {
        "label": "Feature droit d’objection url?"
      },
      "deviceCode": {
        "label": "CY_DEVICE_CODE"
      },
      "lastUsed": {
        "label": "Dernière Utilisation",
        "placeholder": "Dernière utilisation..."
      },
    },
    "duplicate": {
      "error": "L’appareil est déjà attribué. Veuillez choisir un autre appareil."
    },
    "history": {
      "title": "Aperçu des utilisateurs"
    },
    "buttons": {
      "duplicate": "Fusionner les doublons",
      "qrCode": "Regarder le code QR"
    },
    "total": {
      "label": "Total"
    },
  },
  "deviceLocation": {
    "field": {
      "moment": {
        "label": "Mesuré le"
      },
      "point": {
        "label": "Localisation",
        "address": "Adresse"
      }
    }
  },
  "dashboard": {
    "nav": {
      "overview": "Tableau de bord"
    }
  },
  "position": {
    "field": {
      "startDate": {
        "label": "Date de début"
      },
      "job": {
        "label": "Fonction"
      }
    }
  },
  "incedentalExpense": {
    "button": {
      "true": "Approuver",
      "false": "Rejeter"
    },
    "form": {
      "either": "Les deux",
      "true": "Approuvé",
      "false": "Rejeté"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "compensationAmount": {
        "label": "Valeur"
      },
      "employee": {
        "label": "Employé"
      },
      "month": {
        "label": "Date"
      },
      "reason": {
        "label": "Raison"
      },
      "by": {
        "label": "Par"
      },
      "approved": {
        "label": "Approuvé"
      },
      "action": {
        "label": "Action"
      },
      "approvedBy": {
        "label": "Approuvé par"
      },
      "bulkEmployees": {
        "label": "Employés avec dépenses"
      },
      "rejectionReason": {
        "label": "Motif de la désapprobation"
      },
      "type": {
        "label": "Genre",
        "value": {
          "gross": "Brut",
          "net": "Net"
        }
      },
      "category": {
        "label": "Catégorie",
        "netCatagory": {
          "consumption": "Consommation",
          "officeSupplies": "Fournitures de bureau",
          "travel": "Voyager",
          "accommodation": "Accommodation",
          "carRelatedExpenses": "Faire le plein / Parking",
          "other": "Autre"
        },
        "grosCatagory": {
          "recruitment": "Recrutement",
          "bonus": "Bonus",
          "lead": "Lead",
          "other": "Autre",
          "headquarter": "Quartier",
          "call_center": "Centre d'appels",
        }
      },
      "receipt": {
        "modal": {
          "title": "Remboursement"
        },
        "label": "Billet"
      }
    }
  },
  "factor": {
    "field": {
      "factor": {
        "label": "Facteur"
      },
      "startDate": {
        "label": "Date de début"
      },
      "endDate": {
        "label": "Date de fin"
      },
      "reason": {
        "label": "Raison"
      },
      "by": {
        "label": "Par"
      },
      "action": {
        "label": "Action"
      }
    }
  },
  "employee": {
    "earning": {
      "buttonTitle": "Salaires",
      "modalTitle": "Les salaires au fil du temps",
      "field": {
        "earning": {
          "label": "Salaires",
          "tag": "Salaire"
        },
        "period": {
          "label": "Période",
          "tag": "Période"
        }
      }
    },
    "period": "Période: ",
    "creditations": "Créditation",
    "incedentals": "Rémunération occasionnelle",
    "invoiceNumber": "Numéro de facture",
    "compensation": {
      "modalTitle": "Autre rémunération employé",
      "buttonTitle": "Rémunération employé",
      "clearCompensation": "Mettre la rémunération à zéro",
      "saveCompensation": "Sauvegarder rémunération",
      "editCompensation": "Editer rémunération",
      "bulkButtonTitle": "Créer des compensations en vrac"
    },
    "factor": {
      "modalTitle": "Facteur employé",
      "buttonTitle": "Facteur employé",
      "clearCompensation": "Mettre le facteur à zéro",
      "saveCompensation": "Sauvegarder facteur",
      "editCompensation": "Editer facteur"
    },
    "edit": {
      "title": "Editer employé",
      "interviewTitle": "Interviews",
      "attachmentTitle": "Annexes",
      "positionTitle": "Positions",
      "callBackTitle": "Rendez-vous de rappels",
      intentionDeclaration:{
        download: "Int. decl.",
        french: "Française",
        dutch: "Néerlandaise"
      },
      "disableSaveDR": "Vous devez sélectionner le recto et le verso de la carte d'identité avant de pouvoir l'enregistrer."
    },
    "modal": {
      "viewID": {
        "title": "Employé ",
        "toolTip": "ID employé",
        "noID": "Pas de ID disponible pour cet employé"
      }
    },
    "actions": "Actions",
    "editTooltip": "Editer employé",
    "createButton": "Ajouter employé",
    "attachment": "Annexe",
    "attachmentLabel": "Annexe",
    "attachmentEmpty": "Pas téléchargé",
    "uploadIDFirst": "Veuillez télécharger et enregistrer les deux photos d'identité en premier.",
    "nav": {
      "overview": "Employés",
      "applicants": "Postuler à un emploi",
      "interviews": "Applications",
      "traineeships": "Progression du stage"
    },
    "save": {
      "error": "Impossible de sauvegarder utilisateur!",
      "success": "Utilisateur sauvegardé"
    },
    "filter": {
      "group": {
        "selectedText": "# des # groupes sélectées",
        "noneSelectedText": "Choisir des groupes",
        "placeholder": "Chercher..."
      },
      "deleted": "Employés supprimés",
      "onlyDeleted": "Seulement supprimé",
      "withoutDeleted": "Sans enlevé",
      "bothDeletedAntNot": "Tout"

    },
    "currentTraineeship": "Statut traineeship",
    "showTraineeshipButton": "Afficher traineeships",
    "showTraineeshipButtonTooltip": "Afficher traineeships",
    "showUserButton": "Afficher utilisateur",
    "showUserButtonTooltip": "Afficher utilisateur",
    "sendAppInviteEmail": {
      "tooltip": " Envoyer un courriel d'invitation à l'application du promoteur",
      "success": "Le courriel a été envoyé",
      "error": "Impossible d’envoyer un courriel"
    },
    "rotateEmployeeAvatar": {
      "success": "L’avatar est tourné",
      "error": "Impossible de tourner l’avatar"
    },
    "traineeship": {
      "approveButton": "Approuver",
      "reset": {
        "title": "Réinitialiser",
        "confirm": "Êtes-vous sûr de vouloir réinitialiser le traineeship de cet employé par défaut ? Toutes les réponses seront supprimées.",
      }
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "employee": {
        "label": "Promoteur",
        "placeholder": "Promoteur..."
      },
      "adres": {
        "label": "Adresse"
      },
      "last_sign_in": {
        "label": "Dernier jour ouvrable"
      },
      "compensationAmount": {
        "label": "Rémunération financier occasionnelle"
      },
      "firstName": {
        "label": "Prénom",
        "placeholder": "Prénom..."
      },
      "middleName": {
        "label": "Insertion",
        "placeholder": "Insertion..."
      },
      "lastName": {
        "label": "Nom de famille",
        "placeholder": "Nom de famille..."
      },
      "dateOfBirth": {
        "label": "Date de naissance"
      },
      "phoneNumbers": {
        "label": "Numéros de téléphone",
        "placeholder": "Numéros de téléphone..."
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom..."
      },
      "can_change_own_campaign": {
        "label": "Peut sélectionner soi-même sa campagne",
        "placeholder": "Peut sélectionner soi-même sa campagne..."
      },
      "factor": {
        "label": "Facteur",
        "placeholder": "Facteur..."
      },
      "avatar": {
        "label": "Photo de profile",
        "placeholder": "Photo de profile..."
      },
      "mainBranch": {
        "label": "Siège social",
        "placeholder": "Choisir siège social..."
      },
      "main_branch": {
        "label": "Siège social",
        "placeholder": "Choisir siège social..."
      },
      "branches": {
        "label": "Succursales",
        "placeholder": "Succursale...",
        "selectedText": "# des # succursales",
        "noneSelectedText": "Sélectionner succursale"
      },
      "initials": {
        "label": "Initiales",
        "placeholder": "Initiales..."
      },
      "first_name": {
        "label": "Prénom",
        "placeholder": "Prénom..."
      },
      "middle_name": {
        "label": "Insertion",
        "placeholder": "Insertion..."
      },
      "last_name": {
        "label": "Nom de famille",
        "placeholder": "Nom de famille..."
      },
      "gender": {
        "label": "Sexe",
        "value": {
          "male": "Homme",
          "female": "Femme",
          "other": "Autre",
          "unkown": "inconnu"
        }
      },
      "age": {
        "label": "Age soumis"
      },
      "date_of_birth": {
        "label": "Date de naissance"
      },
      "phone_numbers": {
        "label": "Numéros de téléphone",
        "placeholder": "Numéros de téléphone..."
      },
      "ssn": {
        "label": "BSN",
        "placeholder": "BSN..."
      },
      "ssnBE": {
        "label": "Numéro de Registre national",
        "placeholder": "Numéro de Registre national..."
      },
      "isFreelance": {
        "label": "Entrepreneur indépendant?"
      },
      "companyName": {
        "label": "Nom de l’entreprise"
      },
      "dateStarted": {
        "label": "Date de début"
      },
      "vatNumber": {
        "label": "VAT-ID"
      },
      "cocNumber": {
        "label": "Numéro de la Chambre de Commerce"
      },
      "referralSource": {
        "label": "Source"
      },
      "country": {
        "label": "Pays",
        "placeholder": "Pays...",
        "value": {
          "NL": "NL",
          "BE": "BE",
          "FR": "FR",
          "DE": "DE",
          "OTHER": "Autre"
        }
      },
      "iban": {
        "label": "IBAN",
        "placeholder": "IBAN..."
      },
      "house_number": {
        "label": "Numéro de maison",
        "placeholder": "Numéro de maison..."
      },
      "houseNumber": {
        "label": "Numéro de maison ",
        "placeholder": "Numéro de maison..."
      },
      "house_number_suffix": {
        "label": "Suffixe du numéro de maison",
        "placeholder": "Suffixe du numéro de maison..."
      },
      "houseNumberSuffix": {
        "label": "Suffixe du numéro de maison ",
        "placeholder": "Suffixe du numéro de maison..."
      },
      "city": {
        "label": "Ville",
        "placeholder": "Ville..."
      },
      "city_of_residence": {
        "label": "Lieu"
      },
      "email": {
        "label": "Adresse électronique",
        "placeholder": "Adresse électronique..."
      },
      "street": {
        "label": "Rue",
        "placeholder": "Rue..."
      },
      "zip_code": {
        "label": "Code postal",
        "placeholder": "Code postal..."
      },
      "zipCode": {
        "label": "Code postal",
        "placeholder": "Code postal..."
      },
      "employee_number": {
        "label": "Numéro de personnel",
        "placeholder": "Numéro de personnel..."
      },
      "employeeNumber": {
        "label": "Numéro de personnel",
        "placeholder": "Numéro de personnel..."
      },
      "driving_licence": {
        "label": "A un permis de conduire",
        "placeholder": "Permis de conduire...",
        "value": {
          "false": "Non",
          "true": "Oui"
        }
      },
      "public_transport_student_license": {
        "label": "A transport public étudiant",
        "placeholder": "Transport public étudiant...",
        "value": {
          "false": "Non",
          "true": "Oui"
        }
      },
      "drivingLicence": {
        "label": "A un permis de conduire",
        "placeholder": "Permis de conduire...",
        "value": {
          "false": "Non",
          "true": "Oui"
        }
      },
      "canCall": {
        "label": "Peut téléphoner",
        "value": {
          "false": "Non",
          "true": "Oui",
          "both": "Les deux sites"
        }
      },
      "bank_card": {
        "label": "Carte bancaire",
        "placeholder": "Carte bancaire..."
      },
      "bankCard": {
        "label": "Carte bancaire ",
        "placeholder": "Carte bancaire..."
      },
      "tax_exemption": {
        "label": "Crédit d’impôt",
        "placeholder": "Crédit d’impôt..."
      },
      "taxExemption": {
        "label": "Crédit d’impôt ",
        "placeholder": "Crédit d’impôt..."
      },
      "can_change_tax_exemption": {
        "label": "L'exonération fiscale peut-elle être modifiée?",
        "placeholder": "L'exonération fiscale peut-elle être modifiée?"
      },
      "canChangeTaxExemption": {
        "label": "L'exonération fiscale peut-elle être modifiée?",
        "placeholder": "L'exonération fiscale peut-elle être modifiée?"
      },
      "contract_signature": {
        "label": "Contrat",
        "placeholder": "Contrat..."
      },
      "contractSignature": {
        "label": "Contrat",
        "placeholder": "Contrat..."
      },
      "identification": {
        "label": "Carte d’identité face",
        "placeholder": "Carte d’identité face..."
      },
      "identification_back": {
        "label": "Carte d’identité inversée",
        "placeholder": "Carte d’identité inversée..."
      },
      "identificationBack": {
        "label": "Carte d’identité inversée ",
        "placeholder": "Carte d’identité inversée..."
      },
      "bw_id": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "bwID": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "city_of_birth": {
        "label": "Lieu de naissance",
        "placeholder": " Lieu de naissance..."
      },
      "cityOfBirth": {
        "label": " Lieu de naissance",
        "placeholder": " Lieu de naissance..."
      },
      "country_of_birth": {
        "label": "Pays de naissance",
        "placeholder": "Pays de naissance..."
      },
      "countryOfBirth": {
        "label": "Pays de naissance ",
        "placeholder": "Pays de naissance..."
      },
      "id_type": {
        "label": "ID type",
        "placeholder": "ID type..."
      },
      "idType": {
        "label": "ID type",
        "placeholder": "ID type..."
      },
      "id_number": {
        "label": "ID numéro",
        "placeholder": "ID numéro..."
      },
      "idNumber": {
        "label": "ID numéro",
        "placeholder": "ID numéro..."
      },
      "interviewer": {
        "label": "Intervieweur?",
        "title": "L'employé peut alors être choisi dans la liste des examinateurs pour les entretiens d'embauche "
      },
      "coins": {
        "label": "Pièces"
      },
      "blockedClients": {
        "label": "Clients bloqués"
      },
      status: {
        label: 'Statut',
        value: {
          active: "Actif",
          inactive: "Inactif",
          outflow: "Sortie",
        }
      },
      "referralSourceOther": {
        "label": "Source autre"
      },
      "points": {
        "label": "Points"
      },
      "legalGuardianName": {
        "label": "Nom du tuteur légal"
      },
      "legalGuardianPhone": {
        "label": "Numéro de téléphone du tuteur légal"
      },
      "legalGuardianEmail": {
        "label": "Adresse électronique du tuteur légal"
      },
      "travelingDistance": {
        "label": "Distance de déplacement"
      },
      "publicTransportStudentLicenseType": {
        "label": "Genre de transport public pour les étudiants",
        "value": {
          "no": "Aucun",
          "week": "Semaine",
          "weekend": "Week-end"
        }
      },
      "nationalityInternal": {
        "label": "Nationalité"
      },
      "externalContractSigned": {
        "label": "Contrat signé en dehors du système"
      },
      "bic": {
        "label": "BIC",
        "placeholder": "BIC..."
      },
      "canSelectOwnCampaign": {
        "label": "Peut choisir sa propre campagne",
        "info": "Ce freelance peut choisir les campagnes pour lesquelles il recrute (cela se fait sur l'application de vente sur le terrain)."
      }
    },
    "walkinModal": {
      "title": "Candidat entre le bureau"
    },
    "periodWeek": "Période {{periodNumber}}: ",
    "isActive": "L'utilisateur est activé",
    status: {
      missingDocument: "Document manquant"
    },
    "isNotActive": "Le compte de l'utilisateur n'est pas encore activé, voir le bouton de renvoi du courrier d'activation en bas de l'écran pour envoyer un nouveau courrier électronique.",
    "calls": {
      "modal": {
        "incoming": "Appels entrants",
        "outcoming": "Appels sortants",
        "buttonTitle": "Appels téléphoniques",
        "id": "ID",
        "caller": "Appelant",
        "calledUser": "A",
        "start": "Début",
        "duration": "Durée",
        "voicelogs": "Voicelogs",
        "listenCall": "Conversation après écoute",
        "audioError": "Votre navigateur ne prend pas en charge la balise audio."
      },
      "modalTitle": "Appels téléphoniques des employés"
    },
    "credits": "Crédit",
    "anonymizeModal": {
      'title': 'Anonymiser',
      'description': 'Êtes-vous sûr de vouloir rendre cet employé anonyme {{fullName}}? Toutes les informations personnelles seront écrasées.',
      'anonymizeButton': 'Anonymiser',
    }
  },
  "export": {
    "editTooltip": "Modifier résultats",
    "exportTooltip": "Modifier résultats",
    "save": {
      "error": "Impossible de sauvegarder la définition!",
      "success": "La définition a été sauvegardée"
    },
    "download": {
      "error": "Impossible de télécharger l’exportation!"
    },
    "value_null": {
      "null": "égal à null",
      "not_null": "N’est pas égal à null"
    },
    "date_component_truncate": {
      "century": "siècle",
      "day": "jour",
      "decade": "décennie",
      "dow": "jour de la semaine",
      "doy": "jour de l’année",
      "epoch": "secondes depuis 1-1-1970 00:00:00 UTC",
      "hour": "heure",
      "isodow": "Jour de la semaine (ISO)",
      "isoyear": "Jour de l’année (ISO)",
      "microseconds": "microseconde",
      "millenium": "millénaire",
      "milliseconds": "milliseconde",
      "minute": "minute",
      "month": "mois",
      "quarter": "trimestre",
      "second": "seconde",
      "timezone": "différence de fuseau horaire par rapport à UTC",
      "timezone_hour": "heures de décalage par rapport à UTC",
      "timezone_minute": "minutes de différence par rapport à UTC",
      "week": "semaine",
      "year": "année",
      "millennium": "millénaire"
    },
    "createButton": "Ajouter exportation"
  },
  "branch": {
    "createButton": "Créer une succursale",
    "editTooltip": "Modifier succursale",
    "nav": {
      "overview": "Succursale"
    },
    "create": {
      "title": "Créer une succursale"
    },
    "overview": {
      "createButton": "Ajouter une succursale"
    },
    "edit": {
      "title": "Modifier succursale"
    },
    "save": {
      "error": "Impossible de sauvegarder la succursale!",
      "success": "La succursale a été sauvegardée"
    },
    "filter": {
      "search": {
        "label": "Rechercher",
        "placeholder": "Nom..."
      }
    },
    "field": {
      "mailjetTemplateWelcome": {
        "label": "Template ID courriel de bienvenue",
        "info": "Le message de bienvenue que les nouvelles demandes de cette branche reçoivent, un identifiant transactionnel mailjet"
      },
      "mailjetTemplateActivation": {
        "label": "Template ID courriel d’activation",
        "info": "Le message d'activation du compte que les nouveaux employés de cette branche reçoivent, un identifiant transactionnel mailjet"
      },
      "mailjetTemplatePasswordReset": {
        "label": "Template ID courriel de reset mots de passe ",
        "info": "Le modèle de réinitialisation de mot de passe pour cette branche, un identifiant transactionnel mailjet"
      },
      "mailjetTemplateAccountEdited": {
        "label": "Template ID courriel de compte changé ",
        "info": "Le modèle de mailjet du courrier que les employés reçoivent lorsque leurs informations dans le système sont mises à jour, un identifiant transactionnel mailjet"
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom..."
      },
      "branch": {
        "label": "Succursale",
        "placeholder": "Succursale..."
      },
      "code": {
        "label": "Code",
        "placeholder": "Code..."
      },
      "avatar": {
        "label": "Avatar",
        "placeholder": "Avatar..."
      },
      "address": {
        "label": "Adresse",
        "placeholder": "Adresse..."
      },
      "phoneNumber": {
        "label": "Numéro de téléphone",
        "placeholder": "Numéro de téléphone..."
      },
      "email": {
        "label": "Adresse éléctronique",
        "placeholder": "Adresse éléctronique..."
      },
      "manager": {
        "label": "Manager",
        "placeholder": "Choisir manager..."
      },
      "nmbrsCodeCostplace": {
        "label": "Nmrs de Code Costcenter",
        "placeholder": "Nmrs de Code Costcenter..."
      },
      "nmbrsCodeDepartment": {
        "label": "Nmrs de Code Departement",
        "placeholder": "Nmrs de Code Departement..."
      },
      "public": {
        "label": "Public?",
        "title": "Tous les utilisateurs peuvent voir les succursales publiques.",
        "info": "Tous les utilisateurs peuvent voir les succursales publiques."
      }
    },
    "multiselect": {
      "placeholder": "Chercher succursale...",
      "selectedText": "# des # succursales sélectionnées",
      "noneSelectedText": "Sélectionnez succursale"
    },
    "textInputExample": "Les horaires disponibles pour ce lieu dans le pop-up du plan d'entretien lors de la demande. Entrée par unité de temps. Par exemple, 10h00 ou 01h30.",
    "interviewSlots": "Créneaux d'entretien",
    "interviewTimes": "Par jour, en créneaux, par exemple 10 heures du matin."
  },
  "bwBranchTranslation": {
    "editTooltip": "Modifier succursale",
    "createButton": "Créer succursale",
    "nav": {
      "overview": "Succursales"
    },
    "create": {
      "title": "Créer succursale"
    },
    "overview": {
      "createButton": "Ajouter succursale",
      "title": "Succursales BW"
    },
    "edit": {
      "title": "Modifier succursale"
    },
    "save": {
      "error": "Impossible de sauvegarder la succursale!",
      "success": "La succursale a été sauvegardée"
    },
    "filter": {
      "search": {
        "label": "Chercher",
        "placeholder": "Nom..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom..."
      },
      "officeCode": {
        "label": " Code d’office "
      },
      "branch": {
        "label": "Succursale",
        "placeholder": "Succursale..."
      },
      "code": {
        "label": "Code",
        "placeholder": "Code..."
      },
      "avatar": {
        "label": "Avatar",
        "placeholder": "Avatar..."
      },
      "address": {
        "label": "Adresse",
        "placeholder": "Adresse..."
      },
      "phone_number": {
        "label": "Numéro de téléphone",
        "placeholder": "Numéro de téléphone..."
      },
      "email": {
        "label": "Adresse électronique",
        "placeholder": "Adresse électronique..."
      },
      "manager": {
        "label": "Manager",
        "placeholder": "Choisissez manager..."
      },
      "nmbrs_code_costplace": {
        "label": "Nmrs de Code Costcenter",
        "placeholder": "Nmrs de Code Costcenter..."
      },
      "nmbrs_code_department": {
        "label": "Nmrs de Code Departement",
        "placeholder": "Nmrs de Code Departement..."
      },
      "public": {
        "label": "Public?",
        "title": "Tous les utilisateurs peuvent voir les succursales publiques."
      }
    },
    "multiselect": {
      "placeholder": "Chercher succursale...",
      "selectedText": "# des # succursales sélectionnées",
      "noneSelectedText": "Sélectionner succursale"
    }
  },
  "exportArchive": {
    "title": "Archive d’exportation",
    "download": "Télécharger",
    "field": {
      "id": {
        "label": "ID"
      },
      "file": {
        "label": "Télécharger"
      },
      "bundleDescription": {
        "label": "Exporter"
      },
      "definitionDescription": {
        "label": "Fichier"
      },
      "createdAt": {
        "label": "Exporté(e) le"
      }
    }
  },
  "bucket": {
    "save": {
      "error": "Impossible de sauvegarder bucket!",
      "success": "Bucket a été sauvegardé"
    },
    "field": {
      "callers": {
        "placeholder": "Ajouter appelant...",
        "label": "Appelant"
      }
    }
  },
  "call": {
    "dropdownError": "Définissez le statut du rendez-vous!",
    "toggleScanButton": {
      "show": "Afficher scan",
      "hide": "Cacher scan"
    },
    "modal": {
      "anonymous": "Callagent",
      "downloadButton": "Télécharger voicelog"
    },
    "resultOptions": {
      "reachedChanged": "Abonnement modifié",
      "reachedThrough": "Soumettre l'abonnement",
      "reachedCancelled": "Abonnement annulé",
      "reachedLater": "Abonnement plus tard",
      "reachedCallback": "Rendez-vous de rappel (R.D.R.) pris",
      "notReachedPhone": "Numéro de téléphone correct / non du client",
      "notReachedCallBack": "Pas enregistré, encore demain",
      "notReachedCallBackSoon": "Non enregistré, encore une fois dans cinq minutes",
      "notReachedNoAnswer": "Non inclus, continuer pour l'exportation"
    },
    "mobile": "Mobile",
    "landLine": "Fixe",
    "attempts": "Tentatives d'appel",
    "maxAttempts": "Nombre maximum",
    "statistics": {
      "amount": "Nombre d'enregistrements",
      "target": "A atteindre",
      "reached": "Atteint",
      "percentage": "Pourcentage d'atteinte",
      "not_reachable": "Inatteignable",
      "cancelations": "Annulation",
      "not_reached": "N'a pas été atteint",
      "call_later": "Rappeller plus tard",
      "label": "Statistiques"
    },
    "confirmModal": {
      "unsavedChanges": "Le rendez-vous actuel contient des modifications non enregistrées, êtes-vous sûr de vouloir changer ?",
      "cancelSub": "Voulez-vous vraiment annuler cet abonnement ?",
      "callTomorrow": "Êtes-vous sûr de vouloir rappeler cette inscription demain?",
      "callFiveMin": "Êtes-vous sûr de vouloir rappeler cette inscription dans cinq minutes ?"
    },
    "newAppointment": {
      "modalHeader": "Planifier un nouveau rendez-vous",
      "header": "Sélectionnez la date et l'heure du nouveau rendez-vous"
    },
  },
  "campaign": {
    "editTooltip": "Modifier item",
    "nav": {
      "overview": "Campagnes"
    },
    "page": {
      "overview": {
        "title": "Campagne"
      }
    },
    "createButton": "Ajouter campagne",
    "save": {
      "error": "Impossible de sauvegarder campagne!",
      "success": "Campagne a été sauvegardée"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "branches": {
        "label": "Succursale",
        "placeholder": "Succursale...",
        "selectedText": "# des # succursales",
        "noneSelectedText": "Choisir succursale",
        "info": "Quelles succursales peuvent utiliser cette campagne, les employés de ZZP peuvent également être limités dans cette manière de campagne par le biais de leur (principale) succursale."
      },
      "products": {
        "label": "Produits",
        "placeholder": "Produits...",
        "selectedText": "# des # produits",
        "noneSelectedText": "Sélectionner produit"
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom..."
      },
      "captainsheetContractText": {
        "label": "Captainsheet contrat"
      },
      "callScriptWelcome": {
        "label": "Script d’appel",
        "placeholder": "Script d’appel..."
      },
      "client": {
        "label": "Client",
        "placeholder": "Client..."
      },
      "draftContentbundle": {
        "label": "Draft contentbundle",
        "placeholder": "Draft contentbundle...",
        "info": "Si une table est en mode brouillon (test), ce paquet de contenu est envoyé en même temps que la table."
      },
      "finalContentbundle": {
        "label": "Contentbundle final",
        "placeholder": "Contentbundle final...",
        "info": "Lorsqu'un tableau est en mode final (production), ce paquet de contenu est envoyé aussi."
      },
      "unit": {
        "label": "Devis de prix",
        "info": "La période de facturation qui apparaît sur l'écran d'enregistrement du produit, par exemple \"Par mois\", \"Par semaine\", \"Par jour\"."
      },
      "rexUsername": {
        "label": "REX extension nom d’utilisateur",
        "info": "Le nom d'utilisateur du numéro de téléphone spécifique (de rex V1) à appeler pour cette campagne spécifique."
      },
      "rexPassword": {
        "label": "REX extension mot de passe",
        "info": "le mot de passe du numéro de téléphone spécifique (de rex V1) à appeler pour cette campagne spécifique."
      },
      "enableStartDate": {
        "label": "Utiliser date de début",
        "info": "Pour cette campagne, utilisez la date limite de début telle que configurée sur le produit associé."
      },
      "bwIds": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs & walker ID..."
      },
      "rexV2OutboundNumber": {
        "label": "Numéro de sortie Rex",
        "options": {
          "anonymous": "Anonyme"
        }
      },
      "enableEmergencySignature": {
        "label": "Acceptez signature comme vérification",
        "info": "Sur l'écran d'enregistrement de la tablette, montrez la possibilité d'utiliser la vérification par signature au lieu de la vérification par SMS"
      },
      "enableGenderOther": {
        "label": "Acceptez 'Autre' comme genre",
        "info": "Sur l'écran d'enregistrement de la tablette, montrez la possibilité d'utiliser 'Autre' comme genre"
      },
      "requireFirstName": {
        "label": "Exiger que le prénom soit défini",
        "info": "Exiger sur l'écran d'enregistrement de la tablette que le prénom soit défini"
      },
      "defaultPaymentInterval": {
        "label": "Fréquence par défaut",
        "info": "La fréquence de paiement par défaut qui sera utilisée pour tous les enregistrements de cette campagne, si aucune période de paiement spécifique n'a été sélectionnée par le recruteur/client"
      },
      "sepa": {
        "label": "SEPA",
        "placeholder": "SEPA"
      },
      "configuration": {
        "label": "Configuration",
      },
      "wageStructure": {
        "label": "Structure de primes active",
        "info": "La structure des primes actuellement utilisée pour récompenser les employés qui ont des résultats appartenant à cette campagne"
      },
      "ageWildcards": {
        "label": "Jokers",
      }
    }
  },
  "bwProductTranslation": {
    "edit": {
      "b&w": "B&W",
      "sms": "Incenova",
      "metafields": "Metafields"
    },
    "field": {
      "id": {
        "label": "id"
      },
      "bwCampaign": {
        "label": "B&W Campagne"
      },
      "bwProductId": {
        "label": "Briggs & Walker Id"
      },
      "accountDomain": {
        "label": "Account Domain B&W"
      },
      "projectCode": {
        "label": "Code Projet B&W"
      },
      "productCode": {
        "label": "Code produit B&W"
      },
      "product": {
        "label": "Produit Incenova"
      },
      "quantity": {
        "label": "Nombre"
      },
      "amount": {
        "label": "Prix unitaire"
      }
    }
  },
  "ContentbundleTranslation": {
    "edit": {
      "Contentbundle": "Module d’enregistrement"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "name": {
        "label": "Nom"
      }
    }
  },
  "bwCampaignTranslation": {
    "nav": {
      "overview": "Campagnes BW"
    },
    "page": {
      "overview": {
        "title": "Campagne BW"
      }
    },
    "createButton": "Ajouter campagne",
    "save": {
      "error": "Impossible de sauvegarder campagne!",
      "success": "Campagne a été sauvegardée"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "branches": {
        "label": "Succursale",
        "placeholder": "Succursale...",
        "selectedText": "# des # succursales",
        "noneSelectedText": "Sélectionner succursale"
      },
      "campaign": {
        "label": "Campagne Incenova"
      },
      "products": {
        "label": "Produits",
        "placeholder": "Produits...",
        "selectedText": "# des # produits",
        "noneSelectedText": "Sélectionner produit"
      },
      "accountDomain": {
        "label": "Account Domain B&W"
      },
      "projectCode": {
        "label": "Code Projet B&W"
      },
      "name": {
        "label": "Nom",
        "placeholder": "Nom..."
      },
      "callScriptWelcome": {
        "label": "Script d’appel",
        "placeholder": "Script d’appel..."
      },
      "client": {
        "label": "Client",
        "placeholder": "Client..."
      },
      "draftContentbundle": {
        "label": "Draft contentbundle",
        "placeholder": "Draft contentbundle..."
      },
      "finalContentbundle": {
        "label": "Contentbundle final",
        "placeholder": "Contentbundle final..."
      },
      "unit": {
        "label": "Devis de prix"
      },
      "enableStartDate": {
        "label": "Utiliser date de début"
      },
      "bwIds": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "captainsheetContract": {
        "label": "Captainsheet contrat",
        "placeholder": "Captainsheet contrat..."
      },
      "importStatus": {
        "label": "Statut de l'importation de l'abonnement"
      }
    }
  },
  "bwProductDefaultMetafieldValue": {
    "field": {
      "value": {
        "label": "Valeur"
      }
    }
  },
  "chapter": {
    "createButton": "Nouveau chapitre",
    "save": {
      "error": "Impossible de sauvegarder chapitre!",
      "duplicateNameError": "Il existe déjà un chapitre portant ce nom!",
      "noNameError": "Vous n’avez pas rempli un nom!",
      "success": "Le chapitre a été sauvegardé"
    },
    "delete": {
      "content": "Vous êtes sur le point de supprimer un chapitre. Voulez-vous continuer?",
      "error": "Impossible de supprimer le chapitre!",
      "success": "Le chapitre a été supprimé"
    }
  },
  "permission": {
    "showDescriptionButton": "Afficher la description des droits",
    "hideDescriptionButton": "Cacher la description des droits",
    "allocation": {
      "modify_team": {
        "label": "Créer, mettre à jour et supprimer des équipes",
        "description": "Modifier pour toutes les succursales, même si l'utilisateur n'est pas membre de cette succursale."
      },
      "modify_team:branch": {
        "label": " Créer, mettre à jour et supprimer des équipes pour sa propre succursale",
        "description": "Uniquement modifier dans les succursales dont l’utilisateur est membre."
      }
    }
  },
  "qrCodeGenerator": {
    "title": "Générateur de code QR",
    "field": {
      "CY_API_URL": "CY_API_URL",
      "CY_REFERER": "CY_REFERER",
      "CY_WEBSOCKET_URL": "CY_WEBSOCKET_URL",
      "CY_API_KEY": "CY_API_KEY",
      "CY_CERT_NAME": "CY_CERT_NAME",
      "CY_GOOGLE_MAPS_API_KEY": "CY_GOOGLE_MAPS_API_KEY",
      "CY_SENTRY_DSN": "CY_SENTRY_DSN",
      "CY_DEVICE_ENDPOINT": "CY_DEVICE_ENDPOINT",
      "CY_EMPLOYEES_FOR_DEBUG": "CY_EMPLOYEES_FOR_DEBUG",
      "CY_DEVICE_CODE": "CY_DEVICE_CODE"
    }
  },
  "tooltips": {
    "delete": "Supprimer",
    "restore": "Restaurer",
    "edit": "Modifier",
    "view": "Afficher",
    "rotate": "Tourner"
  },
  "bwImportFailure": {
    "field": {
      "trash": {
        "label": "Supprimer des erreurs"
      },
      "id": {
        "label": "ID"
      },
      "bwSubscriptionId": {
        "label": "Attribut d’enregistrement B&W"
      },
      "failure": {
        "label": "Erreur"
      },
      "bwData": {
        "label": "Données"
      },
      "createdAt": {
        "label": "Mauvais moment"
      }
    }
  },
  "news": {
    "createButton": "Créer une nouvelle",
    "saveAndPublishButton": "Sauvegarder & publier",
    "field": {
      "id": {
        "label": "ID"
      },
      "title": {
        "label": "Titre"
      },
      "image": {
        "label": "Image"
      },
      "status": {
        "label": "Statut",
        "value": {
          "draft": "Concept",
          "published": "Publié"
        }
      },
      "content": {
        "label": "Message",
        "preview": "Exemple",
        "markdown": "Markdown formatage"
      },
      "createdBy": {
        "label": "Crée par"
      },
      "createdAt": {
        "label": "Crée le"
      },
      "publishedBy": {
        "label": "Publié par"
      },
      "publishedAt": {
        "label": "Publié le"
      }
    }
  },
  "milestone": {
    "createButton": "Créer jalon",
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom"
      },
      "description": {
        "label": "Description"
      },
      "points": {
        "label": "Points"
      },
      "icon": {
        "label": "Icône"
      }
    }
  },
  "backbone": {
    "importer": {
      "employees": {
        "title": "Employés & Succursales",
        "buttonTitle": "Importer employés & succursales"
      },
      "customer": {
        "title": "Clients",
        "buttonTitle": "Importer clients"
      },
      "subscription": {
        "title": "Enregistrements",
        "buttonTitle": "Importer enregistrements"
      },
      "databaseReset": {
        "title": "Reset la base de données",
        "buttonTittle": "Reset DB"
      },
      "batches": {
        "title": "Importer batches",
        "buttonTitle": "Importer batches"
      }
    }
  },
  "tip": {
    "overview": {
      "createButton": "Créer conseil"
    },
    "type": {
      "basic": "Général",
      "new_employees": "Nouveaux employés",
      "new_job_applications": "Nouvelles candidatures"
    },
    "nav": {
      "overview": "Conseils"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "value": {
          "basic": "Standard",
          "new_job_applications": "Nouveaux candidats",
          "new_employees": "Nouveaux employés"
        }
      },
      "text": {
        "label": "Texte",
        "placeholder": "Texte..."
      }
    },
    "create": {
      "title": "Ajouter conseil"
    },
    "edit": {
      "title": "Modifier conseil"
    },
    "createButton": "Ajouter conseil",
    "editTooltip": "Modifier item"
  },
  "history": {
    "modal": {
      "buttonTitle": "History",
      "subscription": {
        "title": "History of this subscription"
      },
      "team": {
        "title": "History of this team"
      }
    },
  },
  "metabase": {
    "createButton": "Ajouter une metabase",
    "filter": {
      "both": "Les deux",
      "yes": "Oui",
      "no": "Non"
    },
    "field": {
      "acties": {
        "label": "Actions"
      },
      "groups": {
        "label": "Groupes"
      },
      "sidebarEnabled": {
        "label": "Barre latérale active"
      },
      "enabled": {
        "label": "Actif"
      },
      "sequenceNumber": {
        "label": "Séquence de la barre de navigation",
        "info": "A quel endroit par rapport aux autres métabases le lien vers cette métabase doit apparaître dans l'en-tête de l'application, un nombre plus faible signifie que la métabase apparaît plus à gauche dans la version ordinateur et en haut dans la version mobile."
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom"
      },
      "dashboardId": {
        "label": "Dashboard ID",
        "info": "L'ID du dashboard de metabase, ce numéro d'ID peut être trouvé dans l'url de votre dashboard sur metabase. e.g. metabase-url.com/dashboard/ID"
      },
      "updatedAt": {
        "label": "Dernière mise à jour"
      },
      "employees": {
        "label": "Employés"
      },
      "clients": {
        "label": "Clients"
      },
      "params": {
        "label": "Paramètres",
        "info": "Les paramètres qui sont envoyés au tableau de bord de la métabase, les noms suivants doivent être utilisés pour le paramètre dans le tableau de bord :\n employee,\n main_branch,\n branches,\n all_branches,\n client",
        "value": {
          "employee": "Membre du personnel",
          "mainBranch": "Siège social",
          "branches": "Branches",
          "all_branches": "Toute branches",
          "client": "Client"
        }
      }
    }
  },
  "earningPeriod": {
    "modal": {
      "confirmExport": "Ceci est une exportation définitive et va geler les salaires de la période avec la date de début {{date}}, êtes-vous sûr ?"
    },
    "filter": {
      "yes": "Oui",
      "no": "Non",
      "both": "Les deux"
    },
    "field": {
      "frozen": {
        "label": "Salaires gelés"
      },
      "Acties": {
        "label": "Actions"
      },
      "id": {
        "label": "ID"
      },
      "endDate": {
        "label": "Date de fin de la période"
      },
      "amount": {
        "label": "Paiement total de la période"
      },
      "startDate": {
        "label": "Date de début de la période"
      }
    }
  },
  "earning": {
    "inspection": {
      "dropOutReasons": "{{amount}} raisons de l'échec",
      "creditReasons": "{{amount}} raisons de la créditation",
      "earned": "Mérits",
      "bruttoDeclarations": "Déclarations brut",
      "netDeclarations": "Déclarations net",
      "donors": "Donateurs",
      "scores": "Scores",
      "max": "Max",
      "average": "Don moyen",
      "enrollmentResults": "Résultats de l'enregistrement",
      "week": "Semaine",
      "date": "Date",
      "client": "Client & campagne",
      "bruto": "Brut",
      "attrition": "Échec",
      "net": "Net",
      "total": "Totale",
      "credit": "Creditations",
      "incidental": "Dépenses incidentales",
      "reason": "Raison",
      "type": "Genre",
      "amount": "Montant",
      "bonus": "Bonus"
    },
    "invoice": {
      "validDate": {
        "label": "Date d'expiration: "
      },
      "approveEmployee": {
        "label": "La période est correcte"
      }
    },
    "week": {
      "label": "Semaine"
    },
    "weekPeriod": {
      "label": "Semaine {startWeekNumber} - %{endWeekNumber}"
    },
    "field": {
      "Acties": {
        "label": "Actions"
      },
      "id": {
        "label": "ID"
      },
      "amount": {
        "label": "Salaires"
      },
      "totalEarned": {
        "label": "Prix total"
      },
      "creditations": {
        "label": "Creditations"
      },
      "datetimeApprovedByEmployee": {
        "label": "Employé approuvé"
      },
      "withheldEarningAmount": {
        "label": "Retenu"
      },
      "datetimeApprovedByCompany": {
        "label": "Entreprise approuvée"
      },
      "nettoSubscriptionAmount": {
        "label": "Montant net"
      },
      "sumSubscriptionPrices": {
        "label": "Le don d'inscription"
      },
      "currentEmployeeFactor": {
        "label": "Facteur (courant)"
      },
      "sumCreditationPrices": {
        "label": "Crédit don (d'inscription)"
      },
      "incidentalWages": {
        "label": "Compensation incidentèle"
      },
      "earningWithoutVat": {
        "label": "Totale hors TVA"
      },
      "employeeId": {
        "label": "ID Employé"
      },
      "earning": {
        "label": "Montant mérité"
      },
      "updatedAt": {
        "label": "Dernière modification"
      },
      "startedAt": {
        "label": "Période"
      },
      "startDate": {
        "label": "Jour de début de la période"
      },
      "contractSigned": {
        "label": "Contrat signé"
      }
    },
    "sync": "Recalculer les revenus de cette période",
    "syncRunning": "Les salaires sont recalculés, rafraîchissez la page dans quelques minutes.",
    "filter": {
      "internalSignatureSigned": "Contrat signé système interne",
      "externalSignatureSigned": "Contrat signé système externe"
    }
  },
  "callPlanning": {
    "addSubModal": {
      "title": "Ajouter des inscriptions pour campagne {{campaign}}"
    },
    language: 'Langue',
    spokenLanguage: 'Langue',
    "employee": "Employé",
    "markAllDone": "Marquer tout comme fini",
    "reachedDone": "Marquer 'atteint' comme fini",
    "importSubscriptions": "Ajouter des inscriptions",
    "confirmManuallyMark": "Confirmer que tous les abonnements {{amount}} de la campagne {{campaign}} doivent être marqués comme réalisés",
    "confirmManuallyReached": "Confirmer que tous 'atteint' les abonnements de la campagne {{campaign}} doivent être marqués comme réalisés",
    "confirmImportHeader": "Confirmation de l'importation",
    "confirmImport": "Êtes-vous sûr de vouloir importer les enregistrements de la campagne {{name}} ?",
    "importRecords": "Importer des inscriptions",
    "amountOfDonors": "{{amount}} Inscriptions",
    "steps": {
      "importSubs": "Importer",
      "assign": "Assigner des employés",
      "current": "Campagnes d'appels actuels"
    },
    "appointments": "Voir",
    "appointmentModal": {
      "subscriptionType": "Inscription",
      "bucketType": "Seau",
      "title": "Dates d'appel {{type}} {{id}}"
    }
  },
  "token": {
    "createButton": "Créer token",
    "field": {
      "id": {
        "label": "ID"
      },
      "token": {
        "label": "Token"
      },
      "createdAt": {
        "label": "Créé le"
      },
      "lastUsedAt": {
        "label": "Dernière utilisation le"
      },
      "expiresAt": {
        "label": "Expire le"
      },
      "expired": {
        "label": "Expiré"
      },
      "content": {
        "markdown": "Markdown formatage"
      }
    }
  },
  "clientFile": {
    "createButton": "Ajouter un fichier client",
    "field": {
      "id": {
        "label": "ID"
      },
      "client": {
        "label": "Client"
      },
      "name": {
        "label": "Titre du fichier"
      },
      "createdAt": {
        "label": "Date de téléchargement"
      },
      "deletionDate": {
        "label": "Supprimer date"
      },
      "uploader": {
        "label": "Télécharger"
      },
      "file": {
        "label": "Fichier"
      }
    }
  },
  "csv": {
    "csvMissing": "Télécharger un csv",
    "createButton": "Téléchargement csv",
    "field": {
      "id": {
        "label": "Id"
      },
      "createdAt": {
        "label": "Date de téléchargement"
      },
      "updatedAt": {
        "label": "Dernière mise à jour"
      },
      "uploader": {
        "label": "Téléchargeur"
      },
      "csv": {
        "label": "Fichier"
      },
      "type": {
        "label": "Type",
        "options": {
          "coin_addition": "Ajout de pièces",
          "external_sub_to_call": "Inscription externe après l'appel",
          "client_ref_nrs": "Numéro de référence du client",
          "job_applications": "Candidatures",
          "azg_subscriptions": "AzG enregistrements",
        },
        "example": {
          "title": "Explication",
          "downloadExampleButton": "Télécharger le fichier d'exemple",
          "coin_addition": "Le fichier CSV est composé de 2 colonnes : l'identifiant de l'employé et le nombre de pièces en nombres entiers. Voir également le fichier d'exemple avec 2 employés. La première ligne est pour l'employé avec l'id 1 qui reçoit 25 pièces supplémentaires. La deuxième ligne est pour l'employé 2 qui reçoit 13 pièces.",
          "external_sub_to_call": "Le fichier CSV comprend au moins 26 colonnes : external_id, status, employee_id, recruit_date, start_date, frequency, date_of_cancellation, date_of_birth, first_name, middle_name, last_name, gender, street, house_no, house_no_suffix, city, zipcode, country, email, mobile, landline, iban, product_id, campaign_id, amount, quantity, *custom. Voir l'exemple ci-dessous pour un fichier csv contenant un enregistrement. La personnalisation de la dernière colonne signifie que chaque colonne après la 21e colonne sera placée comme une phrase dans le champ de commentaire de l'appel d'offres.",
          "client_ref_nrs": "Le fichier CSV comporte 2 colonnes : numéro de référence et identifiant client.",
          "job_applications": "Le fichier CSV comporte 8 colonnes : creation date, first_name, initials, last_name, email, phone_number, referral_source, referral_source_other.",
          "azg_subscriptions": "The CSV file is unchanged format CSV form AzG. Columns which are processed are: id_werving, qual_id, opmerking, bedrag NIEUW, Frequentie NIEUW (columns number 7,9,16,18 respectively)."
        }
      },
      "status": {
        "label": "Statut",
        "options": {
          "uploaded": "Téléchargé sur",
          "processed": "Traité"
        }
      }
    }
  },
  "Appointment": {
    "toggleLegend": "légende",
    "field": {
      "id": {
        "label": "ID"
      },
      "status": {
        "label": "statut",
        "value": {
          "queued": "Après avoir appelé",
          "assigned": "Assigné",
          "reached": "Atteint",
          "not reachable": "Inaccessible",
          "not reached": "Non atteint"
        },
        "legend": {
          "carryThrough": "Continué sans changement",
          "carryThroughChanged": "Transmission modifiée",
          "phoneIncorrect": "Téléphone incorrect",
          "notAnswered": "Non inclus",
          "cancelled": "Annulé",
          "callBackSoonNoTime": "Rappel bientôt sans rendez-vous",
          "callBackSoon": "Rappelez-nous bientôt",
          "callBackNow": "Rappeler le rendez-vous maintenant",
          "callBackToday": "Rappeler le rendez-vous aujourd'hui",
          "callBackFuture": "Rappeler le rendez-vous un autre jour"
        }
      },
      "remarks": {
        "label": "Remarques",
        "value": {
          "": "",
          "changed": "Abonnement modifié",
          "carry through": "Soumettre l'abonnement",
          "canceled": "Abonnement annulé",
          "carry through later": "Abonnement plus tard",
          "call appointment": "Rendez-vous de rappel (R.D.R.) pris",
          "phonenumber not correct": "Numéro de téléphone correct / non du client",
          "not answered call back": "Pas enregistré, encore demain",
          "not answered call back soon": "Non enregistré, encore une fois dans cinq minutes",
          "not answered": "Non inclus, continuer pour l'exportation"
        }
      },
      "subscription": {
        "label": "Inscription"
      },
      "bucket": {
        "label": "Seau"
      },
      "callAt": {
        "label": "Appel à"
      },
      "caller": {
        "label": "Nabeller"
      }
    }
  },
  "shiftType": {
    "createButton": "Ajouter le type d'équipe",
    "edit": {
      "title": "Réglage du type de changement de vitesse"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "name": {
        "label": "Nom"
      },
      "description": {
        "label": "Description"
      },
      "isDefault": {
        "label": "Par défaut ?"
      },
      "baseWage": {
        "label": "Déplacement de la rémunération de base"
      }
    }
  },
  subscriptionReason: {
    createButton: 'Ajouter une raison',
    edit: {
      title: 'Modifier la raison',
    },
    field: {
      id: {
        label: 'ID',
      },
      code: {
        label: 'Code',
      },
      description: {
        label: 'Description',
      },
        type: {
            label: 'Type',
            value: {
                cancelled: 'Annulé',
                completed: 'Terminé',
                general: 'Général',
            },
        },
    }
  },
  "signedDocument": {
    "confirm": "Êtes-vous sûr de vouloir supprimer le fichier {{fileName}} ?",
    "notificationModel": "Votre document à Importé"
  },
  "shiftWageDefinition": {
    "edit": {
      "title": "Définition du salaire"
    },
    "modal": {
      "buttonTitle": "Ajout de règles salariales",
      "modalTitle": "Définition de la règle salariale"
    },
    "field": {
      "name": {
        "label": "Nom de la définition du salaire"
      },
      "startDate": {
        "label": "Date d'entrée en vigueur"
      }
    }
  },
  "shiftWageRule": {
    "field": {
      "age": {
        "label": "Âge"
      },
      "factor": {
        "label": "Facteur"
      }
    }
  },
  "jobFactor": {
    "field": {
      "startDate": {
        "label": "Date d'entrée en vigueur"
      },
      "factor": {
        "label": "Facteur"
      }
    }
  },
  "alphaWage": {
    "field": {
      "id": {
        "label": "ID"
      },
      "employee": {
        "label": "Membre du personnel"
      },
      "date": {
        "label": "Date"
      },
      "basePay": {
        "label": "Salaire de base"
      },
      "factorPay": {
        "label": "Bonus"
      },
      "creditAmount": {
        "label": "Crédits"
      },
      "finalPay": {
        "label": "Salaire de fin de carrière"
      }
    }
  },
  "alphaPeriodWage": {
    "field": {
      "id": {
        "label": "ID"
      },
      "employee": {
        "label": "Employé"
      },
      "startDate": {
        "label": " Période"
      },
      "amountEarned": {
        "label": "Salaire total"
      },
      factorWage: {
        label: "Prime"
      },
      baseWage: {
        label: "Salaire de base"
      },
      "incidentalExpenses": {
        "label": "Indemnité accessoire"
      },
    }
  },
  "contentbundleFolder": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Nom"
      }
    }
  },
  "contentbundleCondition": {
    "field": {
      "product": {
        "label": "Produit"
      },
      "metafield": {
        "label": "Metafield"
      },
      "metafieldValue": {
        "label": "Valeur du méta-champ"
      }
    }
  },
  "contentbundleHitbox": {
    "field": {
      "nextScreen": {
        "label": "Aller à l'écran d'enregistrement"
      },
      "slideshow": {
        "label": "Aller dans un autre dossier"
      },
      "link": {
        "label": "Aller sur un site web"
      },
      "product": {
        "label": "Mettre le produit suivant pour cet abonnement"
      },
      "metafield": {
        "label": "Sélectionnez un méta-champ pour préfixer une valeur"
      },
      "metafieldValue": {
        "label": "Définir la valeur de ce méta-champ"
      },
      standardField:{
        'label': "Champ standard à définir",
        value: {
          'spokenLanguage': "Langue"
        }

      },
      standardFieldValue:{
        label: "Valeur à définir pour le champ standard",
        spokenLanguageOptions: {
            value:{
            NL: 'Néerlandaise',
            FR: "française",
            DE: "Allemande",
            EN: "Anglaise"
          }
        }
    }
  }
  },
  bwMetafieldTranslation: {
    field: {
      bwField: {
        label: "Briggs and Walker champ"
      },
      standardField: {
        label: "Incenova champ"
      }
    },
  },
  employeeSettings: {
    nav: {
      label: "Réglages"
    },
    general: {
      settings: {
        label: "Réglages"
      }
    },
    options: {
      referralSource: {
        label: "La source"
      },
      whatsappStatus: {
        label: "Statut WhatsApp"
      },
      employeeReason: {
        label: "Raison de l'employé"
      },


    }
  },
  whatsappStatus: {
    create: {
      title: 'Ajouter le statut Whatsapp'
    },
    edit: {
      title: 'modifier le statut Whatsapp'
    },
    field: {
      id: {
        label: "ID"
      },
      title: {
        label: "Nom"
      },
    },
    filter: {
      "deleted": "Statuts Whatsapp supprimés",
      "onlyDeleted": "Supprimé uniquement",
      "withoutDeleted": "Sans supprimé",
      "bothDeletedAndNot": "Tous les deux"
    }
  },
  employeeReason: {
    create: {
      title: "Ajouter un motif d'employé"
    },
    edit: {
      title: "Modifier la raison de l'employé"
    },
    field: {
      id: {
        label: "ID"
      },
      name: {
        label: "Nom"
      },
      type: {
        label: "Le genre",
        value: {
          "rejection-company": "Rejet de l'entreprise",
          "rejection-applicant": "Rejet du candidat",
          general: "Général",
        }
      }
    },
    filter: {
      "deleted": "Raisons de l'employés supprimé",
      "onlyDeleted": "Supprimé uniquement",
      "withoutDeleted": "Sans supprimé",
      "bothDeletedAndNot": "Tous les deux"
    }
  },
  interview: {
    dropoutReasonModal: {
      title: 'Confirmer le rejet {{name}}',
      confirm: {
        rejected: 'Pour quelle raison {{name}} a-t-il été rejeté ?',
        'not-interested': "Pour quelle raison {{name}} n'est-il pas intéressé ?"
      }
    },
  },
  recruiterOverview: {
    week: "Semaine {{number}}",
    client: "Client",
    brutto: "Brutto",
    netto: "Netto",
    cancelled: "Annulé",
    registrationFee: "Frais d'inscription",
    baseSalary: "Salaire de base",
    bonus: "Bonus",
    total: "Total",
    price: "Prix",
    cancelReason: "Motif d'annulation",
    registrationDate:"Date d'inscription",
    processingDate: "Date de traitement",
    date: "Date",
    reason: "Motif",
    amount: "Montant",
    totalAmount: "Montant total",
    totalBase: 'Salaire de base',
    totalBonus: 'Bonus',
    creditations: "Créditations",
    incidentals: "Indemnité accessoire",
    subtotalNoVAT: "Sous-total hors TVA",
    vat: "TVA",
    subtotalWithVAT: "Sous-total avec TVA",
  }
}
