import { observable } from 'mobx';
import { Model, Store } from '../Base';
import { Question } from './Question';

export class Answer extends Model {
    static backendResourceName = 'answer';

    @observable id = null;
    @observable answer = '';
    @observable ordering = 0;

    relations () {
        return {
            question: Question,
        };
    }
}

export class AnswerStore extends Store {
    Model = Answer;
    static backendResourceName = 'answer';
}
