import {Model, Store} from '../Base';
import {observable} from "mobx";
import {WageStructure} from "./WageStructure";
import {Campaign} from "../Campaign";

export class ActiveWageStructure extends Model {
    static backendResourceName = 'active_wage_structure';

    @observable id = null;


    relations() {
        return {
            campaign: Campaign,
            wageStructure: WageStructure
        };
    }
}

export class ActiveWageStructureStore extends Store {
    Model = ActiveWageStructure;
    static backendResourceName = 'active_wage_structure';
}
