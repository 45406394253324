import {Casts, Model, Store} from '../Base';
import {observable} from "mobx";
import {WageComponentStore} from "./WageComponent";
import {EarningStore} from "./Earning";
import {ActiveWageStructureStore} from "./activeWageStructure";

export class WageStructure extends Model {
    static backendResourceName = 'wage_structure';

    @observable id = null;
    @observable startDate = null;
    @observable name = ''
    @observable draft = true


    casts() {
        return {
            startDate: Casts.date
        }
    }


    relations() {
        return {
            earnings: EarningStore,
            wageComponents: WageComponentStore,
            activeWageStructures: ActiveWageStructureStore
        };
    }
}

export class WageStructureStore extends Store {
    Model = WageStructure;
    static backendResourceName = 'wage_structure';
}
