import hn from './style/theme/hn.js';
import zsp from './style/theme/zsp.js';
import cy from './style/theme/cy.js';
import fym from './style/theme/fym.js';
import as from './style/theme/as.js'
import ceom from './style/theme/ceom.js'
import dr from './style/theme/dr.js';
import briggs from './style/theme/briggs.js'
import thnl from './style/theme/th-nl'
import upmarketing from './style/theme/upmarketing'
import uphillmarketing from './style/theme/uphillmarketing'

import faviconUphillmarketing from './image/favicon-uphill-new.ico'
import faviconUpmarketing from './image/favicon-upmarketing.ico';
import faviconHn from './image/favicon-hn.ico';
import faviconZsp from './image/favicon-zsp.ico';

// {theme-select-copy-pasta}
const parser = document.createElement('a');

// Compatibilty with http://localhost/hn.sms.test/
parser.href = window.location.href.replace('localhost', '');

const hostname = parser.hostname.split('.');
const subdomain = hostname.length === 4 ? hostname[1] : hostname[0];


export const theme = getTheme()

export default theme


export function getTheme() {
    switch (subdomain) {
        case 'hn':
            document.getElementById('favicon').setAttribute('href', faviconHn);

            // v1 compatibiltiy.
            document.getElementsByTagName('body')[0].setAttribute('class', subdomain);
            return hn;
        case 'zsp':
        case 'zetespee':
            document.getElementById('favicon').setAttribute('href', faviconZsp);

            // v1 compatibiltiy.
            // const a = document.getElementsByTagName('body')[0].setAttribute('class', subdomain);
            return zsp;
        case '4ym':
            //document.getElementById('favicon').setAttribute('href', faviconZsp);

            // v1 compatibiltiy.
            //const a = document.getElementsByTagName('body')[0].setAttribute('class', subdomain);
            return fym;
        case 'ceomarketing':
            document.getElementsByTagName('body')[0].setAttribute('class', 'ceomarketing');
            return ceom;
        case 'cy':
            return cy;
        case 'direct-result':
            document.getElementsByTagName('body')[0].setAttribute('class', 'dr');
            return dr;
        case 'dr-react':
        case 'react':
            document.getElementsByTagName('body')[0].setAttribute('class', 'dr');
            return dr;
        case 'briggsandwalker':
        case 'agency-test':
            document.getElementsByTagName('body')[0].setAttribute('class', 'briggs');
            return briggs;
        case 'th-nl':
            document.getElementsByTagName('body')[0].setAttribute('class', 'th-nl');
            return thnl;
        case 'alphasociety':
            document.getElementsByTagName('body')[0].setAttribute('class', 'alphasociety');
            return as;
        case 'upmarketing':
            document.getElementById('favicon').setAttribute('href', faviconUpmarketing);
            // v1 compatibiltiy.
            document.getElementsByTagName('body')[0].setAttribute('class', subdomain);
            return upmarketing;
        case 'uphill-marketing':
            document.getElementById('favicon').setAttribute('href', faviconUphillmarketing);
            // v1 compatibiltiy.
            document.getElementsByTagName('body')[0].setAttribute('class', subdomain);
            return uphillmarketing;
        default:
            return cy;
        // document.getElementsByTagName('body')[0].setAttribute('class', 'ceomarketing');
        // return ceom;
    }

};
