import { observable } from 'mobx';
import { Model, Store } from '../Base';
import { Question } from './Question';

export class Choice extends Model {
    static backendResourceName = 'choice';

    @observable id = null;
    @observable answer = '';
    @observable ordering = 0;

    relations () {
        return {
            question: Question,
        };
    }
}

export class ChoiceStore extends Store {
    Model = Choice;
    static backendResourceName = 'choice';
}
