import { CeoMarketing } from './customerCheck';
import { FEATURES } from 'helpers';

export function getMyResults(user, recruiterScoreOverview, resultsEmployee) {
    if (FEATURES.EXTENSIVE_WAGES_OVERVIEW) {
        return resultsEmployee;
    }

    if ((user.employee && user.employee.id != null) && CeoMarketing()) {
        return recruiterScoreOverview;
    }

    return null;
}

export function hasMyResults(user) {
    return getMyResults(user, 1, 2) !== null;
}

// passing viewstore since import is not working in frontend-v1
export function getDefaultPath(viewStore) {
    let defaultPath = "/result/team/overview"

     if ((!viewStore.currentUser.hasLowLevelPermission('allocation.view_team', ['branch', 'all']))) {
            if (viewStore.currentUser.clients.length > 0 ) {
                const dashboards = viewStore.currentUser.getClientMetabases();
                // if the client does not have a
                if (dashboards.length === 0) {
                    defaultPath = 'client/clientFile/overview';
                } else {
                    const id = dashboards[0].id;
                    defaultPath = `/dashboard/overview/${id}/view`;
                }
            } else {
                defaultPath = '/result/myResults/'
            }
        } else {
            if ((!viewStore.currentUser.hasLowLevelPermission('allocation.change_team', ['branch', 'all'])) && viewStore.currentUser.hasLowLevelPermission('subscription.view_subscription')) {
                defaultPath = "/result/subscription/overview"
            }
        }

    return defaultPath
}
