import { observable } from 'mobx';
import { Model, Store } from './Base';
import { MetafieldStore } from './Metafield';
import { Campaign } from './Campaign';
import { Contentbundle} from './Contentbundle';
import { CampaignStore } from './Campaign';
import { BranchStore } from './Branch';
import { EmployeeStore } from './Employee';

export class ContentbundleAgeWildcard extends Model {
    static backendResourceName = 'contentbundle_age_wildcard';

    @observable id = null;
    @observable name = '';
    @observable minAge = null;
    @observable maxAge = null;
    @observable deleted = null;
    @observable default = false;
    @observable renewEvery = null;

    relations() {
        return {
            metafields: MetafieldStore,
            campaign: Campaign,
            contentbundle: Contentbundle,
            campaigns: CampaignStore,
            branches: BranchStore,
            employees: EmployeeStore,

        };
    }
}

export class ContentbundleAgeWildcardStore extends Store {
    Model = ContentbundleAgeWildcard;

    static backendResourceName = 'contentbundle_age_wildcard';
}

