// Hacky helper to ease working with notifications.
let viewStore = null;

export function configureNotification(givenViewStore) {
    viewStore = givenViewStore;
}

export function showSaveNotification() {
    viewStore.showSaveNotification();
}

export function showDeleteNotification() {
    viewStore.showDeleteNotification();
}

export function showNotification(props) {
    if (typeof props === 'string') {
        viewStore.showNotification({ message: props });
    } else {
        viewStore.showNotification(props);
    }
}
export function showErrorNotification(message) {
    return viewStore.showNotification({
        error: true,
        message,
    });
}
