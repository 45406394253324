import { observable } from 'mobx';
import { Model, Store } from './Base';
import { Metafield } from './Metafield';
import { Product, DisplayConditionStore } from './Product';
import { isEqual, every} from 'lodash';
import moment from 'moment'

export class ProductMetafield extends Model {
    static backendResourceName = 'productmetafield';

    @observable id = null;
    @observable isPreset = false;
    @observable ordering = 0;

    relations() {
        return {
            metafield: Metafield,
            product: Product,
            conditions: DisplayConditionStore,
        };
    }

    comparison_operation_options = {
        '>': function(a, b) { return a > b; },
        '<': function(a, b) { return a < b; },
        '=': function(a, b) { return a === b; }
    };

    shouldShow(metafieldValue, subscriber) {
        const shouldShow = every(this.conditions.models, (dc) => {

            const displayConditionsValue = dc.value;

            if (dc.type === 'metafield') {
                return isEqual(metafieldValue.toJS(), displayConditionsValue.toJS());
            } else {
                const standardField = dc.standardField
                if (standardField === 'age') {
                    if (subscriber.dateOfBirth === null) {
                        return false
                    } else {
                        const age = moment().diff(subscriber.dateOfBirth, 'years')
                        return this.comparison_operation_options[dc.comparisonMethod](age, displayConditionsValue)
                    }
                }
            }
        });

        return !(!shouldShow && this.conditions.length > 0);
    }
}

export class ProductMetafieldStore extends Store {
    static backendResourceName = 'productmetafield';

    Model = ProductMetafield;
    comparator = 'ordering';
}
