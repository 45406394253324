import { observable } from 'mobx';
import { Model, Store } from '../Base';
import { QuestionStore } from './Question';
import { t } from 'i18n'
import {Traineeship} from '../Traineeship';

export const TYPE_TEXT = 'text';
export const TYPE_QUESTIONS = 'questions';
export const TYPE_VIDEO = 'video';
export const TYPE_END_EVALUATION = 'end-evaluation';
export const TYPES = [TYPE_TEXT, TYPE_QUESTIONS, TYPE_VIDEO, TYPE_END_EVALUATION];

export const PAGE_OPTIONS = [
    {
        value: TYPE_TEXT,
        text: t(`traineeshipPage.field.type.value.${TYPE_TEXT}`)
    },
    {
        value: TYPE_QUESTIONS,
        text: t(`traineeshipPage.field.type.value.${TYPE_QUESTIONS}`)
    },
    {
        value: TYPE_VIDEO,
        text: t(`traineeshipPage.field.type.value.${TYPE_VIDEO}`)
    },
]

export class Page extends Model {
    static backendResourceName = 'page';

    @observable id = null;
    @observable text = null;
    @observable type = TYPE_TEXT;
    @observable video = null;
    @observable ordering = 0;
    
    fileFields() {
        return ['video'];
    }

    relations() {
        return {
            questions: QuestionStore,
            traineeship: Traineeship
        };
    }
}

export class PageStore extends Store {
    Model = Page;
    static backendResourceName = 'page';
}
