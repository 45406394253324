import DrTextLogo from '../../image/logo/upmarketing.jpg'
import CYLogo from "../../image/logo/cy.png";
import Logo from '../../image/logo/upmarketing.jpg'
import logoSidebar from '../../image/logo/upmarketing.jpg'
import LoginBackground from '../../image/login-up.png'


// export const COLOR_PRIMARY = '#1E1F60'
// export const COLOR_SECONDARY = '#304FFE'
// export const COLOR_THIRD = '#2E2E3B';

export const COLOR_PRIMARY = '#1586FE';
export const COLOR_SECONDARY = '#1586FE';
export const COLOR_THIRD = '#15e7fe';
export const COLOR_TEXT = 'rgba(255, 255, 255, 0.9)';
export const TAB_TITLE = "Up Marketing";


// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export default {
    primaryColor: COLOR_PRIMARY,
    secondaryColor: COLOR_SECONDARY,
    thirdColor: COLOR_THIRD,
    buttonPrimaryColor: COLOR_PRIMARY,
    buttonPrimaryTextColor: '#fff',
    textColor: COLOR_TEXT,
    COLOR_TEXT: COLOR_TEXT,
    cyLogo: CYLogo,
    textLogo: DrTextLogo,
    logo: Logo,
    sidebarLogo:logoSidebar,
    loginBackground: LoginBackground,
    loginBackgroundColor: COLOR_PRIMARY,
    sidebar: false,
    tabTitlePrefix: TAB_TITLE,
    lightColor: '#F5F5F5'
}
