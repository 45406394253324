import { NavItem as NavItemBase } from 'spider/component/Navigation';

export * from 'spider/component/Navigation';

export class NavItem extends NavItemBase {
    // Remove special case for root url "/".
    stackVersionFromUrl(url) {
        return url.includes('#') ? 'v1' : 'v2';
    }
}
