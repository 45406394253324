import { observable } from 'mobx';
import { Model, Store } from '../Base';
import { ChoiceStore } from './Choice';
import { Page } from './Page'
import { t } from 'i18n' 

export const TYPE_INPUT = 'input';
export const TYPE_CHOICE = 'choice';
export const TYPES = [TYPE_INPUT, TYPE_CHOICE];

export const QUESTIONTYPE_OPTIONS = [
    {
        value: TYPE_INPUT,
        text: t(`traineeshipQuestion.field.type.value.${TYPE_INPUT}`)
    },
    {
        value: TYPE_CHOICE,
        text: t(`traineeshipQuestion.field.type.value.${TYPE_CHOICE}`)
    },
]

export class Question extends Model {
    static backendResourceName = 'question';

    @observable id = null;
    @observable question = '';
    @observable type = TYPE_INPUT;
    @observable ordering = 0;

    relations() {
        return {
            choices: ChoiceStore,
            page: Page
        };
    }
}

export class QuestionStore extends Store {
    Model = Question;
    static backendResourceName = 'question';
}
