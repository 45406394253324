import { observable } from 'mobx';
import { Model, Store } from '../Base';
import { FileDefinitionStore } from './FileDefinition';
import { Client } from '../Client';

export class FileBundle extends Model {
    static backendResourceName = 'file_bundle';

    @observable id = null;
    @observable description = '';

    relations() {
        return {
            fileDefinitions: FileDefinitionStore,
            client: Client,
        };
    }
}

export class FileBundleStore extends Store {
    Model = FileBundle;
    static backendResourceName = 'file_bundle';
}
