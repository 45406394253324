import { observable } from 'mobx';
import { Casts, Model, Store } from './Base';

export class ReferralSource extends Model {
    static backendResourceName = 'referral_source';

    @observable id = null;
    @observable name = '';
    @observable isDefault = false;
    @observable isOther = false;

    @observable createdAt = null;
    @observable updatedAt = null;
    @observable deleted = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,

        };
    }
}

export class ReferralSourceStore extends Store {
    static backendResourceName = 'referral_source';

    Model = ReferralSource;
}
