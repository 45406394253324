import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavItem } from 'spider/component/Navigation';
import styled from 'styled-components';
import { observer } from 'mobx-react';

const MasqueradedNavItem = styled(NavItem)`
    // Need the important so that v1 plays along.
    color: red !important;
`;

@observer
export default class User extends Component {
    static propTypes = {
        viewStore: PropTypes.object.isRequired,
        currentUserName: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        activePath: PropTypes.string,
        show: PropTypes.bool,
    };

    render() {
        const { currentUserName, viewStore, to, activePath, show } = this.props;
        const accountTitle = `${currentUserName}`;

        if (viewStore.isMasqueraded === false) {
            return (
                <NavItem
                    title={accountTitle}
                    to={to}
                    activePath={activePath}
                    show={show}
                />
            );
        } else {
            return (
                <MasqueradedNavItem
                    title={accountTitle}
                    to={to}
                    activePath={activePath}
                    show={show}
                />
            );
        }
    }
}

