import { computed, observable, action } from 'mobx';
import { Model, Store } from './Base';
import { Casts } from 'mobx-spine';
import { User } from './User';
import { Branch, BranchStore } from './Branch';
import { BwEmployeeStore } from './BwEmployee';
import { ClientStore } from './Client';
import { ReferralSource } from './ReferralSource';
import { camelToSnake } from 'spider/helpers';
import { AssignmentStore } from './Traineeship/Assignment';
import { PositionStore } from './Position';
import { CallStore } from "./Call";
import { BucketStore } from "./Bucket";
import { AppointmentStore } from "./Appointment";
import { PeriodEarningStore } from "./PeriodEarning";
import { ImportedCreditationCsvStore } from "./ImportedCreditationCsv";
import { TeamMemberStore } from "./TeamMember";
import { Factor, FactorStore } from "./Factor";
import { EarningStore } from "./Wages/Earning";
import { MetabaseStore } from "./Metabase";
import { IncedentalExpenseStore } from "./IncedentalExpense";

export class Employee extends Model {
    static backendResourceName = 'employee';

    @observable id = null;
    @observable status = 'active';
    @observable initials = '';
    @observable firstName = '';
    @observable middleName = '';
    @observable lastName = '';
    @observable interviewer = false;
    @observable gender = '';
    @observable dateOfBirth = null;
    @observable email = '';
    @observable street = '';
    @observable zipCode = '';
    @observable houseNumber = '';
    @observable ssn = '';
    @observable houseNumberSuffix = '';
    @observable city = '';
    @observable iban = '';
    @observable bic = '';
    @observable bsn = '';
    @observable country = '';
    @observable countryOfBirth = '';
    @observable nationalityInternal = '';
    @observable cityOfBirth = '';
    @observable dateStarted = null;
    @observable dateEnded = null;
    @observable phoneNumbers = [];
    @observable employeeNumber = '';
    @observable drivingLicence = false;
    @observable taxExemption = false;
    @observable canChangeTaxExemption = false;
    @observable idType = null;
    @observable idNumber = null;
    @observable lastLogIn = null;
    @observable lastSignIn = null;
    @observable avatar = null;
    @observable drivingLicencePicture = null;
    @observable bankCard = null;
    @observable contractSignature = null;
    @observable identification = null;
    @observable identificationBack = null;
    @observable referralSourceOther = '';
    @observable coins = 0; // Cents
    @observable createdAt = null;
    @observable isFreelance = false;
    @observable vatNumber = '';
    @observable cocNumber = '';
    @observable companyName = '';
    @observable canSelectOwnCampaign = false;
    @observable points = 0;
    @observable compensationAmount = 0;
    @observable canCall = false;
    @observable rexuserId = 0;
    @observable publicTransportStudentLicense = false;
    @observable publicTransportStudentLicenseType = 'no';
    @observable travelingDistance = null;
    @observable anonymized = false;

    @observable legalGuardianName = '';
    @observable legalGuardianPhone = '';
    @observable legalGuardianEmail = '';

    @observable externalContractSigned = false;

    @observable deleted = false;
    @observable currentJobId = -1;

    @observable contractSignatureChecked = false;
    @observable identificationChecked = false;
    @observable identificationBackChecked = false;
    @observable avatarChecked = false;
    @observable bankCardChecked = false;
    @observable requiredDocumentsSigned = true;

    relations() {
        return {
            user: User,
            mainBranch: Branch,
            branches: BranchStore,
            bwIds: BwEmployeeStore,
            blockedClients: ClientStore,
            referralSource: ReferralSource,
            assignments: AssignmentStore,
            positions: PositionStore,
            calls: CallStore,
            appointments: AppointmentStore,
            callbuckets: BucketStore,
            freelanceEarnings: PeriodEarningStore,
            csvsImported: ImportedCreditationCsvStore,
            teamMembers: TeamMemberStore,
            factors: FactorStore,
            currentFactor: Factor,
            earnings: EarningStore,
            metabases: MetabaseStore,
            incedentalExpense: IncedentalExpenseStore,
        };
    }

    casts() {
        return {
            dateOfBirth: Casts.date,
            dateStarted: Casts.date,
            dateEnded: Casts.date,
            lastLogIn: Casts.datetime,
            lastSignIn: Casts.datetime
        };
    }

    @computed
    get humanReadableCoins() {
        return +(this.coins / 100).toFixed(2);  // T23251: Round to at most 2 decimal places (only if necessary)
    }

    @computed
    get name() {
        return `${this.firstName} ${this.middleName} ${this.lastName}`.replace(
            /\s\s+/g,
            ' '
        );
    }

    @action
    fetchCountries() {
        return this.api.get('/employee/country/').then(res => {
            return res.data;
        });
    }

    addCoins(reward) {
        this.__pendingRequestCount += 1;

        const p = this.api.post('coin_action/assign/', {
            reward: parseInt(reward),
            employees: [this.id],
        });

        p.catch(() => {
        }).then(() => {
            this.__pendingRequestCount -= 1;
        });

        return p;
    }

    sendActivationEmail() {
        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'send_activition_email/')
        );
    }

    clearAssignments() {
        return this.wrapPendingRequestCount(
            this.api.post(this.url + 'clear_assignments/')
        );
    }

    saveAttachment(attachment, image) {
        const backendFileField = camelToSnake(attachment);
        const fileField = `_${attachment}`;
        this[fileField] = image;

        const data = new FormData();
        data.append(attachment, image, image.name);

        const headers = { 'Content-Type': 'multipart/form-data' };

        return this.wrapPendingRequestCount(this.api
            .post(`${this.url}${camelToSnake(attachment)}/`, data, { headers })
            .then(response => {
                this[attachment] = response.data[backendFileField];
            })
        );
    }
}

export class EmployeeStore extends Store {
    Model = Employee;
    static backendResourceName = 'employee';
}
