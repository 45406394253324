import { observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import { Device } from './Device';
import moment from 'moment';

export class DeviceLocation extends Model {
    static backendResourceName = 'device_location';

    @observable id = null;
    @observable lat = null;
    @observable lng = null;
    @observable moment = moment();
    @observable _address = '';

    relations() {
        return {
            device: Device,
        };
    }

    casts() {
        return {
            moment: Casts.datetime,
        };
    }

    reverseGeocode() {
        this.api
            .get(`/reverse_geocode/`, {
                lat: this.lat,
                lng: this.lng,
            })
            .then(res => {
                if (res.status === 'OK' && res.results.length > 0) {
                    this._address = res.results[0].formatted_address;
                } else {
                    this._address = '';
                }
            });
    }
}

export class DeviceLocationStore extends Store {
    Model = DeviceLocation;
    static backendResourceName = 'device_location';
}
