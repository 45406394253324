import { observable } from 'mobx';
import { Model, Store, Casts } from './Base';
import { ShiftType } from './ShiftType';
import { ShiftWageRuleStore } from './ShiftWageRule';

export class ShiftWageDefinition extends Model {
    static backendResourceName = 'shift_wage_definition';

    @observable id = null;
    @observable name = '';
    @observable startDate = null;
    @observable createdAt = null;
    @observable updatedAt = null;

    relations() {
        return {
            shiftType: ShiftType,
            rules: ShiftWageRuleStore
        };
    }
    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
            startDate: Casts.date,
        };
    }
}

export class ShiftWageDefinitionStore extends Store {
    static backendResourceName = 'shift_wage_definition';

    Model = ShiftWageDefinition;
}

