import { observable } from 'mobx';
import { Casts, Model, Store } from './Base';
import {Subscription} from './Subscription';
import {Client} from './Client';
import {Csv} from './Csv';

export class ClientRefNr extends Model {
    static backendResourceName = 'client_ref_nr';

    @observable id = null;
    @observable referenceNumber = '';
    @observable createdAt = '';
    @observable updatedAt = '';
    @observable deleted = false;;

    relations() {
        return {
            client : Client,
            subscription : Subscription,
            fromCsv : Csv
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }
}

export class ClientRefNrStore extends Store {
    Model = ClientRefNr;
    static backendResourceName = 'client_ref_nr';
}
