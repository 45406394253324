export default {
  "tariffDocument": {
    "edit": {
      "title": "Tariff document"
    },
    "field": {
      "name": {
        "label": "Name"
      },
      "description": {
        "label": "Description"
      },
      "file": {
        "label": "File"
      }
    }
  },
  "nav": {
    "prompt": {
      "leave": "You didn't save the changes to the bucket of campaign {{bucket}}, are you sure you want to leave the page?"
    },
    "dashboards": {
      "overview": "Dashboards"
    },
    "contentbundle": {
      "overview": "Content bundles"
    },
    "dpn": {
      "overview": "DPN",
      "subnav": {
        "location": "Location Manager",
        "backoffice": "BackOffice",
        "frontend": "Frontend"
      }
    },
    "office": {
      "overview": "Office"
    },
    "product": {
      "overview": "Products"
    },
    "client": {
      "overview": "Customers",
      "subnav": {
        "client": "Customers",
        "campaigne": "Campaigns",
        "bwCampaigne": "BW Campaigns",
        "contentbundle": "Registration modules",
        "products": "Products",
        "metafields": "Metafields",
        "sequences": "Sequences",
        "wagesTariff": "Wages tariff",
        "bwProduct": "BW Products",
        "bwfailures": "BW import errors",
        "clientFile": "Customer files",
        "clientRefNr": "Customer reference numbers"
      }
    },
    "data": {
      "overview": "Organization",
      "subnav": {
        "user": "Users",
        "location": "Branches",
        "bwLocation": "BW Branches",
        "groups": "Permission groups",
        "employees": "Employees",
        "job": "Functions",
        "devices": "Devices",
        "trainship": "Traineeships",
        "metabase": "Metabases",
        "contract": "Contract",
        "tips": "Tips",
        "shop": "Shop",
        "handbook": "Handbook",
        "privacy": "Privacy statement",
        "videos": "Videos",
        "dashboard": "Promoter app dashboard",
        "coins": "Coins",
        "documents": "Documents",
        "news": "News",
        "milestone": "Milestones",
        "promotorIdea": "Promoter ideas",
        "promotorAppMedia": "Promoter app media",
        "csv": "CSV",
        "expenses": "Costs",
        "shiftType": "Shift type",
        "alphaWages": "Wages alpha",
        "subscriptionReason": "Subscription reasons",
      }
    },
    "result": {
      "overview": "Results",
      "subnav": {
        "callPlanning": "Calling planning",
        "teams": "Teams",
        "data": "Enter",
        "call": "Call",
        "callPlan": "Calling planning",
        "overview": "Overview",
        "export": "Export",
        "exportArchive": "Export archive",
        "stats": "Stats",
        "dashboard": " Dashboard",
        "purge": "Purge",
        "iban": "Iban errors",
        "myResults": "My results",
        "creditationImporter": "Creditation importer"
      }
    },
    "personal": {
      "overview": "My staff"
    },
    "promoterApp": {
      "overview": "Promoter app"
    },
    "tablets": {
      "overview": "Tablets",
      "tariff": {
        "overview": "Tariff"
      },
      "map": "Map"
    },
    "wages": {
      "export": "Export",
      "freelance": "Freelance wages",
      "employee": "Employee wages",
      "approval": "Approval",
      "overview": "Wages",
      "incidental": "Incidental"
    },
    "account": {
      "label": "Account",
      "password": "Password",
      "details": "Details"
    }
  },
  "metabase": {
    "createButton": "Add metabase",
    "filter": {
      "both": "Both",
      "yes": "Yes",
      "no": "No"
    },
    "field": {
      "acties": {
        "label": "Actions"
      },
      "groups": {
        "label": "Permission groups"
      },
      "sidebarEnabled": {
        "label": "Sidebar active"
      },
      "enabled": {
        "label": "Active"
      },
      "sequenceNumber": {
        "label": "Navigation bar sequence",
        "info": "At which location relative to other metabases the link to this metabase should appear in the appheader, a lower number means that the metabase appears more to the left in the computer version and at the top of the mobile version."
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "dashboardId": {
        "label": "Dashboard ID",
        "info": "The metabase dashboard id, this id number can be found in the url of your dashboard on metabase. e.g. metabase-url.com/dashboard/ID"
      },
      "updatedAt": {
        "label": "Last update"
      },
      "employees": {
        "label": "Employees"
      },
      "clients": {
        "label": "Customers"
      },
      "params": {
        "label": "Parameters",
        "info": "The parameters that are sent to the metabase dashboard, the following names should be used for the parameter in the dashboard:\n employee, \n main_branch, \n branches,\n all_branches, \n client",
        "value": {
          "employee": "Employee",
          "mainBranch": "Main branch",
          "branches": "Branches",
          "all_branches": "All branches",
          "client": "Client"
        }
      }
    }
  },
  "form": {
    "startDate": "Start Date",
    "endDate": "End Date",
    "restoreButton": "Restore",
    "no": "No",
    "yes": "Yes",
    "either": "Both",
    "search": "Search",
    "jsonButton": "Import/Export",
    "historyButton": "History",
    "saveAndBackButton": "Save & Back",
    "saveAddAnotherButton": "Save & New",
    "saveAndDownloadButton": "Save & Download",
    "copyButton": "Copy",
    "syncButton": "Sync",
    "downloadButton": "Download",
    "cancelButton": "Cancel",
    "backButton": "Back",
    "saveAndHireButton": "Save and accept",
    "saveAndRejectButton": "Save and reject",
    "saveAndHireAndConvertButton": "Save, accept and convert to employee",
    "acceptButton": "Accept",
    "rejectButton": "Refuse",
    "pendingButton": "In anticipation",
    "reExportButton": "Re-export",
    "dateRangePlaceholder": "Select...",
    "datePlaceholder": "Date and time",
    "autoFillButton": "Autofill",
    "fileType": {
      "image": "Picture",
      "any": "",
      "none": "Empty"
    },
    "reconnectButton": "Connect to the call server",
    "validation": {
      "error": "Validation errors have been found"
    },
    "check": "Controlled",
    "addButton": "Add",
    "multiPick": {
      "searchPlaceholder": "Select...",
      "selectedText": "$1 from the $2 selected",
      "noneSelectedText": "None selected",
      "selectAllButton": "Anything",
      "selectNoneButton": "Nothing"
    },
    "importButton": "Import",
    "saveButton": "Save",
    "undoRejectionButton": "Undo rejection",
    "undoHireButton": "Undo hiring",
    "closeButton": "Close",
    "saveAndNewButton": "Save & new",
    "saveLoadingButton": "Saving...",
    "sendButton": "Send",
    "editButton": "Adjust",
    "viewButton": "View",
    "actions": "Actions",
    "deleteButton": "Remove",
    "searchLabel": "Search",
    "applyButton": "Apply",
    "selectPlaceholder": "Select...",
    "deleteConfirmation": "Are you sure you want to delete {{name}}?",
    "restoreConfirmation": "Are you sure you want to restore {{name}}?",
    "typeToSearchText": "Type to search...",
    "copyToClipboardTooltip": "Copy to clipboard",
    "notifications": {
      "saveSuccess": "Saved successfully",
      "deleteSuccess": "Deleted successfully",
      "restoreSuccess": "Restored successfully",
      "saveError": "Error with saving ({{status}})",
      "saveValError": "Not all information is filled in correctly",
      "saveAuthError": "Invalid credentials, check your permissions or try logging in again",
      "newAppVersion": "New version available, click to reload page as soon as possible",
      "blockedCustomer":"Customer is blocked for some employee",
    },
    "validationErrors": {
      "blank": "Field is required",
      "null": "Field is required",
      "max_digits": "Maximum field value exceeded",
      "max_whole_digits": "Maximum field value exceeded",
      "max_value": "Maximum value exceeded",
      "invalid": "Invalid value",
      "reset_code_invalid": "Invalid reset code",
      "password_too_common": "Password is too common",
      "invalid_token": "Invalid token",
      "unique": "Field is not unique",
      "unique_together": "Field is not unique",
      "unknown": "Unknown error in field",
      "date_mismatch": "End date may not lie before start date",
      "routing_error": "Could not calculate route between this activity and previous one",
      "geo": {
        "failed-error": "Unknown error with finding location",
        "failed-no-results": "Could not find location",
        "failed-multiple-results": "Found too many locations",
        "failed-no-housenumber": "Did not find specific house number",
        "not_precise": "Found location, but not precise"
      },
      "invalid_file_type": "Incorrect document file type. Accepted file types: {{types}}."
    },
    "modalConfirmDelete": {
      "title": "Hard delete",
      "content": "You are about to delete this item. Are you sure?"
    },
    "draftJs": {
      "style": {
        "BOLD": {
          "label": "Bold"
        },
        "CODE": {
          "label": "Monospace"
        },
        "ITALIC": {
          "label": "Italic"
        },
        "UNDERLINE": {
          "label": "Underline"
        },
        "blockquote": {
          "label": "Blockquote"
        },
        "code-block": {
          "label": "Code Block"
        },
        "header-five": {
          "label": "H5"
        },
        "header-four": {
          "label": "H4"
        },
        "header-one": {
          "label": "H1"
        },
        "header-six": {
          "label": "H6"
        },
        "header-three": {
          "label": "H3"
        },
        "header-two": {
          "label": "H2"
        },
        "ordered-list-item": {
          "label": "OL"
        },
        "unordered-list-item": {
          "label": "UL"
        }
      }
    }
  },
  "purge": {
    "id": "Id",
    "name": "Name",
    "subscription_count": "Subscription Count",
    "created_at": "Created At",
    "permenantly_deleted_at": "Permenantly Deleted At",
    "download": "Download",
    "permanently_deleted_at": "Permanently Deleted At"
  },
  "earningPeriod": {
    "modal": {
      "confirmExport": "This is a definitive export and will freeze the wages of the period with start date {{date}}, are you sure?"
    },
    "filter": {
      "yes": "Yes",
      "no": "No",
      "both": "Both"
    },
    "field": {
      "frozen": {
        "label": "Wages frozen"
      },
      "Acties": {
        "label": "Actions"
      },
      "id": {
        "label": "ID"
      },
      "endDate": {
        "label": "End date period"
      },
      "amount": {
        "label": "Total payment period"
      },
      "startDate": {
        "label": "Start date periode"
      }
    }
  },
  "earning": {
    "inspection": {
      "dropOutReasons": "{{amount}} outage reasons",
      "creditReasons": "{{amount}} credit reasons",
      "earned": "Earned",
      "bruttoDeclarations": "Gross declarations",
      "netDeclarations": "Net declarations",
      "donors": "Donors",
      "scores": "Scores",
      "max": "Max",
      "average": "Average donation",
      "enrollmentResults": "Subscription results",
      "week": "Week",
      "date": "Date",
      "client": "Customer & campaign",
      "bruto": "Gross",
      "attrition": "Attrition",
      "net": "Net",
      "total": "Total",
      "credit": "Credits",
      "incidental": "Incidental expenses",
      "reason": "Reason",
      "type": "Type",
      "amount": "Amount",
      "bonus": "Bonus"
    },
    "invoice": {
      "validDate": {
        "label": "Expiration date: : "
      },
      "approveEmployee": {
        "label": "Periode is correct"
      }
    },
    "week": {
      "label": "Week"
    },
    "weekPeriod": {
      "label": "Week {startWeekNumber} - %{endWeekNumber}"
    },
    "sync": "Recalculate earnings this period now",
    "syncRunning": "Wages are recalculated, refresh the page in a few minutes",
    "filter": {
      "internalSignatureSigned": "Contract signed internal system",
      "externalSignatureSigned": "Contract signed external system"
    },
    "field": {
      "Acties": {
        "label": "Actions"
      },
      "id": {
        "label": "ID"
      },
      "employeeId": {
        "label": "Employee ID"
      },
      "amount": {
        "label": "Wages"
      },
      "contractSigned": {
        "label": "Contract signed"
      },
      "totalEarned": {
        "label": "Total price"
      },
      "creditations": {
        "label": "Credits"
      },
      "datetimeApprovedByEmployee": {
        "label": "Approved by employee"
      },
      "withheldEarningAmount": {
        "label": "Retained"
      },
      "datetimeApprovedByCompany": {
        "label": "Approved by company"
      },
      "nettoSubscriptionAmount": {
        "label": "Net amount"
      },
      "sumSubscriptionPrices": {
        "label": "Subscription amount"
      },
      "currentEmployeeFactor": {
        "label": "Factor (current)"
      },
      "sumCreditationPrices": {
        "label": "Credit (Subscription) bedrag"
      },
      "incidentalWages": {
        "label": "Incidental compensation"
      },
      "earningWithoutVat": {
        "label": "Total excl. VAT"
      },
      "earning": {
        "label": "Amount earned"
      },
      "updatedAt": {
        "label": "Last change"
      },
      "startedAt": {
        "label": "Period"
      },
      "startDate": {
        "label": "Start day period"
      }
    }
  },
  "coin": {
    "field": {
      "id": "Id",
      "total": "Total",
      "coin": {
        "slug": "Slug",
        "task": "Coin task"
      },
      "employee": {
        "id": "id",
        "name": "Staff member",
        "coins": "Earned coins",
        "points": "Earned points",
        "createdAt": "Created At"
      },
      "performedAt": {
        "label": "Earned on"
      }
    }
  },
  "product": {
    "deleteTooltip": "Remove product",
    "restoreTooltip": "Product Recovery",
    "viewTooltip": "View product",
    "editTooltip": "Edit product",
    "copyTooltip": "Copy a product",
    "noBriggsAndWalkerConnected": "No IDs linked",
    "nav": {
      "overview": "Products"
    },
    "page": {
      "overview": {
        "title": "Products"
      }
    },
    "createButton": "Add product",
    "save": {
      "error": "Could not save product!",
      "success": "Product is stored"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "factor": {
        "label": "Factor"
      },
      "icon": {
        "label": "Icon",
        "helpText": "Drag an image or click here to upload."
      },
      "blockers": {
        "label": "Blockers",
        "placeholder": "Blockers...",
        "selectedText": "$1 of $2 blockers",
        "noneSelectedText": "Select blocker",
        "checkAll": "Check all",
        "uncheckAll": "Uncheck all"
      },
      "metafields": {
        "label": "Metafields",
        "placeholder": "Metafields...",
        "selectedText": "$1 of $2 metafields",
        "noneSelectedText": "Select metafield",
        "checkAll": "Check all",
        "uncheckAll": "Uncheck all"
      },
      "includeScanFiles": {
        "label": "Include scans from tablet in welcome mail"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "min_age": {
        "label": "Required minimum age",
        "placeholder": "Minimum age..."
      },
      "client": {
        "label": "Customer",
        "placeholder": "Customer..."
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "donation": "Donation",
        "subscription": "Subscription",
        "contract": "Contract"
      },
      "welcomeEmailTemplate": {
        "label": "Welcome email template",
        "placeholder": "Welcome email template...",
        "help": "The template ID of a mailjet transactional template"
      },
      "textVerificationMessage": {
        "label": "Verification message",
        "placeholder": "Verification message...",
        "help": "Verification message which will be sent by sms message. Example:\n Your unique SMS verification code is {code}"
      },
      "paymentInterval": {
        "label": "Frequency",
        "info": "Frequency info",
        "value": {
          "null": "One-time",
          "P1D": "Daily",
          "P1W": "Weekly",
          "P1M": "Monthly",
          "P3M": "Every Quarter",
          "P6M": "Half yearly",
          "P1Y": "Annual"
        }
      },
      "paymentIntervalEnabled": {
        "label": "Specific payment frequency"
      },
      "max_start_date_delta": {
        "label": "Max. later start date",
        "placeholder": "e.g. for 2 months: P2M",
        "error": {
          "incorrect": "Format is not correct"
        }
      },
      "bw_id": {
        "label": "Briggs & Walker clutch",
        "placeholder": "Briggs and walker id..."
      },
      "welcomeSmsEnabled":{
        label: 'Use a welcome sms'
      },
      "welcomeSmsText":{
        label: 'Welcome sms text'
      },
      "welcomeSmsSender":{
        label: 'Welcome sms sender'
      },
    },
    "create": {
      "title": "Create product"
    },
    "edit": {
      "title": "Customize product",
      "basicTitle": "Basic data",
      "blockerTitle": "Blockers",
      "metafieldTitle": "Metafields"
    }
  },
  "blocker": {
    "field": {
      "id": {
        "label": "ID"
      },
      "issue_model": {
        "label": "Model",
        "placeholder": "Model..."
      },
      "issue_reason": {
        "label": "Reason",
        "placeholder": "Reason..."
      },
      "is_export_blocking": {
        "label": "Export blocking?",
        "placeholder": "Export blocking..."
      }
    }
  },
  "fileDefinition": {
    "save": {
      "error": "Couldn't save definition!",
      "success": "Definition is stored"
    },
    "import": {
      "error": "Couldn't import definition!",
      "success": "Definition is imported"
    },
    "choice_operator": {
      "exact": "equals",
      "exact_not": "is not equal to",
      "lt": "is less than",
      "lte": "is less than / equal to",
      "gt": "is greater than",
      "gte": "is greater than / equal to"
    },
    "group_combinator": {
      "and": "and",
      "or": "or"
    },
    "convertCase": {
      "uppercase": "Capital letters",
      "lowercase": "Lower case",
      "titlecase": "Capital letter per word"
    },
    "location": {
      "left": "left",
      "right": "right",
      "both": "Both",
      "everywhere": "Anywhere"
    },
    "repeat": {
      "label": "Repeat",
      "no": "No",
      "yes": "Yes"
    },
    "jsonModal": {
      "title": "Edit definition",
      "instructions": "The text below can be copied and pasted into another export. By clicking on import this will be applied. Afterwards you can save the new export."
    },
    "overview": {
      "title": "File definitions"
    },
    "field": {
      "format": {
        "label": "Format"
      },
      "description": {
        "label": "Description"
      }
    },
    "edit": {
      "title": "Edit file definition"
    }
  },
  "filterOption": {
    "save": {
      "error": "Could not save filter!",
      "success": "Filter is saved"
    }
  },
  "hardDelete": {
    "header": "Do you really want to remove this?",
    "content": "This action cannot be undone!",
    "confirm": "Yes",
    "cancel": "No"
  },
  "subscription": {
    "edit": {
      "productInformation": "Product information",
      "contactInformation": "Contact information",
      "metafields": "Metafields",
      "cancelations": "Cancellations",
      "enrollment": "Enrollment",
      "others": "Diversen",
      "recruitInformation": "Recruitment information",
      "remarks": "Remarks",
      "personalInformation": "Personal information",
      "addressInformation": "Address information",
      "financialInformation": "Financial information",
      "subscriptionId": "Subscription ID"
    },
    "creditationImporter": {
      "title": "Creditation importer",
      "importer": {
        "fileButton": "Select file",
        "buttonTitle": "Start import",
        "dropFiles": "Upload files"
      }
    },
    "calls": {
      "modal": {
        "buttonTitle": "Phone calls",
        "caller": "Caller",
        "start": "Start",
        "duration": "Duration",
        "voicelogs": "Voicelogs"
      },
      "modalTitle": "Telephone calls to subscriber"
    },
    "cancel": {
      "modalTitle": "Cancel subscription",
      "saveCancelation": "Save cancellation",
      "details": "Cancellation details"
    },
    "nav": {
      "overview": "Subscribers"
    },
    "page": {
      "overview": {
        "title": "Subscribers"
      }
    },
    "overviewMock": {
      "title": "Test subscribers"
    },
    "download": {
      "button": "CSV"
    },
    "error": {
      "cancelationWithoutReason": "Enter a reason for the cancellation"
    },
    "resendEmail": {
      "button": "Send email again",
      "confirmationQuestion": "Are you sure you want to resend the confirmation email?",
      "emailToResend": "Email address for the confirmation email",
      "resendConfirmation": "Email address for the confirmation email",
      "sentConfirmation": "Email sent successfully",
      "invalidEmail": "Email address invalid",
      "invalidInputEmail": "Enter a valid email address",
      "sendAttachment": "Send attachments"
    },
    "field": {
      "updatedAt": {
        "label": "Updated on"
      },
      "id": {
        "label": "ID"
      },
      "subscriber": {
        "lastName": "Last name"
      },
      "iban": {
        "label": "Iban"
      },
      "amount": {
        "label": "Amount"
      },
      "quantity": {
        "label": "Number of"
      },
      "total": {
        "label": "Total",
        "completed": "Ready",
        "deleted": "Removed",
        "bucket": "In CallQueue",
        "validation": "Ready for validation",
        "queue": "Ready for CallQueue",
        "export": "Ready for export",
        "attention": "Attention needed"
      },
      "product": {
        "label": "Product",
        "placeholder": "Product..."
      },
      "cancelPaymentRecruiter": {
        "label": "Cancel payment to recruiter"
      },
      "status": {
        "label": "Status",
        "placeholder": "Status...",
        "value": {
          "needs validation": "ready for validation",
          "needs attention": "attention needed",
          "deleted": "removed",
          "ready for export": "ready for export",
          "in call bucket": "in CallQueue",
          "completed": "ready",
          "canceled": "cancelled",
          "queue": "ready for CallQueue",
          "creditation": "Crediting"
        }
      },
      "cancelled": {
        "label": "Cancelled",
        "options": {
          "all": "All",
          "only": "Only cancelled",
          "without": "Without cancelled"
        }
      },
      "campaign": {
        "label": "Campaign"
      },
      "client": {
        "label": "Customer",
        "placeholder": "Customer..."
      },
      "clientRefNr": {
        "label": "Customer reference number",
        "placeholder": "Customer reference number..."
      },
      "error": {
        "label": "Error",
        "subscription": "Subscription Error",
        "subscriber": "Subscriber Error"
      },
      "date": {
        "label": "Date",
        "placeholder": "Date..."
      },
      "entryRemarks": {
        "label": "Note input",
        "placeholder": "Note input..."
      },
      "callRemarks": {
        "label": "Remark CallQueue",
        "placeholder": "Remark label..."
      },
      "clientRemarks": {
        "label": "Customer comments",
        "placeholder": "Customer Comment..."
      },
      "teamMember": {
        "label": "Promoter",
        "placeholder": "Promoter..."
      },
      "endDate": {
        "label": "Date of termination"
      },
      "paymentMethod": {
        "label": "Payment method",
        "placeholder": "Payment method...",
        "value": {
          "invoice": "invoice",
          "directDebit": "direct debit",
          "acceptgiro": "acceptance giro",
          "cash": "cash",
          "direct debit": "Direct flow"
        }
      },
      "datetimeOfRegistration": {
        "label": "Registration date"
      },
      "datetimeOfCancelation": {
        "label": "Cancellation date",
        "placeholder": "Cancellation date..."
      },
      "reasonForCancelation": {
        "label": "Cancellation description",
        "placeholder": "Description for the cancellation reason..."
      },
      "overrideCallAmountCheck": {
        "label": "Maximum number of call attempts exceeded"
      },
      "startDate": {
        "label": "Effective date",
        "error": {
          "exceeding": "Too far into the future"
        }
      },
      "employee": {
        "name": "Employee name"
      },
      "paymentInterval": {
        "label": "Frequency",
        "changedOneTimer": "Converted to one-off",
        "value": {
          "null": "Once",
          "P1D": "Daily",
          "P1W": "Weekly",
          "P1M": "Monthly",
          "P3M": "Every Quarter",
          "P6M": "Halfyearly",
          "P1Y": "Yearly"
        },
      },
      "creditation": {
        "label": "Crediting",
        "options": {
          "both": "Both",
          "false": "False",
          "true": "True"
        }
      },
      "systemStatus": {
        "label": "System status"
      },
      "appointmentStatus": {
        "label": "Appointment status"
      },
      "is_imported": {
        "label": "Has been imported",
        "placeholder": "Has been imported..."
      },
      "datetimeOfTermination": {
        "label": "Date successful completion of subscription"
      },
        disableBlockers: {
            label: "Disable blockers",
        },
        subscriptionCode: {
            label: 'Cancellation reason',
            info: 'Cancellation reason is optional, it indicates the cancellation\'s category.'
        },
      "ignoreErrors":{
        "label": "Ignore subscription errors for status change"
      }
    },
    "button": {
      "add": "Add"
    },
    "createButton": "Add Subscriber",
    "cancelTooltip": "Subscription cancellation",
    "editTooltip": "Change subscription",
    "uncancelTooltip": "Subscription de-cancellation",
    "deleteTooltip": "Delete registration",
    "lookupTooltip": "Subscription lookup",
    "save": {
      "error": "Couldn't save subscriber!",
      "success": "Subscriber is saved"
    },
    "delete": {
      "error": "Could not remove subscriber!",
      "success": "Subscriber has been removed"
    },
    "modal": {
      "view": {
        "title": "Registration"
      },
      "delete": {
        "content": "You are about to delete an entry. Do you want to continue?",
        "cancelButton": "Cancel",
        "deleteButton": "Cancellation"
      },
      "cancel": {
        "title": "Subscription cancellation",
        "cancelButton": "Cancel",
        "saveButton": "Cancellation",
        "notification": "Subscription is canceled"
      }
    },
    "changeStatus": {
      "error": "Could not change status!",
      "success": "Status has been updated.",
      "modalTitle": "Change status of subscription",
      "modalTitleMultiple": "Change status of {{amount}} subscriptions",
      "changeButton": "Change status",
      "label": "Choose the status of subscription"
    },
    "reexport": {
      "error": "Couldn't re-export!",
      "success": "subscription has been re-exported"
    },
    "uncancel": {
      "error": "Couldn't undo cancellation!",
      "success": "Cancellation is undone."
    },
    "zsp": {
      "missingScan": "No scan has been uploaded yet. Please upload a scan first."
    },
    "termination": {
      "modalTitle": "Terminate subscription after success",
      "saveTermination": "Terminate Oplaan",
      "title": "Completed after disbursement"
    },
    "terminationTooltip": "Terminate subscription after success",
    "unterminateTooltop": "Do not terminate subscription",
    "creditationProcessed": "Report credit as received",
    "creditationNotProcessed": "Undo credit processing"
  },
  "subscriber": {
    "noPicture": "No image available",
    "loading": "Loading",
    "field": {
      "updatedAt": {
        "label": "Updated on"
      },
      spokenLanguage:{
        label: "Language"
      },
      "gender": {
        "salutation": {
          "male": "Mr.",
          "female": "Mrs.",
          "unknown": ""
        },
        "label": "Gender",
        "value": {
          "male": "Man",
          "female": "Woman",
          "unknown": "Unknown"
        }
      },
      "dateOfBirth": {
        "label": "Date of birth"
      },
      "city": {
        "label": "Location"
      },
      "street": {
        "label": "Street"
      },
      "houseNumber": {
        "label": "House number"
      },
      "houseNumberSuffix": {
        "label": "Addition"
      },
      "zipCode": {
        "label": "Postcode"
      },
      "iban": {
        "label": "Iban"
      },
      "mobile": {
        "label": "Mobile phone number"
      },
      "landline": {
        "label": "Fixed telephone number"
      },
      "email": {
        "label": "Email"
      },
      "lastName": {
        "label": "Last name"
      },
      "firstName": {
        "label": "First name"
      },
      "middleName": {
        "label": "Insertions"
      },
      "initials": {
        "label": "Initials"
      },
      "date_of_birth": {
        "error": {
          "too_young": "Younger than minimum age."
        }
      },
      "country": {
        "label": "Country",
        "value": {
          "NL": "Netherlands",
          "DE": "Germany",
          "FR": "France",
          "BE": "Belgium",
          "GB": "England"
        }
      }
    }
  },
  "shopItem": {
    "nav": {
      "overview": "Shop"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "ordering": {
        "label": "Order",
        "placeholder": "Order..."
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "price": {
        "label": "Coins",
        "placeholder": "Coins..."
      },
      "image": {
        "label": "Image",
        "placeholder": "Image..."
      }
    },
    "create": {
      "title": "Add article"
    },
    "edit": {
      "title": "Customize article"
    },
    "createButton": "Add article",
    "editTooltip": "Edit item",
    "filter": {
      "deleted": "Deleted items",
      "onlyDeleted": "Only deleted",
      "withoutDeleted": "Without deleted",
      "bothDeletedAndNot": "Both"
    }
  },
  "promotorIdea": {
    "nav": {
      "overview": "Promoter app Ideas"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "employee": {
        "label": "Employee",
        "placeholder": "Employee..."
      },
      "branch": {
        "label": "Branch office",
        "placeholder": "Location..."
      },
      "idea": {
        "label": "Idea",
        "placeholder": "Idea..."
      },
      "subject": {
        "label": "Topic",
        "placeholder": "Subject..."
      }
    },
    "create": {
      "title": "Add an idea"
    },
    "edit": {
      "title": "Adapt idea"
    },
    "createButton": "Add an idea",
    "editTooltip": "Edit item"
  },
  "contactTab": {
    "noResults": "Nothing to call after"
  },
  "sequence": {
    "editTooltip": "Edit item",
    "nav": {
      "overview": "Sequences"
    },
    "page": {
      "overview": {
        "title": "Sequences"
      }
    },
    "createButton": "Add sequence",
    "clearDefaultButton": "Clear default",
    "save": {
      "error": "Could not save sequence!",
      "success": "Sequence is saved"
    },
    "modal": {
      "delete": {
        "content": "You are about to delete a sequence. Do you want to continue?"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "value": {
        "label": "Value"
      },
      "description": {
        "label": "Description"
      },
      "client": {
        "label": "Customer"
      },
      "increment_per_export": {
        "placeholder": "Yes/No..."
      }
    }
  },
  "clientRefNr": {
    "page": {
      "overview": {
        "title": "Reference numbers"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "referenceNumber": {
        "label": "Reference number"
      },
      "subscriptionId": {
        "label": "Subscription ID"
      },
      "client": {
        "label": "Customer"
      },
      "fromCsv": {
        "label": "CSV ID"
      }
    }
  },
  "softDelete": {
    "delete": {
      "error": "Could not delete item!",
      "success": "Item has been removed",
      "tooltip": "Remove item"
    },
    "restore": {
      "error": "Could not restore item!",
      "success": "Item has been reset",
      "tooltip": "Put item back"
    }
  },
  "segment": {
    "field": {
      "id": "ID",
      "name": "Name",
      "is_final": "Has been published"
    },
    "attribute": {
      "field": {
        "id": "ID",
        "segment": "Segment",
        "name": "Name",
        "attribute_name": "Attribute name",
        "aggregation_type": "Type of reporting"
      }
    },
    "createButton": "Add segment"
  },
  "valueNode": {
    "valueType": {
      "label": "Type"
    },
    "nodeType": {
      "label": "Type"
    },
    "choice": {
      "if": "Like",
      "else": "Otherwise"
    },
    "type": {
      "fixed_value": "Fixed value",
      "field_value": "Field value",
      "date_format": "Date format",
      "current_date": "Today's date",
      "choice": "Choice",
      "metafield_value": "Metafield",
      "merge": "Merge",
      "dyadic_arithmetic_operator": "Arithmetic operation",
      "convert_case": "Uppercase conversion",
      "replace": "Replace text",
      "fixed_width": "Fixed column width",
      "date_component": "Part of date",
      "date_truncate": "Abbreviated date",
      "count": "Number of",
      "sequence": "Sequence",
      "substring": "Substring",
      "assigned_field_value": "Assigned field value"
    },
    "dateComponentType": {
      "century": "century",
      "day": "hello",
      "decade": "decade",
      "dow": "day of the week",
      "doy": "day of the year",
      "epoch": "seconds since 1-1-1970 00:00:00 UTC",
      "hour": "hour",
      "isodow": "day of the week (ISO)",
      "isoyear": "day of the year (ISO)",
      "microseconds": "microsecond",
      "millenium": "millennium",
      "milliseconds": "millisecond",
      "minute": "minute",
      "month": "month",
      "quarter": "quarter",
      "second": "second",
      "timezone": "UTC time zone difference",
      "timezone_hour": "hours difference from UTC",
      "timezone_minute": "minutes difference from UTC",
      "week": "week",
      "year": "year",
      "millennium": "millennium"
    },
    "mathOperator": {
      "add": "+",
      "sub": "-",
      "mul": "*"
    },
    "choiceOperator": {
      "exact": "equal to",
      "exact_not": "is not equal to",
      "lt": "less than",
      "lte": "is less than / equal to",
      "gt": "is greater than",
      "gte": "is greater than / equal to"
    },
    "groupCombinator": {
      "and": "and",
      "or": "or"
    },
    "convertCase": {
      "uppercase": "Capital letters",
      "lowercase": "Lower case",
      "titlecase": "Capital letter per word"
    },
    "location": {
      "left": "left",
      "right": "right",
      "both": "Both",
      "everywhere": "Anywhere"
    },
    "repeat": {
      "label": "Repeat",
      "no": "No",
      "yes": "Yes"
    }
  },
  "pageNotFoundMessage": "Page not found :(",
  "paginator": {
    "previousText": "<",
    "nextText": ">"
  },
  "user": {
    "editTooltip": "Edit user",
    "masqueradeTooltip": "User masquerading",
    "forgotPassword": "Forgot your password?",
    "sendResetLink": "Send reset link",
    "backToLogin": "Back to login",
    "passwordPlaceholder": "new password",
    "passwordConfirmationPlaceholder": "Repeat password...",
    "sendResetCodeEmailSuccess": "If your username is known, a reset code has been sent to your email.",
    "sendResetCodeEmailError": "Could not reset password. Check username.",
    "editButton": "Adjust",
    "saveButton": "Save",
    "navProfile": "Profile",
    "navChangePassword": "Change password",
    "navLogout": "Logout",
    "resetPassword": {
      "resetButton": "Reset",
      "saveSuccess": "Password is successfully reset.",
      "title": "Reset Password",
      "body": "Reset your password here.",
      "password": "New password",
      "repeatPassword": "Repeat new password",
      "doesNotMatch": "Passwords do not match!",
      "requirements": {
        "length": "The password must be at least 8 characters long.",
        "generic": "The password must not be too generic.",
        "similar": "The password must not be too similar to your personal details.",
        "numeric": "The password can't be entirely numeric."
      },
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "last_login": {
        "label": "Last logged in"
      },
      "username": {
        "label": "Username",
        "placeholder": "Username..."
      },
      "email": {
        "label": "Email"
      },
      "password": {
        "label": "Password",
        "placeholder": "password..."
      },
      "groups": {
        "label": "Permission groups",
        "multiselect": {
          "selectedText": "# of # groups selected",
          "noneSelectedText": "Select groups",
          "placeholder": "Searching..."
        }
      },
      "lastLogin": {
        "label": "Last login"
      },
      "isActive": {
        "label": "User is active"
      }
    },
    "filter": {
      "search": {
        "label": "Search",
        "placeholder": "User name, first name, last name..."
      },
      "branches": {
        "label": "Branch office"
      },
      "group": {
        "label": "Group"
      },
      "status": {
        "label": "Status"
      },
      "deleted": "Removed"
    },
    "account": {
      "title": "Profile",
      "language": "Language",
      "logoutButton": "Logout",
      "unMasquerade": "Unmasquerade"
    },
    "changePassword": {
      "title": "Change Password",
      "field": {
        "passwordOld": {
          "label": "Old password"
        },
        "passwordNew": {
          "label": "New password"
        }
      },
      "saveButton": "Change Password",
      "success": "Password has been changed",
      "error": "Couldn't change the password!"
    },
    "passwordForgot": {
      "title": "Forgotten password",
      "requestButton": "Send reset link",
      "requestedEmailText": "If the email address is correct, an email with a link to reset the password has been sent"
    },
    "nav": {
      "overview": "Users"
    },
    "login": {
      "title": "Login",
      "forgotPasswordLink": "Forgot your password?",
      "errors": {
        "invalidCredentials": "Username or password incorrect",
        "unknown": "Unknown error, status code: {{status}}."
      },
      "loginButton": "Login"
    },
    "save": {
      "error": "Could not save user!",
      "success": "User saved"
    },
    "view": {
      "create": {
        "title": "Create user"
      },
      "edit": {
        "title": "Customize user"
      },
      "changePassword": {
        "title": "Change password",
        "newPassword": "New password",
        "confirmedPassword": "confirmed password"
      },
      "profile": {
        "title": "Your profile"
      },
      "forgotPassword": {
        "title": "Forgotten your password?"
      }
    },
    "groups": "Permission groups",
    "masqueradeButton": "Start masquerade",
    "unmasqueradeButton": "Stop masquerade"
  },
  "contentbundle": {
    "slide": {
      "SlidesEdit": "Folders"
    },
    "copyTooltip": "Copy content bundle",
    "editTooltip": "Edit content bundle",
    "deleteTooltip": "Delete content bundle",
    "createButton": "Add content bundle",
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "client": {
        "label": "Customer"
      },
      "show_recruiter_signature": {
        "label": "Promoter signature?"
      },
      "campaign": {
        "label": "Campaign",
        "placeholder": "Select a campaign..."
      },
      "logo": {
        "label": "Logo",
        "save": {
          "error": "Logo must be a png file"
        }
      },
      "products": {
        "label": "Customer"
      },
      "translations": {
        "label": "Translations"
      },
      "styles": {
        "label": "Styles"
      }
    },
    "copy": {
      "success": "The campaign has been copied.",
      "error": "The campaign could not be copied."
    },
    "noPadding": "No padding",
    "basicInfo": "Basic information",
    "layout": "Layout",
    "currentFormElementDetails": "Element details",
    "addElements": "Adding elements",
    "removeElement": "Remove",
    "addElementType": {
      "attest": "Add certificate",
      "filler": "Adding a filler",
      "text": "Add text",
      "metafield": "Add metafield",
      "field": "Add field",
      "image": "Add image"
    },
    "edit": {
      "addForm": "Add form",
      "slides": "Edit slides",
      "addFolder": "Add folder",
      "addSlide": "Add slide"
    }
  },
  "contentbundleAgeWildcard": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "minAge": {
        "label": "Minimum age"
      },
      "maxAge": {
        "label": "Maximum age"
      },
      "renewEvery": {
        "label": "Renew every {N} sub",
      },
      "default": {
        "label": "Add by default?",
      },
      "employees": {
        "label": "For employees"
      },
      "branches": {
        "label": "For branches"
      },
    },
    "edit": {
      "title": "Edit joker"
    },
    "create": {
      "title": "Create joker"
    }
  },
  "contentbundleSlide": {
    "copyTooltip": "Copy content bundle",
    "deleteSlideTooltip": "Remove slide",
    "editSlideTooltip": "Edit slide",
    "editTooltip": "Edit chapter",
    "addHitboxButton": "Add Hitbox",
    "save": {
      "error": "Couldn't save slide!",
      "overlapError": "One or more hitboxes overlap. This is not allowed.",
      "duplicateNameError": "There is already a slide with this name!",
      "success": "Slide has been saved"
    },
    "delete": {
      "content": "You are about to delete a slide. Do you want to continue?",
      "error": "Couldn't remove slide!",
      "success": "Slide has been removed"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "final": {
        "label": "From this slide to registration form"
      },
      "file": {
        "label": "Slide"
      }
    }
  },
  "displayCondition": {
    "field": {
      "metafield": {
        "label": "Metafield"
      },
      "value": {
        "label": "Value"
      },
      "type": {
        "label": "Type",
        "value": {
          "standard": "Standard field",
          "metafield": "Metafield"
        }
      },
      "standardField": {
        "label": "Field",
        "value": {
          "age": "Age"
        }
      },
      "comparisonMethod": {
        "label": "Comparator",
        "value": {
          "=": "Equal to",
          "<": "Less than",
          ">": "More than"
        }
      }
    }
  },
  "importedCreditationCsv": {
    "field": {
      "csv": {
        "label": "CSV"
      },
      "importer": {
        "label": "Importer"
      },
      "createdAt": {
        "label": "Date"
      }
    },
    "actions": {
      "label": "Actions"
    }
  },
  "contentbundleSlideSettingsCondition": {
    "addConditionButton": "Add condition",
    "addConditionButtonTitle": "If one of the conditions is met, then this slide will be displayed.",
    "field": {
      "productId": {
        "label": "Product"
      },
      "metafieldId": {
        "label": "Metafield"
      },
      "metafieldValue": {
        "label": "Value"
      }
    }
  },
  "fileBundle": {
    "form": {
      "client": {
        "label": "Customer"
      },
      "description": {
        "label": "Description"
      },
      "name": {
        "label": "Name"
      }
    },
    "create": {
      "title": "Add export"
    },
    "edit": {
      "title": "File definition"
    },
    "modal": {
      "delete": {
        "content": "You are about to delete a file definition. This will also remove previously generated export files of this definition from the archive. Do you want to continue?",
        "cancelButton": "Cancel",
        "deleteButton": "Cancellation"
      }
    },
    "exportModal": {
      "showModalButton": "Exporting results",
      "title": "Export options",
      "performExport": "Export",
      "isDefinitive": "Final export",
      "dateRange": "Date",
      "confirmDefinitive": "Are you sure you want to finalize this export? This will actually handle all selected subscriptions."
    },
    "exportErrorModal": {
      "title": "Errors during export",
      "fileDefitionHeader": "Errors in file definition",
      "unknownErrorMessage": "An unknown error has occurred during export"
    },
    "metadata": {
      "title": "Metadata",
      "fields": {
        "description": {
          "label": "Description"
        },
        "format": {
          "label": "File type"
        },
        "encoding": {
          "label": "File Encoding"
        }
      }
    },
    "field": {
      "id": {
        "title": "ID"
      },
      "client": {
        "title": "Customer",
        "placeholder": "Customer..."
      },
      "description": {
        "title": "Description",
        "placeholder": "Description..."
      }
    },
    "save": {
      "error": "Couldn't save bundle!",
      "success": "Bundle is saved"
    },
    "addInputNodeButton": "Add node"
  },
  "fileNode": {
    "deleteConfirmation": "Are you sure you want to delete the element {{elementName}}?"
  },
  "generatedBundle": {
    "field": {
      "id": {
        "label": "ID"
      },
      "subscriptionId": {
        "label": "Subscription ID"
      },
      "client": {
        "label": "Customer"
      },
      "createdAt": {
        "label": "Created"
      }
    }
  },
  "group": {
    "createButton": "Add group",
    "field": {
      "id": {
        "label": "ID"
      },
      "isDefault": {
        "label": "Default employee"
      },
      "isDefaultApplicant": {
        "label": "Default applicant"
      },
      "isDefaultClient": {
        "label": "Default customer"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "permissions": {
        "label": "Permission",
        "placeholder": "Permission..."
      },
      "default": {
        "label": "Default employee",
        "placeholder": "Standard..."
      },
      "defaultApplicant": {
        "label": "Default applicant",
        "placeholder": "Standard applicant..."
      },
      "defaultClient": {
        "label": "Default customer user"
      }
    },
    "save": {
      "error": "Couldn't save group!",
      "success": "Group is saved"
    },
    "editTooltip": "Edit group",
    "nav": {
      "overview": "Permission groups"
    },
    "filter": {
      "permission": {
        "selectedText": "# of # rights selected",
        "noneSelectedText": "Choose rights",
        "placeholder": "Searching..."
      }
    }
  },
  "issue": {
    "field": {
      "reason": {
        "value": {
          "start_date exceeds max delta": "Start date is too late",
          "iban missing": "Missing IBAN",
          "quantity missing": "Missing quantity",
          "amount missing": "Missing amount",
          "email missing": "Missing e-mail",
          "landline missing": "Missing landline number",
          "below minimum age": "Below minimum age",
          "last_name missing": "Missing last name",
          "gender unknown": "Missing gender",
          "date_of_birth missing": "Missing date of birth",
          "mobile missing": "Missing mobile number",
          "phone number missing": "Missing mobile and landline numbers",
          "address invalid": "Address invalid",
          "address incomplete": "Address incomplete",
          "address unsure": "Address vague",
          "duplicate iban": "Dual IBAN for customer",
          "known iban": "Known IBAN",
          "recruiter iban": "IBAN of an employee",
          "duplicate email": "Email customer already known in the system",
          "recruiter email": "Email from an employee",
          "landline_invalid": "Invalid landline phone number",
          "mobile_invalid": "Invalid mobile number",
          "duplicate phone": "Phone number customer already known in the system",
          "recruiter phone": "Phone number from an employee",
        }
      }
    }
  },
  "callPlanning": {
    "addSubModal": {
      "title": "Add subscriptions for campaign {{campaign}}"
    },
    spokenLanguage: 'Language',
    language: 'Language',
    "appointments": "View",
    "employee": "Caller",
    "markAllDone": "Mark all as done",
    "reachedDone": "Mark 'reached' as done",
    "importSubscriptions": "Add subscriptions",
    "confirmManuallyMark": "Confirm that all {{amount}} subscriptions of campaign {{campaign}} should be marked as done",
    "confirmManuallyReached": "Confirm that all 'reached' subscriptions of campaign {{campaign}} should be marked as done",
    "confirmImportHeader": "Import confirmation",
    "confirmImport": "Are you sure you want to import the registrations of campaign {{name}}?",
    "importRecords": "Importing subscriptions",
    "amountOfDonors": "{{amount}} Entries: {{reached}} achieved, {{totalDone}} done ({{percentage}} %).",
    "steps": {
      "importSubs": "Import",
      "assign": "Assign employees",
      "current": "Current call campaigns"
    },
    "appointmentModal": {
      "subscriptionType": "Registration",
      "bucketType": "Bucket",
      "title": "Call appointments {{type}} {{id}}."
    }
  },
  "jobApplication": {
    "nav": {
      "overview": "Applications",
      "interviews": "Interviews"
    },
    "add": "Add applicant",
    "sendActivationEmailButton": "(Re)send activation email",
    "sendNoShowEmailButton": "(Re)send no-show email",
    "sendColdLeadEmailButton": "(Re)send cold lead email",
    "sendActivationEmailSuccessMessage": "Sent successfully",
    "filter": {
      "minCallAmount": "Minimal number of calls",
      "maxCallAmount": "Maximal number of calls",
      corrected: "corrected"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "callBackAppointment": {
        "label": "Callback appointment time"
      },
      "nationalityInternal": {
        "label": "Nationality"
      },
      "remarks": {
        "label": "Remarks"
      },
      "viaVia": {
        "label": "ViaVia",
        "placeholder": "ViaVia..."
      },
      "employee": {
        "label": "Promoter",
        "placeholder": "Promoter..."
      },
      "last_sign_in": {
        "label": "Last working day"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "avatar": {
        "label": "Profile photo",
        "placeholder": "Profile photo..."
      },
      "public_transport_student_license": {
        "label": "Has student OV",
        "placeholder": "Student OV...",
        "value": {
          "false": "No",
          "true": "Yes"
        }
      },
      "publicTransportStudentLicenseType": {
        "label": "Type of student OV",
        "value": {
          "no": "None",
          "week": "Week",
          "weekend": "Weekend"
        }
      },
      "mainBranch": {
        "label": "Headquarters",
        "placeholder": "Select headquarters..."
      },
      "notInterested": {
        "label": "No appointment",
        "info": "This applicant has indicated that he/she does not want to continue with the application process."
      },
      "travelingDistance": {
        "label": "Travel distance"
      },
      "notInterestedReason": {
        "label": "Reason for no appointment"
      },
      "countryOfBirth": {
        "label": "Country of birth"
      },
      "cityOfBirth": {
        "label": "City of birth"
      },
      "branches": {
        "label": "Branch office",
        "placeholder": "Location...",
        "selectedText": "# of # establishments",
        "noneSelectedText": "Select location"
      },
      "initials": {
        "label": "Initials",
        "placeholder": "Initials..."
      },
      "firstName": {
        "label": "First name",
        "placeholder": "First name..."
      },
      "middleName": {
        "label": "Insertion",
        "placeholder": "Insertion..."
      },
      "lastName": {
        "label": "Last name",
        "placeholder": "Last name..."
      },
      "gender": {
        "label": "Gender",
        "value": {
          "male": "man",
          "female": "woman",
          "unkown": "unknown",
          "other": "otherwise"
        }
      },
      "onHold": {
        "labelDr": "Do not start right away",
        "label": "On hold",
        "value": {
          "true": "Yes",
          "false": "No",
          "all": "Both"
        }
      },
      "onHoldReason": {
        "label": "Reason for on hold"
      },
      "age": {
        "label": "Age"
      },
      "dateOfBirth": {
        "label": "Date of birth"
      },
      "phoneNumbers": {
        "label": "Telephone numbers",
        "placeholder": "Phone numbers..."
      },
      "ssn": {
        "label": "BSN",
        "placeholder": "BSN..."
      },
      "dateStarted": {
        "label": "Date in service"
      },
      "country": {
        "label": "Country",
        "placeholder": "Land...",
        "value": {
          "NL": "EN"
        }
      },
      "iban": {
        "label": "IBAN",
        "placeholder": "IBAN..."
      },
      "bic": {
        "label": "BIC",
        "placeholder": "BIC..."
      },
      "houseNumber": {
        "label": "House number",
        "placeholder": "House number..."
      },
      "houseNumberSuffix": {
        "label": "House number addition",
        "placeholder": "House number addition..."
      },
      "city": {
        "label": "Town",
        "placeholder": "City..."
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "street": {
        "label": "Street",
        "placeholder": "Street..."
      },
      "zipCode": {
        "label": "Postcode",
        "placeholder": "Zip code..."
      },
      "employeeNumbefr": {
        "label": "Personnel reference number",
        "placeholder": "Personnel no..."
      },
      "drivingLicence": {
        "label": "Has driving licence",
        "placeholder": "Driver's license...",
        "value": {
          "false": "No",
          "true": "Yes"
        }
      },
      "bankCard": {
        "label": "Bank Card",
        "placeholder": "Bank card..."
      },
      "taxExemption": {
        "label": "Tax credit",
        "placeholder": "Tax credits..."
      },
      "canChangeTaxExemption": {
        "label": "Can tax exemption change?",
        "placeholder": "Can tax exemption change?"
      },
      "contractSignature": {
        "label": "Contract",
        "placeholder": "Contract..."
      },
      "identification": {
        "label": "ID card front side",
        "placeholder": "ID card front..."
      },
      "identification_back": {
        "label": "ID card back",
        "placeholder": "ID card back..."
      },
      "bw_id": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "city_of_birth": {
        "label": "Birthplace",
        "placeholder": "hometown..."
      },
      "country_of_birth": {
        "label": "Country of birth",
        "placeholder": "Country of birth..."
      },
      "id_type": {
        "label": "ID type",
        "placeholder": "ID type..."
      },
      "id_number": {
        "label": "ID number",
        "placeholder": "ID number..."
      },
      "interviewer": {
        "label": "Interviewer?",
        "title": "The employee can then be chosen from the list of interviewers for job interviews."
      },
      "coins": {
        "label": "Coins"
      },
      "blockedClients": {
        "label": "Blocked Customers"
      },
      "referral_source": {
        "label": "Source"
      },
      "referralSource": {
        "label": "Source"
      },
      "referralSourceOther": {
        "label": "Source else"
      },
      "legalGuardianName": {
        "label": "Name of legal guardian"
      },
      "legalGuardianPhone": {
        "label": "Phone number of legal guardian"
      },
      "legalGuardianEmail": {
        "label": "E-mail address of legal guardian"
      },
      "noShowSent": {
        "label": "No show email sent",
        "info": "If an applicant has not shown up (has a no-show status) and has been called at least once, a no-show email can be sent (in the toolbar). This email contains a reminder to the applicant that he/she can come for an interview."
      },
      "legalGuardianDisabled": {
        "label": "Legal guardian not required"
      },
      "coldLeadSent": {
        "label": "Cold lead email sent",
        "info": "If an applicant has not shown up (has a cold lead status) and has been called at least five times, a cold lead email can be sent (in the toolbar). This email contains a final reminder to the applicant to come in for an interview."
      },
      "createdAt": {
        "label": "Created at"
      },
      "lastInterview": {
        "label": "Last interview"
      },
      "employeeNumber": {
        "label": "Personnel reference number",
        "placeholder": "Personnel no..."
      },
      "dropoutReason":{
        label: 'Dropout reason'
      },
    },
    "calls": {
      "modal": {
        "incoming": "Incoming calls",
        "outcoming": "Outcomings calls",
        "buttonTitle": "Phone calls",
        "id": "ID",
        "caller": "Caller",
        "calledUser": "To",
        "start": "Start",
        "duration": "Duration",
        "voicelogs": "Voicelogs",
        "listenCall": "Play call recording",
        "audioError": "Your browser does not support the audio tag. "
      },
      "modalTitle": "Job applicant phone calls"
    }
  },
  "referralSource": {
    "nav": {
      "overview": "Source"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "isDefault": {
        "label": "Default?"
      },
      "isOther": {
        "label": "Different?"
      },
      "name": {
        "label": "Name"
      }
    },
    filter: {
      "deleted": "Deleted sources",
      "onlyDeleted": "Only deleted",
      "withoutDeleted": "Without deleted",
      "bothDeletedAndNot": "Both"
    },
    create:{
      title: 'Add source'
    },
    "createButton": "Add source",
    edit: {
      title: "Edit referral source"
    }
  },
  "job": {
    "editTooltip": "Edit function",
    "nav": {
      "overview": "Function"
    },
    "createButton": "Add function",
    "save": {
      "error": "Could not save function!",
      "success": "Function is saved"
    },
    "field": {
      "id": {
        "label": "ID",
        "placeholder": "ID..."
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "isDefault": {
        "label": "Default position?",
        "placeholder": "Default position..."
      },
      "isTeamPosition": {
        "label": "Team position?",
        "placeholder": "Team position..."
      },
      "isFreelance": {
        "label": "Freelance?",
        "placeholder": "Freelance..."
      },
      "baseFactor": {
        "label": "Basic factor"
      }
    },
    "application": {
      "createButton": "Add applicant",
      "action": {
        "waitForContractUpload": "Waiting for contract uploaded",
        "readyToConvertToEmployee": "Ready to convert to active employee",
        "waitForInterviewApproval": "Waiting for appointment confirmation",
        "checkInterview": "Check appointment",
        "makeAppointment": "Schedule an appointment",
        "appointmentMade": "Waiting for interview"
      },
      "field": {
        "amountofcalls": {
          "label": "Number of calls",
          lastCall: "Last call",
          hasCalls: "Has calls?",
          corrected: "Corrected number of calls"
        },
        callAppointment: {
            label: "Call appointment status"
        },
        "dateofinterview": {
          "label": "Date Interview"
        },
        "whatsappStatus": {
          "label": "Whatsapp Status",
          "value": {
            "to_call": "Request to call",
            "reminder_appointment": "Appointment reminder",
            "congratulations_hired": "Congratulation on admission"
          },
          "save": "Whatsapp Status Saved",
          "saveError": "Whatsapp Status not saved"
        },
        "id": {
          "label": "ID"
        },
        "name": {
          "label": "Name"
        },
        "remarks": {
          "label": "Remarks"
        },
        "age": {
          "label": "Age",
          "placeholder": "Age..."
        },
        "status": {
          "label": "Status",
          "multiselect": {
            "selectedText": "# of # statuses",
            "noneSelectedText": "Select status"
          },
          "multiselectFilter": {
            "placeholder": "Find..."
          },
          "value": {
            "undecided": "To be decided",
            "hired": "Hired",
            "rejected": "Rejected",
            "on_hold": "On hold",
            "no-show": "No Show",
            "to-plan": "Schedule an appointment",
            "planned": "Interview planned",
            "awaiting-interview": "Waiting for Interview",
            "completed-interview": "Interview completed",
            "to-plan-no-show": "Schedule an appointment after No Show",
            "to-plan-cold-lead": "Schedule an appointment after Cold Lead",
            "not-interested": "No appointment"
          }
        },
        "createdAt": {
          "label": "Created at"
        },
        "previous_call_attempts": {
          "label": "Previous call attempts"
        },
        "referralSource": {
          "label": "Source"
        },
        "branch": {
          "label": "Branch office"
        },
        "email": {
          "label": "Email"
        },
        "phoneNumber": {
          "label": "Phone"
        },
        "referral_source": {
          "label": "Source"
        },
        "viaVia": {
          "label": "ViaVia Source"
        }
      },
      "filter": {
        "search": {
          "label": "Search",
          "placeholder": "Search by first name, last name, email..."
        },
        "active": {
          "label": "Active?",
          "value": {
            "all": "Both",
            "active-only": "Active promoters only",
            "non-active-only": "No active promoters yet"
          }
        }
      },
      "page": {
        "overview": {
          "title": "Job applications"
        },
        "edit": {
          "title": "Application"
        }
      },
      "plan": "Add interview",
      "modal": {
        "delete": {
          "content": "You are about to delete an applicant. Do you want to continue?",
          "cancelButton": "No",
          "deleteButton": "Remove"
        },
        "deleteFile": {
          "content": "Are you sure you want to delete this file?",
          "cancelButton": "No",
          "deleteButton": "Remove"
        }
      },
      "save": {
        "success": "Request saved",
        "error": "Error while saving request"
      },
      "delete": {
        "error": "Could not delete item!",
        "success": "Item has been removed"
      }
    },
    "callbackAppointment": {
      "options": {
        "none": "without",
        "pending": "Upcoming",
        "done": "Ready",
        "notReached": "Not achieved"
      },
      "modalCallButton": "Scheduled Interviews",
      "callBackAppointment": "Scheduled conversation",
      "callbackAppointments": "Scheduled Interviews",
      "callAppointmentStatus": "Status",
      "deleteDialog": {
        "title": "Are you sure you want to delete the callback appointment?",
        "confirm": {
          "label": "Remove"
        }
      },
      "save": {
        "success": "Callback appointment saved",
        "error": "Error saving callback appointment"
      },
      "header": "Callback appointments",
      "createButton": "Add callback appointment",
      "field": {
        "status": {
          "label": "Appointment status"
        },
        "date": {
          "label": "Date and Time"
        }
      }
    },
    "interview": {
      "createButton": "Add interview",
      "hireButton": "Hire",
      "rejectButton": "Reject",
      "noShowButton": "No Show",
      "deleteDialog": {
        "title": "Are you sure you want to delete the interview?",
        "confirm": {
          "label": "Remove"
        }
      },
      "button": {
        "hired": "Hire",
        "rejected": "Reject",
        "no-show": "No show",
        "undecided": "To be decided"
      },
      "field": {
        "id": {
          "label": "ID"
        },
        "name": {
          "label": "Name"
        },
        "interviewer": {
          "label": "Interviewer",
          "placeholder": "Choose interviewer..."
        },
        "branch": {
          "label": "Branch office"
        },
        "date": {
          "label": "Date and Time"
        },
        "time": {
          "label": "Time",
          "none": "First choose a location and date",
          "placeholder": "Choose time..."
        },
        "status": {
          "label": "Status interview invitation"
        }
      },
      "status": {
        "pending": "To be decided",
        "accepted": "Hired",
        "rejected": "Interview canceled by candidate",
        "rejectedRemark": "Do not want another appointment",
        "make-new-appointment": "Make new appointment"
      },
      "page": {
        "overview": {
          "title": "Interviews"
        }
      },
      "save": {
        "success": "Interview saved",
        "error": "Error while saving interview"
      },
      "empty": "No interviews requested",
      "multiselect": {
        "placeholder": "Find interviewer...",
        "selectedText": "# of # interviewers selected",
        "noneSelectedText": "Select interviewer"
      }
    }
  },
  "client": {
    "addUserButton": "Add customer user",
    "headerUserTable": "Customer users",
    "user": {
      "header": "Customer user",
      "emptyMail": "Enter an email address",
      "emptyFirstName": "Enter the customer user's first name",
      "emptyLastName": "Enter the customer user's last name",
      "incorrectMail": "The entered value in not a valid email address",
      "email": "Email"
    },
    "editButton": "Edit customer",
    "deleteButton": "Delete customer",
    "view": {
      "title": "Customers"
    },
    "edit": {
      "title": "Edit customer"
    },
    "create": {
      "title": "Create customer"
    },
    "overview": {
      "title": "Customers",
      "createButton": "Add customer"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "campaigns": {
        "label": "Number of campaigns"
      },
      "showMaxAmountOfCalls": {
        "label": "Use maximum number of call attempts"
      },
      "maxAmountOfCalls": {
        "label": "Total maximum number of call attempts client"
      },
      "users": {
        "label": "Number of users"
      },
      "max_start_date_delta": {
        "label": "Max. later start date",
        "placeholder": "e.g. for 2 months: P2M",
        "error": {
          "incorrect": "Format is not correct"
        }
      },
      "doubleConfigured": {
        "label": "Dual tablet configuration",
        "helpText": "For this customer a tablet can be scheduled twice on the same day. If this is the case the team with the lowest id number will be used first. As soon as that team is logged out, the next team will automatically be selected."
      },
      "sendEncryptedSubscriberMails": {
        "label": "Send encrypted mails",
        "helpText": "Mails from this customer will be encrypted before the subscriber receives his/her data. This is done with the last four digits of the customer's IBAN."
      },
      "maximumStorageTerm": {
        "label": "Max. retention period",
        "placeholder": "e.g. for 2 months: P2M",
        "helpText": "Example: P2M = 2 months, P84D = 84 days, P10W = 10 weeks"
      },
      "products": {
        "label": "Products"
      },
      "Acties": {
        "label": "Actions"
      },
      maxSubAmountWages: {
        label: "Max payout amount"
      }
    },
    "deleteTooltip": "Delete customer",
    "restoreTooltip": "Restore customer",
    "editTooltip": "Customize customer",
    "modal": {
      "delete": {
        "content": "You are about to delete a customer. Do you want to continue?",
        "cancelButton": "No",
        "deleteButton": "Remove"
      }
    },
    "save": {
      "error": "Couldn't save client!",
      "success": "Customer is stored"
    }
  },
  "wagesTariff": {
    "title": "Wages tariff PDF",
    "edit": "Wages tariff edit",
    "upload": "Drop PDF file here!"
  },
  "contract": {
    "title": "Contract",
    "field": {
      "id": {
        "label": "ID"
      },
      "label": {
        "label": "Label"
      },
      "content": {
        "label": "Content"
      },
      "draft": {
        "label": "Draft?"
      }
    }
  },
  "document": {
    "title": "Documents",
    "field": {
      "id": {
        "label": "ID"
      },
      "icon": {
        "label": "Icon"
      },
      "type": {
        "label": "Type",
        "value": {
          "employment contract": "Work contract",
          "contract": "Contract",
          "manual": "Manual"
        }
      },
      "userType": {
        "label": "Shown for",
        "value": {
          "basic": "All",
          "new_employees": "Only active employees",
          "new_job_applications": "Only job applicants"
        }
      },
      "label": {
        "label": "Label"
      },
      "required": {
        "label": "Required",
        "autoAssigning": "Automatically assigning"
      },
      "content": {
        "label": "Content"
      },
      "status": {
        "label": "Status",
        "value": {
          "draft": "Draft",
          "published": "Published",
          "archived": "Archived"
        }
      },
      "isPayroll": {
        "label": "Payroll document",
      },
    },
    "createButton": "Add document"
  },
  "handbook": {
    "title": "Handbook"
  },
  "privacy": {
    "title": "Privacy statement"
  },
  "captainsheetContractText": {
    "title": "Captainsheet Contract Edit"
  },
  "team": {
    "totalRecords": "Teams",
    "totalTeamMembers": "Promoters",
    "totalSubscriptionCount": "Subscriptions",
    "totalTeamMembersWithSignIn": "Sign in",
    "totalTeamMembersWithSignOut": "Sign out",
    "totalAverage": "A.P.P.",
    "totalAverageDR": "Avg. score",
    "totalSubscriptionsFactored": "Subscriptions total",
    "clear": "Clear field",
    "nav": {
      "overview": "Teams"
    },
    "title": "Team Planning",
    "page": {
      "overview": {
        "title": "Teams",
        "day": [
          "So",
          "Ma",
          "Di",
          "Wo",
          "Do",
          "Fri",
          "Za"
        ]
      }
    },
    "createButton": "Plan team",
    "importFromBriggsAndWalkersButton": "Import B&W",
    "prevButton": "Previous",
    "nextButton": "Next",
    "toFlushViewButton": "Flush teams",
    "downloadButton": "Team csv",
    "form": {
      "dateRangePlaceholder": "Choose date range"
    },
    "editTooltip": "Customize team",
    "viewTooltip": "View team",
    "viewSubscriptionsTooltip": "View entries",
    "noSubscriptionsTooltip": "No subscriptions",
    "batchTooltip": "Show subscribers of this team",
    "deleteTooltip": "Remove team",
    "copyTooltip": "Copy team",
    "flushTooltip": "Release locked scans",
    "uploadTooltip": "Upload scans",
    "addLocationButton": "Add location",
    "save": {
      "error": "Couldn't save team!",
      "success": "Team is saved"
    },
    "delete": {
      "confirm": "Really? Remove it?",
      "error": "Couldn't delete team!",
      "success": "Team has been removed"
    },
    "filter":{
      "deleted": "Deleted teams",
      "onlyDeleted": "Only deleted",
      "withoutDeleted": "Without deleted",
      "bothDeletedAndNot": "Both",
      "today": "Today",
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "client": {
        "label": "Customer"
      },
      "source": {
        "label": "Data source",
        "options": {
          "incenova": "Only Incenova",
          "bw": "Only B&W",
          "all": "All"
        }
      },
      "subscriptionsCount": {
        "label": "Team Score"
      },
      "day": {
        "label": "Day"
      },
      "week": {
        "label": "Week"
      },
      "attachement": {
        "label": "result",
        "verify": "Verify"
      },
      "members": {
        "label": "Members"
      },
      "date": {
        "label": "Date",
        "placeholder": "Date..."
      },
      "startTime": {
        "label": "Start time"
      },
      "endTime": {
        "label": "End time"
      },
      "status": {
        "label": "Status",
        "placeholder": "Status...",
        "value": {
          "planned": "Planned",
          "uploaded": "Uploaded",
          "entry": "enter",
          "completed": "Settled"
        }
      },
      "branch": {
        "label": "Branch office",
        "placeholder": "Location...",
        "permission": {
          "allocation": {
            "modify_team": "Adaptable to all branches, even if the user is not a member of that branch.",
            "modify_team:branch": "Only editable to branches the user is a member of. The dropdown will also only show branches that the user is a member of."
          }
        }
      },
      "campaign": {
        "label": "Campaign",
        "placeholder": "Campaign..."
      },
      "driver": {
        "label": "Driver",
        "placeholder": "Driver..."
      },
      "location": {
        "label": "Locations",
        "placeholder": "Location...",
        "add": "Add location"
      },
      "teamMember": {
        "add": "Add team member"
      },
      "remarks": {
        "label": "General note"
      },
      "teamCaptain": {
        "label": "Team Captain"
      },
      "shiftType": {
        "label": "Shift type"
      },
      "carDamageStart": {
        "label": "Car damage at the start"
      },
      "carDamageEnd": {
        "label": "Car damage at the end"
      },
      "carMileageStart": {
        "label": "Car mileage at the start"
      },
      "carMileageEnd": {
        "label": "Car mileage at the end"
      },
      "createdAt": {
        "label": "Created at"
      },
      "deleted": {
        "label": "Deleted"
      },
      "externalId": {
        "label": "External ID"
      },
      "licensePlate": {
        "label": "License plate"
      },
      "updatedAt": {
        "label": "Last updated at"
      }
    },
    "empty": {
      "label": "Empty teams?",
      "only": "Empty teams only",
      "none": "No empty teams",
      "both": "All teams"
    },
    "amount": {
      "label": "Number of",
      "placeholder": "Number of..."
    },
    "membersCount": {
      "label": "Members",
      "placeholder": "Members...",
      "empty": "No team members yet..."
    },
    "batchUpload": {
      "error": "Couldn't upload scans!",
      "success": "Scans have been uploaded",
      "errorUnknownFileType": "Choose a ZIP, PDF or JPEG file."
    },
    "sheetToScan": {
      "error": "Couldn't move sheet!",
      "success": "Sheet has been moved"
    },
    "scanToSheet": {
      "error": "Couldn't move scan!",
      "success": "Scan has been moved"
    },
    "saveStatusEntry": {
      "error": "Could not change status!",
      "success": "Status has been updated"
    },
    "lockScan": {
      "error": "Couldn't lock scan!",
      "errorNoEmployee": "Could not lock scan! Make sure your login is linked to a user.",
      "noScans": "No scans found."
    },
    "modal": {
      "delete": {
        "content": "You are about to delete a team. Do you want to continue?",
        "cancelButton": "No",
        "deleteButton": "Remove"
      }
    },
    "member": {
      "delete": {
        "tooltip": "Delete team member"
      },
      "restore": {
        "tooltip": "Recovery team member"
      }
    }
  },
  "teamLocation": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Location",
        "placeholder": "Location..."
      }
    }
  },
  "teamMember": {
    "field": {
      "employeeID": {
        "label": "Employee ID"
      },
      "plannedBrach": {
        "label": "Branch for which recruited"
      },
      "tabletBranch": {
        "label": "Branch tablet"
      },
      "location": {
        "label": "Dockyard location"
      },
      "campaign": {
        "label": "Campaign"
      },
      "date": {
        "label": "Date"
      },
      "id": {
        "label": "ID"
      },
      "team_member": {
        "label": "Team member",
        "placeholder": "Team member...",
      },
      "employee": {
        "label": "Team member",
        "placeholder": "Team member..."
      },
      "device": {
        "label": "Device",
        "placeholder": "Device..."
      },
      "function": {
        "label": "Function",
        "placeholder": "Function...",
        "info": "The function of the employee on the day of this team"
      },
      "note": {
        "this_is_old": "do not use",
        "label": "Note"
      },
      "remarks": {
        "label": "Note"
      },
      "sign": {
        "in": "Sign In",
        "out": "Sign Out"
      },
      "driver": {
        "label": "Is driver"
      },
      "subscriptionsCount": {
        "label": "Score"
      },
      "quantitiesCount": {
        "label": "Number of"
      },
      "shiftType": {
        "label": "Shift type"
      },
      "autoSignedOut": {
        "label": "Automatically signed out"
      },
      "createdAt": {
        "label": "Created at"
      },
      "damageSignIn": {
        "label": "Damage at sign in"
      },
      "damageSignOut": {
        "label": "Damage at sign out"
      },
      "deleted": {
        "label": "Deleted"
      },
      "fieldDay": {
        "label": "Field day"
      },
      "functionOverridden": {
        "label": "Function overridden"
      },
      "shiftTypeOverride": {
        "label": "Shift type overridden"
      },
      "signIn": {
        "label": "Signed in"
      },
      "signInLat": {
        "label": "Latitude sign in"
      },
      "signInLng": {
        "label": "Longitude sign in"
      },
      "signInTime": {
        "label": "Time at sign in"
      },
      "signOut": {
        "label": "Signed out"
      },
      "signOutLat": {
        "label": "Latitude sign out"
      },
      "signOutLng": {
        "label": "Longitude sign out"
      },
      "signOutTime": {
        "label": "Time at sign out"
      },
      "team": {
        "label": "Team ID"
      },
      "updatedAt": {
        "label": "Last updated at"
      }
    },
    "duplicate": {
      "error": "Team member already added. Select another team member."
    }
  },
  "traineeship": {
    "nav": {
      "overview": "Traineeships"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "title": {
        "label": "Title",
        "placeholder": "Title..."
      },
      "slug": {
        "label": "Slug",
        "placeholder": "Slug..."
      },
      "ordering": {
        "label": "Ordering",
        "placeholder": "Ordering..."
      },
      "autoAssign": {
        "label": "Auto assign",
        "placeholder": "Auto assign...",
        "labelEdit": "Automatically assign to everyone?",
        "autoAssigning": "Automatically assigning"
      },
      "video": {
        "label": "Video",
        "placeholder": "Video..."
      }
    },
    "create": {
      "title": "Add traineeship"
    },
    "delete": {
      "error": "Could not remove traineeship!",
      "success": "Traineeship has been removed"
    },
    "edit": {
      "pagesTitle": "Pages",
      "title": "Customize traineeship"
    },
    "createButton": "Add traineeship",
    "editTooltip": "Edit item",
    "statusSave": {
      "error": "Something went wrong changing the status.",
      "success": "The status has been successfully changed."
    },
    "assignmentCreate": {
      "error": "Something went wrong with making the traineeship available.",
      "success": "The traineeship has been successfully made available."
    },
    "contract": {
      "overview": "Contract"
    },
    "page": {
      "traineeshipEdit": "Customize traineeship",
      "traineeshipPageEdit": "Update page {{id}}.",
      "traineeshipPageAdd": "Add page"
    }
  },
  "traineeshipProgress": {
    "employeeTitle": "Staff member",
    "traineeshipTitle": "Traineeship",
    "traineeshipsTitle": "Traineeships",
    "statusTitle": "Status",
    "statusUnopened": "Not yet opened",
    "questionTitle": "Question",
    "answerTitle": "Answer",
    "noQuestions": "No questions asked",
    "noAnswer": "Not filled in",
    "errorOpening": "Could not open traineeship",
    "errorApproving": "Could not approve traineeship"
  },
  "traineeshipPage": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "text": {
        "label": "Text",
        "placeholder": "Text..."
      },
      "textPreview": {
        "label": "Text preview"
      },
      "video": {
        "label": "Video"
      },
      "questions": {
        "label": "Questions"
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "value": {
          "text": "Text",
          "questions": "Questions",
          "video": "Movie",
          "end-evaluation": "End Evaluation"
        }
      },
      "ordering": {
        "label": "Order"
      }
    },
    "create": {
      "title": "Add page"
    },
    "edit": {
      "title": "Customize page"
    },
    "createButton": "Add page",
    "save": {
      "success": "Page has been saved",
      "error": "Something went wrong saving the page"
    },
    "upload": {
      "error": "Something went wrong while uploading the video"
    }
  },
  "traineeshipQuestion": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "type": {
        "label": "Type",
        "value": {
          "input": "Free input field",
          "choice": "Multiple choice"
        }
      },
      "ordering": {
        "label": "Ordering"
      },
      "question": {
        "label": "Question",
        "placeholder": "question..."
      }
    },
    "createButton": "Add question",
    "newQuestion": "Add question",
    "editQuestion": "Question {{id}} modify",
    "selectQuestionType": "Select the question type"
  },
  "traineeshipChoice": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "ordering": {
        "label": "Order",
        "placeholder": "Order..."
      },
      "answer": {
        "label": "Answer",
        "placeholder": "Answer..."
      }
    },
    "createButton": "Add option"
  },
  "promotorAppMedia": {
    "nav": {
      "overview": "Promoter app media"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id"
      },
      "name": {
        "label": "Type",
        "placeholder": "Name...",
        "value": {
          "ViaVia": "ViaVia",
          "to-chose": "Choose a value"
        }
      },
      "enabled": {
        "label": "Active",
        "placeholder": "Active...",
        "value": {
          "true": "Yes",
          "false": "No"
        }
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "ViaViaImage": {
        "label": "Image",
        "placeholder": "Image...",
        "helpText": "Drag an image or click here to the left to upload."
      },
      "textField": {
        "label": "Promoter App Media text",
        "placeholder": "Promoter App Media text..."
      }
    },
    "create": {
      "title": "Add Promoter app media"
    },
    "edit": {
      "title": "Customize Promoter app media"
    },
    "createButton": "Add promoter app media",
    "editTooltip": "Promoter app media editing",
    "empty_image": "Empty"
  },
  "metafield": {
    "nav": {
      "overview": "Metafields"
    },
    "copyTooltip": "Copying an item",
    "editTooltip": "Edit item",
    "page": {
      "overview": {
        "title": "Metafields"
      }
    },
    "createButton": "Add metafield",
    "clearDefaultButton": "Clear default",
    "save": {
      "error": "Couldn't save metafield!",
      "success": "Metafield is stored"
    },
    "modal": {
      "delete": {
        "header": "Delete metafield",
        "content": "If you delete this metafield, it will also be deleted from the following products:",
        "confirmation": "Are you sure you want to delete this metafield?"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "slug": {
        "label": "Slug"
      },
      "label": {
        "label": "Label"
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "value": {
          "choice": "Choice field",
          "text": "Free input field",
          "boolean": "True/false",
          "multichoice": "Multiple choice",
          "suggestions": "Suggestions"
        }
      },
      "valueType": {
        "label": "Value Type",
        "placeholder": "Value of this type",
        "value": {
          "text": "text",
          "integer": "integer",
          "float": "float",
          "boolean": "boolean",
          "date": "date",
          "datetime": "datetime"
        }
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "is_preset": {
        "label": "Preset",
        "placeholder": "Preset..."
      },
      "ordering": {
        "label": "Order",
        "placeholder": "Order..."
      },
      "showInConfirm": {
        "label": "Show in confirm",
        "placeholder": "Show in confirm..."
      },
      "required": {
        "label": "Required",
        "placeholder": "Required..."
      },
      "forTeamCsv": {
        "label": "Grouping for team csv?"
      },
      "default_values": {
        "label": "Default value",
        "placeholder": "Default..."
      },
      "metadata": {
        "amount": {
          "label": "Amount"
        },
        "label": {
          "label": "Label"
        },
        "value": {
          "label": "Value"
        },
        "default": {
          "label": "Default"
        }
      },
      "controlledField": {
        "label": "Overwrite registration field",
        "info": "The value filled in here overwrites the default value of a subscription, subscriber. Currently only amount is supported. In that case, the value of this field is used for the amount field of the subscriber"
      }
    }
  },
  "viaVia": {
    "edit": {
      "title": "Edit viaVia"
    },
    "editTooltip": "Edit branch",
    "approveButton": "Agree",
    "rejectButton": "Reject",
    "nav": {
      "overview": "Via Via"
    },
    "unmatchButton": "Disconnect",
    "showMatchModalButton": "Linking",
    "matchModal": {
      "title": "Manual matching",
      "search": "Search for applicant"
    },
    "page": {
      "overview": {
        "title": "Via via"
      }
    },
    "save": {
      "error": "Couldn't save via via!",
      "success": "Via via is stored"
    },
    "detele": {
      "error": "Couldn't delete via via!",
      "success": "Via via is deleted"
    },
    "match": {
      "error": "Couldn't save match!",
      "success": "Match is saved"
    },
    "unmatch": {
      "error": "Couldn't save unmatch!",
      "success": "Unmatch is saved"
    },
    "filter": {
      "search": {
        "label": "Search",
        "placeholder": "Name..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "auto_matched": {
        "label": "AM?",
        "placeholder": "Automatic...",
        "title": "Auto-matched?"
      },
      "firstName": {
        "label": "First name",
        "placeholder": "First name..."
      },
      "middleName": {
        "label": "Insertion",
        "placeholder": "Insertion..."
      },
      "fullName": {
        "label": "Full name",
        "placeholder": "first and last name..."
      },
      "lastName": {
        "label": "Last name",
        "placeholder": "Last name..."
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "status": {
        "label": "Status",
        "placeholder": "Status...",
        "value": {
          "open": "Open",
          "matched": "Linked",
          "approved": "Settled"
        }
      },
      "phoneNumbers": {
        "label": "Phone number",
        "placeholder": "Phone number..."
      },
      "created_at": {
        "label": "Created on",
        "placeholder": "Created on..."
      },
      "branch": {
        "label": "Branch office",
        "placeholder": "Location..."
      },
      "job_application": {
        "label": "Applicant",
        "placeholder": "Applicant..."
      },
      "created_by": {
        "label": "Contributed by",
        "placeholder": "Brought to you by..."
      }
    }
  },
  "companyVideo": {
    "field": {
      "id": {
        "label": "ID"
      },
      "ordering": {
        "label": "Order",
        "placeholder": "Order..."
      },
      "title": {
        "label": "Title",
        "placeholder": "Title..."
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "thumb": {
        "label": "Picture",
        "placeholder": "Picture..."
      },
      "open": {
        "label": "Public",
        "title": "All users are allowed to see public videos."
      },
      "visibleToApplicants": {
        "label": "Visible to applicants",
      },
      "visibleToEmployees": {
        "label": "Visible to employees",
      },
      "video": {
        "label": "Video",
        "placeholder": "Video..."
      }
    }
  },
  "video": {
    "editTooltip": "Video editing",
    "createButton": "Add video",
    "nav": {
      "overview": "Video"
    },
    "overview": {
      "title": "Videos",
      "addButton": "Add video"
    },
    "edit": {
      "title": "Customize video"
    },
    "create": {
      "title": "Add video"
    },
    "save": {
      "error": "Could not save video!",
      "success": "Video is saved"
    },
    "delete": {
      "error": "Could not delete video!",
      "success": "Video has been removed"
    },
    "filter": {
      "search": {
        "label": "Search",
        "placeholder": "Name..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "ordering": {
        "label": "Order",
        "placeholder": "Order..."
      },
      "title": {
        "label": "Title",
        "placeholder": "Title..."
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "thumb": {
        "label": "Picture",
        "placeholder": "Picture..."
      },
      "open": {
        "label": "Public",
        "title": "All users are allowed to see public videos."
      },
      "video": {
        "label": "Video",
        "placeholder": "Video..."
      }
    },
    "modal": {
      "delete": {
        "content": "You are about to delete a video. Do you want to continue?",
        "cancelButton": "No",
        "deleteButton": "Remove"
      }
    }
  },
  "device": {
    "buttons": {
      "duplicate": "Merging duplicates",
      "qrCode": "View Qr code"
    },
    "logs": "View logs",
    "mergeButton": "Merge",
    "nav": {
      "overview": "Devices"
    },
    "overview": {
      "title": "Devices"
    },
    "modalMerge": {
      "title": "Choose duplicate",
      "noDuplicatesFound": "No duplicates found"
    },
    "editTooltip": "Customize device",
    "historyTooltip": "View history",
    "singular": "Device",
    "createButton": "Add device",
    "platform": {
      "ios": "ios",
      "android": "android"
    },
    "statuses": {
      "active": "Active",
      "unknown": "Unknown",
      "disabled": "Disabled"
    },
    "save": {
      "error": "Could not save device!",
      "success": "Device is saved"
    },
    "filter": {
      "search": {
        "label": "Search",
        "placeholder": "Device code, description..."
      },
      "branch": {
        "label": "Branch office"
      },
      "status": {
        "label": "Status",
        "multiselect": {
          "selectedText": "# of # statuses",
          "noneSelectedText": "Select status"
        },
        "multiselectFilter": {
          "placeholder": "Find..."
        }
      }
    },
    "field": {
      "identifier": {
        "label": "Device code"
      },
      "description": {
        "label": "Description",
        "placeholder": "Description..."
      },
      "status": {
        "label": "Status",
        "value": {
          "active": "Active",
          "unknown": "Unknown",
          "disabled": "Disabled"
        }
      },
      "branch": {
        "label": "Location",
        "placeholder": "Location..."
      },
      "subBranches": {
        "label": "sub-branches",
        "placeholder": "sub-branches..."
      },
      "owner": {
        "label": "Owner",
        "placeholder": "Owner..."
      },
      "employee": {
        "label": "Staff member"
      },
      "mode": {
        "label": "Mode"
      },
      "platform": {
        "label": "Platform"
      },
      "apiUrl": {
        "label": "CY_API_URL"
      },
      "referer": {
        "label": "CY_REFER"
      },
      "websocketUrl": {
        "label": "CY_WEBSOCKET_URL"
      },
      "apiKey": {
        "label": "CY_API_KEY"
      },
      "certName": {
        "label": "CY_CERT_NAME"
      },
      "googleMapsApiKey": {
        "label": "CY_GOOGLE_MAPS_API_KEY"
      },
      "sentryDsn": {
        "label": "CY_SENTRY_DSN"
      },
      "deviceEndpoint": {
        "label": "CY_DEVICE_ENDPOINT"
      },
      "employeesForDebug": {
        "label": "CY_EMPLOYEES_FOR_DEBUG"
      },
      "featureCaptainsheetDamage": {
        "label": "Feature captainsheet damage to?"
      },
      "featureRechtVanBezwaar": {
        "label": "Feature right of objection url?"
      },
      "deviceCode": {
        "label": "CY_DEVICE_CODE"
      },
      "lastUsed": {
          "label": "Last Used",
          "placeholder": "Last used..."
      },
    },
    "duplicate": {
      "error": "Device is already assigned. Select another device."
    },
    "history": {
      "title": "Overview of users"
    },
    "total": {
      "label": "Total"
    },
  },
  "deviceLocation": {
    "field": {
      "moment": {
        "label": "Measured on"
      },
      "point": {
        "label": "Location",
        "address": "Address"
      }
    }
  },
  "dashboard": {
    "nav": {
      "overview": "Dashboard"
    }
  },
  "position": {
    "field": {
      "startDate": {
        "label": "Start date"
      },
      "job": {
        "label": "Function"
      }
    }
  },
  "incedentalExpense": {
    "button": {
      "true": "Approve",
      "false": "Reject"
    },
    "form": {
      "either": "Both",
      "true": "Approved",
      "false": "Disapproved"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "compensationAmount": {
        "label": "Value"
      },
      "employee": {
        "label": "Employee"
      },
      "month": {
        "label": "Date"
      },
      "reason": {
        "label": "Reason"
      },
      "by": {
        "label": "By"
      },
      "bulkEmployees": {
        "label": "Employee with release"
      },
      "approved": {
        "label": "Approved"
      },
      "action": {
        "label": "Actions"
      },
      "approvedBy": {
        "label": "Approved by"
      },
      "rejectionReason": {
        "label": "Rejection reason"
      },
      "type": {
        "label": "Type",
        "value": {
          "gross": "Gross",
          "net": "Net"
        }
      },
      "category": {
        "label": "Category",
        "netCatagory": {
          "consumption": "Consumption",
          "officeSupplies": "Office supplies",
          "travel": "Travel",
          "accommodation": "Accommodation",
          "carRelatedExpenses": "Refueling / Parking",
          "other": "Other"
        },
        "grosCatagory": {
          "recruitment": "Recruitment",
          "bonus": "Bonus",
          "lead": "Lead",
          "headquarter": "Zentrale",
          "call_center": 'Call center',
          "other": "Other"
        }
      },
      "receipt": {
        "modal": {
          "title": "Compensation"
        },
        "label": "Receipt"
      }
    }
  },
  "factor": {
    "field": {
      "factor": {
        "label": "Factor"
      },
      "startDate": {
        "label": "Start date"
      },
      "endDate": {
        "label": "Eind date"
      },
      "reason": {
        "label": "Reason"
      },
      "by": {
        "label": "By"
      },
      "action": {
        "label": "Action"
      }
    }
  },
  "employee": {
    "walkinModal": {
      "title": "Applicant walks in"
    },
    status: {
      missingDocument: "Missing document"
    },
    "isActive": "User is activated",
    "isNotActive": "User account not yet activated, see re-send activation mail button at bottom of screen to resend email",
    "earning": {
      "buttonTitle": "Wages",
      "modalTitle": "Wage over time",
      "field": {
        "earning": {
          "tag": "Wage",
          "label": "Wage"
        },
        "period": {
          "tag": "Period",
          "label": "Period"
        }
      }
    },
    "period": "Period: ",
    "periodWeek": "Period {{periodNumber}}: ",
    "credits": "Credit",
    "incedentals": "incidental compensation",
    "invoiceNumber": "Invoice number",
    "compensation": {
      "modalTitle": "Other compensation employee",
      "buttonTitle": "Employee compensation",
      "bulkButtonTitle": "Create bulk compensations",
      "clearCompensation": "Set compensation to zero",
      "saveCompensation": "Save compensation",
      "editCompensation": "Edit compensation"
    },
    "factor": {
      "modalTitle": "Employee factor",
      "buttonTitle": "Employee factor",
      "clearCompensation": "Set factor to zero",
      "saveCompensation": "Save factor",
      "editCompensation": "Edit factor"
    },
    "calls": {
      "modal": {
        "incoming": "Incoming calls",
        "outcoming": "Outcomings calls",
        "buttonTitle": "Phone calls",
        "id": "ID",
        "caller": "Caller",
        "calledUser": "To",
        "start": "Start",
        "duration": "Duration",
        "voicelogs": "Voicelogs",
        "listenCall": "Play call recording",
        "audioError": "Your browser does not support the audio tag. "
      },
      "modalTitle": "Employee phone calls"
    },
    "edit": {
      "title": "Edit employee",
      "interviewTitle": "Interviews",
      "callBackTitle": "Callback appointments",
      "attachmentTitle": "Attachments",
      "positionTitle": "Positions",
      intentionDeclaration:{
        download: "Int. decl.",
        french: "French",
        dutch: "Dutch"
      },
      "disableSaveDR": "You need to select the front side and the back side of the ID before you can save."
    },
    "modal": {
      "viewID": {
        "title": "Staff member",
        "toolTip": "ID employee",
        "noID": "No ID available for this employee"
      }
    },
    "actions": "Actions",
    "editTooltip": "Edit employee",
    "createButton": "Add employee",
    "attachment": "Annex",
    "attachmentLabel": "Annex",
    "attachmentEmpty": "Not uploaded",
    "uploadIDFirst": "Please upload and save both ID pictures first.",
    "nav": {
      "applicants": "Applicants",
      "interviews": "Job interviews",
      "traineeships": "Traineeship progress",
      "overview": "Employees"
    },
    "save": {
      "error": "Could not save user!",
      "success": "User saved"
    },
    "filter": {
      "group": {
        "selectedText": "# of # groups selected",
        "noneSelectedText": "Select groups",
        "placeholder": "Searching..."
      },
      "deleted": "Deleted employees",
      "onlyDeleted": "Only deleted",
      "withoutDeleted": "Without deleted",
      "bothDeletedAntNot": "All"
    },
    "currentTraineeship": "Status traineeship",
    "showTraineeshipButton": "Show traineeships",
    "showTraineeshipButtonTooltip": "Show traineeships",
    "showUserButton": "Show user",
    "showUserButtonTooltip": "Show user",
    "sendAppInviteEmail": {
      "tooltip": "Send promoter app invite email",
      "success": "Email has been sent",
      "error": "Could not send email"
    },
    "rotateEmployeeAvatar": {
      "success": "Avatar is rotated",
      "error": "Could not rotate avatar"
    },
    "traineeship": {
      "approveButton": "Approve",
      "reset": {
        "title": "Reset to default",
        "confirm": "Are you sure you want to reset this employee's traineeship to default? All answers will be deleted.",
      }
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "employee": {
        "label": "Promoter",
        "placeholder": "Promoter..."
      },
      "last_sign_in": {
        "label": "Last working day"
      },
      "nationalityInternal": {
        "label": "Nationality"
      },
      "firstName": {
        "label": "First name",
        "placeholder": "First name..."
      },
      "middleName": {
        "label": "Insertion",
        "placeholder": "Insertion..."
      },
      "lastName": {
        "label": "Last name",
        "placeholder": "Last name..."
      },
      "dateOfBirth": {
        "label": "Date of birth"
      },
      "phoneNumbers": {
        "label": "Telephone numbers",
        "placeholder": "Phone numbers..."
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "can_change_own_campaign": {
        "label": "Can select own campaign",
        "placeholder": "Can self-select campaign..."
      },
      "avatar": {
        "label": "Profile photo",
        "placeholder": "Profile photo..."
      },
      "mainBranch": {
        "label": "Headquarters",
        "placeholder": "Select headquarters..."
      },
      "main_branch": {
        "label": "Headquarters",
        "placeholder": "Select headquarters..."
      },
      "branches": {
        "label": "Branches",
        "placeholder": "Location...",
        "selectedText": "# of # establishments",
        "noneSelectedText": "Select location"
      },
      "initials": {
        "label": "Initials",
        "placeholder": "Initials..."
      },
      "first_name": {
        "label": "First name",
        "placeholder": "First name..."
      },
      "middle_name": {
        "label": "Insertion",
        "placeholder": "Insertion..."
      },
      "last_name": {
        "label": "Last name",
        "placeholder": "Last name..."
      },
      "gender": {
        "label": "Gender",
        "value": {
          "male": "man",
          "female": "woman",
          "unkown": "unknown",
          "other": "Otherwise"
        }
      },
      "age": {
        "label": "Specified age"
      },
      "date_of_birth": {
        "label": "Date of birth"
      },
      "phone_numbers": {
        "label": "Telephone numbers",
        "placeholder": "Phone numbers..."
      },
      "ssn": {
        "label": "BSN",
        "placeholder": "BSN..."
      },
      "ssnBE": {
        "label": "Social Security number",
        "placeholder": "Social Security number..."
      },
      "isFreelance": {
        "label": "Self-employed?"
      },
      "companyName": {
        "label": "Company name"
      },
      "dateStarted": {
        "label": "Start date"
      },
      "vatNumber": {
        "label": "VAT ID"
      },
      "cocNumber": {
        "label": "KVK number"
      },
      "referralSource": {
        "label": "Source"
      },
      "country": {
        "label": "Country",
        "placeholder": "Land...",
        "value": {
          "NL": "EN",
          "BE": "BE",
          "FR": "FR",
          "DE": "THE",
          "OTHER": "Otherwise"
        }
      },
      "iban": {
        "label": "IBAN",
        "placeholder": "IBAN..."
      },
      "house_number": {
        "label": "House number",
        "placeholder": "House number..."
      },
      "houseNumber": {
        "label": "House number",
        "placeholder": "House number..."
      },
      "house_number_suffix": {
        "label": "House number addition",
        "placeholder": "House number addition..."
      },
      "houseNumberSuffix": {
        "label": "House number addition",
        "placeholder": "House number addition..."
      },
      "city": {
        "label": "Town",
        "placeholder": "City..."
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "street": {
        "label": "Street",
        "placeholder": "Street..."
      },
      "zip_code": {
        "label": "Postcode",
        "placeholder": "Zip code..."
      },
      "zipCode": {
        "label": "Postcode",
        "placeholder": "Zip code..."
      },
      "employee_number": {
        "label": "Personnel no.",
        "placeholder": "Personnel no..."
      },
      "employeeNumber": {
        "label": "Personnel no.",
        "placeholder": "Personnel no..."
      },
      "driving_licence": {
        "label": "Has driving licence",
        "placeholder": "Driver's license...",
        "value": {
          "false": "No",
          "true": "Yes"
        }
      },
      "public_transport_student_license": {
        "label": "Has student OV",
        "placeholder": "Student OV...",
        "value": {
          "false": "No",
          "true": "Yes"
        }
      },
      "drivingLicence": {
        "label": "Has driving licence",
        "placeholder": "Driver's license...",
        "value": {
          "false": "No",
          "true": "Yes"
        }
      },
      "canCall": {
        "label": "Can call",
        "value": {
          "false": "No",
          "true": "Yes",
          "both": "Both"
        }
      },
      "bank_card": {
        "label": "Bank Card",
        "placeholder": "Bank card..."
      },
      "bankCard": {
        "label": "Bank Card",
        "placeholder": "Bank card..."
      },
      "tax_exemption": {
        "label": "Tax credit",
        "placeholder": "Tax credits..."
      },
      "taxExemption": {
        "label": "Tax credit",
        "placeholder": "Tax credits..."
      },
      "can_change_tax_exemption": {
        "label": "Can tax exemption change?",
        "placeholder": "Can tax exemption change?"
      },
      "canChangeTaxExemption": {
        "label": "Can tax exemption change?",
        "placeholder": "Can tax exemption change?"
      },
      "contract_signature": {
        "label": "Contract",
        "placeholder": "Contract..."
      },
      "contractSignature": {
        "label": "Contract",
        "placeholder": "Contract..."
      },
      "identification": {
        "label": "ID card front side",
        "placeholder": "ID card front..."
      },
      "identification_back": {
        "label": "ID card back",
        "placeholder": "ID card back..."
      },
      "identificationBack": {
        "label": "ID card back",
        "placeholder": "ID card back..."
      },
      "bw_id": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "bwID": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "city_of_birth": {
        "label": "Birthplace",
        "placeholder": "hometown..."
      },
      "cityOfBirth": {
        "label": "Birthplace",
        "placeholder": "hometown..."
      },
      "country_of_birth": {
        "label": "Country of birth",
        "placeholder": "Country of birth..."
      },
      "countryOfBirth": {
        "label": "Country of birth",
        "placeholder": "Country of birth..."
      },
      "id_type": {
        "label": "ID type",
        "placeholder": "ID type..."
      },
      "idType": {
        "label": "ID type",
        "placeholder": "ID type..."
      },
      "id_number": {
        "label": "ID number",
        "placeholder": "ID number..."
      },
      "idNumber": {
        "label": "ID number",
        "placeholder": "ID number..."
      },
      "interviewer": {
        "label": "Interviewer?",
        "title": "The employee can then be chosen from the list of interviewers for job interviews."
      },
      "coins": {
        "label": "Coins"
      },
      "blockedClients": {
        "label": "Blocked Customers"
      },
      "referralSourceOther": {
        "label": "Source else"
      },
      status: {
        label: 'Status',
        value: {
          active: "Active",
          inactive: "Inactive",
          outflow: "Outflow",
        }
      },
      "points": {
        "label": "Points"
      },
      "legalGuardianName": {
        "label": "Name of legal guardian"
      },
      "legalGuardianPhone": {
        "label": "Phone number of legal guardian"
      },
      "legalGuardianEmail": {
        "label": "E-mail address of legal guardian"
      },
      "adres": {
        "label": "Address"
      },
      "compensationAmount": {
        "label": "Occasional financial compensation"
      },
      "externalContractSigned": {
        "label": "Contract signed outside system"
      },
      "factor": {
        "label": "Factor",
        "placeholder": "Factor..."
      },
      "travelingDistance": {
        "label": "Travel distance"
      },
      "bic": {
        "label": "BIC",
        "placeholder": "BIC..."
      },
      "city_of_residence": {
        "label": "Location"
      },
      "publicTransportStudentLicenseType": {
        "label": "Type of student OV",
        "value": {
          "no": "No",
          "week": "Week",
          "weekend": "Weekend"
        }
      },
      "canSelectOwnCampaign": {
        "label": "May select own campaign",
        "info": "This freelancer can choose which campaigns he or she recruits for (This is done on the field sales app)"
      }
    },
    "creditations": "Crediting",
    "anonymizeModal": {
      'title': 'Anonymize',
      'description': 'Are you sure you want to anonymize employee {{fullName}}? All personal information will be overwritten. This cannot be undone.',
      'anonymizeButton': 'Anonymize',
    }
  },
  "export": {
    "editTooltip": "Customize Results",
    "exportTooltip": "Export Results",
    "save": {
      "error": "Couldn't save definition!",
      "success": "Definition is stored"
    },
    "download": {
      "error": "Couldn't download exports!"
    },
    "value_null": {
      "null": "is equal to null",
      "not_null": "is not equal to null"
    },
    "date_component_truncate": {
      "century": "century",
      "day": "hello",
      "decade": "decade",
      "dow": "day of the week",
      "doy": "day of the year",
      "epoch": "seconds since 1-1-1970 00:00:00 UTC",
      "hour": "hour",
      "isodow": "day of the week (ISO)",
      "isoyear": "day of the year (ISO)",
      "microseconds": "microsecond",
      "millenium": "millennium",
      "milliseconds": "millisecond",
      "minute": "minute",
      "month": "month",
      "quarter": "quarter",
      "second": "second",
      "timezone": "UTC time zone difference",
      "timezone_hour": "hours difference from UTC",
      "timezone_minute": "minutes difference from UTC",
      "week": "week",
      "year": "year",
      "millennium": "millennium"
    },
    "createButton": "Export Add"
  },
  "branch": {
    "createButton": "Add branch",
    "editTooltip": "Edit branch",
    "nav": {
      "overview": "Branches"
    },
    "create": {
      "title": "Add branch"
    },
    "overview": {
      "createButton": "Add branch"
    },
    "edit": {
      "title": "Change location"
    },
    "save": {
      "error": "Couldn't save the office!",
      "success": "Establishment is stored"
    },
    "filter": {
      "search": {
        "label": "Search",
        "placeholder": "Name..."
      }
    },
    "field": {
      "mailjetTemplateWelcome": {
        "label": "Template ID welcome email",
        "info": "The welcome message that new requests from this branch receive, a mailjet transactional id"
      },
      "mailjetTemplateActivation": {
        "label": "Template ID activation email",
        "info": "The acccount activation message that new requests/employees from this branch receive, a mailjet transactional id"
      },
      "mailjetTemplatePasswordReset": {
        "label": "Template ID password reset email",
        "info": "The password reset template for this branch, a mailjet transactional id"
      },
      "mailjetTemplateAccountEdited": {
        "label": "Template ID account custom email",
        "info": "The mailjet template of the mail that employees receive when their information in the system is updated, a mailjet transactional id"
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "branch": {
        "label": "Branch office",
        "placeholder": "Location..."
      },
      "code": {
        "label": "Code",
        "placeholder": "Code..."
      },
      "avatar": {
        "label": "Avatar",
        "placeholder": "Avatar..."
      },
      "address": {
        "label": "Address",
        "placeholder": "Address..."
      },
      "phoneNumber": {
        "label": "Phone number",
        "placeholder": "Phone number..."
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "manager": {
        "label": "Manager",
        "placeholder": "Choose manager..."
      },
      "nmbrsCodeCostplace": {
        "label": "Nmbrs Code Costcenter",
        "placeholder": "Nmbrs Code Costcenter..."
      },
      "nmbrsCodeDepartment": {
        "label": "Nmbrs Code Department",
        "placeholder": "Nmbrs Code Department..."
      },
      "public": {
        "label": "Public?",
        "title": "All users are allowed to see public branches.",
        "info": "All users are allowed to see public branches."
      }
    },
    "multiselect": {
      "placeholder": "Find branch...",
      "selectedText": "# of # establishments selected",
      "noneSelectedText": "Select location"
    },
    "textInputExample": "The available times for this site in the interview plan popup when requesting. Input by time unit. E.g. 10:00 AM or 01:30 PM",
    "interviewSlots": "Interview slots",
    "interviewTimes": "By day, in slots e.g. 10:00 AM"
  },
  "bwBranchTranslation": {
    "editTooltip": "Edit branch",
    "createButton": "Add branch",
    "nav": {
      "overview": "Branches"
    },
    "create": {
      "title": "Create branch"
    },
    "overview": {
      "createButton": "Add branch",
      "title": "BW Branches"
    },
    "edit": {
      "title": "Change location"
    },
    "save": {
      "error": "Couldn't save the office!",
      "success": "Establishment is stored"
    },
    "filter": {
      "search": {
        "label": "Search",
        "placeholder": "Name..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "officeCode": {
        "label": "Office Code"
      },
      "branch": {
        "label": "Branch office",
        "placeholder": "Location..."
      },
      "code": {
        "label": "Code",
        "placeholder": "Code..."
      },
      "avatar": {
        "label": "Avatar",
        "placeholder": "Avatar..."
      },
      "address": {
        "label": "Address",
        "placeholder": "Address..."
      },
      "phone_number": {
        "label": "Phone number",
        "placeholder": "Phone number..."
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "manager": {
        "label": "Manager",
        "placeholder": "Choose manager..."
      },
      "nmbrs_code_costplace": {
        "label": "Nmbrs Code Costcenter",
        "placeholder": "Nmbrs Code Costcenter..."
      },
      "nmbrs_code_department": {
        "label": "Nmbrs Code Department",
        "placeholder": "Nmbrs Code Department..."
      },
      "public": {
        "label": "Public?",
        "title": "All users are allowed to see public branches."
      }
    },
    "multiselect": {
      "placeholder": "Find branch...",
      "selectedText": "# of # establishments selected",
      "noneSelectedText": "Select location"
    }
  },
  "exportArchive": {
    "title": "Export archive",
    "download": "Download",
    "field": {
      "id": {
        "label": "ID"
      },
      "file": {
        "label": "Download"
      },
      "bundleDescription": {
        "label": "Export"
      },
      "definitionDescription": {
        "label": "File"
      },
      "createdAt": {
        "label": "Exported on"
      }
    }
  },
  "bucket": {
    "save": {
      "error": "Couldn't save bucket!",
      "success": "Bucket has been saved"
    },
    "field": {
      "callers": {
        "label": "Callers",
        "placeholder": "Add caller..."
      }
    }
  },
  "call": {
    "resultOptions": {
      "reachedChanged": "Subscription changed",
      "reachedThrough": "Subscribe through",
      "reachedCancelled": "Subscription cancelled",
      "reachedLater": "Submit subscription later",
      "reachedCallback": "Callback (T.B.A.) made",
      "notReachedPhone": "Incorrect/not customer's phone number",
      "notReachedCallBack": "Not answered, again tomorrow",
      "notReachedCallBackSoon": "Not answered, again in five minutes",
      "notReachedNoAnswer": "Not reached, forward for export"
    },
    "modal": {
      "anonymous": "call agent",
      "downloadButton": "Download voicelog"
    },
    "mobile": "Mobile",
    "landLine": "Landline",
    "attempts": "Call attempts",
    "maxAttempts": "Max number",
    "statistics": {
      "label": "Statistics",
      "amount": "Number of records",
      "target": "To be achieved",
      "reached": "Reached",
      "percentage": "Reach percentage",
      "not_reachable": "Not reachable",
      "cancelations": "Cancellation",
      "not_reached": "Not achieved",
      "call_later": "Call back later"
    },
    "toggleScanButton": {
      "show": "Show scan",
      "hide": "Hide scan"
    },
    "confirmModal": {
      "unsavedChanges": "The current appointment contains unsaved changes, are you sure you want to switch?",
      "cancelSub": "Are you sure you want to cancel this subscription?",
      "callTomorrow": "Are you sure you want to call this subscription back tomorrow?",
      "callFiveMin": "Are you sure you want to call this subscription back in five minutes? "
    },
    "newAppointment": {
      "modalHeader": "Schedule new appointment",
      "header": "Select the date and time of the new appointment"
    },
    dropdownError: "Set appointment status!"
    },
    "bwCampaignTranslation": {
      "nav": {
        "overview": "BwCampagnes"
      },
      "page": {
        "overview": {
          "title": "BW Campaign"
        }
      },
      "createButton": "Add campaign",
      "save": {
        "error": "Could not save campaign!",
        "success": "Campaign is saved"
      },
      "field": {
        "id": {
          "label": "ID"
        },
        "branches": {
          "label": "Branch office",
          "placeholder": "Branching...",
          "selectedText": "# of # establishments",
          "noneSelectedText": "Select location"
        },
        "campaign": {
          "label": "Incenova Campaign"
        },
        "importStatus": {
          "label": "Registration import status"
        },
        "products": {
          "label": "Products",
          "placeholder": "Products...",
          "selectedText": "# of # products",
          "noneSelectedText": "Select Product"
        },
        "accountDomain": {
          "label": "Account Domain B&W"
        },
        "projectCode": {
          "label": "Project Code B&W"
        },
        "name": {
          "label": "Name",
          "placeholder": "Name..."
        },
        "callScriptWelcome": {
          "label": "Call script",
          "placeholder": "Belscript ..."
        },
        "client": {
          "label": "Customer",
          "placeholder": "Customer..."
        },
        "draftContentbundle": {
          "label": "Draft content bundle",
          "placeholder": "Draft content bundle..."
        },
        "finalContentbundle": {
          "label": "Final content bundle",
          "placeholder": "Final content bundle..."
        },
        "unit": {
          "label": "Pricing"
        },
        "enableStartDate": {
          "label": "Use start date"
        },
        "bwIds": {
          "label": "Briggs and walker id",
          "placeholder": "Briggs and walker id..."
        },
        "captainsheetContract": {
          "label": "Captainsheet contract",
          "placeholder": "Captainsheet contract ..."
        }
      }
    },
  "campaign": {
    "editTooltip": "Edit item",
    "nav": {
      "overview": "Campaigns"
    },
    "page": {
      "overview": {
        "title": "Campaign"
      }
    },
    "createButton": "Add campaign",
    "save": {
      "error": "Couldn't save campaign!",
      "success": "Campaign is saved"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "rexV2OutboundNumber": {
        "label": "Dial out Rex",
        "options": {
          "anonymous": "Anonymous"
        }
      },
      "branches": {
        "label": "Branch office",
        "placeholder": "Location...",
        "selectedText": "# of # establishments",
        "noneSelectedText": "Select location",
        "info": "Which branches can use this campaign, ZZP employees can also be limited in this way of campaign through their (main) branch office"
      },
      "products": {
        "label": "Products",
        "placeholder": "Products...",
        "selectedText": "# of # products",
        "noneSelectedText": "Select Product"
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "captainsheetContractText": {
        "label": "Captainsheet contract"
      },
      "callScriptWelcome": {
        "label": "Call script",
        "placeholder": "Belscript..."
      },
      "client": {
        "label": "Customer",
        "placeholder": "Customer..."
      },
      "draftContentbundle": {
        "label": "Draft content bundle",
        "placeholder": "Draft content bundle...",
        "info": "If a table is in draft (test) mode, this content bundle is sent along"
      },
      "finalContentbundle": {
        "label": "Final content bundle",
        "placeholder": "Final content bundle...",
        "info": "When a table is in final (production) mode, this content bundle is sent along"
      },
      "unit": {
        "label": "Price quote",
        "info": "The period of the charge that appears on the product registration screen, e.g. \"Monthly\", \"Weekly\", \"Daily\"."
      },
      "rexUsername": {
        "label": "REX extension username",
        "info": "The user name of the specific phone number (from rex V1) to be called for this specific campaign"
      },
      "rexPassword": {
        "label": "REX extension password",
        "info": "the password of the specific phone number (from rex V1) to be called for this specific campaign"
      },
      "enableStartDate": {
        "label": "Use start date",
        "info": "Use for this campaign the start date limit as configured on the associated product"
      },
      "bwIds": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "enableEmergencySignature": {
        "label": "Accept signature as verification",
        "info": "Show on the tablet registration screen the possibility to use signature verification instead of SMS verification"
      },
      "enableGenderOther": {
        "label": "Accept 'Other' as gender",
        "info": "Show on the tablet registration screen the possibility to use 'Other' as gender"
      },
      "requireFirstName": {
        "label": "Require first name to be set",
        "info": "Require on the tablet registration screen that the first name is set"
      },
      "sepa": {
        "label": "SEPA",
        "placeholder": "SEPA"
      },
      "configuration": {
        "label": "Configuration",
      },
      "defaultPaymentInterval": {
        "label": "Frequency",
        "info": "Frequency info"
      },
      "wageStructure": {
        "label": "Active bonus structure",
        "info": "The bonus structure currently used to reward employees with results belonging to this campaign"
      },
      "ageWildcards": {
        "label": "Jokers",
      },
    }
  },
  "shiftType": {
    "createButton": "Add shift type",
    "field": {
      "id": {
        "label": "Id"
      },
      "name": {
        "label": "Name"
      },
      "description": {
        "label": "Description"
      },
      "isDefault": {
        "label": "Default?"
      },
      "baseWage": {
        "label": "Basic pay shift"
      }
    },
    "edit": {
      "title": "Shift type adjustment"
    }
  },
    subscriptionReason: {
        createButton: 'Add reason',
        edit: {
            title: 'Edit reason',
        },
        field: {
            id: {
                label: 'ID',
            },
            code: {
                label: 'Code',
            },
            description: {
                label: 'Description',
            },
            type: {
                label: 'Type',
                value: {
                    cancelled: 'Cancelled',
                    completed: 'Completed',
                    general: 'General',
                },
            },
        }
    },
  "csv": {
    "csvMissing": "Upload a csv",
    "createButton": "Csv upload",
    "field": {
      "id": {
        "label": "Id"
      },
      "createdAt": {
        "label": "Upload date"
      },
      "updatedAt": {
        "label": "Last update"
      },
      "uploader": {
        "label": "Uploader"
      },
      "csv": {
        "label": "File"
      },
      "type": {
        "label": "Type",
        "options": {
          "coin_addition": "Coin addition",
          "external_sub_to_call": "Subscription external calling",
          "client_ref_nrs": "Client reference number",
          "job_applications": "Job applications",
          "azg_subscriptions": "AzG subscriptions",
        },
        "example": {
          "title": "Explanation",
          "downloadExampleButton": "Download example file",
          "coin_addition": "The CSV file has 2 columns: id of employee & number of coins in integers. See also the example file which contains 2 employees. The first line is for employee with id 1 who gets 25 coins. The second line is for employee 2 who gets 13 coins.",
          "external_sub_to_call": "The CSV file consisting of at least 26 columns: external_id, status, employee_id, recruit_date, start_date, frequency, datetime_of_cancelation, date_of_birth, first_name, middle_name, last_name, gender, street, house_no, house_no_suffix, city, zipcode, country, email, mobile, landline, iban, product_id, campaign_id, amount, quantity, *custom. See the example below for a CSV file with registered registration. The last column custom image that any column after the 21st column will be placed as a sentence in the field of the entry.",
          "client_ref_nrs": "The CSV file has 2 columns: reference number and client id.",
          "job_applications": "The CSV file has 8 columns:creation date, first_name, initials, last_name, email, phone_number, referral_source, referral_source_other.",
          "azg_subscriptions": "The CSV file is unchanged format CSV form AzG. Columns which are processed are: id_werving, qual_id, opmerking, bedrag NIEUW, Frequentie NIEUW (columns number 7,9,16,18 respectively)."
        }
      },
      "status": {
        "label": "Status",
        "options": {
          "uploaded": "Uploaded",
          "processed": "Processed"
        }
      }
    }
  },
  "bwProductTranslation": {
    "edit": {
      "b&w": "B&W",
      "sms": "Incenova",
      "metafields": "Metafields"
    },
    "field": {
      "id": {
        "label": "id"
      },
      "bwCampaign": {
        "label": "B&W Campaign"
      },
      "bwProductId": {
        "label": "Briggs & Walker Id"
      },
      "accountDomain": {
        "label": "Account Domain B&W"
      },
      "projectCode": {
        "label": "Project Code B&W"
      },
      "productCode": {
        "label": "Product Code B&W"
      },
      "product": {
        "label": "Product Incenova"
      },
      "quantity": {
        "label": "Number of"
      },
      "amount": {
        "label": "Price per piece"
      }
    }
  },
  "ContentbundleTranslation": {
    "edit": {
      "Contentbundle": "Content bundles"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "name": {
        "label": "Name"
      }
    }
  },
  "bwProductDefaultMetafieldValue": {
    "field": {
      "value": {
        "label": "Value"
      }
    }
  },
  "chapter": {
    "createButton": "Add chapter",
    "save": {
      "error": "Couldn't save chapter!",
      "duplicateNameError": "There is already a chapter with this name!",
      "noNameError": "You have not entered a name!",
      "success": "Chapter is saved"
    },
    "delete": {
      "content": "You are about to delete a chapter. Do you want to continue?",
      "error": "Couldn't delete chapter!",
      "success": "Chapter has been deleted"
    }
  },
  "permission": {
    "showDescriptionButton": "Show rights description",
    "hideDescriptionButton": "Hide rights description",
    "allocation": {
      "modify_team": {
        "label": "Create, update and delete teams",
        "description": "Adaptable to all branches, even if the user is not a member of that branch."
      },
      "modify_team:branch": {
        "label": "Create, update and delete teams for own branch",
        "description": "Only editable to branches the user is a member of."
      }
    }
  },
  "qrCodeGenerator": {
    "title": "Qr-code generator",
    "field": {
      "CY_API_URL": "CY_API_URL",
      "CY_REFERER": "CY_REFER",
      "CY_WEBSOCKET_URL": "CY_WEBSOCKET_URL",
      "CY_API_KEY": "CY_API_KEY",
      "CY_CERT_NAME": "CY_CERT_NAME",
      "CY_GOOGLE_MAPS_API_KEY": "CY_GOOGLE_MAPS_API_KEY",
      "CY_SENTRY_DSN": "CY_SENTRY_DSN",
      "CY_DEVICE_ENDPOINT": "CY_DEVICE_ENDPOINT",
      "CY_EMPLOYEES_FOR_DEBUG": "CY_EMPLOYEES_FOR_DEBUG",
      "CY_DEVICE_CODE": "CY_DEVICE_CODE"
    }
  },
  "tooltips": {
    "delete": "Remove",
    "restore": "Restore",
    "edit": "Edit",
    "view": "View",
    "rotate": "Turning"
  },
  "bwImportFailure": {
    "field": {
      "id": {
        "label": "ID"
      },
      "bwSubscriptionId": {
        "label": "B&W subscription reference"
      },
      "failure": {
        "label": "Error"
      },
      "bwData": {
        "label": "Data"
      },
      "createdAt": {
        "label": "Faulty moment"
      },
      "trash": {
        "label": "Remove Errors"
      }
    }
  },
  "news": {
    "createButton": "Create News",
    "saveAndPublishButton": "Save & publish",
    "field": {
      "id": {
        "label": "ID"
      },
      "title": {
        "label": "Title"
      },
      "image": {
        "label": "Image"
      },
      "status": {
        "label": "Status",
        "value": {
          "draft": "Concept",
          "published": "Published"
        }
      },
      "content": {
        "label": "Message",
        "preview": "Example",
        "markdown": "Markdown formatting"
      },
      "createdBy": {
        "label": "Created by"
      },
      "createdAt": {
        "label": "Created on"
      },
      "publishedBy": {
        "label": "Published by"
      },
      "publishedAt": {
        "label": "Published on"
      }
    }
  },
  "milestone": {
    "createButton": "Create Milestone",
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      },
      "description": {
        "label": "Description"
      },
      "points": {
        "label": "Points"
      },
      "icon": {
        "label": "Icon"
      }
    }
  },
  "token": {
    "createButton": "Add token",
    "field": {
      "id": {
        "label": "ID"
      },
      "token": {
        "label": "Token"
      },
      "createdAt": {
        "label": "Created at"
      },
      "lastUsedAt": {
        "label": "Last used on"
      },
      "expiresAt": {
        "label": "Expires on "
      },
      "expired": {
        "label": "Expired"
      },
      "content": {
        "markdown": "Markdown formatting"
      }
    }
  },
  "tip": {
    "overview": {
      "createButton": "Create tip"
    },
    "type": {
      "basic": "General",
      "new_employees": "New employees",
      "new_job_applications": "New applications"
    },
    "nav": {
      "overview": "Tips"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "value": {
          "basic": "Standard",
          "new_job_applications": "New applicants",
          "new_employees": "New employees"
        }
      },
      "text": {
        "label": "Text",
        "placeholder": "Text..."
      }
    },
    "create": {
      "title": "Add a tip"
    },
    "edit": {
      "title": "Customize tip"
    },
    "createButton": "Add a tip",
    "editTooltip": "Edit item"
  },
  "history": {
    "modal": {
      "buttonTitle": "History",
      "subscription": {
        "title": "History of this subscription"
      },
      "team": {
        "title": "History of this team"
      }
    },
  },
  "clientFile": {
    "createButton": "Add customer file",
    "field": {
      "id": {
        "label": "ID"
      },
      "client": {
        "label": "Customer"
      },
      "name": {
        "label": "File name"
      },
      "createdAt": {
        "label": "Upload date"
      },
      "deletionDate": {
        "label": "Remove datum"
      },
      "uploader": {
        "label": "Uploader"
      },
      "file": {
        "label": "File"
      }
    }
  },
  "Appointment": {
    "toggleLegend": "legend",
    "field": {
      "id": {
        "label": "ID"
      },
      "status": {
        "label": "Status",
        "value": {
          "queued": "To be called",
          "assigned": "Assigned",
          "reached": "Reached",
          "not reachable": "Not reachable",
          "not reached": "Not achieved"
        },
        "legend": {
          "carryThrough": "Continued unchanged",
          "carryThroughChanged": "Continue changed",
          "phoneIncorrect": "Phone incorrect",
          "notAnswered": "Not included",
          "cancelled": "Cancelled",
          "callBackSoonNoTime": "Call back soon without an appointment",
          "callBackSoon": "Call back soon",
          "callBackNow": "Callback appointment five minutes",
          "callBackToday": "Call back appointment today",
          "callBackFuture": "Call back appointment other day"
        }
      },
      "remarks": {
        "label": "Remarks",
        "value": {
          "": "",
          "changed": "Subscription changed",
          "carry through": "Subscribe through",
          "canceled": "Subscription cancelled",
          "carry through later": "Submit subscription later",
          "call appointment": "Callback (T.B.A.) made",
          "phonenumber not correct": "Incorrect/not customer's phone number",
          "not answered call back": "Not answered, again tomorrow",
          "not answered call back soon": "Not answered, again in five minutes",
          "not answered": "Not reached, forward for export"
        }
      },
      "subscription": {
        "label": "Registration"
      },
      "bucket": {
        "label": "Bucket"
      },
      "callAt": {
        "label": "Calling"
      },
      "caller": {
        "label": "Caller"
      }
    }
  },
  "brandingMessage": "Powered by Code Yellow",
  "formElement": {
    "field": {
      "label": {
        "label": "Label"
      },
      "textType": {
        "label": "Type"
      },
      "text": {
        "label": "Text"
      },
      "metafield": {
        "label": "Metafield"
      },
      "thirdGenderEnabled": {
        "label": "Third gender enabled"
      },
      "readOnly": {
        "label": "Read only"
      },
      "autofill": {
        "label": "Autofill"
      },
      "field": {
        "label": "Field",
        "value": {
          "referenceNr": "Reference Number",
          "initials": "Initials",
          "firstName": "First name",
          "middleName": "Middle name",
          "lastName": "Last name",
          "gender": "Gender",
          "dateOfBirth": "Date of Birth",
          "email": "Email",
          "street": "Street",
          "houseNumber": "House Number",
          "zipCode": "Zip code",
          "city": "City",
          "datetimeOfRegistration": "Date of Registration",
          "startDate": "Start date",
          "iban": "Account Number",
          "paymentInterval": "Frequency",
          "houseNumberSuffix": "House Number Suffix",
          "phonenumbers": "Phone",
          "landline": "Landline",
          "mobile": "Mobile",
          "commencingDate": "Commencing Date",
          "clientRefNr": "Client reference number"
        }
      },
      "styles": {
        "label": "Styles"
      }
    }
  },
  "backbone": {
    "importer": {
      "employees": {
        "title": "Employees & Locations",
        "buttonTitle": "Import Employees & Branches"
      },
      "batches": {
        "title": "Import batches",
        "buttonTitle": "Import batches"
      },
      "customer": {
        "title": "Customers",
        "buttonTitle": "Import customers"
      },
      "subscription": {
        "title": "Subscriptions",
        "buttonTitle": "Importing subscriptions"
      },
      "databaseReset": {
        "title": "Reset database",
        "buttonTittle": "Reset DB"
      }
    }
  },
  "signedDocument": {
    "confirm": "Are you sure you want to delete the file {{fileName}}?",
    "notificationModel": "Your document has been uploaded"
  },
  "shiftWageDefinition": {
    "edit": {
      "title": "Wage definition"
    },
    "modal": {
      "buttonTitle": "Adding wage rules",
      "modalTitle": "Wage rule definition"
    },
    "field": {
      "name": {
        "label": "Wage definition name"
      },
      "startDate": {
        "label": "Effective date"
      }
    }
  },
  "shiftWageRule": {
    "field": {
      "age": {
        "label": "Age"
      },
      "factor": {
        "label": "Factor"
      }
    }
  },
  "jobFactor": {
    "field": {
      "startDate": {
        "label": "Effective date"
      },
      "factor": {
        "label": "Factor"
      }
    }
  },
  "alphaWage": {
    "field": {
      "id": {
        "label": "ID"
      },
      "employee": {
        "label": "Staff member"
      },
      "date": {
        "label": "Date"
      },
      "basePay": {
        "label": "Basic pay"
      },
      "factorPay": {
        "label": "Bonus"
      },
      "creditAmount": {
        "label": "Credits"
      },
      "finalPay": {
        "label": "Final salary"
      }
    }
  },
  "alphaPeriodWage": {
    "field": {
      "id": {
        "label": "ID"
      },
      "employee": {
        "label": "Employee"
      },
      "startDate": {
        "label": "Period"
      },
      "amountEarned": {
        "label": "Total wage"
      },
      factorWage: {
        label: "Bonus"
      },
      baseWage: {
        label: "Base wage"
      },
      "incidentalExpenses": {
        "label": "Incidental compensation"
      },
    }
  },
  "contentbundleFolder": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Name"
      }
    }
  },
  "contentbundleCondition": {
    "field": {
      "product": {
        "label": "Product"
      },
      "metafield": {
        "label": "Metafield"
      },
      "metafieldValue": {
        "label": "Metafield value"
      }
    }
  },
  "contentbundleHitbox": {
    "field": {
      "nextScreen": {
        "label": "Go to the registration screen"
      },
      "slideshow": {
        "label": "Go to another folder"
      },
      "link": {
        "label": "Go to a website"
      },
      "product": {
        "label": "Put the next product for this subscription"
      },
      "metafield": {
        "label": "Select a metafield to prefix a value"
      },
      "metafieldValue": {
        "label": "Set the value for this metafield"
      },
      standardField:{
        'label': "Standard field to set",
        value: {
          'spokenLanguage': "Language"
        }

      },
      standardFieldValue:{
        label: "Value for this standard field",
        spokenLanguageOptions: {
            value:{
            NL: 'Dutch',
            FR: "French",
            DE: "German",
            EN: "English"
          }
        }
    }
    }
  },
  bwMetafieldTranslation: {
    field: {
      bwField: {
        label: "Briggs and Walker field"
      },
      standardField: {
        label: "Incenova field"
      }
    },
  },
  employeeSettings: {
    nav:{
      label: "Settings"
    },
    general:{
      settings: {
        label: "Settings"
      }
    },
    options: {
      referralSource: {
        label: "Source"
      },
      whatsappStatus: {
        label: "Whatsapp status"
      },
      employeeReason: {
        label: "Employee reason"
      },


    }
  },
  whatsappStatus:{
    create:{
      title: 'Add Whatsapp status'
    },
    edit:{
    title: 'Add Whatsapp status'
    },
    field:{
      id: {
        label: "ID"
      },
      title: {
        label: "Name"
      },
    },
    filter: {
      "deleted": "Deleted statuses",
      "onlyDeleted": "Only deleted",
      "withoutDeleted": "Without deleted",
      "bothDeletedAndNot": "Both"
    }
  },
  employeeReason: {
    create:{
      title: 'Add employee reason'
    },
    edit:{
    title: 'Edit employee reason'
    },
    field:{
      id: {
        label: "ID"
      },
      name: {
        label: "Name"
      },
      type:{
        label: "Type",
        value:{
          "rejection-company": "Rejection of company",
          "rejection-applicant": "Rejection of applicant",
          general: "General",
        }
      }
    },
    filter: {
      "deleted": "Deleted reasons",
      "onlyDeleted": "Only deleted",
      "withoutDeleted": "Without deleted",
      "bothDeletedAndNot": "Both"
    }
  },
  interview:{
      dropoutReasonModal:{
        title: 'Confirm rejection {{name}}',
        confirm:{
          rejected: 'For what reason was {{name}} rejected?',
          'not-interested': 'For what reason is {{name}} not interested?'
        }
    },
  },
  recruiterOverview: {
    week: 'Week {{number}}',
    client: 'Client',
    brutto: 'Brutto',
    netto: 'Netto',
    cancelled: 'Cancelled',
    registrationFee: 'Registration fee',
    baseSalary: 'Base salary',
    bonus: 'Bonus',
    total: 'Total',
    price: 'Price',
    cancelReason: 'Cancellation reason',
    registrationDate: 'Registration date',
    processingDate: 'Processing date',
    date: 'Date',
    reason: 'Reason',
    amount: 'Amount',
    totalAmount: 'Total amount',
    totalBase: 'Base wage',
    totalBonus: 'Bonus',
    creditations: 'Creditations',
    incidentals: 'Incidental compensation',
    subtotalNoVAT: 'Subtotal excl. VAT',
    vat: 'VAT',
    subtotalWithVAT: 'Subtotal incl. VAT',
  }
}
