import {observable} from 'mobx';
import {Casts, Model, Store} from '../../Base';
import {ContentbundleFolder} from '../ContentbundleFolder'
import {ContentbundleHitboxStore} from './ContentbundleHitbox';
import {ContentbundleConditionStore} from './ContentbundleCondition';

export class ContentbundleSlide extends Model {
    static backendResourceName = 'contentbundle_slide';

    @observable id = null;
    @observable name = '';
    @observable final = false;
    @observable file = null;
    @observable ordering = null;
    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            folder: ContentbundleFolder,
            hitboxes: ContentbundleHitboxStore,
            contentbundleConditions: ContentbundleConditionStore,
        }
    }
}

export class ContentbundleSlideStore extends Store {
    Model = ContentbundleSlide;
    static backendResourceName = 'contentbundle_slide';
}

