import { observable } from 'mobx';
import { Model, Store } from './Base';
import { EmployeeStore } from './Employee';

export class BwEmployee extends Model {
    static backendResourceName = 'bwemployee_translation';

    @observable id = null;
    @observable bwEmployeeId = null;

    relations() {
        return {
            employee: EmployeeStore,
        };
    }
}

export class BwEmployeeStore extends Store {
    Model = BwEmployee;
    static backendResourceName = 'bwemployee_translation';
}
