import { observable } from 'mobx';
import { Model, Store } from './Base';
import { Client } from './Client';
import { FileDefinitionStore } from './export/FileDefinition';
import { ProductMetafield, ProductMetafieldStore } from './ProductMetafield';
import { BWProductStore } from './Bwtranslation';
import { t } from '../i18n'

export class Product extends Model {
    static backendResourceName = 'product';

    @observable id = null;
    @observable name = '';
    @observable maxStartDateDelta = null;
    @observable minAge = 18;
    @observable factor = '1.00';
    @observable welcomeEmailTemplate = '';
    @observable textVerificationMessage = '';
    @observable type = null;
    @observable icon = null;
    @observable includeScanFiles = true;
    @observable paymentIntervalEnabled = false;
    @observable paymentInterval = null;

    @observable welcomeSmsEnabled = false;
    @observable welcomeSmsText = null;
    @observable welcomeSmsSender = null;

    relations() {
        return {
            client: Client,
            fileDefinitions: FileDefinitionStore,
            blockers: BlockerStore,
            productMetafields: ProductMetafieldStore,
            bwId: BWProductStore,
        };
    }

    //
    // @action
    // fetch(options = {}) {
    //     this.__pendingRequestCount += 1;
    //
    //     debugger;
    //
    //     const data = Object.assign(
    //         Model.buildFetchModelParams(this),
    //         this.__fetchParams,
    //         options.data
    //     );
    //
    //     return this.__getApi()
    //         .fetchModel({
    //             url: options.url || this.url,
    //             data,
    //             requestOptions: omit(options, ['data', 'url']),
    //         })
    //         .then(
    //             action(res => {
    //                 this.fromBackend(res);
    //                 this.__pendingRequestCount -= 1;
    //             })
    //         );
    // }

}

export class ProductStore extends Store {
    Model = Product;
    static backendResourceName = 'product';
}

export class Blocker extends Model {
    static backendResourceName = 'blocker';

    @observable id = null;
    @observable isExportBlocking = false;
    @observable issueReason = '';
    @observable issueModel = '';

    relations() {
        return {
            product: Product,
        };
    }
}

export class BlockerStore extends Store {
    Model = Blocker;
    static backendResourceName = 'blocker';
}

export class MetafieldValue extends Model {
    static backendResourceName = 'metafield';

    @observable id = null;
}

export class MetafieldValueStore extends Store {
    Model = MetafieldValue;
    static backendResourceName = 'metafield';
}

export const DISPLAY_CONDITION_TYPES = [
    {
        value: 'standard',
        text: t('displayCondition.field.type.value.standard'),
    },
    {
        value: 'metafield',
        text: t('displayCondition.field.type.value.metafield'),
    },
]
export const DISPLAY_CONDITION_STANDARD_FIELD_OPTIONS = [
    {
        value: 'age',
        text: t('displayCondition.field.standardField.value.age'),
    },
]

export const DISPLAY_CONDITION_COMPARISON_METHOD_OPTIONS = {
    'age': [
        {
            value: '=',
            text: t('displayCondition.field.comparisonMethod.value.='),
        },
        {
            value: '<',
            text: t('displayCondition.field.comparisonMethod.value.<'),
        },
        {
            value: '>',
            text: t('displayCondition.field.comparisonMethod.value.>'),
        },
    ]
}

export class DisplayCondition extends Model {
    static backendResourceName = 'display_condition';

    @observable id = null;
    @observable type = null;
    @observable standardField = null;
    @observable comparisonMethod = '=';
    @observable metafield = null;
    @observable value = [];

    relations() {
        return {
            productMetafield: ProductMetafield,
        };
    }
}

export class DisplayConditionStore extends Store {
    Model = DisplayCondition;
    static backendResourceName = 'display_condition';
}
