import { observable } from 'mobx';
import {Casts, Model, Store} from './Base';
import {Subscription} from "./Subscription";
import {Employee} from "./Employee";
import {Bucket} from "./Bucket";

export class Appointment extends Model {
    static backendResourceName = 'appointment';

    @observable id = null;
    @observable status = '';
    @observable callAt = null;
    @observable remarks = '';
    @observable createdAt = null;
    @observable updatedAt = null;


    relations() {
        return {
            subscription: Subscription,
            caller: Employee,
            bucket: Bucket
        };
    }
    casts() {
        return {
            callAt: Casts.datetime,
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

}

export class AppointmentStore extends Store {
    Model = Appointment;
    static backendResourceName = 'appointment';
}
