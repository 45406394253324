export function encode(s) {
    // string to utf-8 string
    let utf8s = unescape(encodeURIComponent(s));

    // utf-8 string to array of bytes
    let bs = [];
    for (let i = 0; i < utf8s.length; i++) {
        bs.push(utf8s.charCodeAt(i));
    }

    // array of bytes to array of ints <41
    let ns = [];
    for (let i = 0; i < bs.length; i += 2) {
        let n, c;
        if (i === bs.length - 1) {
            n = bs[i];
            c = 2;
        } else {
            n = bs[i] * 256 + bs[i + 1];
            c = 3;
        }
        for (let j = 0; j < c; j++) {
            ns.push(n % 45);
            n = Math.floor(n / 45);
        }
    }

    // array of ints <45 to string of QR alphanumeric compatible characters
    let res = ns.map(i => "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ $%*+-./:".charAt(i)).join('');

    return res;
}
