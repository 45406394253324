import React from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import ViewStore from 'spider/store/View';
import { t } from './i18n';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
// If taking advantage of automatic instrumentation (highly recommended)
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { ReCyCleTheme } from 're-cy-cle';
import { api } from 'store/Base';
import { User } from 'store/User';
import { BASE_NAME, configOverride, PUBLIC_URL } from 'helpers';
import { observable } from 'mobx';
import getTheme from './style/theme';
import { configureModal } from 'helpers/modal';
import { configureDateLib } from 'mobx-spine';
import { configurePermission } from 'spider/component/Permission';
import { configureBasename } from 'spider/helpers/bindUrlParams';
import { configureNotification } from 'helpers/notification';

import 'daycy/dist/daycy.css';
import 'style/semantic-ui/daycy.css';
import 'style/semantic-ui.css';
import 'style/extra-icons.css';
import 'style/custom-icons/cy-custom-icons.css';
import { engineApi } from "./store/Base";
import { ENGINE_API_BASE_URL } from "./helpers";
// custom to sms
import 'style/sms.css';
import 'react-quill/dist/quill.snow.css';
import 'style/react-grid-layout.css';

const parser = document.createElement('a');

// Compatibilty with http://localhost/hn.sms.test/
parser.href = window.location.href.replace('localhost', '');
const hostname = parser.hostname.split('.');
const subdomain = hostname.length === 4 ? hostname[1] : hostname[0];
const enviroment = window.location.host.includes('staging') ? 'staging' : 'production'


/*
 * Dynamically load css per customer
 */
switch (subdomain) {
    case 'hn':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-hn.css')
        break
    case 'zsp':
    case 'zetespee':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-zsp.css')
        break
    case 'ceomarketing':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-ceom.css')
        break
    case 'cy':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-cy.css')
        break
    case 'direct-result':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-dr.css')
        break
    case 'th-nl':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-th-nl.css')
        break
    case 'dr-react':
    case 'react':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-dr.css')
        break
    case 'briggsandwalker':
    case 'agency-test':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-briggs.css')
        break
    case 'alphasociety':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-alpha.css')
        break
    case 'upmarketing':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-upmarketing.css')
        break
    case 'uphill-marketing':
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-uphill-marketing.css')
        break
    default:
        // eslint-disable-next-line no-unused-expressions
        import('style/semantic-ui/foo/bar/semantic-cy.css')
}

window.t = t;

export const history = createBrowserHistory();

if (process.env.REACT_APP_CY_FRONTEND_SENTRY_DNS && process.env.REACT_APP_CY_FRONTEND_SENTRY_DNS.length > 0) {
    Sentry.init({
        dsn: process.env.REACT_APP_CY_FRONTEND_SENTRY_DNS,
        integrations: [
            new TracingIntegrations.BrowserTracing({
                // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
                routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
            }),
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        release: process.env.REACT_APP_CY_FRONTEND_SENTRY_RELEASE,
        enviroment: enviroment
    });
    Sentry.setContext("client", {
        name: subdomain,
    });
}

configureDateLib('moment');

class ConfigOverridableViewStore extends ViewStore {
    /**
     * Global isLoading tracker.
     */
    @observable isLoading = false;

    @observable engineApi = null;

    constructor({
        api,
        engineApi,
        user,
        socketUrl = null,
        fetchBootstrap = true
    }) {
        super({ api, user, socketUrl, fetchBootstrap });
        this.engineApi = engineApi;

        this.engineApi.onRequestError = this.handleRequestError;
    }


    fetchBootstrap() {
        return super.fetchBootstrap().then(res => {
            configOverride(res);
            if (ENGINE_API_BASE_URL) {
                this.engineApi.baseUrl = ENGINE_API_BASE_URL;
            }
        });
    }

    parseCurrentUserFromBootstrap(res) {
        this.currentUser.fromBackend({
            data: res.user,
            repos: res.with,
            relMapping: res.with_mapping,
        });
        if (process.env.REACT_APP_CY_FRONTEND_SENTRY_DNS && process.env.REACT_APP_CY_FRONTEND_SENTRY_DNS.length > 0) {
            Sentry.setUser({ id: this.currentUser.id, email: this.currentUser.email, username: this.currentUser.username })
        }
    }
}

export const viewStore = new ConfigOverridableViewStore({
    api,
    engineApi,
    user: new User({}, {
        relations: ['employee.branches', 'employee.mainBranch', 'employee.metabases', 'groups.metabases', 'clients.metabases'],
    }),
    socketUrl: `${PUBLIC_URL || ''}/ws/`.replace('v2/', '')
});
window.viewStore = viewStore

configureModal(viewStore);
configureNotification(viewStore);
configurePermission(viewStore);
configureBasename(BASE_NAME);


ReactDOM.render(
    <Sentry.ErrorBoundary showDialog={true}>
        <ReCyCleTheme theme={getTheme()}>
            <Router history={history}>
                <App store={viewStore} />
            </Router>
        </ReCyCleTheme>
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
);
