import { observable } from 'mobx';
import { Model, Store } from './Base';
import { PageStore } from './Traineeship/Page';

export class Traineeship extends Model {
    static backendResourceName = 'traineeship';

    @observable id = null;
    @observable title = '';
    @observable slug = '';
    @observable autoAssign = false;
    @observable ordering = null;
    @observable deleted = false;

    relations() {
        return {
            pages: PageStore,
        };
    }
}

export class TraineeshipStore extends Store {
    Model = Traineeship;
    static backendResourceName = 'traineeship';
}
