import {observable} from 'mobx';
import {Casts, Model, Store} from './Base';
import {User} from './User';
import {ClientRefNrStore} from './ClientRefNr';
import {t} from '../i18n'

export const CSV_STATUSES = [
    {
        value: 'uploaded',
        text: t('csv.field.status.options.uploaded')
    },
    {
        value: 'processed',
        text: t('csv.field.status.options.processed')
    }
]

export const CSV_TYPES = [
    {
        value: 'coin_addition',
        text: t('csv.field.type.options.coin_addition')
    },
    {
        value: 'external_sub_to_call',
        text: t('csv.field.type.options.external_sub_to_call')
    },
    {
        value: 'client_ref_nrs',
        text: t('csv.field.type.options.client_ref_nrs')
    },
    {
        value: 'job_applications',
        text: t('csv.field.type.options.job_applications')
    }
]

export const CSV_TYPES_CEOM = [
    {
        value: 'azg_subscriptions',
        text: t('csv.field.type.options.azg_subscriptions')
    }
]


export class Csv extends Model {
    static backendResourceName = 'csv';

    static omitFields = ['status'];

    @observable id = null;
    @observable csv = null;
    @observable type = null;
    @observable status = null;
    @observable createdAt = null;
    @observable updatedAt = null;
    @observable taskProcessorFailureReason = '';

    // Used for showing csv processor progress.
    @observable _current = 0;
    @observable _total = 0;

    relations() {
        return {
            uploader: User,
            clientRefNrs: ClientRefNrStore,
        };
    }

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

}

export class CsvStore extends Store {
    Model = Csv;
    static backendResourceName = 'csv';
}
