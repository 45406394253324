import { observable } from 'mobx';
import { Model, Store } from '../Base';

export class ChoiceNode extends Model {
    static backendResourceName = 'choice_node';

    @observable operator = '';

    relations() {
        return {
            rvalue: ValueNode,
            lvalue: ValueNode,
            consequence: ValueNode,
        };
    }
}

export class ChoiceNodeStore extends Store {
    Model = ChoiceNode;
    static backendResourceName = 'choice_node';
}

export class ValueNode extends Model {
    static backendResourceName = 'value_node';

    @observable type = '';
    @observable value = null;
    @observable valueType = '';
    @observable fieldSlug = '';
    @observable format = '';
    @observable operator = '';
    @observable metafield = null;

    // Convertcase
    @observable conversion = '';

    // Merge
    @observable separator = '';

    // DateTruncate
    @observable component = '';
    @observable outputTimezone = '';

    // Replace
    @observable location = '';
    @observable target = '';
    @observable replacement = '';

    // FixedWidth
    @observable width = null;
    @observable paddingStyle = '';
    @observable paddingChar = '';
    @observable choppingStyle = '';

    relations() {
        return {
            choices: ChoiceNodeStore,
            defaultValue: ValueNode,
            rvalue: ValueNode,
            lvalue: ValueNode,
            inputNode: ValueNode,
            inputNodes: ValueNodeStore,
        };
    }
}

export class ValueNodeStore extends Store {
    Model = ValueNode;
    static backendResourceName = 'value_node';
}

export class ValueNodeFactory {
    // This will mutate an existing node when it is passed in, or
    // create a fresh one when null is passed in.
    static createValueNode(node, nodeType) {
        if (!node) node = new ValueNode();

        node.type = nodeType;

        // Set any relations to the empty list or to null, otherwise
        // views which assume these properties exist will crash.
        switch (node.type) {
            case 'merge':
                if (node.inputNodes === undefined) node.inputNodes = [];
                break;
            case 'choice':
                if (node.choices === undefined) node.choices = [];
                break;
            case 'convert_case':
                if (node.inputNode === undefined) node.inputNode = null;
                break;
            case 'current_date':
                break;
            case 'date_component':
                if (node.inputNode === undefined) node.inputNode = null;
                break;
            case 'fixed_value':
                break;
            case 'date_format':
            case 'date_truncate':
            case 'dyadic_arithmetic_operator':
            case 'field_value':
            case 'fixed_width':
            case 'metafield_value':
            case 'replace':
            default:
                return null;
        }
        return node;
    }
}
