import {observable} from 'mobx';
import {Contentbundle} from "../Contentbundle";
import { Casts, Model, Store } from '../Base';
import {ContentbundleSlideStore} from './Slide/ContentbundleSlide';

export class ContentbundleFolder extends Model {
    static backendResourceName = 'contentbundle_folder';

    @observable id = null;
    @observable name = '';
    @observable readonly = null;
    @observable ordering = null;
    @observable createdAt = null;
    @observable updatedAt = null;

    casts() {
        return {
            createdAt: Casts.datetime,
            updatedAt: Casts.datetime,
        };
    }

    relations() {
        return {
            contentbundle: Contentbundle,
            slides: ContentbundleSlideStore
        }
    }
}

export class ContentbundleFolderStore extends Store {
    Model = ContentbundleFolder;
    static backendResourceName = 'contentbundle_folder';
}
