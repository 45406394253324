import { observable } from 'mobx';
import { Model, Store } from '../Base';
import { Traineeship } from '../Traineeship';
import { Employee } from '../Employee';
import { ChoiceStore } from './Choice';
import { AnswerStore } from './Answer';

export const STATUS_OPEN = 'open';
export const STATUS_DONE = 'done';
export const STATUS_APPROVED = 'approved';
export const STATUS = [STATUS_OPEN, STATUS_DONE, STATUS_APPROVED];

export class Assignment extends Model {
    static backendResourceName = 'assignment';

    @observable id = null;
    @observable status = '';

    relations () {
        return {
            traineeship: Traineeship,
            employee: Employee,
            answers: AnswerStore,
            choices: ChoiceStore,
        };
    }
}

export class AssignmentStore extends Store {
    Model = Assignment;
    static backendResourceName = 'assignment';
}
