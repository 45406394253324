import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import NotificationArea from 'component/NotificationArea';
import AppHeader from './AppHeader';
import { withRouter } from 'react-router-dom';
import Router from './Router';
import StartupError from './StartupError';

import BaseLogin, { PrimaryLink } from 'spider/semantic-ui/Login'
import { BrandingContainer } from 'spider/semantic-ui/SidebarScreen'
import { Sidebar as USidebar, SidebarContent } from 'spider/semantic-ui/Admin/Overview'
import { AppContainer, Body, ContentContainer } from 're-cy-cle'
import { theme } from 'styles'
import styled from "styled-components";
import BriggsSideBar from "../component/Briggs/Sidebar";
import { Responsive } from 'semantic-ui-react';
import getTheme from "../style/theme";
import i18next from 'i18next'
import { Helmet } from 'react-helmet'

const BackgroundDiv = styled.div`
    flex: 1;
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;

    background-image: url('${props => props.src}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;


const SidebarLogin = styled(USidebar)`
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.80) 100%);
    z-index: 10;

    &, label, h2 {
        color: #fff !important;
    }

    h2 {
        font-size: 1.3rem;
    }

    input {
        color: rgba(0, 0, 0, 0.87) !important;
    }

    ${PrimaryLink} {
        color: white !important;
    }

    ${SidebarContent} {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    ${BrandingContainer} {
        color: rgba(255, 255, 255, 0.7);
        &:hover {
            color: #fff;
            filter: unset;
        }
    }
`

const Logo = styled.img`
    src: ${props => props.src};
    transform: scale(0.8);
`

export function loginScreen(Screen) {
    return observer(class extends Screen {

        render() {
            return (
                <Body style={{ alignItems: "flex-end" }}>
                    <ContentContainer>
                        <SidebarLogin>
                            <Logo src={theme.logo} />
                            {this.renderContent()}
                            <div style={{ flex: '1' }} />
                            {/*{this.renderBranding()}*/}
                        </SidebarLogin>
                    </ContentContainer>
                    <BackgroundDiv src={theme.loginBackground} />
                </Body>
            )
        }
    })
}

@loginScreen
class Login extends BaseLogin {
}

const pathsThatSkipAuthentication = [
    /^\/login\/forgot$/,
    /^\/user\/\d+\/reset-password\/[^/]+$/,
    /^\/register/,
];

const FlexContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${({ direction = 'row' }) => direction};
    overflow: hidden;
`;

@withRouter
@observer
export default class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object,
    };

    static childContextTypes = {
        viewStore: PropTypes.object,
    };


    constructor(...args) {
        super(...args);

        this.onTabSelect = this.onTabSelect.bind(this);
        this.onTabHooverIn = this.onTabHooverIn.bind(this);
        this.onTabHooverOut = this.onTabHooverOut.bind(this);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize()

        i18next.on('languageChanged', () => this.forceUpdate());

    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
        window.removeEventListener("resize", this.resize.bind(this));

        i18next.off('languageChanged');

    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {

        if (this.sidebarOpen === true && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.toggleSideBar(false);
        }
    }

    @observable tab = '';
    @observable tabHoover = ''
    @observable sidebarOpen = false;

    @action
    toggleSideBar = (boolean) => {
        if (boolean) {
            this.sidebarOpen = boolean;
        } else {
            this.sidebarOpen = !this.sidebarOpen
        }
    }

    onTabSelect(tab) {
        if (tab === this.tab) {
            tab = null;
        }
        this.tab = tab;
    }

    onTabHooverIn(tab) {
        this.tabHoover = tab;
    }

    onTabHooverOut(tab) {
        this.tabHoover = '';
    }


    getChildContext() {
        return {
            viewStore: this.props.store,
        };
    }

    resize() {
        this.isMobile = window.innerwidth < Responsive.onlyComputer.minWidth
    }


    @observable isMobile = false;

    fillObject(from, to) {
        // eslint-disable-next-line no-unused-vars
        for (const key in from) {
            if (from.hasOwnProperty(key)) {
                if (Object.prototype.toString.call(from[key]) === '[object Object]') {
                    if (!to.hasOwnProperty(key)) {
                        to[key] = {};
                    }
                    this.fillObject(from[key], to[key]);
                    // eslint-disable-next-line no-unused-vars
                } else if (!to.hasOwnProperty(key)) {
                    // eslint-disable-next-line no-unused-vars
                    to[key] = "Translate: " + from[key];
                }
            }
        }
    }

    render() {
        const { store, location } = this.props;
        const { render: Modal, ...modalProps } = store.currentModal || {};



        //Used for generating trnaslations
        // const object1 = nl
        // const object2 = fr
        //
        // this.fillObject(object1, object2);
        // console.log(JSON.stringify(object2, null, '    '))


        let content = null;
        if (
            store.isAuthenticated ||
            pathsThatSkipAuthentication.some(regex =>
                regex.test(location.pathname)
            )
        ) {
            content = <Router store={store} />;
        } else if (store.bootstrapCode === 200) {

            content = <Login submitButtonColor={theme.buttonPrimaryColor} usernameField="username" viewStore={store} />;

        } else if (store.bootstrapCode !== null) {
            // The not null check is important, since we don't want to flash a startup error while the XHR request is running.
            return <StartupError code={store.bootstrapCode} />;
        }
        return (

            <React.Fragment>
                <Helmet>
                    <title>{getTheme().tabTitlePrefix}</title>
                </Helmet>
                <FlexContainer>
                    {/*TODO refactor to briggs sidebar*/}
                    {/*{(store.isAuthenticated && (theme.sidebar || store.isMasqueraded)) ? <AppSidebar*/}
                    {/*    selected={this.tab}*/}
                    {/*    hoovering={this.tabHoover}*/}
                    {/*    hooverIn={this.onTabHooverIn}*/}
                    {/*    hooverOut={this.onTabHooverOut}*/}
                    {/*    onSelect={this.onTabSelect}*/}
                    {/*    store={store}*/}
                    {/*/> : null}*/}
                    <BriggsSideBar open={this.sidebarOpen} innerRef={this.wrapperRef} />
                    <AppContainer>
                        {store.isAuthenticated ?
                            <AppHeader
                                toggleSideBar={this.toggleSideBar}
                                sidebarActive={this.sidebarOpen}
                                isAuthenticated={store.isAuthenticated}
                                currentUserName={store.currentUser.fullName}
                                hasPermission={store.currentUser.hasLowLevelPermission.bind(store.currentUser)}


                                viewStore={store}
                                theme={getTheme()} /> : null}
                        <Body>{content}</Body>
                        {Modal ? (
                            <Modal
                                viewStore={store}
                                {...modalProps}
                            />
                        ) : null}
                        <NotificationArea store={store} />
                    </AppContainer>
                </FlexContainer>
            </React.Fragment>
        );
    }
}
