export default {
  "tariffDocument": {
    "edit": {
      "title": "Tarief document"
    },
    "field": {
      "name": {
        "label": "Naam"
      },
      "description": {
        "label": "Beschrijving"
      },
      "file": {
        "label": "Bestand"
      }
    }
  },
  "brandingMessage": "Powered by Code Yellow",
  "nav": {
    "account": {
      "label": "Account",
      "password": "Wachtwoord",
      "details": "Details"
    },
    "prompt": {
      "leave": "Je hebt de wijzigingen van de bucket van campagne {{bucket}} niet opgeslagen, weet je zeker dat je de pagina wil verlaten?"
    },
    "dashboards": {
      "overview": "Dashboards"
    },
    "contentbundle": {
      "overview": "Inschrijfmodules"
    },
    "dpn": {
      "overview": "DPN",
      "subnav": {
        "location": "Location Manager",
        "backoffice": "BackOffice",
        "frontend": "Frontend"
      }
    },
    "office": {
      "overview": "Office"
    },
    "product": {
      "overview": "Producten"
    },
    "client": {
      "overview": "Klanten",
      "subnav": {
        "client": "Klanten",
        "campaigne": "Campagnes",
        "bwCampaigne": "BW Campagnes",
        "contentbundle": "Inschrijfmodules",
        "products": "Producten",
        "metafields": "Metafields",
        "sequences": "Sequenties",
        "clientRefNr": "Client ref",
        "wagesTariff": "Wages tariff",
        "bwProduct": "BW Producten",
        "bwfailures": "BW import fouten",
        "clientFile": "Klant bestanden"
      }
    },
    "data": {
      "overview": "Organisatie",
      "subnav": {
        "user": "Gebruikers",
        "location": "Vestigingen",
        "bwLocation": "BW Vestigingen",
        "groups": "Permissie groepen",
        "employees": "Medewerkers",
        "expenses": "Kosten",
        "alphaWages": "Lonen alpha",
        "job": "Functies",
        "devices": "Apparaten",
        "trainship": "Traineeships",
        "metabase": "Metabases",
        "contract": "Contract",
        "tips": "Tips",
        "shop": "Shop",
        "handbook": "Handbook",
        "privacy": "Privacy statement",
        "videos": "Videos",
        "dashboard": "Promotor app dashboard",
        "coins": "Coins",
        "documents": "Documenten",
        "news": "Nieuws",
        "milestone": "Milestones",
        "promotorIdea": "Promotor ideeën",
        "promotorAppMedia": "Promotor app media",
        "csv": "CSV",
        "shiftType": "Shift type",
        subscriptionReason: "Abonnement reden",
      }
    },
    "result": {
      "overview": "Resultaten",
      "subnav": {
        "callPlanning": "Nabel planning",
        "teams": "Teams",
        "data": "Invoeren",
        "call": "Nabellen",
        "callPlan": "Nabel planning",
        "export": "Export",
        "overview": "Inschrijvingen",
        "exportArchive": "Export-archief",
        "stats": "Stats",
        "dashboard": " Dashboard",
        "purge": "Purge",
        "iban": "Iban errors",
        "myResults": "Mijn resultaten",
        "creditationImporter": "Creditaties importeren"
      }
    },
    "personal": {
      "overview": "Mijn personeel"
    },
    "promoterApp": {
      "overview": "Promotor app"
    },
    "tablets": {
      "overview": "Tablets",
      "tariff": {
        "overview": "Tarief"
      },
      "map": "Kaart"
    },
    "wages": {
      "export": "Exporteren",
      "freelance": "ZZP lonen",
      "employee": "Medewerker lonen",
      "approval": "Goedkeuringen",
      "overview": "Lonen",
      "incidental": "Incidenteel"
    }
  },
  "metabase": {
    "createButton": "Metabase toevoegen",
    "filter": {
      "both": "Beide",
      "yes": "Ja",
      "no": "Nee"
    },
    "field": {
      "acties": {
        "label": "Acties"
      },
      "groups": {
        "label": "Permissie groepen"
      },
      "sidebarEnabled": {
        "label": "Sidebar actief"
      },
      "enabled": {
        "label": "Actief"
      },
      "sequenceNumber": {
        "label": "Navigatie bar volgorde",
        "info": "Op welke locatie t.o.v. andere metabases de link naar deze metabase in de appheader moet verschijnen, een lager getal betekend dat de metabase meer aan de linkerkant in de computer versie en bovenaan de mobile versie verschijnt."
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam"
      },
      "dashboardId": {
        "label": "Dashboard ID",
        "info": "De metabase dashboard id, dit id nummer kan worden gevonden in de url van je dashboard op metabase. b.v. metabase-url.com/dashboard/ID"
      },
      "updatedAt": {
        "label": "Laatste update"
      },
      "employees": {
        "label": "Medewerkers"
      },
      "clients": {
        "label": "Klanten"
      },
      "params": {
        "label": "Parameters",
        "info": "De parameters die worden meegestuurd naar het metabase dashboard, de volgende namen dienen voor de parameter te worden gebruikt in het dashboard:\n employee, \n main_branch, \n branches, \n all_branches, \n client",
        "value": {
          "employee": "Medewerker",
          "mainBranch": "Hoofd vestiging",
          "branches": "Vestigingen",
          "all_branches": "Alle vestigingen",
          "client": "Klant"
        }
      }
    }
  },
  "form": {
    "either": "Beide",
    "startDate": "Start Datum",
    "endDate": "Eind Datum",
    "restoreButton": "Herstellen",
    "no": "Nee",
    "yes": "Ja",
    "search": "Zoeken...",
    "jsonButton": "Import/Export",
    "historyButton": "Geschiedenis",
    "saveAndBackButton": "Opslaan & terug",
    "saveAddAnotherButton": "Opslaan & nieuw",
    "saveAndDownloadButton": "Opslaan & download",
    "copyButton": "Copy",
    "syncButton": "Sync",
    "downloadButton": "Download",
    "cancelButton": "Annuleer",
    "backButton": "Terug",
    "saveAndHireButton": "Opslaan en aannemen",
    "saveAndRejectButton": "Opslaan en weigeren",
    "saveAndHireAndConvertButton": "Opslaan, aannemen en omzetten naar werknemer",
    "acceptButton": "Accepteren",
    "rejectButton": "Weigeren",
    "pendingButton": "In afwachting",
    "reExportButton": "Wederuitvoer",
    "dateRangePlaceholder": "Selecteer…",
    "datePlaceholder": "Datum en tijd",
    "modalConfirmDelete": {
      "title": "Hard delete",
      "content": "You are about to delete this item. Are you sure?"
    },
    reconnectButton: "Verbinden met telefoon server",
    "autoFillButton": "Autofill",
    "fileType": {
      "image": "Plaatje",
      "any": "",
      "none": "Leeg"
    },
    "draftJs": {
      "style": {
        "BOLD": {
          "label": "Bold"
        },
        "CODE": {
          "label": "Monospace"
        },
        "ITALIC": {
          "label": "Italic"
        },
        "UNDERLINE": {
          "label": "Underline"
        },
        "blockquote": {
          "label": "Blockquote"
        },
        "code-block": {
          "label": "Code Block"
        },
        "header-five": {
          "label": "H5"
        },
        "header-four": {
          "label": "H4"
        },
        "header-one": {
          "label": "H1"
        },
        "header-six": {
          "label": "H6"
        },
        "header-three": {
          "label": "H3"
        },
        "header-two": {
          "label": "H2"
        },
        "ordered-list-item": {
          "label": "OL"
        },
        "unordered-list-item": {
          "label": "UL"
        }
      }
    },
    "validation": {
      "error": "Er zijn validatiefouten gevonden"
    },
    "check": "Gecontroleerd",
    "addButton": "Toevoegen",
    "multiPick": {
      "searchPlaceholder": "Select…",
      "selectedText": "$1 van de $2 geselecteerd",
      "noneSelectedText": "Geen geselecteerd",
      "selectAllButton": "Alles",
      "selectNoneButton": "Niks"
    },
    "importButton": "Importeer",
    "saveButton": "Opslaan",
    "undoRejectionButton": "Weigering ongedaan maken",
    "undoHireButton": "Aannemen ongedaan maken",
    "closeButton": "Sluiten",
    "saveAndNewButton": "Save & new",
    "saveLoadingButton": "Saving…",
    "sendButton": "Send",
    "editButton": "Aanpassen",
    "viewButton": "View",
    "actions": "Acties",
    "deleteButton": "Verwijderen",
    "searchLabel": "Search",
    "applyButton": "Apply",
    "selectPlaceholder": "Select…",
    "deleteConfirmation": "Wilt u dit echt verwijderen {{name}}?",
    "restoreConfirmation": "Wil je dit echt herstellen {{name}}?",
    "typeToSearchText": "Type to search…",
    "copyToClipboardTooltip": "Copy to clipboard",
    "notifications": {
      "saveSuccess": "Saved successfully",
      "deleteSuccess": "Deleted successfully",
      "restoreSuccess": "Restored successfully",
      "saveError": "Error with saving ({{status}})",
      "saveValError": "Not all information is filled in correctly",
      "saveAuthError": "Invalid credentials, check your permissions or try logging in again",
      "newAppVersion": "New version available, click to reload page as soon as possible",
      "blockedCustomer":"Klant is geblokeerd voor een van de medewerkers",
    },
    "validationErrors": {
      "blank": "Field is required",
      "null": "Field is required",
      "max_digits": "Maximum field value exceeded",
      "max_whole_digits": "Maximum field value exceeded",
      "max_value": "Maximum value exceeded",
      "invalid": "Invalid value",
      "reset_code_invalid": "Invalid reset code",
      "password_too_common": "Password is too common",
      "invalid_token": "Invalid token",
      "unique": "Field is not unique",
      "unique_together": "Field is not unique",
      "unknown": "Unknown error in field",
      "date_mismatch": "End date may not lie before start date",
      "routing_error": "Could not calculate route between this activity and previous one",
      "geo": {
        "failed-error": "Unknown error with finding location",
        "failed-no-results": "Could not find location",
        "failed-multiple-results": "Found too many locations",
        "failed-no-housenumber": "Did not find specific house number",
        "not_precise": "Found location, but not precise"
      },
      "invalid_file_type": "Verkeerd bestandsformaat. Geaccepteerde bestandstypen: {{types}}."
    }
  },
  "purge": {
    "id": "Id",
    "name": "Name",
    "subscription_count": "Subscription Count",
    "created_at": "Created At",
    "permanently_deleted_at": "Permanently Deleted At",
    "download": "Download",
    "permenantly_deleted_at": "Permenantly Deleted At"
  },
  "earningPeriod": {
    "modal": {
      "confirmExport": "Dit is een definitive export en zal de lonen van de periode met startdatum {{date}}  bevriezen, weet je het zeker?"
    },
    "filter": {
      "yes": "Ja",
      "no": "Nee",
      "both": "Beide"
    },
    "field": {
      "frozen": {
        "label": "Lonen bevroren"
      },
      "Acties": {
        "label": "Acties"
      },
      "id": {
        "label": "Id"
      },
      "endDate": {
        "label": "Einddatum periode"
      },
      "amount": {
        "label": "Totale betaling periode"
      },
      "startDate": {
        "label": "Startdatum periode"
      }
    }
  },
  "earning": {
    "inspection": {
      "dropOutReasons": "{{amount}} uitval redenen",
      "creditReasons": "{{amount}} creditatie redenen",
      "earned": "Verdiend",
      "bruttoDeclarations": "Bruto declaraties",
      "netDeclarations": "Netto declaraties",
      "donors": "Donors",
      "scores": "Scores",
      "max": "Max",
      "average": "Gemiddelde donatie",
      "enrollmentResults": "Inschrijving Resultaten",
      "week": "Week",
      "date": "Datum",
      "client": "Klant & campagne",
      "bruto": "Bruto",
      "attrition": "Uitval",
      "net": "Netto",
      "total": "Totaal",
      "credit": "Creditaties",
      "incidental": "Incidentele uitgaven",
      "reason": "Reden",
      "type": "Type",
      "amount": "Bedrag",
      "bonus": "Bonus"
    },
    "invoice": {
      "validDate": {
        "label": "Vervaldatum: "
      },
      "approveEmployee": {
        "label": "Periode klopt"
      }
    },
    "week": {
      "label": "Week"
    },
    "weekPeriod": {
      "label": "Week {startWeekNumber} - %{endWeekNumber}"
    },
    "sync": "Verdiensten deze periode nu opnieuw berekenen",
    "syncRunning": "Lonen worden her-berekend, ververs de pagina in enkele minuten",
    "filter": {
      "internalSignatureSigned": "Contract getekend intern syteem",
      "externalSignatureSigned": "Contract getekend extern syteem"
    },
    "field": {
      "Acties": {
        "label": "Acties"
      },
      "id": {
        "label": "ID"
      },
      "employeeId": {
        "label": "Medewerker ID"
      },
      "amount": {
        "label": "Lonen"
      },
      "contractSigned": {
        "label": "Contract getekend"
      },
      "totalEarned": {
        "label": "Totaal prijs"
      },
      "creditations": {
        "label": "Creditaties"
      },
      "datetimeApprovedByEmployee": {
        "label": "Goedgekeurd medewerker"
      },
      "withheldEarningAmount": {
        "label": "Ingehouden"
      },
      "datetimeApprovedByCompany": {
        "label": "Goedgekeurd bedrijf"
      },
      "nettoSubscriptionAmount": {
        "label": "Netto aantal"
      },
      "sumSubscriptionPrices": {
        "label": "Inschrijfbedrag"
      },
      "currentEmployeeFactor": {
        "label": "Factor (huidig)"
      },
      "sumCreditationPrices": {
        "label": "Credit (Inschrijf)bedrag"
      },
      "incidentalWages": {
        "label": "Incidentele compensatie"
      },
      "earningWithoutVat": {
        "label": "Totaal excl. btw"
      },
      "earning": {
        "label": "Bedrag verdiend"
      },
      "updatedAt": {
        "label": "Laatste wijziging"
      },
      "startedAt": {
        "label": "Periode"
      },
      "startDate": {
        "label": "Startdag periode"
      }
    }
  },
  "coin": {
    "field": {
      "id": "Id",
      "total": "Totaal",
      "coin": {
        "slug": "Slug",
        "task": "Coin taak"
      },
      "employee": {
        "id": "id",
        "name": "Medewerker",
        "coins": "Verdiende coins",
        "points": "Verdiende punten",
        "createdAt": "Created At"
      },
      "performedAt": {
        "label": "Verdiend op"
      }
    }
  },
  "product": {
    "deleteTooltip": "Product verwijderen",
    "restoreTooltip": "Product herstellen",
    "viewTooltip": "Product bekijken",
    "editTooltip": "Product bewerken",
    "copyTooltip": "Product kopieren",
    "noBriggsAndWalkerConnected": "Geen IDs gekoppeld",
    "nav": {
      "overview": "Producten"
    },
    "page": {
      "overview": {
        "title": "Producten"
      }
    },
    "createButton": "Product toevoegen",
    "save": {
      "error": "Kon product niet opslaan!",
      "success": "Product is opgeslagen"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "factor": {
        "label": "Factor"
      },
      "icon": {
        "label": "Icon",
        "helpText": "Sleep een plaatje of click hier om te uploaden,\n maximale dimensies van 4096 pixels in beide richtingen."
      },
      "blockers": {
        "label": "Blokkers",
        "placeholder": "Blokkers...",
        "selectedText": "$1 van $2 blokkers",
        "noneSelectedText": "Selecteer blokker",
        "checkAll": "Alles selecteren",
        "uncheckAll": "Alles deselecteren"
      },
      "metafields": {
        "label": "Metafields",
        "placeholder": "Metafields...",
        "selectedText": "$1 van $2 metafields",
        "noneSelectedText": "Selecteer metafields",
        "checkAll": "Alles selecteren",
        "uncheckAll": "Alles deselecteren"
      },
      "includeScanFiles": {
        "label": "Scan van tablet bij mail voegen"
      },
      "name": {
        "label": "Naam",
        "placeholder": "Naam..."
      },
      "min_age": {
        "label": "Vereiste minimale leeftijd",
        "placeholder": "Minimale leeftijd..."
      },
      "client": {
        "label": "Klant",
        "placeholder": "Klant..."
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "donation": "Donation",
        "subscription": "Subscription",
        "contract": "Contract"
      },
      "welcomeEmailTemplate": {
        "label": "Welcome email template",
        "placeholder": "Welcome email template...",
        "help": "De template ID van een mailjet transactional template"
      },
      "textVerificationMessage": {
        "label": "Verificatiebericht",
        "placeholder": "Verificatiebericht...",
        "help": "Verificatiebericht dat per sms wordt verzonden. Voorbeeld:\n Uw unieke sms verificatiecode is {code}"
      },
      "paymentInterval": {
        "label": "Frequentie",
        "info": "Frequentie info",
        "value": {
          "null": "Eenmalig",
          "P1D": "Dagelijks",
          "P1W": "Wekelijks",
          "P1M": "Maandelijks",
          "P3M": "Ieder Kwartaal",
          "P6M": "Half jaarlijks",
          "P1Y": "Jaarlijks"
        }
      },
      "paymentIntervalEnabled": {
        "label": "Specifieke product frequentie"
      },
      "max_start_date_delta": {
        "label": "Max. latere startdatum",
        "placeholder": "e.g. voor 2 maanden: P2M",
        "error": {
          "incorrect": "Format klopt niet"
        }
      },
      "bw_id": {
        "label": "Briggs & Walker koppeling",
        "placeholder": "Briggs and walker id..."
      },
      "welcomeSmsEnabled":{
        label: 'Gebruik een welkomst sms'
      },
      "welcomeSmsText":{
        label: 'Welkom sms-tekst'
      },
      "welcomeSmsSender":{
        label: 'Welkom sms afzender'
      },
    },
    "create": {
      "title": "Product aanmaken"
    },
    "edit": {
      "title": "Product aanpassen",
      "basicTitle": "Basisgegevens",
      "blockerTitle": "Blockers",
      "metafieldTitle": "Metafields"
    }
  },
  "blocker": {
    "field": {
      "id": {
        "label": "ID"
      },
      "issue_model": {
        "label": "Model",
        "placeholder": "Model..."
      },
      "issue_reason": {
        "label": "Reden",
        "placeholder": "Reden..."
      },
      "is_export_blocking": {
        "label": "Export blocking?",
        "placeholder": "Export blocking..."
      }
    }
  },
  "fileDefinition": {
    "save": {
      "error": "Kon definitie niet opslaan!",
      "success": "Definitie is opgeslagen"
    },
    "import": {
      "error": "Kon definitie niet importeren!",
      "success": "Definitie is geimporteerd"
    },
    "choice_operator": {
      "exact": "is gelijk aan",
      "exact_not": "is niet gelijk aan",
      "lt": "is kleiner dan",
      "lte": "is kleiner dan / gelijk aan",
      "gt": "is groter dan",
      "gte": "is groter dan / gelijk aan"
    },
    "group_combinator": {
      "and": "en",
      "or": "of"
    },
    "convertCase": {
      "uppercase": "Hoofdletters",
      "lowercase": "Kleine letters",
      "titlecase": "Hoofdletter per woord"
    },
    "location": {
      "left": "links",
      "right": "rechts",
      "both": "Beide",
      "everywhere": "Overal"
    },
    "repeat": {
      "label": "Herhalen",
      "no": "Nee",
      "yes": "Ja"
    },
    "jsonModal": {
      "title": "Definitie bewerken",
      "instructions": "Onderstaande tekst kun je kopieren en plakken in een andere export. Door op \"importeer\" te klikken wordt dit toegepast. Daarna kun je de nieuwe export opslaan."
    },
    "overview": {
      "title": "Bestandsdefinities"
    },
    "field": {
      "format": {
        "label": "Format"
      },
      "description": {
        "label": "Beschrijving"
      }
    },
    "edit": {
      "title": "Bewerk bestandsdefinitie"
    }
  },
  "filterOption": {
    "save": {
      "error": "Kon filter niet opslaan!",
      "success": "Filter is opgeslagen"
    }
  },
  "hardDelete": {
    "header": "Wilt u dit echt verwijderen?",
    "content": "Deze actie kan niet ongedaan worden gemaakt!",
    "confirm": "Ja",
    "cancel": "Nee"
  },
  "subscription": {
    "edit": {
      "productInformation": "Product informatie",
      "contactInformation": "Contact informatie",
      "metafields": "Metafields",
      "cancelations": "Annuleringen",
      "enrollment": "Inschrijving",
      "others": "Diversen",
      "recruitInformation": "Wervings informatie",
      "remarks": "Opmerkingen",
      "personalInformation": "Persoonlijke informatie",
      "addressInformation": "Address informatie",
      "financialInformation": "Financiele informatie",
      "subscriptionId": "Inschrijving ID"
    },
    "creditationImporter": {
      "title": "Creditatie importer",
      "importer": {
        "fileButton": "Bestand selecteren",
        "buttonTitle": "Begin import",
        "dropFiles": "Bestanden uploaden"
      }
    },
    "calls": {
      "modal": {
        "buttonTitle": "Telefoon gesprekken",
        "caller": "Beller",
        "start": "Start",
        "duration": "Duur",
        "voicelogs": "Voicelogs"
      },
      "modalTitle": "Telefoon gesprekken met abonnee"
    },
    "cancel": {
      "modalTitle": "Abonnement annuleren",
      "saveCancelation": "Annulering opslaan",
      "details": "Details annulering"
    },
    "termination": {
      "modalTitle": "Abonnement na success beëindigen",
      "saveTermination": "Beëindigen Oplaan",
      "title": "Afgerond na uitbetaling"
    },
    "nav": {
      "overview": "Abonnees"
    },
    "page": {
      "overview": {
        "title": "Abonnees"
      }
    },
    "overviewMock": {
      "title": "Test abonnees"
    },
    "download": {
      "button": "CSV"
    },
    "error": {
      "cancelationWithoutReason": "Vul een reden voor de annulering in"
    },
    "resendEmail": {
      "button": "E-mail opnieuw verzenden",
      "confirmationQuestion": "Weet je zeker dat je de bevestigingsmail opnieuw wil sturen?",
      "emailToResend": "Email address voor de bevestigings email",
      "resendConfirmation": "Email address for the confirmation email",
      "sentConfirmation": "Email succesvol verstuurd",
      "invalidEmail": "E-mailadres ongeldig",
      "invalidInputEmail": "Voer een geldig e-mailadres in",
      "sendAttachment": "Bijlagen verzenden"
    },
    "field": {
      "updatedAt": {
        "label": "Geupdate op"
      },
      "id": {
        "label": "ID"
      },
      "creditation": {
        "label": "Creditatie",
        "options": {
          "both": "Beide",
          "false": "Nee",
          "true": "Ja"
        }
      },
      "systemStatus": {
        "label": "Systeem status"
      },
      "subscriber": {
        "lastName": "Achternaam"
      },
      "appointmentStatus": {
        "label": "Status van afspraak"
      },
      "iban": {
        "label": "Iban"
      },
      "amount": {
        "label": "Bedrag"
      },
      "quantity": {
        "label": "Aantal"
      },
      "total": {
        "label": "Totaal",
        "completed": "Klaar",
        "deleted": "Verwijderd",
        "bucket": "In nabelrij",
        "validation": "Klaar voor validatie",
        "queue": "Klaar voor nabelrij",
        "export": "Klaar voor export",
        "attention": "Aandacht nodig"
      },
      "product": {
        "label": "Product",
        "placeholder": "Product..."
      },
      "cancelPaymentRecruiter": {
        "label": "Annuleer betaling aan werver"
      },
      "status": {
        "label": "Status",
        "placeholder": "Status...",
        "value": {
          "needs validation": "klaar voor validatie",
          "needs attention": "aandacht nodig",
          "deleted": "verwijderd",
          "ready for export": "klaar voor export",
          "in call bucket": "in nabelrij",
          "completed": "klaar",
          "canceled": "geannuleerd",
          "queue": "klaar voor nabelrij",
          "creditation": "Creditatie"
        }
      },
      "cancelled": {
        "label": "Geannuleerd",
        "options": {
          "all": "Alles",
          "only": "Alleen geannuleerd",
          "without": "Zonder geannuleerd"
        }
      },
      "campaign": {
        "label": "Campagne"
      },
      "client": {
        "label": "Klant",
        "placeholder": "Klant..."
      },
      "clientRefNr": {
        "label": "Client ref",
        "placeholder": "Client ref..."
      },
      "error": {
        "label": "Fout",
        "subscription": "Abonnement Fout",
        "subscriber": "Abonnee Fout"
      },
      "date": {
        "label": "Datum",
        "placeholder": "Datum..."
      },
      "is_imported": {
        "label": "Is geimporteerd",
        "placeholder": "Is geimporteerd..."
      },
      "entryRemarks": {
        "label": "Opmerking invoer",
        "placeholder": "Opmerking invoer..."
      },
      "callRemarks": {
        "label": "Opmerking nabel",
        "placeholder": "Opmerking nabel..."
      },
      "clientRemarks": {
        "label": "Opmerking klant",
        "placeholder": "Opmerking klant..."
      },
      "teamMember": {
        "label": "Promotor",
        "placeholder": "Promotor..."
      },
      "endDate": {
        "label": "Datum stopzetting"
      },
      "paymentMethod": {
        "label": "Betaalwijze",
        "placeholder": "Betaalwijze...",
        "value": {
          "invoice": "factuur",
          "directDebit": "automatische incasso",
          "acceptgiro": "acceptgiro",
          "cash": "contant",
          "direct debit": "Directe stroom"
        }
      },
      "datetimeOfRegistration": {
        "label": "Schrijfdatum"
      },
      "datetimeOfTermination": {
        "label": "Datum succesvolle afronding abonnement"
      },
      "ignoreErrors":{
        "label": "Abonnementsfouten negeren voor statuswijziging"
      },
      "datetimeOfCancelation": {
        "label": "Annuleringsdatum",
        "placeholder": "Annuleringsdatum..."
      },
      "reasonForCancelation": {
        "label": "Reden van annulering",
        "placeholder": "Reden van annulering..."
      },
      "overrideCallAmountCheck": {
        "label": "Maximaal aantal bel pogingen overschrijden"
      },
      "startDate": {
        "label": "Ingangs-datum",
        "error": {
          "exceeding": "Te ver in de toekomst"
        }
      },
      "employee": {
        "name": "Employee naam"
      },
      "paymentInterval": {
        "label": "Frequentie",
        "changedOneTimer": "Omgezet naar eenmalig",
        "value": {
          "null": "Eenmalig",
          "P1D": "Dagelijks",
          "P1W": "Wekelijks",
          "P1M": "Maandelijks",
          "P3M": "Ieder Kwartaal",
          "P6M": "Half jaarlijks",
          "P1Y": "Jaarlijks"
        }
      },
        disableBlockers: {
            label: "Blokkeerders uitschakelen",
        },
        subscriptionCode: {
            label: "Annulering beschrijving",
            info: "Optionele annulerings beschrijving, deze geeft de reden annulering aan..."
        },
    },
    "button": {
      "add": "Toevoegen"
    },
    "createButton": "Abonnee toevoegen",
    "terminationTooltip": "Abonnement na success beëindigen",
    "unterminateTooltop": "Abonnement toch niet beëindigen",
    "cancelTooltip": "Abonnement opzeggen",
    "creditationProcessed": "Creditatie als ontvangen aangeven",
    "creditationNotProcessed": "Creditatie verwerking ongedaan maken",
    "editTooltip": "Abonnement wijzigen",
    "uncancelTooltip": "Abonnement de-annuleren",
    "deleteTooltip": "Verwijder inschrijving",
    "lookupTooltip": "Abonnement opzoeken",
    "save": {
      "error": "Kon abonnee niet opslaan!",
      "success": "Abonnee is opgeslagen"
    },
    "delete": {
      "error": "Kon abonnee niet verwijderen!",
      "success": "Abonnee is verwijderd"
    },
    "modal": {
      "view": {
        "title": "Inschrijving"
      },
      "delete": {
        "content": "Je staat op het punt om een inschrijving te verwijderen. Wilt u doorgaan?",
        "cancelButton": "Annuleer",
        "deleteButton": "Opzeggen"
      },
      "cancel": {
        "title": "Abonnement opzeggen",
        "cancelButton": "Annuleer",
        "saveButton": "Opzeggen",
        "notification": "Abonnement is geannuleerd"
      }
    },
    "changeStatus": {
      "error": "Kon status niet aanpassen!",
      "success": "Status is aangepast.",
      "modalTitle": "Status van inschrijving wijzigen ID",
      "modalTitleMultiple": "Status van {{amount}} inschrijvingen wijzigen",
      "changeButton": "Status veranderen",
      "label": "Kies inschrijving status"
    },
    "reexport": {
      "error": "Kon niet opnieuw exporteren!",
      "success": "abonnement is opnieuw geëxporteerd"
    },
    "uncancel": {
      "error": "Kon annulering niet ongedaan maken!",
      "success": "Annulering is ongedaan gemaakt."
    },
    "zsp": {
      "missingScan": "Er is nog geen scan geupload. Upload eerst een scan."
    }
  },
  "subscriber": {
    "noPicture": "Geen afbeelding beschikbaar",
    "loading": "Aan het laden",
    "field": {
      "updatedAt": {
        "label": "Geupdate op"
      },
      "gender": {
        "salutation": {
          "male": "Dhr.",
          "female": "Mevr.",
          "unknown": ""
        },
        "label": "Geslacht",
        "value": {
          "male": "Man",
          "female": "Vrouw",
          "unknown": "Onbekend"
        }
      },
      spokenLanguage:{
        label: "Gesproken taal"
      },
      "dateOfBirth": {
        "label": "Geboortedatum"
      },
      "city": {
        "label": "Plaats"
      },
      "street": {
        "label": "Straat"
      },
      "houseNumber": {
        "label": "Huisnummer"
      },
      "houseNumberSuffix": {
        "label": "Toevoeging"
      },
      "zipCode": {
        "label": "Postcode"
      },
      "iban": {
        "label": "Iban"
      },
      "mobile": {
        "label": "Mobiele telefoon nummer"
      },
      "landline": {
        "label": "Vast telefoon nummer"
      },
      "email": {
        "label": "Email"
      },
      "lastName": {
        "label": "Achternaam"
      },
      "firstName": {
        "label": "Voornaam"
      },
      "middleName": {
        "label": "Tussenvoegsels"
      },
      "initials": {
        "label": "Initialen"
      },
      "date_of_birth": {
        "error": {
          "too_young": "Jonger dan minimale leeftijd."
        }
      },
      "country": {
        "label": "Land",
        "value": {
          "NL": "Nederland",
          "DE": "Duitsland",
          "FR": "Frankrijk",
          "BE": "België",
          "GB": "Engeland"
        }
      }
    }
  },
  "shopItem": {
    "nav": {
      "overview": "Shop"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "ordering": {
        "label": "Volgorde",
        "placeholder": "Volgorde..."
      },
      "name": {
        "label": "Name",
        "placeholder": "Name..."
      },
      "description": {
        "label": "Omschrijving",
        "placeholder": "Omschrijving..."
      },
      "price": {
        "label": "Coins",
        "placeholder": "Coins..."
      },
      "image": {
        "label": "Afbeelding",
        "placeholder": "Afbeelding..."
      }
    },
    "create": {
      "title": "Artikel toevoegen"
    },
    "edit": {
      "title": "Artikel aanpassen"
      },
    "createButton": "Artikel toevoegen",
    "editTooltip": "Item bewerken",
    "filter": {
      "deleted": "Verwijderde artikelen",
      "onlyDeleted": "Alleen verwijderd",
      "withoutDeleted": "Zonder verwijderd",
      "bothDeletedAndNot": "Alles"
    }
  },
  "promotorIdea": {
    "nav": {
      "overview": "Promotor app ideeen"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "employee": {
        "label": "Werknemer",
        "placeholder": "Werknemer..."
      },
      "branch": {
        "label": "Vestiging",
        "placeholder": "Vestiging..."
      },
      "idea": {
        "label": "Idee",
        "placeholder": "Idee..."
      },
      "subject": {
        "label": "Onderwerp",
        "placeholder": "Onderwerp..."
      }
    },
    "create": {
      "title": "Idee toevoegen"
    },
    "edit": {
      "title": "Idee aanpassen"
    },
    "createButton": "Idee toevoegen",
    "editTooltip": "Item bewerken"
  },
  "contactTab": {
    "noResults": "Niets om na te bellen"
  },
  "sequence": {
    "editTooltip": "Item bewerken",
    "nav": {
      "overview": "Sequenties"
    },
    "page": {
      "overview": {
        "title": "Sequenties"
      }
    },
    "createButton": "Sequentie toevoegen",
    "clearDefaultButton": "Default wissen",
    "save": {
      "error": "Kon sequentie niet opslaan!",
      "success": "Sequentie is opgeslagen"
    },
    "modal": {
      "delete": {
        "content": "Je staat op het punt om een sequentie te verwijderen. Wilt u doorgaan?"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam"
      },
      "value": {
        "label": "Waarde"
      },
      "description": {
        "label": "Omschrijving"
      },
      "client": {
        "label": "Klant"
      },
      "increment_per_export": {
        "placeholder": "Ja/Nee..."
      }
    }
  },
  "clientRefNr": {
    "page": {
      "overview": {
        "title": "Referentienummers"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam"
      },
      "referenceNumber": {
        "label": "Referentienummer"
      },
      "subscriptionId": {
        "label": "Inschrijving ID"
      },
      "client": {
        "label": "Klant"
      },
      "fromCsv": {
        "label": "CSV ID"
      }
    }
  },
  "softDelete": {
    "delete": {
      "error": "Kon item niet verwijderen!",
      "success": "Item is verwijderd",
      "tooltip": "Verwijder item"
    },
    "restore": {
      "error": "Kon item niet terugzetten!",
      "success": "Item is teruggezet",
      "tooltip": "Zet item terug"
    }
  },
  "segment": {
    "field": {
      "id": "ID",
      "name": "Naam",
      "is_final": "Is gepubliceerd"
    },
    "attribute": {
      "field": {
        "id": "ID",
        "segment": "Segment",
        "name": "Naam",
        "attribute_name": "Attribuut naam",
        "aggregation_type": "Type rapportage"
      }
    },
    "createButton": "Segment toevoegen"
  },
  "valueNode": {
    "valueType": {
      "label": "Type"
    },
    "nodeType": {
      "label": "Type"
    },
    "choice": {
      "if": "Als",
      "else": "Anders"
    },
    "type": {
      "fixed_value": "Fixed value",
      "field_value": "Field value",
      "date_format": "Date format",
      "current_date": "Datum van vandaag",
      "choice": "Choice",
      "metafield_value": "Metafield",
      "merge": "Samenvoegsel",
      "dyadic_arithmetic_operator": "Rekenkundige bewerking",
      "convert_case": "Hoofdletter conversie",
      "replace": "Tekst vervangen",
      "fixed_width": "Vaste kolombreedte",
      "date_component": "Deel van datum",
      "date_truncate": "Afgekorte datum",
      "count": "Aantal",
      "sequence": "Sequentie",
      "substring": "Substring",
      "assigned_field_value": "Toegewezen veld waarde"
    },
    "dateComponentType": {
      "century": "eeuw",
      "day": "dag",
      "decade": "decenium",
      "dow": "dag van de week",
      "doy": "dag van het jaar",
      "epoch": "seconden sinds 1-1-1970 00:00:00 UTC",
      "hour": "uur",
      "isodow": "dag van de week (ISO)",
      "isoyear": "dag van het jaar (ISO)",
      "microseconds": "microseconde",
      "millenium": "millenium",
      "milliseconds": "milliseconde",
      "minute": "minuut",
      "month": "maand",
      "quarter": "kwartaal",
      "second": "seconde",
      "timezone": "tijdzone verschil van UTC",
      "timezone_hour": "uren verschil van UTC",
      "timezone_minute": "minuten verschil van UTC",
      "week": "week",
      "year": "jaar",
      "millennium": "millennium"
    },
    "mathOperator": {
      "add": "+",
      "sub": "-",
      "mul": "*"
    },
    "choiceOperator": {
      "exact": "gelijk is aan",
      "exact_not": "niet gelijk is aan",
      "lt": "kleiner is dan",
      "lte": "kleiner is dan / gelijk is aan",
      "gt": "groter is dan",
      "gte": "groter is dan / gelijk is aan"
    },
    "groupCombinator": {
      "and": "en",
      "or": "of"
    },
    "convertCase": {
      "uppercase": "Hoofdletters",
      "lowercase": "Kleine letters",
      "titlecase": "Hoofdletter per woord"
    },
    "location": {
      "left": "links",
      "right": "rechts",
      "both": "Beide",
      "everywhere": "Overal"
    },
    "repeat": {
      "label": "Herhalen",
      "no": "Nee",
      "yes": "Ja"
    }
  },
  "pageNotFoundMessage": "Page not found :(",
  "paginator": {
    "previousText": "<",
    "nextText": ">"
  },
  "user": {
    "editTooltip": "Gebruiker bewerken",
    "masqueradeTooltip": "Gebruiker masqueraden",
    "forgotPassword": "Wachtwoord vergeten?",
    "sendResetLink": "Stuur reset link",
    "backToLogin": "Terug naar login",
    "passwordPlaceholder": "nieuw wachtwoord",
    "passwordConfirmationPlaceholder": "wachtwoord herhalen...",
    "sendResetCodeEmailSuccess": "Mocht uw gebruikersnaam bekend zijn, is er een reset code naar uw mail verstuurd.",
    "sendResetCodeEmailError": "Kon het wachtwoord niet resetten. Controleer gebruikersnaam.",
    "editButton": "Aanpassen",
    "saveButton": "Opslaan",
    "navProfile": "Profiel",
    "navChangePassword": "Wachtwoord aanpassen",
    "navLogout": "Uitloggen",
    "resetPassword": {
      "resetButton": "Reset",
      "saveSuccess": "Wachtwoord is succesvol gereset",
      "title": "Wachtwoord Reset",
      "body": "Stel hier uw wachtwoord in.",
      "password": "Nieuw wachtwoord",
      "repeatPassword": "Herhaal nieuw wachtwoord",
      "doesNotMatch": "Wachtwoorden komen niet overeen!",
      "requirements": {
        "length": "Het wachtwoord moet minstens 8 tekens bevatten.",
        "generic": "Het wachtwoord mag niet te algemeen zijn.",
        "similar": "Het wachtwoord mag niet teveel op uw persoonlijke gegevens lijken.",
        "numeric": "Het wachtwoord mag niet alleen cijfers bevatten.",
      },
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "last_login": {
        "label": "Laatst ingelogd"
      },
      "username": {
        "label": "Gebruikersnaam",
        "placeholder": "Gebruikersnaam..."
      },
      "email": {
        "label": "Email"
      },
      "password": {
        "label": "Wachtwoord",
        "placeholder": "wachtwoord..."
      },
      "groups": {
        "label": "Permissie groepen",
        "multiselect": {
          "selectedText": "# van # groepen geselecteerd",
          "noneSelectedText": "Kies groepen",
          "placeholder": "Zoeken..."
        }
      },
      "lastLogin": {
        "label": "Laatste login"
      },
      "isActive": {
        "label": "Gebruiker is actief"
      }
    },
    "filter": {
      "search": {
        "label": "Zoeken",
        "placeholder": "Gebruikersnaam, voornaam, achternaam..."
      },
      "branches": {
        "label": "Vestiging"
      },
      "group": {
        "label": "Group"
      },
      "status": {
        "label": "Status"
      },
      "deleted": "Verwijderde"
    },
    "account": {
      "title": "Profiel",
      "language": "Taal",
      "logoutButton": "Uitloggen",
      "unMasquerade": "Unmasquerade"
    },
    "changePassword": {
      "title": "Wachtwoord veranderen",
      "field": {
        "passwordOld": {
          "label": "Oud wachtwoord"
        },
        "passwordNew": {
          "label": "Nieuw wachtwoord"
        }
      },
      "saveButton": "Verander wachtwoord",
      "success": "Wachtwoord is aangepast",
      "error": "Kon het wachtwoord niet aanpassen!"
    },
    "passwordForgot": {
      "title": "Wachtwoord vergeten",
      "requestButton": "Stuur reset link",
      "requestedEmailText": "Als het email adres klopt is er een email met een link verstuurd om het wachtwoord te herstellen"
    },
    "nav": {
      "overview": "Gebruikers"
    },
    "login": {
      "title": "Inloggen",
      "forgotPasswordLink": "Wachtwoord vergeten?",
      "errors": {
        "invalidCredentials": "Gebruikernaam of wachtwoord klopt niet",
        "unknown": "Onbekende fout, status code: {{status}}"
      },
      "loginButton": "Inloggen"
    },
    "save": {
      "error": "Kon gebruiker niet opslaan!",
      "success": "Gebruiker is opgeslagen"
    },
    "view": {
      "create": {
        "title": "Gebruiker aanmaken"
      },
      "edit": {
        "title": "Gebruiker aanpassen"
      },
      "changePassword": {
        "title": "Wachtwoord aanpassen",
        "newPassword": "Nieuwe wachtwoord",
        "confirmedPassword": "bevestigd wachtwoord"
      },
      "profile": {
        "title": "Jouw profiel"
      },
      "forgotPassword": {
        "title": "Wachtwoord vergeten?"
      }
    },
    "groups": "Permissie groepen",
    "masqueradeButton": "Start masquerade",
    "unmasqueradeButton": "Stop masquerade"
  },
  "contentbundle": {
    "slide": {
      "SlidesEdit": "Folders"
    },
    "copyTooltip": "Inschrijfmodule kopieren",
    "editTooltip": "Inschrijfmodule bewerken",
    "deleteTooltip": "Inschrijfmodule verwijderen",
    "createButton": "Inschrijfmodule toevoegen",
    "noPadding": "Geen opvulling",
    "basicInfo": "Basis informatie",
    "layout": "Indeling",
    "currentFormElementDetails": "Element details",
    "addElements": "Elementen toevoegen",
    "removeElement": "Verwijder",
    "addElementType": {
      "attest": "Attest toevoegen",
      "filler": "Filler toevoegen",
      "text": "Text toevoegen",
      "metafield": "Metafield toevoegen",
      "field": "Field toevoegen",
      "image": "Image toevoegen"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam"
      },
      "client": {
        "label": "Klant"
      },
      "products": {
        "label": "Klant"
      },
      "show_recruiter_signature": {
        "label": "Promotor handtekening?"
      },
      "campaign": {
        "label": "Campagne",
        "placeholder": "Selecteer een campagne…"
      },
      "translations": {
        "label": "Vertalingen"
      },
      "styles": {
        "label": "Stijlen"
      },
      "logo": {
        "label": "Logo",
        "save": {
          "error": "Logo must be a png file"
        }
      }
    },
    "copy": {
      "success": "De campagne is gekopieërd.",
      "error": "De campagne kon niet worden gekopieërd."
    },
    "edit": {
      "addForm": "Form toevoegen",
      "slides": "Slides bewerken",
      "addFolder": "Voeg folder toe",
      "addSlide": "Voeg slide toe"
    }
  },
  "formElement": {
    "field": {
      "label": {
        "label": "Label"
      },
      "textType": {
        "label": "Type"
      },
      "text": {
        "label": "Text"
      },
      "metafield": {
        "label": "Metafield"
      },
      "thirdGenderEnabled": {
        "label": "Third gender enabled"
      },
      "readOnly": {
        "label": "Read only"
      },
      "autofill": {
        "label": "Autofill"
      },
      "field": {
        "label": "Field",
        "value": {
          "referenceNr": "Reference Number",
          "initials": "Initials",
          "firstName": "First name",
          "middleName": "Middle name",
          "lastName": "Last name",
          "gender": "Gender",
          "dateOfBirth": "Date of Birth",
          "email": "Email",
          "street": "Street",
          "houseNumber": "House Number",
          "zipCode": "Zipcode",
          "city": "City",
          "datetimeOfRegistration": "Date of Registration",
          "startDate": "Start date",
          "iban": "Account Number",
          "paymentInterval": "Frequency",
          "houseNumberSuffix": "House Number Suffix",
          "phonenumbers": "Phone",
          "landline": "Landline",
          "mobile": "Mobile",
          "commencingDate": "Commencing Date",
          "clientRefNr": "Client reference number"
        }
      },
      "styles": {
        "label": "Styles"
      }
    }
  },
  "contentbundleAgeWildcard": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam"
      },
      "minAge": {
        "label": "Minimumleeftijd"
      },
      "maxAge": {
        "label": "Maximumleeftijd"
      },
      "renewEvery": {
        "label": "Vernieuwen elke {N} sub",
      },
      "default": {
        "label": "Standaard toevoegen?",
      },
      "employees": {
        "label": "Voor de medewerkers"
      },
      "branches": {
        "label": "Voor vestiging"
      },
    },
    "edit": {
      "title": "Joker bewerken"
    },
    "create": {
      "title": "Joker toevoegen"
    }
  },
  "contentbundleSlide": {
    "copyTooltip": "Inschrijfmodule kopieren",
    "deleteSlideTooltip": "Verwijder slide",
    "editSlideTooltip": "Bewerk slide",
    "editTooltip": "Bewerk hoofdstuk",
    "addHitboxButton": "Hitbox toevoegen",
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam"
      },
      "final": {
        "label": "Vanaf deze slide naar registratieformulier"
      },
      "file": {
        "label": "Slide"
      }
    },
    "save": {
      "error": "Kon slide niet opslaan!",
      "overlapError": "Een of meerdere hitboxen overlappen. Dit is niet toegestaan.",
      "duplicateNameError": "Er bestaat al een slide met deze naam!",
      "success": "Slide is opgeslagen"
    },
    "delete": {
      "content": "Je staat op het punt om een slide te verwijderen. Wilt u doorgaan?",
      "error": "Kon slide niet verwijderen!",
      "success": "Slide is verwijderd"
    }
  },
  "displayCondition": {
    "field": {
      "metafield": {
        "label": "Metafield"
      },
      "value": {
        "label": "Waarde"
      },
      "type": {
        "label": "Type",
        "value": {
          "standard": "Standaard veld",
          "metafield": "Metafield"
        }
      },
      "standardField": {
        "label": "Veld",
        "value": {
          "age": "Leeftijd"
        }
      },
      "comparisonMethod": {
        "label": "Verglijker",
        "value": {
          "=": "Gelijk aan",
          "<": "Minder dan",
          ">": "Meer dan"
        }
      }
    }
  },
  "importedCreditationCsv": {
    "field": {
      "csv": {
        "label": "CSV"
      },
      "importer": {
        "label": "Importer"
      },
      "createdAt": {
        "label": "Datum"
      }
    },
    "actions": {
      "label": "Acties"
    }
  },
  "contentbundleSlideSettingsCondition": {
    "addConditionButton": "Voeg conditie toe",
    "addConditionButtonTitle": "Als 1 van de condities voldoet, dan wordt deze slide weergegeven.",
    "field": {
      "productId": {
        "label": "Product"
      },
      "metafieldId": {
        "label": "Metafield"
      },
      "metafieldValue": {
        "label": "Waarde"
      }
    }
  },
  "fileBundle": {
    "form": {
      "client": {
        "label": "Klant"
      },
      "description": {
        "label": "Beschrijving"
      },
      "name": {
        "label": "Naam"
      }
    },
    "create": {
      "title": "Export toevoegen"
    },
    "edit": {
      "title": "Bestandsdefinitie"
    },
    "modal": {
      "delete": {
        "content": "Je staat op het punt om een bestandsdefinitie te verwijderen. Hierbij worden ook eerder gegenereerde exportbestanden van deze definitie verwijderd uit het archief. Wil je doorgaan?",
        "cancelButton": "Annuleer",
        "deleteButton": "Opzeggen"
      }
    },
    "exportModal": {
      "showModalButton": "Resultaten exporteren",
      "title": "Export opties",
      "performExport": "Exporteren",
      "isDefinitive": "Definitieve export",
      "dateRange": "Datum",
      "confirmDefinitive": "Weet je zeker dat je deze export definitief wilt exporteren? Dit zal alle geselecteerde inschrijvingen daadwerkelijk afhandelen en doorvoeren."
    },
    "exportErrorModal": {
      "title": "Fouten tijdens export",
      "fileDefitionHeader": "Fouten in bestandsdefinitie",
      "unknownErrorMessage": "Er is een onbekende fout opgetreden tijdens de export"
    },
    "metadata": {
      "title": "Metadata",
      "fields": {
        "description": {
          "label": "Beschrijving"
        },
        "format": {
          "label": "Bestandstype"
        },
        "encoding": {
          "label": "Bestandsencoding"
        }
      }
    },
    "field": {
      "id": {
        "title": "ID"
      },
      "client": {
        "title": "Klant",
        "placeholder": "Klant..."
      },
      "description": {
        "title": "Beschrijving",
        "placeholder": "Beschrijving..."
      }
    },
    "save": {
      "error": "Kon bundel niet opslaan!",
      "success": "Bundel is opgeslagen"
    },
    "addInputNodeButton": "Voeg node toe"
  },
  "fileNode": {
    "deleteConfirmation": "Weet u zeker dat u het element \"{{elementName}}\" wil verwijderen?"
  },
  "generatedBundle": {
    "field": {
      "id": {
        "label": "ID"
      },
      "subscriptionId": {
        "label": "Inschrijving ID"
      },
      "client": {
        "label": "Klant"
      },
      "createdAt": {
        "label": "Aangemaakt"
      }
    }
  },
  "group": {
    "createButton": "Groep toevoegen",
    "field": {
      "id": {
        "label": "ID"
      },
      "isDefault": {
        "label": "Default medewerker"
      },
      "isDefaultApplicant": {
        "label": "Default sollicitant"
      },
      "isDefaultClient": {
        "label": "Default klant gebruiker"
      },
      "defaultClient": {
        "label": "Default klant gebruiker"
      },
      "name": {
        "label": "Naam",
        "placeholder": "Naam..."
      },
      "permissions": {
        "label": "Toestemming",
        "placeholder": "Toestemming..."
      },
      "default": {
        "label": "Default medewerker",
        "placeholder": "Standaard..."
      },
      "defaultApplicant": {
        "label": "Default solicitant",
        "placeholder": "Standaard aanvrager..."
      }
    },
    "save": {
      "error": "Kon groep niet opslaan!",
      "success": "Groep is opgeslagen"
    },
    "editTooltip": "Groep bewerken",
    "nav": {
      "overview": "Permissie groepen"
    },
    "filter": {
      "permission": {
        "selectedText": "# van # rechten geselecteerd",
        "noneSelectedText": "Kies rechten",
        "placeholder": "Zoeken..."
      }
    }
  },
  "issue": {
    "field": {
      "reason": {
        "value": {
          "start_date exceeds max delta": "Startdatum te laat",
          "iban missing": "Mist IBAN",
          "quantity missing": "Mist aantal",
          "amount missing": "Mist bedrag",
          "email missing": "Mist e-mail adres",
          "landline_invalid": "Ongeldig vast telefoonnummer",
          "landline missing": "Mist vaste telefoon",
          "below minimum age": "Onder minimale leeftijd",
          "last_name missing": "Mist achternaam",
          "gender unknown": "Mist geslacht ",
          "date_of_birth missing": "Mist geboortedatum",
          "mobile_invalid": "Ongeldig mobiel telefoonnummer",
          "mobile missing": "Mist mobiel telefoonnummer",
          "phone number missing": "Mist vast en mobiel telefoonnummer",
          "address invalid": "Adres ongeldig",
          "address incomplete": "Adres incompleet",
          "address unsure": "Adres vaag",
          "duplicate iban": "Dubbele IBAN voor abonnee",
          "known iban": "IBAN abonnee al bekend",
          "recruiter iban": "IBAN van een medewerker",
          "duplicate email": "E-mail abonnee al bekend",
          "recruiter email": "E-mail van een medewerker",
          "duplicate phone": "Telefoonnummer abonnee al bekend",
          "recruiter phone": "Telefoonnummer van een medewerker"
        }
      }
    }
  },
  "callPlanning": {
    "addSubModal": {
      "title": "Inschrijvingen toevoegen voor campagne {{campaign}}"
    },
    spokenLanguage: "Taal",
    language: 'Taal',
    "appointments": "Bekijk",
    "employee": "Medewerker",
    "markAllDone": "Markeer alles als klaar",
    "reachedDone": "Markeer 'bereikt' als klaar",
    "importSubscriptions": "Inschrijvingen toevoegen",
    "confirmManuallyMark": "Bevestig dat alle {{amount}} inschrijven van campagne {{campaign}} als klaar moeten worden gemarkeerd",
    "confirmManuallyReached": "Bevestig dat alle 'bereikte' abonnementen van campagne {{campaign}} als klaar moeten worden gemarkeerd",
    "confirmImportHeader": "Importeer bevestiging",
    "confirmImport": "Weet je zeker dat je de inschrijvingen van campagne {{name}} wil importeren?",
    "importRecords": "Inschrijvingen importeren",
    "amountOfDonors": "{{amount}} Inschrijvingen: {{reached}} bereikt, {{totalDone}} klaar ({{percentage}} %).",
    "steps": {
      "importSubs": "Importeren",
      "assign": "Medewerkers toewijzen",
      "current": "Huidige bel campaignes"
    },
    "appointmentModal": {
      "subscriptionType": "Inschrijving",
      "bucketType": "Bucket",
      "title": "Belafspraken {{type}} {{id}}"
    }
  },
  "jobApplication": {
    "nav": {
      "overview": "Aanvragen",
      "interviews": "Sollicitaties"
    },
    "add": "Voeg solicitant toe",
    "sendActivationEmailButton": "(Re)send activation email",
    "sendNoShowEmailButton": "(Re)send no-show email",
    "sendColdLeadEmailButton": "(Re)send cold lead email",
    "sendActivationEmailSuccessMessage": "Sent successfully",
    "filter": {
      "minCallAmount": "Minimaal aantal keren gebeld",
      "maxCallAmount": "Maximaal aantal keren gebeld",
      "corrected": "(correctie)"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "callBackAppointment": {
        "label": "Terugbelafspraak tijd"
      },
      "remarks": {
        "label": "Opmerkingen"
      },
      "nationalityInternal": {
        "label": "Nationaliteit"
      },
      "viaVia": {
        "label": "ViaVia",
        "placeholder": "ViaVia..."
      },
      "employee": {
        "label": "Promotor",
        "placeholder": "Promotor..."
      },
      "last_sign_in": {
        "label": "Laatste werkdag"
      },
      "name": {
        "label": "Naam",
        "placeholder": "Naam..."
      },
      "avatar": {
        "label": "Profiel foto",
        "placeholder": "Profiel foto..."
      },
      "public_transport_student_license": {
        "label": "Heeft studenten OV",
        "placeholder": "Studenten OV...",
        "value": {
          "false": "Nee",
          "true": "Ja"
        }
      },
      "publicTransportStudentLicenseType": {
        "label": "Type studenten OV",
        "value": {
          "no": "Geen",
          "week": "Week",
          "weekend": "Weekend"
        }
      },
      "mainBranch": {
        "label": "Hoofdvestiging",
        "placeholder": "Kies hoofdvestiging..."
      },
      "notInterested": {
        "info": "Deze solicitant heeft aangegeven niet verder te willen gaan met het solicitatie process",
        "label": "Geen afspraak"
      },
      dropoutReason:{
        label: "Uitval reden"
      },
      "travelingDistance": {
        "label": "Reisafstand"
      },
      "notInterestedReason": {
        "label": "Reden geen afspraak"
      },
      "countryOfBirth": {
        "label": "Geboorteland"
      },
      "cityOfBirth": {
        "label": "Geboortestad"
      },
      "branches": {
        "label": "Vestiging",
        "placeholder": "Vestiging...",
        "selectedText": "# van # vestigingen",
        "noneSelectedText": "Selecteer vestiging"
      },
      "initials": {
        "label": "Initialen",
        "placeholder": "Initialen..."
      },
      "firstName": {
        "label": "Voornaam",
        "placeholder": "Voornaam..."
      },
      "middleName": {
        "label": "Tussenvoegsel",
        "placeholder": "Tussenvoegsel..."
      },
      "lastName": {
        "label": "Achternaam",
        "placeholder": "Achternaam..."
      },
      "gender": {
        "label": "Geslacht",
        "value": {
          "male": "man",
          "female": "vrouw",
          "other": "anders",
          "unkown": "onbekend"
        }
      },
      "onHold": {
        "labelDr": "Nog niet meteen beginnen",
        "label": "On hold",
        "value": {
          "true": "Ja",
          "false": "Nee",
          "all": "Alles"
        }
      },
      "onHoldReason": {
        "label": "Reden voor on hold"
      },
      "age": {
        "label": "Leeftijd"
      },
      "dateOfBirth": {
        "label": "Geboortedatum"
      },
      "phoneNumbers": {
        "label": "Telefoonnummers",
        "placeholder": "Telefoonnummers..."
      },
      "ssn": {
        "label": "BSN",
        "placeholder": "BSN..."
      },
      "dateStarted": {
        "label": "Datum in dienst"
      },
      "country": {
        "label": "Land",
        "placeholder": "Land...",
        "value": {
          "NL": "NL"
        }
      },
      "iban": {
        "label": "IBAN",
        "placeholder": "IBAN..."
      },
      "bic": {
        "label": "BIC",
        "placeholder": "BIC..."
      },
      "houseNumber": {
        "label": "Huisnummer",
        "placeholder": "Huisnummer..."
      },
      "houseNumberSuffix": {
        "label": "Huisnummertoevoeging",
        "placeholder": "Huisnummertoevoeging..."
      },
      "city": {
        "label": "Stad",
        "placeholder": "Stad..."
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "street": {
        "label": "Straat",
        "placeholder": "Straat..."
      },
      "zipCode": {
        "label": "Postcode",
        "placeholder": "Postcode..."
      },
      "employeeNumber": {
        "label": "Personeels-/referentienummer",
        "placeholder": "Personeels-/refnr..."
      },
      "drivingLicence": {
        "label": "Heeft rijbewijs",
        "placeholder": "Rijbewijs...",
        "value": {
          "false": "Nee",
          "true": "Ja"
        }
      },
      "bankCard": {
        "label": "Bankpas",
        "placeholder": "Bankpas..."
      },
      "taxExemption": {
        "label": "Heffingskorting",
        "placeholder": "Heffingskorting..."
      },
      "canChangeTaxExemption": {
        "label": "Kan belastingvrijstelling veranderen?",
        "placeholder": "Kan belastingvrijstelling veranderen?"
      },
      "contractSignature": {
        "label": "Contract",
        "placeholder": "Contract..."
      },
      "identification": {
        "label": "ID kaart voorkant",
        "placeholder": "ID kaart voorkant..."
      },
      "identification_back": {
        "label": "ID kaart achterkant",
        "placeholder": "ID kaart achterkant..."
      },
      "bw_id": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "city_of_birth": {
        "label": "Geboortestad",
        "placeholder": "Geboortestad..."
      },
      "country_of_birth": {
        "label": "Geboorteland",
        "placeholder": "Geboorteland..."
      },
      "id_type": {
        "label": "ID type",
        "placeholder": "ID type..."
      },
      "id_number": {
        "label": "ID number",
        "placeholder": "ID number..."
      },
      "interviewer": {
        "label": "Interviewer?",
        "title": "De medewerker kan dan gekozen worden in de lijst van interviewers voor sollicitatiegesprekken."
      },
      "coins": {
        "label": "Coins"
      },
      "blockedClients": {
        "label": "Geblokkeerde klanten"
      },
      "referral_source": {
        "label": "Source"
      },
      "referralSource": {
        "label": "Source"
      },
      "referralSourceOther": {
        "label": "Source anders"
      },
      "legalGuardianName": {
        "label": "Naam van wettelijke voogd"
      },
      "legalGuardianPhone": {
        "label": "Telefoon van wettelijke voogd"
      },
      "legalGuardianEmail": {
        "label": "E-mailadres van wettelijke voogd"
      },
      "noShowSent": {
        "info": "Als een solicitant niet is komen opdagen en (een no show status heeft) en minimaal een keer is gebeld kan er een no-show email worden vestuurd (in de toolbar). Deze mail bevat een reminder aan de solicitant dat hij op interview langs kan komen.",
        "label": "No show email verstuurd"
      },
      "legalGuardianDisabled": {
        "label": "Wettelijke voogd niet vereist"
      },
      "coldLeadSent": {
        "info": "Als een solicitant niet is komen opdagen en (een cold lead status heeft) en minimaal vijf keer is gebeld kan er een cold lead email worden vestuurd (in de toolbar). Deze mail bevat een laatste reminder aan de solicitant dat hij op interview langs kan komen.",
        "label": "Cold lead email verstuurd"
      },
      "createdAt": {
        "label": "Aangemaakt"
      },
      "lastInterview": {
        "label": "Laatste interview"
      },
      "employeeNumbefr": {
        "label": "Personeelsnummer",
        "placeholder": "Personeel nee..."
      }
    },
    "calls": {
      "modal": {
        "incoming": "Binnenkomende gesprekken",
        "outcoming": "Uitgaande gesprekken",
        "buttonTitle": "Telefoon gesprekken",
        "id": "ID",
        "caller": "Beller",
        "calledUser": "To",
        "start": "Start",
        "duration": "Duur",
        "voicelogs": "Voicelogs",
        "listenCall": "Gesprek na luisteren",
        "audioError": "Uw browser ondersteunt de audio tag niet."
      },
      "modalTitle": "Telefoongesprekken van sollicitant"
    }
  },
  "referralSource": {
    create:{
      title: 'Source toevoegen'
    },
    "createButton": "Source toevoegen",
    "nav": {
      "overview": "Source"
    },
    edit:{
      title: 'Source bewerken'
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "isDefault": {
        "label": "Default"
      },
      "isOther": {
        "label": "Anders"
      },
      "name": {
        "label": "Naam"
      }
    },
    filter: {
      "deleted": "Verwijderde sources",
      "onlyDeleted": "Alleen verwijderd",
      "withoutDeleted": "Zonder verwijderd",
      "bothDeletedAndNot": "Alles"
    }
  },
  "job": {
    "editTooltip": "Functie bewerken",
    "nav": {
      "overview": "Functies"
    },
    "createButton": "Functie toevoegen",
    "save": {
      "error": "Kon functie niet opslaan!",
      "success": "Functie is opgeslagen"
    },
    "field": {
      "id": {
        "label": "ID",
        "placeholder": "ID..."
      },
      "name": {
        "label": "Naam",
        "placeholder": "Naam..."
      },
      "isDefault": {
        "label": "Default positie?",
        "placeholder": "Default positie..."
      },
      "isTeamPosition": {
        "label": "Teampositie?",
        "placeholder": "Teampositie..."
      },
      "isFreelance": {
        "label": "Freelance?",
        "placeholder": "Freelance..."
      },
      "baseFactor": {
        "label": "Basis factor"
      }
    },
    "application": {
      "createButton": "Sollicitant toevoegen",
      "action": {
        "waitForContractUpload": "Wachten op contract geüpload",
        "readyToConvertToEmployee": "Klaar om te converteren naar actieve werknemer",
        "waitForInterviewApproval": "Wachten op bevestiging afspraak",
        "checkInterview": "Controleer afspraak",
        "makeAppointment": "Afspraak inplannen",
        "appointmentMade": "Wachtend op interview"
      },
      "field": {
        "amountofcalls": {
          "label": "Aantal keren gebeld",
          lastCall: "Laatste belgesprek",
          hasCalls: "Gebeld?",
          corrected: "Correctie aantal keren gebeld"
        },
        callAppointment: {
            label: "Status belafspraak"
        },
        "dateofinterview": {
          "label": "Datum interview"
        },
        "whatsappStatus": {
          "label": "Whatsapp Status",
          "value": {
            "to_call": "Verzoek om te bellen",
            "reminder_appointment": "Afspraak reminder",
            "congratulations_hired": "Felicitatie bij aanname"
          },
          "save": "Whatsapp Status opgeslagen",
          "saveError": "Whatsapp Status niet opgeslagen"
        },
        "id": {
          "label": "ID"
        },
        "name": {
          "label": "Naam"
        },
        "remarks": {
          "label": "Opmerkingen"
        },
        "age": {
          "label": "Leeftijd",
          "placeholder": "Leeftijd..."
        },
        "status": {
          "label": "Status",
          "multiselect": {
            "selectedText": "# van # statussen",
            "noneSelectedText": "Selecteer status"
          },
          "multiselectFilter": {
            "placeholder": "Zoek..."
          },
          "value": {
            "undecided": "Nog te beslissen",
            "hired": "Aangenomen",
            "rejected": "Afgewezen",
            "on_hold": "On hold",
            "no-show": "No Show",
            "to-plan": "Afspraak inplannen",
            "planned": "Interview gepland",
            "awaiting-interview": "Wachtend op Interview",
            "completed-interview": "Interview voltooid",
            "to-plan-no-show": "Afspraak inplannen na No Show",
            "to-plan-cold-lead": "Afspraak inplannen na Cold Lead",
            "not-interested": "Geen afspraak"
          }
        },
        "createdAt": {
          "label": "Aangemaakt"
        },
        "previous_call_attempts": {
          "label": "Eerdere belpogingen"
        },
        "referralSource": {
          "label": "Source"
        },
        "branch": {
          "label": "Vestiging"
        },
        "email": {
          "label": "Email"
        },
        "phoneNumber": {
          "label": "Telefoon"
        },
        "referral_source": {
          "label": "Source"
        },
        "viaVia": {
          "label": "ViaVia Bron"
        }
      },
      "filter": {
        "search": {
          "label": "Zoeken",
          "placeholder": "Zoek op voornaam, achternaam, email..."
        },
        "active": {
          "label": "Actief?",
          "value": {
            "all": "Alles",
            "active-only": "Alleen actieve promotors",
            "non-active-only": "Nog geen actieve promotors"
          }
        }
      },
      "page": {
        "overview": {
          "title": "Sollicitatieaanvragen"
        },
        "edit": {
          "title": "Sollicitatieaanvraag"
        }
      },
      "plan": "Interview toevoegen",
      "modal": {
        "delete": {
          "content": "Je staat op het punt om een sollicitant te verwijderen. Wilt u doorgaan?",
          "cancelButton": "Nee",
          "deleteButton": "Verwijderen"
        },
        "deleteFile": {
          "content": "Weet u zeker dat u dit bestand wilt verwijderen?",
          "cancelButton": "Nee",
          "deleteButton": "Verwijderen"
        }
      },
      "save": {
        "success": "Aanvraag opgeslagen",
        "error": "Fout bij opslaan aanvraag"
      },
      "delete": {
        "error": "Kon item niet verwijderen!",
        "success": "Item is verwijderd"
      }
    },
    "callbackAppointment": {
      "options": {
        "none": "zonder",
        "pending": "Aankomend",
        "done": "Klaar",
        "notReached": "Niet bereikt"
      },
      "modalCallButton": "Geplande gesprekken",
      "callBackAppointment": "Gepland gesprek",
      "callbackAppointments": "Geplande gesprekken",
      "callAppointmentStatus": "Status",
      "deleteDialog": {
        "title": "Weet u zeker dat u de terugbelafspraak wilt verwijderen?",
        "confirm": {
          "label": "Verwijderen"
        }
      },
      "save": {
        "success": "Terugbelafspraak opgeslagen",
        "error": "Fout bij opslaan terugbelafspraak"
      },
      "header": "Terugbelafspraken",
      "createButton": "Terugbelafspraak toevoegen",
      "field": {
        "status": {
          "label": "Status"
        },
        "date": {
          "label": "Datum en Tijd"
        }
      }
    },
    interview: {
      "createButton": "Interview toevoegen",
      "hireButton": "Aannemen",
      "rejectButton": "Afwijzen",
      "noShowButton": "No Show",
      "deleteDialog": {
        "title": "Weet u zeker dat u het interview wilt verwijderen?",
        "confirm": {
          "label": "Verwijderen"
        }
      },
      "button": {
        "hired": "Aannemen",
        "rejected": "Afwijzen",
        "no-show": "No show",
        "undecided": "Nog te beslissen"
      },
      "field": {
        "id": {
          "label": "ID"
        },
        "name": {
          "label": "Naam"
        },
        "interviewer": {
          "label": "Interviewer",
          "placeholder": "Kies interviewer..."
        },
        "branch": {
          "label": "Vestiging"
        },
        "date": {
          "label": "Datum en Tijd"
        },
        "time": {
          "label": "Tijd",
          "none": "Kies eerst een vestiging en datum",
          "placeholder": "Kies tijd..."
        },
        "status": {
          "label": "Status"
        }
      },
      "status": {
        "pending": "Wachtend op sollicitatie",
        "accepted": "Aangenomen",
        "rejected": "Interview geannuleerd door kandidaat",
        "rejectedRemark": "Wil geen nieuwe afspraak",
        "make-new-appointment": "Maak nieuwe afspraak"
      },
      "page": {
        "overview": {
          "title": "Interviews"
        }
      },
      "save": {
        "success": "Interview opgeslagen",
        "error": "Fout bij opslaan interview"
      },
      "empty": "Geen interviews aangevraagd",
      "multiselect": {
        "placeholder": "Zoek interviewer...",
        "selectedText": "# van # interviewers geselecteerd",
        "noneSelectedText": "Selecteer interviewer"
      }
    }
  },
  "client": {
    "addUserButton": "Klant gebruiker toevoegen",
    "headerUserTable": "Klant gebruikers",
    "user": {
      "header": "Klant gebruiker",
      "emptyMail": "Voer een email address in",
      "emptyFirstName": "Voer de voornaam van de klant gebruiker in",
      "emptyLastName": "Voer de achternaam van de klant gebruiker in",
      "incorrectMail": "De ingevoerde waarde in geen geldig email address",
      "email": "Email"
    },
    "editButton": "Bewerk klant",
    "deleteButton": "Verwijder klant",
    "view": {
      "title": "Klanten"
    },
    "edit": {
      "title": "Bewerk klant"
    },
    "create": {
      "title": "Klant aanmaken"
    },
    "overview": {
      "title": "Klanten",
      "createButton": "Klant toevoegen"
    },
    "field": {
      "Acties": {
        "label": "Acties"
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam"
      },
      "campaigns": {
        "label": "Aantal campagnes"
      },
      "showMaxAmountOfCalls": {
        "label": "Maximaal aantal belpogingen gebruiken"
      },
      "maxAmountOfCalls": {
        "label": "Maximaal aantal belpogingen"
      },
      'maxSubAmountWages': {
        label: "Maximaal verloon bedrag"
      },
      "users": {
        "label": "Aantal gebruikers"
      },
      "max_start_date_delta": {
        "label": "Max. latere startdatum",
        "placeholder": "e.g. voor 2 maanden: P2M",
        "error": {
          "incorrect": "Format klopt niet"
        }
      },
      "doubleConfigured": {
        "label": "Dubbele tabletconfiguratie",
        "helpText": "Voor deze klant kan een tablet op dezelfde dag twee maal worden gepland, Mocht dit het geval zijn zal het team met het laagste id nummer als eerste worden gebruikt. Zodra er voor dat team is uitgelogd word er automatisch doorgeschakelt naar het volgende team."
      },
      "sendEncryptedSubscriberMails": {
        "label": "Versleuteld mails versturen",
        "helpText": "Mails van deze klant zullen worden versleuteld voordat de abbonnee zijn/haar gegevens ontvangt. Dit gebeurd met de laatste vier cijfers van het IBAN van de klant."
      },
      "maximumStorageTerm": {
        "label": "Max. bewaartermijn",
        "placeholder": "e.g. voor 2 maanden: P2M",
        "helpText": "Een bewaarperiode opgeschreven in het formaat van ISO 8601, Voorbeeld: P2M = 2 maanden, P84D = 84 dagen, P10W = 10 weken"
      },
      "products": {
        "label": "Producten"
      }
    },
    "deleteTooltip": "Verwijder klant",
    "restoreTooltip": "Restore klant",
    "editTooltip": "Pas klant aan",
    "modal": {
      "delete": {
        "content": "Je staat op het punt om een klant te verwijderen. Wilt u doorgaan?",
        "cancelButton": "Nee",
        "deleteButton": "Verwijderen"
      }
    },
    "save": {
      "error": "Kon klant niet opslaan!",
      "success": "Klant is opgeslagen"
    }
  },
  "wagesTariff": {
    "title": "Wages tariff PDF",
    "edit": "Wages tariff edit",
    "upload": "Drop PDF file here!"
  },
  "contract": {
    "title": "Contract",
    "field": {
      "id": {
        "label": "ID"
      },
      "label": {
        "label": "Label"
      },
      "content": {
        "label": "Content"
      },
      "draft": {
        "label": "Draft?"
      }
    }
  },
  "document": {
    "title": "Documenten",
    "createButton": "Document toevoegen",
    "field": {
      "id": {
        "label": "ID"
      },
      "icon": {
        "label": "Icon"
      },
      "type": {
        "label": "Type",
        "value": {
          "employment contract": "Werkcontract",
          "contract": "Contract",
          "manual": "Manual"
        }
      },
      "userType": {
        "label": "Shown for",
        "value": {
          "basic": "All",
          "new_employees": "Only Active Employees",
          "new_job_applications": "Only Job Applicants"
        }
      },
      "label": {
        "label": "Label"
      },
      "required": {
        "label": "Vereist",
         "autoAssigning": "Automatisch toewijzen"
      },
      "content": {
        "label": "Content"
      },
      "status": {
        "label": "Status",
        "value": {
          "draft": "Draft",
          "published": "Published",
          "archived": "Archived"
        }
      },
      "isPayroll": {
        "label": "Payroll document",
      },
    }
  },
  "handbook": {
    "title": "Handbook"
  },
  "privacy": {
    "title": "Privacy statement"
  },
  "captainsheetContractText": {
    "title": "Captainsheet Contract Edit"
  },
  "team": {
    "totalRecords": "Teams",
    "totalTeamMembers": "Promoters",
    "totalSubscriptionCount": "Inschrijvingen",
    "totalTeamMembersWithSignIn": "Sign in",
    "totalTeamMembersWithSignOut": "Sign out",
    "totalAverage": "A.P.P.",
    "totalAverageDR": "Gem. score",
    "totalSubscriptionsFactored": "Inschrijvingen totaal",
    "clear": "Veld leeg maken",
    "nav": {
      "overview": "Teams"
    },
    "title": "Team Planning",
    "page": {
      "overview": {
        "title": "Teams",
        "day": [
          "zo",
          "ma",
          "di",
          "wo",
          "do",
          "vr",
          "za"
        ]
      }
    },
    "createButton": "Plan team",
    "importFromBriggsAndWalkersButton": "Importeer B&W",
    "prevButton": "Vorige",
    "nextButton": "Volgende",
    "toFlushViewButton": "Flush teams",
    "downloadButton": "Team csv",
    "form": {
      "dateRangePlaceholder": "Choose date range"
    },
    "editTooltip": "Pas team aan",
    "viewTooltip": "Bekijk team",
    "viewSubscriptionsTooltip": "Bekijk inschrijvingen",
    "noSubscriptionsTooltip": "Geen inschrijvingen",
    "batchTooltip": "Laat abonnees van dit team zien",
    "deleteTooltip": "Verwijder team",
    "copyTooltip": "Kopieer team",
    "flushTooltip": "Geef gelockte scans vrij",
    "uploadTooltip": "Upload scans",
    "addLocationButton": "Voeg locatie toe",
    "save": {
      "error": "Kon team niet opslaan!",
      "success": "Team is opgeslagen"
    },
    "delete": {
      "confirm": "Echt verwijderen?",
      "error": "Kon team niet verwijderen!",
      "success": "Team is verwijderd"
    },
    "filter":{
      "deleted": "Verwijderde teams",
      "onlyDeleted": "Alleen verwijderd",
      "withoutDeleted": "Zonder verwijderd",
      "bothDeletedAndNot": "Alles",
      "today": "Vandaag",
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "client": {
        "label": "Klant"
      },
      "source": {
        "label": "Databron",
        "options": {
          "incenova": "Alleen Incenova",
          "bw": "Alleen B&W",
          "all": "Alles"
        }
      },
      "subscriptionsCount": {
        "label": "Team score"
      },
      "day": {
        "label": "Dag"
      },
      "week": {
        "label": "Week"
      },
      "attachement": {
        "label": "resultaat",
        "verify": "Verifieer"
      },
      "members": {
        "label": "Members"
      },
      "date": {
        "label": "Datum",
        "placeholder": "Datum..."
      },
      "startTime": {
        "label": "Starttijd"
      },
      "endTime": {
        "label": "Eindtijd"
      },
      "status": {
        "label": "Status",
        "placeholder": "Status...",
        "value": {
          "planned": "Geplanned",
          "uploaded": "Geupload",
          "entry": "invoeren",
          "completed": "Afgehandeld"
        }
      },
      "branch": {
        "label": "Vestiging",
        "placeholder": "Vestiging...",
        "permission": {
          "allocation": {
            "modify_team": "Aan te passen naar alle vestigingen, ook al is de gebruiker niet lid van die vestiging.",
            "modify_team:branch": "Alleen aan te passen naar vestigingen waar de gebruiker lid van is. De dropdown zal ook alleen de vestigingen tonen waar die lid van is."
          }
        }
      },
      "campaign": {
        "label": "Campagne",
        "placeholder": "Campagne..."
      },
      "driver": {
        "label": "Driver",
        "placeholder": "Driver..."
      },
      "location": {
        "label": "Locaties",
        "placeholder": "Locatie...",
        "add": "Locatie toevoegen"
      },
      "teamMember": {
        "add": "Voeg teamlid toe"
      },
      "remarks": {
        "label": "Algemene opmerking"
      },
      "teamCaptain": {
        "label": "Team captain"
      },
      "shiftType": {
        "label": "Shift type"
      },
      "carDamageStart": {
        "label": "Autoshade aan het begin"
      },
      "carDamageEnd": {
        "label": "Autoschade aan het einde"
      },
      "carMileageStart": {
        "label": "Gereden kilometers aan het begin"
      },
      "carMileageEnd": {
        "label": "Gereden kilometers aan het einde"
      },
      "createdAt": {
        "label": "Aangemaakt op"
      },
      "deleted": {
        "label": "Verwijderd"
      },
      "externalId": {
        "label": "Externe ID"
      },
      "licensePlate": {
        "label": "Kentekenplaat"
      },
      "updatedAt": {
        "label": "Laatst bewerkt op"
      }
    },
    "empty": {
      "label": "Lege teams?",
      "only": "Alleen lege teams",
      "none": "Geen lege teams",
      "both": "Alle teams"
    },
    "amount": {
      "label": "Aantal",
      "placeholder": "Aantal..."
    },
    "membersCount": {
      "label": "Leden",
      "placeholder": "Leden...",
      "empty": "Nog geen teamleden..."
    },
    "batchUpload": {
      "error": "Kon scans niet uploaden!",
      "success": "Scans zijn geupload",
      "errorUnknownFileType": "Kies een ZIP, PDF of JPEG bestand."
    },
    "sheetToScan": {
      "error": "Kon sheet niet verplaatsen!",
      "success": "Sheet is verplaatst"
    },
    "scanToSheet": {
      "error": "Kon scan niet verplaatsen!",
      "success": "Scan is verplaatst"
    },
    "saveStatusEntry": {
      "error": "Kon status niet aanpassen!",
      "success": "Status is aangepast"
    },
    "lockScan": {
      "error": "Kon scan niet locken!",
      "errorNoEmployee": "Kon scan niet locken! Controleer of jouw inlog gekoppeld is aan een gebruiker.",
      "noScans": "Geen scans gevonden."
    },
    "modal": {
      "delete": {
        "content": "Je staat op het punt om een team te verwijderen. Wilt u doorgaan?",
        "cancelButton": "Nee",
        "deleteButton": "Verwijderen"
      }
    },
    "member": {
      "delete": {
        "tooltip": "Verwijder teamlid"
      },
      "restore": {
        "tooltip": "Herstel teamlid"
      }
    }
  },
  "teamLocation": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Locatie",
        "placeholder": "Locatie..."
      }
    }
  },
  "teamMember": {
    "field": {
      "employeeID": {
        "label": "Medewerker ID"
      },
      "plannedBrach": {
        "label": "Vestiging waarvoor geworven"
      },
      "tabletBranch": {
        "label": "Vestiging tablet"
      },
      "location": {
        "label": "Werf locatie"
      },
      "campaign": {
        "label": "Campagne"
      },
      "date": {
        "label": "Datum"
      },
      "id": {
        "label": "ID"
      },
      "team_member": {
        "label": "Teamlid",
        "placeholder": "Teamlid...",
      },
      "employee": {
        "label": "Teamlid",
        "placeholder": "Teamlid..."
      },
      "device": {
        "label": "Apparaat",
        "placeholder": "Apparaat..."
      },
      "function": {
        "label": "Functie",
        "placeholder": "Functie...",
        "info": "De functie van de medewerker op de dag van dit team"
      },
      "note": {
        "this_is_old": "do not use",
        "label": "Opmerking"
      },
      "remarks": {
        "label": "Opmerking"
      },
      "sign": {
        "in": "Sign In",
        "out": "Sign Out"
      },
      "driver": {
        "label": "Is driver"
      },
      "subscriptionsCount": {
        "label": "Score"
      },
      "quantitiesCount": {
        "label": "Aantal"
      },
      "shiftType": {
        "label": "Shift type"
      },
      "autoSignedOut": {
        "label": "Automatisch afgemeld"
      },
      "createdAt": {
        "label": "Aangemaakt op"
      },
      "damageSignIn": {
        "label": "Schade bij aanmelding"
      },
      "damageSignOut": {
        "label": "Schade bij afmelding"
      },
      "deleted": {
        "label": "Verwijderd"
      },
      "fieldDay": {
        "label": "Werkdag"
      },
      "functionOverridden": {
        "label": "Functie overreden"
      },
      "shiftTypeOverride": {
        "label": "Shift type overreden"
      },
      "signIn": {
        "label": "Aangemeld"
      },
      "signInLat": {
        "label": "Latitude aanmelding"
      },
      "signInLng": {
        "label": "Longitude aanmelding"
      },
      "signInTime": {
        "label": "Tijdstip aanmelding"
      },
      "signOut": {
        "label": "Afgemeld"
      },
      "signOutLat": {
        "label": "Latitude afmelding"
      },
      "signOutLng": {
        "label": "Longitude afmelding"
      },
      "signOutTime": {
        "label": "Tijdstip afmelding"
      },
      "team": {
        "label": "Team ID"
      },
      "updatedAt": {
        "label": "Laatst gewijzigd op"
      }
    },
    "duplicate": {
      "error": "Teamlid is al toegevoegd. Kies een ander teamlid."
    }
  },
  "traineeship": {
    "page": {
      "traineeshipEdit": "Traineeship aanpassen",
      "traineeshipPageEdit": "Pagina {{id}} aanpassen",
      "traineeshipPageAdd": "Pagina toevoegen"
    },
    "nav": {
      "overview": "Traineeships"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "title": {
        "label": "Naam",
        "placeholder": "Title..."
      },
      "slug": {
        "label": "Slug",
        "placeholder": "Slug..."
      },
      "ordering": {
        "label": "Volgorde",
        "placeholder": "Volgorde..."
      },
      "autoAssign": {
        "label": "Auto assign",
        "placeholder": "Auto assign...",
        "labelEdit": "Automatisch toewijzen aan iedereen?",
        "autoAssigning": "Automatisch toewijzen"
      },
      "video": {
        "label": "Video",
        "placeholder": "Video..."
      }
    },
    "create": {
      "title": "Traineeship toevoegen"
    },
    "delete": {
      "error": "Kon traineeship niet verwijderen!",
      "success": "Traineeship is verwijderd"
    },
    "edit": {
      "pagesTitle": "Pagina's",
      "title": "Traineeship aanpassen"
    },
    "createButton": "Traineeship toevoegen",
    "editTooltip": "Item bewerken",
    "statusSave": {
      "error": "Er is iets misgegaan met het wijzigen van de status.",
      "success": "De status is succesvol gewijzigd."
    },
    "assignmentCreate": {
      "error": "Er is iets misgegaan met het beschikbaar maken van de traineeship.",
      "success": "De traineeship is succesvol beschikbaar gemaakt."
    },
    "contract": {
      "overview": "Contract"
    }
  },
  "traineeshipProgress": {
    "employeeTitle": "Medewerker",
    "traineeshipTitle": "Traineeship",
    "traineeshipsTitle": "Traineeships",
    "statusTitle": "Status",
    "statusUnopened": "Nog niet geopend",
    "questionTitle": "Vraag",
    "answerTitle": "Antwoord",
    "noQuestions": "Geen vragen",
    "noAnswer": "Niet ingevuld",
    "errorOpening": "Kon traineeship niet openen",
    "errorApproving": "Kon traineeship niet accorderen"
  },
  "traineeshipPage": {
    "field": {
      "ordering": {
        "label": "Volgorde"
      },
      "id": {
        "label": "Item id",
        "placeholder": "Id..."
      },
      "text": {
        "label": "Tekst",
        "placeholder": "Tekst..."
      },
      "textPreview": {
        "label": "Tekst preview"
      },
      "video": {
        "label": "Video"
      },
      "questions": {
        "label": "Questions"
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "value": {
          "text": "Tekst",
          "questions": "Vragen",
          "video": "Filmpje",
          "end-evaluation": "End Evaluation"
        }
      }
    },
    "create": {
      "title": "Pagina toevoegen"
    },
    "edit": {
      "title": "Pagina aanpassen"
    },
    "createButton": "Pagina toevoegen",
    "save": {
      "success": "Pagina is opgeslagen",
      "error": "Er ging iets mis bij het opslaan van de pagina"
    },
    "upload": {
      "error": "Er ging iets mis bij het uploaden van de video"
    }
  },
  "traineeshipQuestion": {
    "newQuestion": "Vraag toevoegen",
    "editQuestion": "Vraag {{id}} aanpassen",
    "selectQuestionType": "Selecteer het vraagtype",
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "type": {
        "label": "Type",
        "value": {
          "input": "Vrij invulveld",
          "choice": "Meerkeuze"
        }
      },
      "ordering": {
        "label": "Ordering"
      },
      "question": {
        "label": "Vraag",
        "placeholder": "vraag..."
      }
    },
    "createButton": "Vraag toevoegen"
  },
  "traineeshipChoice": {
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "ordering": {
        "label": "Volgorde",
        "placeholder": "Volgorde..."
      },
      "answer": {
        "label": "Antwoord",
        "placeholder": "Antwoord..."
      }
    },
    "createButton": "Optie toevoegen"
  },
  "promotorAppMedia": {
    "nav": {
      "overview": "Promotor app media"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id"
      },
      "name": {
        "label": "Soort",
        "placeholder": "Naam...",
        "value": {
          "ViaVia": "ViaVia",
          "to-chose": "Kies een waarde"
        }
      },
      "enabled": {
        "label": "Actief",
        "placeholder": "Actief...",
        "value": {
          "true": "Ja",
          "false": "Nee"
        }
      },
      "description": {
        "label": "Omschrijving",
        "placeholder": "Omschrijving..."
      },
      "textField": {
        "label": "Promotor App Media tekst",
        "placeholder": "Promotor App Media tekst..."
      },
      "ViaViaImage": {
        "label": "Afbeelding",
        "placeholder": "Afbeelding...",
        "helpText": "Sleep een plaatje of click hier links om te uploaden."
      }
    },
    "create": {
      "title": "Promotor app media toevoegen"
    },
    "edit": {
      "title": "Promotor app media aanpassen"
    },
    "createButton": "Promotor app media toevoegen",
    "editTooltip": "Promotor app media bewerken",
    "empty_image": "Leeg"
  },
  "metafield": {
    "nav": {
      "overview": "Metafields"
    },
    "copyTooltip": "Item kopieren",
    "editTooltip": "Item bewerken",
    "page": {
      "overview": {
        "title": "Metafields"
      }
    },
    "createButton": "Metafield toevoegen",
    "clearDefaultButton": "Default wissen",
    "save": {
      "error": "Kon metafield niet opslaan!",
      "success": "Metafield is opgeslagen"
    },
    "modal": {
      "delete": {
        "header": "Metafield verwijderen",
        "content": "Als u dit metafield verwijdert, wordt het ook verwijderd uit de volgende producten:",
        "confirmation": "Weet u zeker dat u dit metafield wilt verwijderen?"
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "slug": {
        "label": "Slug"
      },
      "label": {
        "label": "Label"
      },
      "controlledField": {
        "label": "Overschrijf veld inschrijving",
        "info": "De hierin gevulde waarde overschrijft de default waarde van een inschrijving, abonnee. Momenteel word alleen amount ondersteund. In dat geval word de waarde van dit veld gebruikt voor het amount veld van de abonnee"
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "value": {
          "choice": "Keuzeveld",
          "text": "Vrij invulveld",
          "boolean": "Waar/onwaar",
          "multichoice": "Meerkeuze",
          "suggestions": "Suggesties"
        }
      },
      "valueType": {
        "label": "Value Type",
        "placeholder": "Value of this type",
        "value": {
          "text": "text",
          "integer": "integer",
          "float": "float",
          "boolean": "boolean",
          "date": "date",
          "datetime": "datetime"
        }
      },
      "description": {
        "label": "Beschrijving",
        "placeholder": "Description..."
      },
      "is_preset": {
        "label": "Preset",
        "placeholder": "Preset..."
      },
      "ordering": {
        "label": "Volgorde",
        "placeholder": "Volgorde..."
      },
      "showInConfirm": {
        "label": "Show in confirm",
        "placeholder": "Show in confirm..."
      },
      "required": {
        "label": "Required",
        "placeholder": "Required..."
      },
      "forTeamCsv": {
        "label": "Groeperen voor team csv?"
      },
      "default_values": {
        "label": "Standaard waarde",
        "placeholder": "Default..."
      },
      "metadata": {
        "amount": {
          "label": "Amount"
        },
        "label": {
          "label": "Label"
        },
        "value": {
          "label": "Waarde"
        },
        "default": {
          "label": "Default"
        }
      }
    }
  },
  "viaVia": {
    "edit": {
      "title": "Bewerk viaVia"
    },
    "editTooltip": "Vestiging bewerken",
    "approveButton": "Accorderen",
    "rejectButton": "Afwijzen",
    "nav": {
      "overview": "Via Via"
    },
    "unmatchButton": "Ontkoppelen",
    "showMatchModalButton": "Koppelen",
    "matchModal": {
      "title": "Handmatig matchen",
      "search": "Zoek naar sollicitant"
      },
    "page": {
      "overview": {
        "title": "Via via "
      }
    },
    "save": {
      "error": "Kon via via niet opslaan!",
      "success": "Via via is opgeslagen"
    },
    "detele": {
      "error": "Kon via via niet verwijderen!",
      "success": "Via via is verwijderde"
    },
    "match": {
      "error": "Kon match niet opslaan!",
      "success": "Match is opgeslagen"
    },
    "unmatch": {
      "error": "Kon unmatch niet opslaan!",
      "success": "Unmatch is opgeslagen"
    },
    "filter": {
      "search": {
        "label": "Zoek",
        "placeholder": "Naam..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "auto_matched": {
        "label": "AM?",
        "placeholder": "Automatch...",
        "title": "Automatch gematched?"
      },
      "firstName": {
        "label": "Voornaam",
        "placeholder": "Voornaam..."
      },
      "middleName": {
        "label": "Tussenvoegsel",
        "placeholder": "Tussenvoegsel..."
      },
      "fullName": {
        "label": "Voor-en achternaam",
        "placeholder": "Voor-en achternaam..."
      },
      "lastName": {
        "label": "Achternaam",
        "placeholder": "Achternaam..."
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "status": {
        "label": "Status",
        "placeholder": "Status...",
        "value": {
          "open": "Open",
          "matched": "Gekoppeld",
          "approved": "Afgehandeld"
        }
      },
      "phoneNumbers": {
        "label": "Telefoonnummer",
        "placeholder": "Telefoonnummer..."
      },
      "created_at": {
        "label": "Aangemaakt op",
        "placeholder": "Aangemaakt op..."
      },
      "branch": {
        "label": "Vestiging",
        "placeholder": "Vestiging..."
      },
      "job_application": {
        "label": "Sollicitant",
        "placeholder": "Sollicitant..."
      },
      "created_by": {
        "label": "Aangedragen door",
        "placeholder": "Aangedragen door..."
      }
    }
  },
  "companyVideo": {
    "field": {
      "id": {
        "label": "ID"
      },
      "ordering": {
        "label": "Volgorde",
        "placeholder": "Volgorde..."
      },
      "title": {
        "label": "Titel",
        "placeholder": "Titel..."
      },
      "description": {
        "label": "Omschrijving",
        "placeholder": "Omschrijving..."
      },
      "thumb": {
        "label": "Plaatje",
        "placeholder": "Plaatje..."
      },
      "open": {
        "label": "Publiek",
        "title": "Alle gebruikers mogen publieke videos zien."
      },
      "visibleToApplicants": {
        "label": "Zichtbaar voor sollicitanten",
      },
      "visibleToEmployees": {
        "label": "Zichtbaar voor medewerkers",
      },
      "video": {
        "label": "Video",
        "placeholder": "Video..."
      }
    }
  },
  "video": {
    "editTooltip": "Video bewerken",
    "createButton": "Video toevoegen",
    "nav": {
      "overview": "Video"
    },
    "overview": {
      "title": "Videos",
      "addButton": "Video toevoegen"
    },
    "edit": {
      "title": "Video aanpassen"
    },
    "create": {
      "title": "Video toevoegen"
    },
    "save": {
      "error": "Kon video niet opslaan!",
      "success": "Video is opgeslagen"
    },
    "delete": {
      "error": "Kon video niet verwijderen!",
      "success": "Video is verwijderd"
    },
    "filter": {
      "search": {
        "label": "Zoek",
        "placeholder": "Naam..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "ordering": {
        "label": "Volgorde",
        "placeholder": "Volgorde..."
      },
      "title": {
        "label": "Titel",
        "placeholder": "Titel..."
      },
      "description": {
        "label": "Omschrijving",
        "placeholder": "Omschrijving..."
      },
      "thumb": {
        "label": "Plaatje",
        "placeholder": "Plaatje..."
      },
      "open": {
        "label": "Publiek",
        "title": "Alle gebruikers mogen publieke videos zien."
      },
      "video": {
        "label": "Video",
        "placeholder": "Video..."
      }
    },
    "modal": {
      "delete": {
        "content": "Je staat op het punt om een video te verwijderen. Wilt u doorgaan?",
        "cancelButton": "Nee",
        "deleteButton": "Verwijderen"
      }
    }
  },
  "device": {
    "buttons": {
      "duplicate": "Duplicaten samenvoegen",
      "qrCode": "Qr code bekijken"
    },
    "logs": "Bekijk logs",
    "mergeButton": "Merge",
    "nav": {
      "overview": "Apparaten"
    },
    "overview": {
      "title": "Apparaten"
    },
    "modalMerge": {
      "title": "Kies duplicaat",
      "noDuplicatesFound": "Geen duplicaten gevonden"
    },
    "editTooltip": "Apparaat aanpassen",
    "historyTooltip": "Geschiedenis weergeven",
    "singular": "Apparaat",
    "createButton": "Apparaat toevoegen",
    "platform": {
      "ios": "ios",
      "android": "android"
    },
    "statuses": {
      "active": "Actief",
      "unknown": "Onbekend",
      "disabled": "Uitgeschakeld"
    },
    "save": {
      "error": "Kon apparaat niet opslaan!",
      "success": "Apparaat is opgeslagen"
    },
    "filter": {
      "search": {
        "label": "Zoek",
        "placeholder": "Apparaatcode, beschrijving..."
      },
      "branch": {
        "label": "Vestiging"
      },
      "status": {
        "label": "Status",
        "multiselect": {
          "selectedText": "# van # statussen",
          "noneSelectedText": "Selecteer status"
        },
        "multiselectFilter": {
          "placeholder": "Zoek..."
        }
      }
    },
    "field": {
      "identifier": {
        "label": "Apparaatcode"
      },
      "description": {
        "label": "Beschrijving",
        "placeholder": "Beschrijving..."
      },
      "status": {
        "label": "Status",
        "value": {
          "active": "Actief",
          "unknown": "Onbekend",
          "disabled": "Uitgeschakeld"
        }
      },
      "branch": {
        "label": "Hoofdvestiging",
        "placeholder": "Hoofdvestiging..."
      },
      "subBranches": {
        "label": "Vestiging",
        "placeholder": "Vestiging..."
      },
      "owner": {
        "label": "Eigenaar",
        "placeholder": "Eigenaar..."
      },
      "employee": {
        "label": "Medewerker"
      },
      "mode": {
        "label": "Modus"
      },
      "platform": {
        "label": "Platform"
      },
      "apiUrl": {
        "label": "CY_API_URL"
      },
      "referer": {
        "label": "CY_REFERER"
      },
      "websocketUrl": {
        "label": "CY_WEBSOCKET_URL"
      },
      "apiKey": {
        "label": "CY_API_KEY"
      },
      "certName": {
        "label": "CY_CERT_NAME"
      },
      "googleMapsApiKey": {
        "label": "CY_GOOGLE_MAPS_API_KEY"
      },
      "sentryDsn": {
        "label": "CY_SENTRY_DSN"
      },
      "deviceEndpoint": {
        "label": "CY_DEVICE_ENDPOINT"
      },
      "employeesForDebug": {
        "label": "CY_EMPLOYEES_FOR_DEBUG"
      },
      "featureCaptainsheetDamage": {
        "label": "Feature captainsheet damage aan?"
      },
      "featureRechtVanBezwaar": {
        "label": "Feature recht van bezwaar url?"
      },
      "deviceCode": {
        "label": "CY_DEVICE_CODE"
      },
      lastUsed: {
          label: "Laatst gebruikt",
          placeholder: "Laatst gebruikt..."
      },
    },
    "duplicate": {
      "error": "Apparaat is al toegewezen. Kies een ander apparaat."
    },
    "history": {
      "title": "Overzicht van gebruikers"
    },
    "total": {
      "label": "Totaal"
    },
  },
  "deviceLocation": {
    "field": {
      "moment": {
        "label": "Gemeten op"
      },
      "point": {
        "label": "Locatie",
        "address": "Adres"
      }
    }
  },
  "dashboard": {
    "nav": {
      "overview": "Dashboard"
    }
  },
  "position": {
    "field": {
      "startDate": {
        "label": "Start datum"
      },
      "job": {
        "label": "Functie"
      }
    }
  },
  "incedentalExpense": {
    "button": {
      "true": "Goedkeuren",
      "false": "Afkeuren"
    },
    "form": {
      "either": "Beide",
      "true": "Goedgekeurd",
      "false": "Afgekeurd"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "compensationAmount": {
        "label": "Waarde"
      },
      "employee": {
        "label": "Medewerker"
      },
      "month": {
        "label": "Datum"
      },
      "reason": {
        "label": "Reden"
      },
      "by": {
        "label": "Door"
      },
      "bulkEmployees": {
        "label": "Medewerkers met uitgave"
      },
      "approved": {
        "label": "Goedgekeurd"
      },
      "action": {
        "label": "Actie"
      },
      "approvedBy": {
        "label": "Gekeurd door"
      },
      "rejectionReason": {
        "label": "Reden van afkeuring"
      },
      "type": {
        "label": "Type",
        "value": {
          "gross": "Bruto",
          "net": "Neto"
        }
      },
      "category": {
        "label": "Categorie",
        "netCatagory": {
          "consumption": "Consumptie",
          "officeSupplies": "Kantoor artikelen",
          "travel": "Reizen",
          "accommodation": "Accommodatie",
          "carRelatedExpenses": "Tanken / Parkeren",
          "other": "Anders"
        },
        "grosCatagory": {
          "recruitment": "Recruitment",
          "bonus": "Bonus",
          "lead": "Lead",
          "other": "Anders",
          "headquarter": "Hoofdkantoor",
          "call_center": 'Callcenter',
        }
      },
      "receipt": {
        "modal": {
          "title": "Compensatie"
        },
        "label": "Bonnetje"
      }
    }
  },
  "factor": {
    "field": {
      "factor": {
        "label": "Factor"
      },
      "startDate": {
        "label": "Start datum"
      },
      "endDate": {
        "label": "Eind datum"
      },
      "reason": {
        "label": "Reden"
      },
      "by": {
        "label": "Door"
      },
      "action": {
        "label": "Actie"
      }
    }
  },
  "employee": {
    "walkinModal": {
      "title": "Sollicitant loopt binnen"
    },
    status: {
      missingDocument: "Mist document"
    },
    "isActive": "Gebruiker is geactiveerd",
    "isNotActive": "Gebruiker account nog niet geactiveerd, zie her-stuur activatie mail knop onderin scherm om opnieuw een email te versturen",
    "earning": {
      "buttonTitle": "Lonen",
      "modalTitle": "Loon over tijd",
      "field": {
        "earning": {
          "label": "Loon",
          "tag": "Loon"
        },
        "period": {
          "label": "Periode",
          "tag": "Periode"
        }
      }
    },
    "period": "Periode: ",
    "periodWeek": "Periode {{periodNumber}}: ",
    "creditations": "Creditatie",
    "incedentals": "Incidentele compensatie",
    "invoiceNumber": "Factuur nummer",
    "compensation": {
      "modalTitle": "Overige compensatie medewerker",
      "buttonTitle": "Compensatie medewerker",
      "bulkButtonTitle": "Bulk Compensaties aanmaken",
      "clearCompensation": "Compensatie op nul zetten",
      "saveCompensation": "Compensatie opslaan",
      "editCompensation": "Compensatie bewerken"
    },
    "factor": {
      "modalTitle": "Factor medewerker",
      "buttonTitle": "Factor medewerker",
      "clearCompensation": "Factor op nul zetten",
      "saveCompensation": "Factor opslaan",
      "editCompensation": "Factor bewerken"
    },
    "calls": {
      "modal": {
        "incoming": "Binnenkomende gesprekken",
        "outcoming": "Uitgaande gesprekken",
        "buttonTitle": "Telefoon gesprekken",
        "id": "ID",
        "caller": "Beller",
        "calledUser": "To",
        "start": "Start",
        "duration": "Duur",
        "voicelogs": "Voicelogs",
        "listenCall": "Gesprek na luisteren",
        "audioError": "Uw browser ondersteunt de audio tag niet."
      },
      "modalTitle": "Telefoongesprekken van medewerker"
    },
    "edit": {
      "title": "Bewerk medewerker",
      "interviewTitle": "Interviews",
      "callBackTitle": "Terugbelafspraken",
      "attachmentTitle": "Bijlages",
      "positionTitle": "Posities",
      intentionDeclaration:{
        download: "Int. decl.",
        french: "Frans",
        dutch: "Nederlands"
      },
      "disableSaveDR": "Je moet de voorkant en de achterkant van het ID uploaden voordat je kunt opslaan."
    },
    "modal": {
      "viewID": {
        "title": "Medewerker ",
        "toolTip": "ID medewerker",
        "noID": "Geen ID beschikbaar van deze medewerker"
      }
    },
    "actions": "Acties",
    "editTooltip": "Medewerker bewerken",
    "createButton": "Medewerker toevoegen",
    "attachment": "Bijlage",
    "attachmentLabel": "Bijlage",
    "attachmentEmpty": "Niet geupload",
    "uploadIDFirst": "Upload en bewaar eerst beide ID foto's.",
    "nav": {
      "applicants": "Solicitatie aanvragen",
      "interviews": "Solicitaties",
      "traineeships": "Traineeship vooruitgang",
      "overview": "Medewerkers"
    },
    "save": {
      "error": "Kon gebruiker niet opslaan!",
      "success": "Gebruiker is opgeslagen"
    },
    "filter": {
      "group": {
        "selectedText": "# van # groepen geselecteerd",
        "noneSelectedText": "Kies groepen",
        "placeholder": "Zoeken..."
      },
      "deleted": "Verwijderde medewerkers",
      "onlyDeleted": "Alleen verwijderd",
      "withoutDeleted": "Zonder verwijderd",
      "bothDeletedAntNot": "Alles"
    },
    "currentTraineeship": "Status traineeship",
    "showTraineeshipButton": "Laat traineeships zien",
    "showTraineeshipButtonTooltip": "Laat traineeships zien",
    "showUserButton": "Laat gebruiker zien",
    "showUserButtonTooltip": "Laat gebruiker zien",
    "sendAppInviteEmail": {
      "tooltip": "Stuur promoter app invite email",
      "success": "Email is verstuurd",
      "error": "Kon email niet versturen"
    },
    "rotateEmployeeAvatar": {
      "success": "Avatar is geroteerd",
      "error": "Kon avatar niet draaien"
    },
    "traineeship": {
      "approveButton": "Approve",
      "reset": {
        "title": "Reset",
        "confirm": "Weet je zeker dat je dit traineeship terug wil zetten naar de standaardinstellingen? Alle antwoorden worden verwijderd.",
      }
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "employee": {
        "label": "Promotor",
        "placeholder": "Promotor..."
      },
      "adres": {
        "label": "Address"
      },
      "last_sign_in": {
        "label": "Laatste werkdag"
      },
      "compensationAmount": {
        "label": "Incidentele financiële compensatie"
      },
      "firstName": {
        "label": "Voornaam",
        "placeholder": "Voornaam..."
      },
      "middleName": {
        "label": "Tussenvoegsel",
        "placeholder": "Tussenvoegsel..."
      },
      "lastName": {
        "label": "Achternaam",
        "placeholder": "Achternaam..."
      },
      "nationalityInternal": {
        "label": "Nationaliteit"
      },
      "externalContractSigned": {
        "label": "Contract buiten systeem getekend"
      },
      "dateOfBirth": {
        "label": "Geboortedatum"
      },
      "phoneNumbers": {
        "label": "Telefoonnummers",
        "placeholder": "Telefoonnummers..."
      },
      "name": {
        "label": "Naam",
        "placeholder": "Naam..."
      },
      "can_change_own_campaign": {
        "label": "Kan zelf campagne selecteren",
        "placeholder": "Kan zelf campagne selecteren..."
      },
      "factor": {
        "label": "Factor",
        "placeholder": "Factor..."
      },
      "avatar": {
        "label": "Profiel foto",
        "placeholder": "Profiel foto..."
      },
      "mainBranch": {
        "label": "Hoofdvestiging",
        "placeholder": "Kies hoofdvestiging..."
      },
      "main_branch": {
        "label": "Hoofdvestiging",
        "placeholder": "Kies hoofdvestiging..."
      },
      "branches": {
        "label": "Vestigingen",
        "placeholder": "Vestiging...",
        "selectedText": "# van # vestigingen",
        "noneSelectedText": "Selecteer vestiging"
      },
      "initials": {
        "label": "Initialen",
        "placeholder": "Initialen..."
      },
      "travelingDistance": {
        "label": "Reisafstand"
      },
      "first_name": {
        "label": "Voornaam",
        "placeholder": "Voornaam..."
      },
      "middle_name": {
        "label": "Tussenvoegsel",
        "placeholder": "Tussenvoegsel..."
      },
      "last_name": {
        "label": "Achternaam",
        "placeholder": "Achternaam..."
      },
      "gender": {
        "label": "Geslacht",
        "value": {
          "male": "Man",
          "female": "Vrouw",
          "other": "Anders",
          "unkown": "onbekend"
        }
      },
      "age": {
        "label": "Opgegeven leeftijd"
      },
      "date_of_birth": {
        "label": "Geboortedatum"
      },
      "phone_numbers": {
        "label": "Telefoonnummers",
        "placeholder": "Telefoonnummers..."
      },
      "ssn": {
        "label": "BSN",
        "placeholder": "BSN..."
      },
      "ssnBE": {
        "label": "Rijksregisternummer",
        "placeholder": "Rijksregisternummer..."
      },
      "isFreelance": {
        "label": "Zelfstandig ondernemer (ZZP'er)"
      },
      "companyName": {
        "label": "Bedrijfsnaam"
      },
      "dateStarted": {
        "label": "Startdatum"
      },
      "vatNumber": {
        "label": "BTW-ID"
      },
      "cocNumber": {
        "label": "KVK-nummer"
      },
      "referralSource": {
        "label": "Lead afkomstig van"
      },
      "country": {
        "label": "Land",
        "placeholder": "Land...",
        "value": {
          "NL": "NL",
          "BE": "BE",
          "FR": "FR",
          "DE": "DE",
          "OTHER": "Anders"
        }
      },
      "iban": {
        "label": "IBAN",
        "placeholder": "IBAN..."
      },
      "bic": {
        "label": "BIC",
        "placeholder": "BIC..."
      },
      "house_number": {
        "label": "Huisnummer",
        "placeholder": "Huisnummer..."
      },
      "houseNumber": {
        "label": "Huisnummer",
        "placeholder": "Huisnummer..."
      },
      "house_number_suffix": {
        "label": "Huisnummertoevoeging",
        "placeholder": "Huisnummertoevoeging..."
      },
      "houseNumberSuffix": {
        "label": "Huisnummertoevoeging",
        "placeholder": "Huisnummertoevoeging..."
      },
      "city": {
        "label": "Stad",
        "placeholder": "Stad..."
      },
      "city_of_residence": {
        "label": "Plaats"
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "street": {
        "label": "Straat",
        "placeholder": "Straat..."
      },
      "zip_code": {
        "label": "Postcode",
        "placeholder": "Postcode..."
      },
      "zipCode": {
        "label": "Postcode",
        "placeholder": "Postcode..."
      },
      "employee_number": {
        "label": "Personeelsnr",
        "placeholder": "Personeelsnr..."
      },
      "employeeNumber": {
        "label": "Personeelsnr",
        "placeholder": "Personeelsnr..."
      },
      "driving_licence": {
        "label": "Heeft rijbewijs",
        "placeholder": "Rijbewijs...",
        "value": {
          "false": "Nee",
          "true": "Ja"
        }
      },
      "public_transport_student_license": {
        "label": "Heeft studenten OV",
        "placeholder": "Studenten OV...",
        "value": {
          "false": "Nee",
          "true": "Ja"
        }
      },
      "publicTransportStudentLicenseType": {
        "label": "Type studenten OV",
        "value": {
          "no": "Geen",
          "week": "Week",
          "weekend": "Weekend"
        }
      },
      "drivingLicence": {
        "label": "Heeft rijbewijs",
        "placeholder": "Rijbewijs...",
        "value": {
          "false": "Nee",
          "true": "Ja"
        }
      },
      "canCall": {
        "label": "Is nabeller",
        "value": {
          "false": "Nee",
          "true": "Ja",
          "both": "Beide"
        }
      },
      "bank_card": {
        "label": "Bankpas",
        "placeholder": "Bankpas..."
      },
      "bankCard": {
        "label": "Bankpas",
        "placeholder": "Bankpas..."
      },
      "tax_exemption": {
        "label": "Heffingskorting",
        "placeholder": "Heffingskorting..."
      },
      "taxExemption": {
        "label": "Heffingskorting",
        "placeholder": "Heffingskorting..."
      },
      "can_change_tax_exemption": {
        "label": "Kan belastingvrijstelling veranderen?",
        "placeholder": "Kan belastingvrijstelling veranderen?"
      },
      "canChangeTaxExemption": {
        "label": "Kan belastingvrijstelling veranderen?",
        "placeholder": "Kan belastingvrijstelling veranderen?"
      },
      "contract_signature": {
        "label": "Contract",
        "placeholder": "Contract..."
      },
      "contractSignature": {
        "label": "Contract",
        "placeholder": "Contract..."
      },
      "identification": {
        "label": "ID kaart voorkant",
        "placeholder": "ID kaart voorkant..."
      },
      "identification_back": {
        "label": "ID kaart achterkant",
        "placeholder": "ID kaart achterkant..."
      },
      "identificationBack": {
        "label": "ID kaart achterkant",
        "placeholder": "ID kaart achterkant..."
      },
      "bw_id": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "bwID": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "city_of_birth": {
        "label": "Geboortestad",
        "placeholder": "Geboortestad..."
      },
      "cityOfBirth": {
        "label": "Geboortestad",
        "placeholder": "Geboortestad..."
      },
      "country_of_birth": {
        "label": "Geboorteland",
        "placeholder": "Geboorteland..."
      },
      "countryOfBirth": {
        "label": "Geboorteland",
        "placeholder": "Geboorteland..."
      },
      "id_type": {
        "label": "ID type",
        "placeholder": "ID type..."
      },
      "idType": {
        "label": "ID type",
        "placeholder": "ID type..."
      },
      "id_number": {
        "label": "ID number",
        "placeholder": "ID number..."
      },
      "idNumber": {
        "label": "ID number",
        "placeholder": "ID number..."
      },
      "interviewer": {
        "label": "Mag solicitaties uitvoeren / is manager",
        "title": "De werknemer kan dan gekozen worden in de lijst van interviewers voor sollicitatiegesprekken."
      },
      "canSelectOwnCampaign": {
        "label": "Mag zelf campagne selecteren",
        "info": "Deze freelance medewerker mag zelf kiezen voor welke campagnes hij of zij werft (Dit gebeurt op de field sales app)"
      },
      "coins": {
        "label": "Coins"
      },
      "blockedClients": {
        "label": "Geblokkeerde klanten"
      },
      "referralSourceOther": {
        "label": "Source anders"
      },
      "points": {
        "label": "Points"
      },
      status: {
        label: 'Status',
        value: {
          active: "Actief",
          inactive: "Inactief",
          outflow: "Uitstroom",
        }

      },
      "legalGuardianName": {
        "label": "Naam van wettelijke voogd"
      },
      "legalGuardianPhone": {
        "label": "Telefoon van wettelijke voogd"
      },
      "legalGuardianEmail": {
        "label": "E-mailadres van wettelijke voogd"
      }
    },
    "credits": "Credit",
    "anonymizeModal": {
      'title': 'Anonimiseren',
      'description': 'Weet u zeker dat u werknemer {{fullName}} wilt anonimiseren? Alle persoonlijke informatie wordt overschreven. Dit kan niet ongedaan worden gemaakt.',
      'anonymizeButton': 'Anonimiseren',
    }
  },
  "export": {
    "createButton": "Export Toevoegen",
    "editTooltip": "Resultaten Aanpassen",
    "exportTooltip": "Resultaten Exporteren",
    "save": {
      "error": "Kon definitie niet opslaan!",
      "success": "Definitie is opgeslagen"
    },
    "download": {
      "error": "Kon export niet downloaden!"
    },
    "value_null": {
      "null": "is gelijk aan null",
      "not_null": "is niet gelijk aan null"
    },
    "date_component_truncate": {
      "century": "eeuw",
      "day": "dag",
      "decade": "decenium",
      "dow": "dag van de week",
      "doy": "dag van het jaar",
      "epoch": "seconden sinds 1-1-1970 00:00:00 UTC",
      "hour": "uur",
      "isodow": "dag van de week (ISO)",
      "isoyear": "dag van het jaar (ISO)",
      "microseconds": "microseconde",
      "millenium": "millenium",
      "milliseconds": "milliseconde",
      "minute": "minuut",
      "month": "maand",
      "quarter": "kwartaal",
      "second": "seconde",
      "timezone": "tijdzone verschil van UTC",
      "timezone_hour": "uren verschil van UTC",
      "timezone_minute": "minuten verschil van UTC",
      "week": "week",
      "year": "jaar",
      "millennium": "millennium"
    }
  },
  "branch": {
    "textInputExample": "De beschikbare tijden voor deze vestiging in de interview plan popup bij aanvragen. Input per tijd eenheid. B.V. 10:00 AM of 01:30 PM",
    "interviewSlots": "Interview slots",
    "interviewTimes": "Per dag, in slots e.g. 10:00 AM",
    "createButton": "Vestiging toevoegen",
    "editTooltip": "Vestiging bewerken",
    "nav": {
      "overview": "Vestigingen"
    },
    "create": {
      "title": "Vestiging toevoegen"
    },
    "overview": {
      "createButton": "Vestiging toevoegen"
    },
    "edit": {
      "title": "Vestiging aanpassen"
    },
    "save": {
      "error": "Kon vestiging niet opslaan!",
      "success": "Vestiging is opgeslagen"
    },
    "filter": {
      "search": {
        "label": "Zoek",
        "placeholder": "Naam..."
      }
    },
    "field": {
      "mailjetTemplateWelcome": {
        "label": "Template ID welkom email",
        "info": "Het welkoms bericht dat nieuwe aanvragen van deze vestiging krijgen, een mailjet transactional id"
      },
      "mailjetTemplateActivation": {
        "info": "Het acccount activatie bericht dat nieuwe aanvragen/medewerkers van deze vestiging krijgen, een mailjet transactional id",
        "label": "Template ID activatie email"
      },
      "mailjetTemplatePasswordReset": {
        "info": "Het de wachtwoord reset template voor deze vestiging, een mailjet transactional id",
        "label": "Template ID wachtwoord reset email"
      },
      "mailjetTemplateAccountEdited": {
        "info": "De mailjet template van de mail die medewerkers krijgen als hun informatie in het systeem is aangepast, een mailjet transactional id",
        "label": "Template ID account aangepast email"
      },
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam",
        "placeholder": "Naam..."
      },
      "branch": {
        "label": "Vestiging",
        "placeholder": "Vestiging..."
      },
      "code": {
        "label": "Code",
        "placeholder": "Code..."
      },
      "avatar": {
        "label": "Avatar",
        "placeholder": "Avatar..."
      },
      "address": {
        "label": "Adres",
        "placeholder": "Adres..."
      },
      "phoneNumber": {
        "label": "Telefoonnummer",
        "placeholder": "Telefoonnummer..."
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "manager": {
        "label": "Manager",
        "placeholder": "Kies manager..."
      },
      "nmbrsCodeCostplace": {
        "label": "Nmbrs Code Costcenter",
        "placeholder": "Nmbrs Code Costcenter..."
      },
      "nmbrsCodeDepartment": {
        "label": "Nmbrs Code Department",
        "placeholder": "Nmbrs Code Department..."
      },
      "public": {
        "label": "Publiek?",
        "info": "Alle gebruikers mogen publieke vestingen zien.",
        "title": "Publiek?"
      }
    },
    "multiselect": {
      "placeholder": "Zoek vestiging...",
      "selectedText": "# van # vestigingen geselecteerd",
      "noneSelectedText": "Selecteer vestiging"
    }
  },
  "bwBranchTranslation": {
    "editTooltip": "Vestiging bewerken",
    "createButton": "Vestiging toevoegen",
    "nav": {
      "overview": "Vestigingen"
    },
    "create": {
      "title": "Vestiging aanmaken"
    },
    "overview": {
      "createButton": "Vestiging toevoegen",
      "title": "BW Vestigingen"
    },
    "edit": {
      "title": "Vestiging aanpassen"
    },
    "save": {
      "error": "Kon vestiging niet opslaan!",
      "success": "Vestiging is opgeslagen"
    },
    "filter": {
      "search": {
        "label": "Zoek",
        "placeholder": "Naam..."
      }
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam",
        "placeholder": "Naam..."
      },
      "officeCode": {
        "label": "Office Code"
      },
      "branch": {
        "label": "Vestiging",
        "placeholder": "Vestiging..."
      },
      "code": {
        "label": "Code",
        "placeholder": "Code..."
      },
      "avatar": {
        "label": "Avatar",
        "placeholder": "Avatar..."
      },
      "address": {
        "label": "Adres",
        "placeholder": "Adres..."
      },
      "phone_number": {
        "label": "Telefoonnummer",
        "placeholder": "Telefoonnummer..."
      },
      "email": {
        "label": "Email",
        "placeholder": "Email..."
      },
      "manager": {
        "label": "Manager",
        "placeholder": "Kies manager..."
      },
      "nmbrs_code_costplace": {
        "label": "Nmbrs Code Costcenter",
        "placeholder": "Nmbrs Code Costcenter..."
      },
      "nmbrs_code_department": {
        "label": "Nmbrs Code Department",
        "placeholder": "Nmbrs Code Department..."
      },
      "public": {
        "label": "Publiek?",
        "title": "Alle gebruikers mogen publieke vestingen zien."
      }
    },
    "multiselect": {
      "placeholder": "Zoek vestiging...",
      "selectedText": "# van # vestigingen geselecteerd",
      "noneSelectedText": "Selecteer vestiging"
    }
  },
  "exportArchive": {
    "title": "Export-archief",
    "download": "Download",
    "field": {
      "id": {
        "label": "ID"
      },
      "file": {
        "label": "Download"
      },
      "bundleDescription": {
        "label": "Export"
      },
      "definitionDescription": {
        "label": "Bestand"
      },
      "createdAt": {
        "label": "Geexporteerd op"
      }
    }
  },
  "bucket": {
    "save": {
      "error": "Kon bucket niet opslaan!",
      "success": "Bucket is opgeslagen"
    },
    "field": {
      "callers": {
        "label": "Nabellers",
        "placeholder": "Voeg beller toe..."
      }
    }
  },
  "call": {
    "dropdownError": "Stel de afspraakstatus in!",
    "resultOptions": {
      "reachedChanged": "Abonnement gewijzigd",
      "reachedThrough": "Abonnement doorvoeren",
      "reachedCancelled": "Abonnement geannuleerd",
      "reachedLater": "Abonnement later doorvoeren",
      "reachedCallback": "Terugbelfspraak (T.B.A.) gemaakt",
      "notReachedPhone": " Telefoonnummer onjuist/niet van klant",
      "notReachedCallBack": "Niet opgenomen, morgen nogmaals",
      "notReachedCallBackSoon": "Niet opgenomen, over vijf minuten nogmaals",
      "notReachedNoAnswer": "Niet opgenomen, doorzetten voor export"
    },
    "modal": {
      "anonymous": "callagent",
      "downloadButton": "Download voicelog"
    },
    "mobile": "Mobiel",
    "landLine": "Vast",
    "attempts": "Bel pogingen",
    "maxAttempts": "Max aantal",
    "statistics": {
      "label": "Statistics",
      "amount": "Aantal records",
      "target": "Te bereiken",
      "reached": "Bereikt",
      "percentage": "Bereik percentage",
      "not_reachable": "Niet bereikbaar",
      "cancelations": "Annulering",
      "not_reached": "Niet bereikt",
      "call_later": "Later terugbellen"
    },
    "toggleScanButton": {
      "show": "Laat scan zien",
      "hide": "Verberg scan"
    },
    "confirmModal": {
      "unsavedChanges": "De huidige afspraak bevat niet opgeslagen wijzigingen, weet je zeker dat je wilt wisselen?",
      "cancelSub": "Weet je zeker dat je deze inschrijving wil annuleren?",
      "callTomorrow": "Weet je zeker dat je deze inschrijving morgen terug wilt bellen?",
      "callFiveMin": "Weet je zeker dat je deze inschrijving over vijf minutes wil terugbellen?"
    },
    "newAppointment": {
      "modalHeader": "Nieuwe afspraak inplannen",
      "header": "Selecteer de datum en tijd van de nieuwe afspraak"
    }
  },
    "bwCampaignTranslation": {
      "nav": {
        "overview": "BwCampagnes"
      },
      "page": {
        "overview": {
          "title": "BW Campagne"
        }
      },
      "createButton": "Campagne toevoegen",
      "save": {
        "error": "Kon campagne niet opslaan!",
        "success": "Campagne is opgeslagen"
      },
      "field": {
        "id": {
          "label": "ID"
        },
        "branches": {
          "label": "Vestiging",
          "placeholder": "Vestiging...",
          "selectedText": "# van # vestigingen",
          "noneSelectedText": "Selecteer vestiging"
        },
        "campaign": {
          "label": "Campagne Incenova"
        },
        "importStatus": {
          "label": "Inschrijving import status"
        },
        "products": {
          "label": "Producten",
          "placeholder": "Producten...",
          "selectedText": "# van # producten",
          "noneSelectedText": "Selecteer product"
        },
        "accountDomain": {
          "label": "Account Domain B&W"
        },
        "projectCode": {
          "label": "Project Code B&W"
        },
        "name": {
          "label": "Naam",
          "placeholder": "Naam..."
        },
        "callScriptWelcome": {
          "label": "Belscript",
          "placeholder": "Belscript..."
        },
        "client": {
          "label": "Klant",
          "placeholder": "Klant..."
        },
        "draftContentbundle": {
          "label": "Draft contentbundle",
          "placeholder": "Draft contentbundle..."
        },
        "finalContentbundle": {
          "label": "Final contentbundle",
          "placeholder": "Final contentbundle..."
        },
        "unit": {
          "label": "Prijsnotatie"
        },
        "enableStartDate": {
          "label": "Gebruik startdatum"
        },
        "bwIds": {
          "label": "Briggs and walker id",
          "placeholder": "Briggs and walker id..."
        },
        "captainsheetContract": {
          "label": "Captainsheet contract",
          "placeholder": "Captainsheet contract..."
        }
      }
    },
  "campaign": {
    "editTooltip": "Item bewerken",
    "nav": {
      "overview": "Campagnes"
    },
    "page": {
      "overview": {
        "title": "Campagne"
      }
    },
    "createButton": "Campagne toevoegen",
    "save": {
      "error": "Kon campagne niet opslaan!",
      "success": "Campagne is opgeslagen"
    },
    "field": {
      "id": {
        "label": "ID"
      },
      "rexV2OutboundNumber": {
        "label": "Uitbel nummer Rex",
        "options": {
          "anonymous": "Anoniem"
        }
      },
      "wageStructure": {
        "label": "Actieve bonusstructuur",
        "info": "De bonusstructuur die momenteel wordt gebruikt voor de verloning van medewerkers die resultaten hebben die behoren tot deze campagne"
      },
      "branches": {
        "label": "Vestiging",
        "placeholder": "Vestiging...",
        "selectedText": "# van # vestigingen",
        "noneSelectedText": "Selecteer vestiging",
        "info": "Welke vestigingen deze campagne kunnen gebruiken, ZZP medewerkers kunnen ook op deze manier van campagne worden gelimiteerd via hun (hoofd) vestiging"
      },
      "products": {
        "label": "Producten",
        "placeholder": "Producten...",
        "selectedText": "# van # producten",
        "noneSelectedText": "Selecteer product"
      },
      "name": {
        "label": "Naam",
        "placeholder": "Naam..."
      },
      "captainsheetContractText": {
        "label": "Captainsheet contract"
      },
      "callScriptWelcome": {
        "label": "Belscript",
        "placeholder": "Belscript..."
      },
      "client": {
        "label": "Klant",
        "placeholder": "Klant..."
      },
      "draftContentbundle": {
        "label": "Draft contentbundle",
        "placeholder": "Draft contentbundle...",
        "info": "Als een tables in draft (test) modus staat wordt deze content bundle meegestuurd"
      },
      "finalContentbundle": {
        "label": "Final contentbundle",
        "placeholder": "Final contentbundle...",
        "info": "Als een tables in final (productie) modus staat wordt deze content bundle meegestuurd"
      },
      "unit": {
        "label": "Prijsnotatie",
        "info": "De periode van de kosten die op het registratie scherm van het product verschijnt, e.g. \"Per maand\", \"Per week\", \"Dagelijks\""
      },
      "rexUsername": {
        "label": "REX extension username",
        "info": "De gebruikersnaam van het specifieke telefoon nummer (uit rex V1) waarmee voor deze specifieke campagne uit gebeld moet worden"
      },
      "rexPassword": {
        "label": "REX extension password",
        "info": "het wachtwoord van het specifieke telefoon nummer (uit rex V1) waarmee voor deze specifieke campagne uit gebeld moet worden"
      },
      "enableStartDate": {
        "label": "Gebruik startdatum",
        "info": "Gebruik voor deze campagne het start datum limit zoals geconfigureerd op het bijbehorende product"
      },
      "bwIds": {
        "label": "Briggs and walker id",
        "placeholder": "Briggs and walker id..."
      },
      "enableEmergencySignature": {
        "label": "Accepteer handtekening als verificatie",
        "info": "Laat op het tablet registratie scherm de mogelijkheid zien om in plaats van sms verificatie handtekening verificatie te gebruiken"
      },
      "enableGenderOther": {
        "label": "Accepteer 'Anders' als geslacht",
        "info": "Laat op het tablet registratie scherm de mogelijkheid zien om als geslacht 'Anders' te gebruiken"
      },
      "requireFirstName": {
        "label": "Voornaam vereist",
        "info": "Vereis op het tablet registratie scherm dat de voornaam ingevuld wordt"
      },
      "defaultPaymentInterval": {
        "label": "Standaard frequentie",
        "info": "De standaard betaal frequentie die voor alle inschrijvingen van deze campagne worden gebruikt, mocht er door de werver/klant geen specifieke betaal periode zijn geselecteerd"
      },
      "sepa": {
        "label": "SEPA",
        "placeholder": "SEPA"
      },
      "configuration": {
        "label": "Configuratie",
      },
      "ageWildcards": {
        "label": "Jokers",
      },
    }
  },
  "shiftType": {
    "createButton": "Shift type toevoegen",
    "edit": {
      "title": "Shift type aanpassen"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "name": {
        "label": "Naam"
      },
      "description": {
        "label": "Beschrijving"
      },
      "isDefault": {
        "label": "Default?"
      },
      "baseWage": {
        "label": "Basis loon shift"
      }
    }
  },
    subscriptionReason: {
        createButton: 'Reden toevoegen',
        edit: {
            title: 'Reden bewerken',
        },
        field: {
            id: {
                label: 'ID',
            },
            code: {
                label: 'Code',
            },
            description: {
                label: 'Beschrijving',
            },
            type: {
                label: 'Type',
                value: {
                    cancelled: 'Geannuleerd',
                    completed: 'Klaar',
                    general: 'Algemeen',
                },
            },
        }
    },
  "csv": {
    "csvMissing": "Upload een csv",
    "createButton": "Csv uploaden",
    "field": {
      "id": {
        "label": "Id"
      },
      "createdAt": {
        "label": "Upload datum"
      },
      "updatedAt": {
        "label": "Laatste aanpassing"
      },
      "uploader": {
        "label": "Uploader"
      },
      "csv": {
        "label": "Bestand"
      },
      "type": {
        "label": "Type",
        "options": {
          "coin_addition": "Coin toevoeging",
          "external_sub_to_call": "Externe na te bellen inschrijving",
          "client_ref_nrs": "Klant referentienummer",
          "job_applications": "Sollicitaties",
          "azg_subscriptions": "AzG inschrijvingen",
        },
        "example": {
          "title": "Uitleg",
          "downloadExampleButton": "Download voorbeeld bestand",
          "coin_addition": "Het CSV bestand bestaat uit 2 kolommen: id van werknemer & aantal coins in gehele getallen. Zie ook het voorbeeld bestand waar 2 werkners in staan. De eerste regel is voor werknemer met id 1 die 25 coins erbij krijgt. De tweede regel is voor werknemer 2 die 13 coins erbij krijgt.",
          "external_sub_to_call": "Het CSV bestand bestaande uit minimaal 26 kolommen: external_id, status, employee_id, recruit_date, start_date, frequency, datetime_of_cancelation, date_of_birth, first_name, middle_name, last_name, gender, street, house_no, house_no_suffix, city, zipcode, country, email, mobile, landline, iban, product_id, campaign_id, amount, quantity, *custom. Zie het voorbeeld hieronder voor een csv bestand met daarin een inschrijving. De laatste kolom custom betekend dat iedere kolom na de 21e kolom als een zin in het opmerkingen veld van de inschrijving zal worden geplaatst.",
          "client_ref_nrs": "Het CSV bestand bestaat uit 2 kolommen: referentienummer en klant id.",
          "job_applications": "Het CSV bestand bestaat uit 8 kolommen: Aanmaakdatum, voornaam, tussenvoegel, achternaam, e-mail, telefoonnummer, source, source anders.",
          "azg_subscriptions": "The CSV file is unchanged format CSV form AzG. Columns which are processed are: id_werving, qual_id, opmerking, bedrag NIEUW, Frequentie NIEUW (columns number 7,9,16,18 respectively)."
        }
      },
      "status": {
        "label": "Status",
        "options": {
          "uploaded": "Ge-upload",
          "processed": "Verwerkt"
        }
      }
    }
  },
  "bwProductTranslation": {
    "edit": {
      "b&w": "B&W",
      "sms": "Incenova",
      "metafields": "Metafields"
    },
    "field": {
      "id": {
        "label": "id"
      },
      "bwCampaign": {
        "label": "B&W Campagne"
      },
      "bwProductId": {
        "label": "Briggs & Walker Id"
      },
      "accountDomain": {
        "label": "Account Domain B&W"
      },
      "projectCode": {
        "label": "Project Code B&W"
      },
      "productCode": {
        "label": "Product Code B&W"
      },
      "product": {
        "label": "Product Incenova"
      },
      "quantity": {
        "label": "Aantal"
      },
      "amount": {
        "label": "Prijs per stuk"
      }
    }
  },
  "ContentbundleTranslation": {
    "edit": {
      "Contentbundle": "Inschrijfmodule"
    },
    "field": {
      "id": {
        "label": "Id"
      },
      "name": {
        "label": "Naam"
      }
    }
  },
  "bwProductDefaultMetafieldValue": {
    "field": {
      "value": {
        "label": "Value"
      }
    }
  },
  "chapter": {
    "createButton": "Hoofdstuk toevoegen",
    "save": {
      "error": "Kon hoofdstuk niet opslaan!",
      "duplicateNameError": "Er bestaat al een hoofdstuk met deze naam!",
      "noNameError": "U heeft geen naam ingevuld!",
      "success": "Hoofdstuk is opgeslagen"
    },
    "delete": {
      "content": "Je staat op het punt om een hoofdstuk te verwijderen. Wilt u doorgaan?",
      "error": "Kon hoofdstuk niet verwijderen!",
      "success": "Hoofdstuk is verwijderd"
    }
  },
  "permission": {
    "showDescriptionButton": "Toon rechten omschrijving",
    "hideDescriptionButton": "Verberg rechten omschrijving",
    "allocation": {
      "modify_team": {
        "label": "Create, update and delete teams",
        "description": "Aan te passen naar alle vestigingen, ook al is de gebruiker niet lid van die vestiging."
      },
      "modify_team:branch": {
        "label": "Create, update and delete teams for own branch",
        "description": "Alleen aan te passen naar vestigingen waar de gebruiker lid van is."
      }
    }
  },
  "qrCodeGenerator": {
    "title": "Qr-code generator",
    "field": {
      "CY_API_URL": "CY_API_URL",
      "CY_REFERER": "CY_REFERER",
      "CY_WEBSOCKET_URL": "CY_WEBSOCKET_URL",
      "CY_API_KEY": "CY_API_KEY",
      "CY_CERT_NAME": "CY_CERT_NAME",
      "CY_GOOGLE_MAPS_API_KEY": "CY_GOOGLE_MAPS_API_KEY",
      "CY_SENTRY_DSN": "CY_SENTRY_DSN",
      "CY_DEVICE_ENDPOINT": "CY_DEVICE_ENDPOINT",
      "CY_EMPLOYEES_FOR_DEBUG": "CY_EMPLOYEES_FOR_DEBUG",
      "CY_DEVICE_CODE": "CY_DEVICE_CODE"
    }
  },
  "tooltips": {
    "delete": "Verwijderen",
    "restore": "Herstellen",
    "edit": "Bewerken",
    "view": "Bekijken",
    "rotate": "Draaien"
  },
  "bwImportFailure": {
    "field": {
      "trash": {
        "label": "Fouten verwijderen"
      },
      "id": {
        "label": "ID"
      },
      "bwSubscriptionId": {
        "label": "B&W inschrijving kenmerk"
      },
      "failure": {
        "label": "Fout"
      },
      "bwData": {
        "label": "Data"
      },
      "createdAt": {
        "label": "Fout moment"
      }
    }
  },
  "news": {
    "createButton": "Nieuws toevoegen",
    "saveAndPublishButton": "Opslaan & publiceren",
    "field": {
      "id": {
        "label": "ID"
      },
      "title": {
        "label": "Titel"
      },
      "image": {
        "label": "Image"
      },
      "status": {
        "label": "Status",
        "value": {
          "draft": "Concept",
          "published": "Gepubliceerd"
        }
      },
      "content": {
        "label": "Bericht",
        "preview": "Voorbeeld",
        "markdown": "Markdown opmaak"
      },
      "createdBy": {
        "label": "Aangemaakt door"
      },
      "createdAt": {
        "label": "Aangemaakt op"
      },
      "publishedBy": {
        "label": "Gepubliceerd door"
      },
      "publishedAt": {
        "label": "Gepubliceerd op"
      }
    }
  },
  "milestone": {
    "createButton": "Milestone toevoegen",
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam"
      },
      "description": {
        "label": "Description"
      },
      "points": {
        "label": "Points"
      },
      "icon": {
        "label": "Icoon"
      }
    }
  },
  "backbone": {
    "importer": {
      "employees": {
        "title": "Medewerkers & Vestigingen",
        "buttonTitle": "Medewerkers & Vestigingen importeren"
      },
      "batches": {
        "title": "Batches importeren",
        "buttonTitle": "Batches importeren"
      },
      "customer": {
        "title": "Klanten",
        "buttonTitle": "Klanten importeren"
      },
      "subscription": {
        "title": "Inschrijvingen",
        "buttonTitle": "Inschrijvingen importeren"
      },
      "databaseReset": {
        "title": "Reset database",
        "buttonTittle": "Reset DB"
      }
    }
  },
  "token": {
    "createButton": "Token maken",
    "field": {
      "id": {
        "label": "ID"
      },
      "token": {
        "label": "Token"
      },
      "createdAt": {
        "label": "Aangemaakt op"
      },
      "lastUsedAt": {
        "label": "Laatst gebruikt op"
      },
      "expiresAt": {
        "label": "Verloopt op"
      },
      "expired": {
        "label": "Verlopen"
      },
      "content": {
        "markdown": "Markdown opmaak"
      }
    }
  },
  "tip": {
    "overview": {
      "createButton": "Tip toevoegen"
    },
    "type": {
      "basic": "Algemeen",
      "new_employees": "Nieuwe medewerkers",
      "new_job_applications": "Nieuwe sollicitatieaanvragen"
    },
    "nav": {
      "overview": "Tips"
    },
    "field": {
      "id": {
        "label": "Id",
        "placeholder": "Id..."
      },
      "type": {
        "label": "Type",
        "placeholder": "Type...",
        "value": {
          "basic": "Standaard",
          "new_job_applications": "Nieuwe sollicitanten",
          "new_employees": "Nieuwe medewerkers"
        }
      },
      "text": {
        "label": "Tekst",
        "placeholder": "Tekst..."
      }
    },
    "create": {
      "title": "Tip toevoegen"
    },
    "edit": {
      "title": "Tip aanpassen"
    },
    "createButton": "Tip toevoegen",
    "editTooltip": "Item bewerken"
  },
  "history": {
    "modal": {
      "buttonTitle": "Geschiedenis",
      "subscription": {
        "title": "Geschiedenis van deze inschrijving"
      },
      "team": {
        "title": "Geschiedenis van dit team"
      }
    },
  },
  "clientFile": {
    "createButton": "Aanmaken klant bestand",
    "field": {
      "id": {
        "label": "ID"
      },
      "client": {
        "label": "Klant"
      },
      "name": {
        "label": "Bestands naam"
      },
      "createdAt": {
        "label": "Upload datum"
      },
      "deletionDate": {
        "label": "Verwijder datum"
      },
      "uploader": {
        "label": "Uploader"
      },
      "file": {
        "label": "Bestand"
      }
    }
  },
  "Appointment": {
    "toggleLegend": "legenda",
    "field": {
      "id": {
        "label": "ID"
      },
      "status": {
        "label": "Status",
        "value": {
          "queued": "Na te bellen",
          "assigned": "Toegewezen",
          "reached": "Bereikt",
          "not reachable": "Niet bereikbaar",
          "not reached": "Niet bereikt"
        },
        "legend": {
          "carryThrough": "Ongewijzigd doorgezet",
          "carryThroughChanged": "Gewijzigd doorgezet",
          "phoneIncorrect": "Telefoon incorrect",
          "notAnswered": "Niet opgenomen",
          "cancelled": "Geannuleerd",
          "callBackSoonNoTime": "Binnenkort terugbellen zonder afspraak",
          "callBackSoon": "Binnenkort terugbellen",
          "callBackNow": "Terugbel afspraak nu",
          "callBackToday": "Terugbel afspraak vandaag",
          "callBackFuture": "Terugbel afspraak andere dag"
        }
      },
      "remarks": {
        "label": "Opmerkingen",
        "value": {
          "": "",
          "changed": "Abonnement gewijzigd",
          "carry through": "Abonnement doorvoeren",
          "canceled": "Abonnement geannuleerd",
          "carry through later": "Abonnement later doorvoeren",
          "call appointment": "Terugbelfspraak (T.B.A.) gemaakt",
          "phonenumber not correct": "Telefoonnummer onjuist/niet van klant",
          "not answered call back": "Niet opgenomen, morgen nogmaals",
          "not answered call back soon": "Niet opgenomen, over vijf minuten nogmaals",
          "not answered": "Niet opgenomen, doorzetten voor export"
        }
      },
      "subscription": {
        "label": "Inschrijving"
      },
      "bucket": {
        "label": "Bucket"
      },
      "callAt": {
        "label": "Belafspraak"
      },
      "caller": {
        "label": "Nabeller"
      }
    }
  },
  "signedDocument": {
    "confirm": "Weet je zeker dat je het bestand {{fileName}} wilt verwijderen?",
    "notificationModel": "Uw document is opgeslagen"
  },
  "shiftWageDefinition": {
    "edit": {
      "title": "Loon definitie"
    },
    "modal": {
      "buttonTitle": "Loonregels Toevoegen",
      "modalTitle": "Loonregel definitie"
    },
    "field": {
      "name": {
        "label": "Loon definitie naam"
      },
      "startDate": {
        "label": "Ingangsdatum"
      }
    }
  },
  "shiftWageRule": {
    "field": {
      "age": {
        "label": "Leeftijd"
      },
      "factor": {
        "label": "Factor"
      }
    }
  },
  "jobFactor": {
    "field": {
      "startDate": {
        "label": "Ingangsdatum"
      },
      "factor": {
        "label": "Factor"
      }
    }
  },
  "alphaWage": {
    "field": {
      "id": {
        "label": "ID"
      },
      "employee": {
        "label": "Medewerker"
      },
      "date": {
        "label": "Datum"
      },
      "basePay": {
        "label": "Basisloon"
      },
      "factorPay": {
        "label": "Bonus"
      },
      "creditAmount": {
        "label": "Credits"
      },
      "finalPay": {
        "label": "Eindloon"
      }
    }
  },
  "alphaPeriodWage": {
    "field": {
      "id": {
        "label": "ID"
      },
      "employee": {
        "label": "Medewerker"
      },
      "startDate": {
        "label": "Periode"
      },
      "amountEarned": {
        "label": "Totaal"
      },
      "baseWage": {
        "label": "Basis"
      },
      "factorWage": {
        "label": "Factor"
      },
      "incidentalExpenses": {
        "label": "Incidentele compensatie"
      },
    }
  },
  "contentbundleFolder": {
    "field": {
      "id": {
        "label": "ID"
      },
      "name": {
        "label": "Naam"
      }
    }
  },
  "contentbundleCondition": {
    "field": {
      "product": {
        "label": "Product"
      },
      "metafield": {
        "label": "Metafield"
      },
      "metafieldValue": {
        "label": "Metafield waarde"
      }
    }
  },
  "contentbundleHitbox": {
    "field": {
      "nextScreen": {
        "label": "Ga naar het registratie scherm"
      },
      "slideshow": {
        "label": "Ga naar een andere folder"
      },
      "link": {
        "label": "Ga naar een website"
      },
      "product": {
        "label": "Zet het volgende product voor deze inschrijving"
      },
      "metafield": {
        "label": "Selecteer een metafield om een waarde voor te zetten"
      },
      "metafieldValue": {
        "label": "Zet de waarde voor dit metafield"
            },
      standardField:{
        'label': "Standaard waarde om te zetten",
        value: {
          'spokenLanguage': "Taal"
        }

      },
      standardFieldValue:{
        label: "Veld waarde voor de standaard waarde",
        spokenLanguageOptions: {
            value:{
            NL: 'Nederlands',
            FR: "Frans",
            DE: "Duits",
            EN: "Engels"
          }
        }
    }
  }
  },
  bwMetafieldTranslation: {
    field: {
      bwField: {
        label: "Briggs and Walker veld"
      },
      standardField: {
        label: "Incenova veld"
      }
    },
  },
  employeeSettings: {
    nav:{
      label: "Instellingen"
    },
    general:{
      settings: {
        label: "Instellingen"
      }
    },
    options: {
      referralSource: {
        label: "Source"
      },
      whatsappStatus: {
        label: "Whatsapp status"
      },
      employeeReason: {
        label: "Medewerker reden"
      },
    }
  },
  whatsappStatus:{
    create:{
      title: 'Whatsapp status toevoegen'
    },
    edit:{
    title: 'Whatsapp status bewerken'
    },
    field:{
      id: {
        label: "ID"
      },
      title: {
        label: "Naam"
      },
    },
    filter: {
      "deleted": "Verwijderde statussen",
      "onlyDeleted": "Alleen verwijderd",
      "withoutDeleted": "Zonder verwijderd",
      "bothDeletedAndNot": "Alles"
    }
  },
  employeeReason: {
    create:{
      title: 'Medewerker reden toevoegen'
    },
    edit:{
    title: 'Medewerker reden bewerken'
    },
    field:{
      id: {
        label: "ID"
      },
      name: {
        label: "Naam"
      },
      type:{
        label: "Type",
        value:{
          "rejection-company": "Afwijzing van bedrijf",
          "rejection-applicant": "Afwijzing van solicitant",
          general: "Algemeen",
        }
      }
    },
    filter: {
      "deleted": "Verwijderde redenen",
      "onlyDeleted": "Alleen verwijderd",
      "withoutDeleted": "Zonder verwijderd",
      "bothDeletedAndNot": "Alles"
    }
  },
  interview:{
      dropoutReasonModal:{
        title: 'Bevestig afwijzing {{name}}',
        confirm:{
          rejected: 'Om welke reden is {{name}} afgewezen?',
          'not-interested': 'Om welke reden is {{name}} niet geintresseerd?'
        }
      }
    },
  recruiterOverview: {
    week: 'Week {{number}}',
    client: 'Doel',
    brutto: 'Bruto',
    netto: 'Netto',
    cancelled: 'Uitval',
    registrationFee: 'Inschrijfbedrag',
    baseSalary: 'Basisloon',
    bonus: 'Bonus',
    total: 'Totaal',
    price: 'Prijs',
    cancelReason: 'Uitval reden',
    registrationDate: 'Inschrijfdatum',
    processingDate: 'Verwerkingsdatum',
    date: 'Datum',
    reason: 'Reden',
    amount: 'Hoeveelheid',
    totalAmount: 'Totaalprijs',
    totalBase: 'Basis',
    totalBonus: 'Bonus',
    creditations: 'Creditatie',
    incidentals: 'Incidentele compensatie',
    subtotalNoVAT: 'Subtotaal excl. BTW',
    vat: 'BTW',
    subtotalWithVAT: 'Subtotaal incl. BTW',
  }
}
