import CYLogo from "../../image/logo/cy.png";
import Logo from '../../image/logo/hn_logo.png'
import LoginBackground from '../../image/login-hn.jpg'
import loginLogo from '../../image/logo/hn_login2.png'
import hn_title from '../../image/logo/hn.png'

const COLOR_THIRD = '#585857' //grey
const COLOR_PRIMARY = '#EF3340' //red
const COLOR_SECONDARY = '#585857'
const COLOR_TEXT = 'rgba(0, 0, 0, 0.7)'
const COLOR_BUTTON_PRIMARY = 'rgba(255, 255, 255, 0.9)'
const TAB_TITLE = "Hot Networkz"
const MENU_TEXT_COLOR = 'rgba(255, 255, 255, 0.9)'


// Theme for re-cy-cle
// TODO: We should slowly refactor all variables to use the theme prop instead of the exported consts above.
export default {
    primaryColor: COLOR_PRIMARY,
    secondaryColor: COLOR_SECONDARY,
    thirdColor: COLOR_THIRD,
    buttonPrimaryColor: COLOR_PRIMARY,
    textColor: COLOR_TEXT,
    MENU_TEXT_COLOR: MENU_TEXT_COLOR,
    cyLogo: CYLogo,
    textLogo: hn_title,
    logo: loginLogo,
    sidebarLogo: Logo,
    loginBackground: LoginBackground,
    buttonPrimaryTextColor: COLOR_BUTTON_PRIMARY,
    loginBackgroundColor: COLOR_PRIMARY,
    sidebar: false,
    tabTitlePrefix: TAB_TITLE,
    lightColor: '#F5F5F5'
}
